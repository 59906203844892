import { Injectable } from '@angular/core';
import { FetchService } from '../../../core/services/fetch.service';
import { Condition, Insurance } from '../../../shared/model/carModels';
import { ZJCargoType } from '../../../core/config/dropdown.conf';
import { REQUEST } from '../../../core/config/linkMap';
import { RendererService } from '../../../core/services/renderer.service';

@Injectable({
  providedIn: 'root'
})
export class OrderInsuranceService {

  constructor(
    private fetchService: FetchService,
    private renderer: RendererService,
  ) { }

  get initBaseSearch(): { car_order_code, risk_start_date } {
    return {
      car_order_code: null,
      risk_start_date: []
    };
  }

  getSearchForm(model): Condition[] {
    return [
      {
        type: 1,
        label: '派车单号',
        value: model.car_order_code,
        key: 'car_order_code',
        placeholder: '输入派车单单号',
        width: '300px'
      },
      {
        type: 9,
        label: '上传时间',
        value: model.risk_start_date,
        key: 'risk_start_date',
      },
    ]
  }

  getExportForm(model): Condition[] {
    return [
      {
        type: 9,
        label: '下单时间',
        value: model.risk_start_date,
        key: 'risk_start_date',
      },
    ]
  }

  getLineOffFormForm(): Condition[] {
    return [
      {
        type: 11,
        label: '类型',
        value: null,
        key: 'addType',
        required: true,
        option: [
          { value: 1, name: '线上新增' },
          { value: 2, name: '线下新增' },
        ]
      }
    ]
  }

  getInsuranceForm(model: Insurance, area: any[], fun: () => void, changeType: (value) => void): Condition[] {
    return [
      {
        type: 1,
        label: '派车单',
        value: model.carOrderCode,
        key: 'dispatchOrder',
        required: true,
        placeholder: '请选择派车单',
        readOnly: true,
        click: fun
      },
      // {
      //   type: 1,
      //   label: '保单序号',
      //   value: !!model.insuranceId ? model.insuranceId : null,
      //   key: 'insuranceId',
      //   required: true,
      //   isHide: true,
      // },
      // {
      //   type: 1,
      //   label: '版本号',
      //   value: !!model.versionCode ? model.versionCode : null,
      //   key: 'versionCode',
      //   isHide: true,
      //   required: true,
      // },
      {
        type: 1,
        label: '派车单编号',
        value: model.dispatchOrderId,
        key: 'dispatchOrderId',
        required: true,
        placeholder: '请填写派车单编号'
      },
      // {
      //   type: 1,
      //   label: '车牌号',
      //   value: model.vno,
      //   key: 'vno',
      //   required: true,
      //   readOnly: true,
      //   placeholder: '请输入车牌号'
      // },
      // {
      //   type: 1,
      //   label: '货物名称',
      //   value: model.cargoName,
      //   key: 'cargoName',
      //   required: true,
      //   readOnly: true,
      //   placeholder: '请输入货物名称'
      // },
      // {
      //   type: 2,
      //   label: '车牌颜色',
      //   value: model.vc,
      //   key: 'vc',
      //   required: true,
      //   placeholder: '请输入车牌颜色',
      //   option: [
      //     {value: 1, name: '蓝色'},
      //     {value: 2, name: '黄色'},
      //   ]
      // },
      // {
      //   type: 2,
      //   label: '卸货方式',
      //   value: model.downType,
      //   key: 'downType',
      //   required: true,
      //   placeholder: '请选择',
      //   option: [
      //     {value: 1, name: '一车一卸'},
      //     {value: 2, name: '一车两卸'},
      //   ],
      //   change: changeType
      // },
      {
        type: 2,
        label: '货物分类',
        value: model.cargoType,
        key: 'cargoType',
        required: true,
        placeholder: '请选择货物分类',
        option: ZJCargoType
      },
      {
        type: 1,
        label: '保额',
        value: model.totalInsuredAmount,
        key: 'totalInsuredAmount',
        rules: ['isNumber'],
        required: true,
        placeholder: '请输入保额'
      },
      {
        type: 14,
        label: '开始时间：',
        value: model.riskStartDate,
        key: 'riskStartDate',
        rules: ['isDate'],
        required: true,
        placeholder: '请选择时间'
      },
      {
        type: 14,
        label: '结束时间：',
        value: model.riskEndDate,
        key: 'riskEndDate',
        rules: ['isDate'],
        required: true,
        placeholder: '请选择时间'
      },
      {
        type: 10,
        label: '起运地址',
        value: !!model.insuranceId ? this.renderer.mergeStart(model) : null,
        key: 'start',
        option: area,
        required: true,
        placeholder: '请选择地址'
      },
      {
        type: 10,
        label: '卸货地址1',
        value: !!model.insuranceId ? this.renderer.mergeEnd(model) : null,
        key: 'endOne',
        option: area,
        required: true,
        placeholder: '请选择地址'
      },
      {
        type: 10,
        label: '卸货地址2',
        value: !!model.insuranceId && !!model.transferPlaceAreaName ? this.renderer.mergeEndTwo(model) : null,
        key: 'endTwo',
        option: area,
        // required: true,
        placeholder: '请选择地址'
      },
      // {
      //   type: 10,
      //   label: '卸货地址2',
      //   value: !!model.insuranceId &&  !!model.downType &&  model.downType == 2 ? this.renderer.mergeEnd(model) : null,
      //   key: 'endTwo',
      //   option: area,
      //   isHide: !!model.downType &&  model.downType == 1,
      //   // required: true,
      //   placeholder: '请选择地址'
      // },
    ];
  }

  getOffInsuranceForm(model: Insurance, area: any[], changeType: (value) => void): Condition[] {
    return [
      {
        type: 1,
        label: '派车单号',
        value: model.carOrderCode,
        key: 'carOrderCode',
        required: true,
        placeholder: '请填写派车单号',
      },
      // {
      //   type: 1,
      //   label: '保单序号',
      //   value: !!model.insuranceId ? model.insuranceId : null,
      //   key: 'insuranceId',
      //   required: true,
      //   isHide: true,
      // },
      // {
      //   type: 1,
      //   label: '版本号',
      //   value: !!model.versionCode ? model.versionCode : null,
      //   key: 'versionCode',
      //   isHide: true,
      //   required: true,
      // },
      // {
      //   type: 1,
      //   label: '关联派车单号',
      //   value: model.dispatchOrderId,
      //   key: 'dispatchOrderId',
      //   required: true,
      //   placeholder: '请填写关联派车单号'
      // },
      {
        type: 1,
        label: '车牌号',
        value: model.vno,
        key: 'vno',
        required: true,
        placeholder: '请输入车牌号',
        rules: ['isPlateNum']
      },
      {
        type: 1,
        label: '货物名称',
        value: model.cargoName,
        key: 'cargoName',
        required: true,
        placeholder: '请输入货物名称'
      },
      // {
      //   type: 2,
      //   label: '车牌颜色',
      //   value: model.vc,
      //   key: 'vc',
      //   required: true,
      //   placeholder: '请输入车牌颜色',
      //   option: [
      //     {value: 1, name: '蓝色'},
      //     {value: 2, name: '黄色'},
      //   ]
      // },
      // {
      //   type: 2,
      //   label: '卸货方式',
      //   value: model.downType,
      //   key: 'downType',
      //   required: true,
      //   placeholder: '请选择',
      //   option: [
      //     {value: 1, name: '一车一卸'},
      //     {value: 2, name: '一车两卸'},
      //   ],
      //   change: changeType
      // },
      {
        type: 2,
        label: '货物分类',
        value: model.cargoType,
        key: 'cargoType',
        required: true,
        placeholder: '请选择货物分类',
        option: ZJCargoType
      },
      // {
      //   type: 1,
      //   label: '被保人名',
      //   value: model.insuranceApplicantName,
      //   key: 'insuranceApplicantName',
      //   required: true,
      //   placeholder: '请填写被保人名'
      // },
      // {
      //   type: 1,
      //   label: '被保人证件号',
      //   value: model.insuranceApplicantId,
      //   key: 'insuranceApplicantId',
      //   required: true,
      //   placeholder: '请输入被保人证件号'
      // },
      // {
      //   type: 1,
      //   label: '被保人手机号',
      //   value: model.insuranceApplicantMobile,
      //   key: 'insuranceApplicantMobile',
      //   required: true,
      //   placeholder: '请输入被保人手机号',
      //   rules: ['isPhone']
      // },
      {
        type: 1,
        label: '保额',
        value: model.totalInsuredAmount,
        key: 'totalInsuredAmount',
        rules: ['isNumber'],
        required: true,
        placeholder: '请输入保额'
      },
      {
        type: 14,
        label: '开始时间：',
        value: model.riskStartDate,
        key: 'riskStartDate',
        rules: ['isDate'],
        required: true,
        placeholder: '请选择时间'
      },
      {
        type: 14,
        label: '结束时间：',
        value: model.riskEndDate,
        key: 'riskEndDate',
        rules: ['isDate'],
        required: true,
        placeholder: '请选择时间'
      },
      {
        type: 10,
        label: '起运地址',
        value: !!model.insuranceId ? this.renderer.mergeStart(model) : null,
        key: 'start',
        option: area,
        required: true,
        placeholder: '请选择地址'
      },
      {
        type: 10,
        label: '卸货地址1',
        value: !!model.insuranceId ? this.renderer.mergeEnd(model) : null,
        key: 'endOne',
        option: area,
        required: true,
        placeholder: '请选择地址'
      },
      {
        type: 10,
        label: '卸货地址2',
        value: !!model.insuranceId && !!model.transferPlaceAreaName ? this.renderer.mergeEndTwo(model) : null,
        key: 'endTwo',
        option: area,
        // required: true,
        placeholder: '请选择地址'
      },
      // {
      //   type: 10,
      //   label: '卸货地址2',
      //   value: !!model.insuranceId &&  !!model.downType &&  model.downType == 2 ? this.renderer.mergeEnd(model) : null,
      //   key: 'endTwo',
      //   option: area,
      //   isHide: !!model.downType &&  model.downType == 1,
      //   // required: true,
      //   placeholder: '请选择地址'
      // },
    ];
  }

  splitStartAndEnd(body: Insurance): Insurance {
    const start = <string[]>body.start;
    const end = <string[]>body.endOne;
    console.log(end);

    if (start.length === 1) {
      body.carriageStartPlaceProvince = start[0].split('+')[0];
      body.carriageStartPlaceProvinceCode = start[0].split('+')[1];
      body.startAreaCode = body.carriageStartPlaceProvinceCode;
      body.startAreaName = body.carriageStartPlaceProvince;
      body.startAreaProvCode = body.carriageStartPlaceProvinceCode;
    }
    if (start.length === 2) {
      body.carriageStartPlaceProvince = start[0].split('+')[0];
      body.carriageStartPlaceProvinceCode = start[0].split('+')[1];
      body.carriageStartPlaceCity = start[1].split('+')[0];
      body.carriageStartPlaceCityCode = start[1].split('+')[1];
      body.startAreaProvCode = body.carriageStartPlaceProvinceCode + '-' + body.carriageStartPlaceCityCode;
      body.startAreaName = body.carriageStartPlaceProvince + '-' + body.carriageStartPlaceCity;
      body.startAreaCode = body.carriageStartPlaceCityCode;
    }
    if (start.length === 3) {
      body.carriageStartPlaceProvince = start[0].split('+')[0];
      body.carriageStartPlaceProvinceCode = start[0].split('+')[1];
      body.carriageStartPlaceCity = start[1].split('+')[0];
      body.carriageStartPlaceCityCode = start[1].split('+')[1];
      body.carriageStartPlaceCounty = start[2].split('+')[0];
      body.carriageStartPlaceCountyCode = start[2].split('+')[1];
      body.startAreaProvCode = body.carriageStartPlaceProvinceCode + '-' + body.carriageStartPlaceCityCode + '-' + body.carriageStartPlaceCountyCode;
      body.startAreaName = body.carriageStartPlaceProvince + '-' + body.carriageStartPlaceCity + '-' + body.carriageStartPlaceCounty;
      body.startAreaCode = body.carriageStartPlaceCityCode;
    }
    if (end.length === 1) {
      body.destinationProvince = end[0].split('+')[0];
      body.destinationProvinceCode = end[0].split('+')[1];
      body.finishAreaCode = body.destinationProvinceCode;
      body.finishAreaName = body.destinationProvince;
      body.finishAreaProvCode = body.destinationProvinceCode;
    }
    if (end.length === 2) {
      body.destinationProvince = end[0].split('+')[0];
      body.destinationProvinceCode = end[0].split('+')[1];
      body.destinationCity = end[1].split('+')[0];
      body.destinationCityCode = end[1].split('+')[1];
      body.finishAreaProvCode = body.destinationProvinceCode + '-' + body.destinationCityCode;
      body.finishAreaName = body.destinationProvince + '-' + body.destinationCity;
      body.finishAreaCode = body.destinationCityCode;
    }
    if (end.length === 3) {
      body.destinationProvince = end[0].split('+')[0];
      body.destinationProvinceCode = end[0].split('+')[1];
      body.destinationCity = end[1].split('+')[0];
      body.destinationCityCode = end[1].split('+')[1];
      body.destinationCounty = end[2].split('+')[0];
      body.destinationCountyCode = end[2].split('+')[1];
      body.finishAreaProvCode = body.destinationProvinceCode + '-' + body.destinationCityCode + '-' + body.destinationCountyCode;
      body.finishAreaName = body.destinationProvince + '-' + body.destinationCity + '-' + body.destinationCounty;
      body.finishAreaCode = body.destinationCityCode;
    }
    if (!!body.endTwo) {
      const endTwo = <string[]>body.endTwo;
      if (endTwo.length === 1) {
        body.destinationTwoProvince = endTwo[0].split('+')[0];
        body.destinationTwoProvinceCode = endTwo[0].split('+')[1];
        body.transferPlaceAreaName = endTwo[0].split('+')[0];
        body.transferPlaceAreaCode = endTwo[0].split('+')[1];
        body.transferPlaceAreaProvCode = endTwo[0].split('+')[1];
      }
      if (endTwo.length === 2) {
        body.destinationTwoProvince = endTwo[0].split('+')[0];
        body.destinationTwoProvinceCode = endTwo[0].split('+')[1];
        body.destinationTwoCity = endTwo[1].split('+')[0];
        body.destinationTwoCityCode = endTwo[1].split('+')[1];
        body.transferPlaceAreaProvCode = endTwo[0].split('+')[1] + '-' + endTwo[1].split('+')[1];
        body.transferPlaceAreaName = endTwo[0].split('+')[0] + '-' + endTwo[1].split('+')[0];
        body.transferPlaceAreaCode = endTwo[1].split('+')[1];
      }
      if (endTwo.length === 3) {
        body.destinationTwoProvince = endTwo[0].split('+')[0];
        body.destinationTwoProvinceCode = endTwo[0].split('+')[1];
        body.destinationTwoCity = endTwo[1].split('+')[0];
        body.destinationTwoCityCode = endTwo[1].split('+')[1];
        body.destinationTwoCounty = endTwo[2].split('+')[0];
        body.destinationTwoCountyCode = endTwo[2].split('+')[1];
        body.transferPlaceAreaProvCode = endTwo[0].split('+')[1] + '-' + endTwo[1].split('+')[1] + '-' + endTwo[2].split('+')[1];
        body.transferPlaceAreaName = endTwo[0].split('+')[0] + '-' + endTwo[1].split('+')[0] + '-' + endTwo[2].split('+')[0];
        body.transferPlaceAreaCode = endTwo[1].split('+')[1];
      }
    }

    delete body.start;
    delete body.endOne;
    delete body.endTwo;
    return body;
  }

  isTypeMin(model: Insurance): string[] {
    const messages = [];
    if ((model.cargoType === -1 || model.cargoType === 0) &&
      (model.totalInsuredAmount < 1 || model.totalInsuredAmount > 3000000)) {
      messages.push('煤炭/钢材/矿石或普通货物的保额范围为1~300万');
    }
    if ((model.cargoType === 4 || model.cargoType === 5) &&
      (model.totalInsuredAmount < 1 || model.totalInsuredAmount > 500000)) {
      messages.push('生鲜/粮食/化肥或易碎品的保额范围为1~50万');
    }
    return messages;
  }

  getArea(): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.fetchService.get(`${REQUEST.ORDER_INSUR.AREA}`, {}, (res: any) => {
        if (!res.isError) {
          const message = JSON.parse(res.message);
          resolve(message);
        }
        resolve([]);
      });
    });
  }

  exportout(params: any) {
    return new Promise<any[]>((resolve, reject) => {
      this.fetchService.get(`${REQUEST.INSURANCE.EXPORTLIST}`, params, (res: any) => {
        resolve(res);
      });
    });
  }
}
