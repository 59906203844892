import {Injectable, Injector} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {forkJoin} from 'rxjs';
import {BASE} from '../config/linkMap';
import {MessageService} from './message.service';
import {OAuthService} from 'angular-oauth2-oidc';
import {AuthenticationService} from './authentication.service';
import {NgxPermissionsService, NgxRolesService} from 'ngx-permissions';
import {authConfig} from '../config/auth';
import {User} from '../../shared/model/carModels';

export function StartupServiceFactory(startupService: StartupService) {
  return () => startupService.load();
}
@Injectable({
  providedIn: 'root'
})
export class StartupService {

  constructor(
    private http: HttpClient,
    private injector: Injector,
    private oAuthService: OAuthService,
    private authService: AuthenticationService,
    private roleService: NgxRolesService,
    private permService: NgxPermissionsService,
  ) { }

  getPermissions(operations: any[]): string[] {
    const permissions = operations.filter(item => item.appId === 20);
    // 组装设置按钮角色
    this.roleService.addRole('SETTING', () => {
      return permissions.length > 0
        && permissions[0].operIds.filter((id: number) => id === 200501000 || id === 200502000 || id === 200503000).length === 3;
    });
    return permissions.length > 0 ? permissions[0].operIds.map((id: number) => String(id)) : [];
  }

  load(): Promise<any> {
    this.oAuthService.configure(authConfig);
    this.oAuthService.setupAutomaticSilentRefresh();
    return new Promise((resolve, reject) => {
      this.roleService.addRole('GUEST', () => !this.oAuthService.hasValidAccessToken());
      const headers = new HttpHeaders()
        .set('Authorization', 'Bearer ' + this.oAuthService.getAccessToken())
        .set('Accept', 'application/x-spring-data-verbose+json');
      const user: User = this.authService.profile;
      if (!!user &&　this.oAuthService.hasValidAccessToken()) {
        forkJoin(
          this.http.get(`${BASE}/v1/users/${user.userId}/operations`, { headers }),
          this.http.get(`${BASE}/v1/users/${user.userId}/roles`, { headers }),
        ).subscribe(([data1, data2]) => {
          const permissions: string[] = this.getPermissions(data1['content']);
          this.permService.loadPermissions(permissions, (permissionName, permissionStore) => !!permissionStore[permissionName]);
          this.injector.get(MessageService).receive();
        },
        () => {},
        () => resolve(null)
        );
      } else {
        resolve(null);
      }
    });
  }
}
