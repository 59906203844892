import { Injectable } from '@angular/core';
import { freightType } from '../../../core/config/dropdown.conf';

@Injectable({
  providedIn: 'root'
})
export class CarChooseModalService {

  constructor() { }

  get getBaseIfo(): any {
    return [
      { key: 'owner', value: '车主' },
      { key: 'plateNum', value: '车牌号码' },
      { key: 'length', value: '车辆长度' },
      { key: 'capacityTonnage', value: '载重' },
      { key: 'vehicleWidth', value: '车宽' },
      { key: 'normalRoad', value: '长跑地区' },
      { key: 'operationCertificate', value: '道路运输许可证' },
      { key: 'vehcileLicenseCode', value: '行驶证号码' },
      { key: 'carType', value: '车辆类型' },
      { key: 'cypp', value: '承运车辆品牌' },
      { key: 'vehicleIdentityCode', value: '车架号' },
      { key: 'logisticsCompany', value: '挂靠物流公司' },
      { key: 'issuingOrganization', value: '行驶证发证机关' },
      { key: 'registerDate', value: '行驶证注册日期' },
      { key: 'issueDate', value: '行驶证发证日期' },
      { key: 'vehicleEnergyType', value: '车辆能源类型' },
      { key: 'roadTransportCertificateValidPeriodFrom', value: '道路运输证有效期自' },
      { key: 'roadTransportCertificateValidPeriodTo', value: '道路运输证有效期至' }
    ];
  }

  get getLicenseInfo(): any {
    return [
      { key: 'operationCertPic', value: '道路运输证' },
      { key: 'vehicleRegistration', value: '车主声明' },
      { key: 'frontPic', value: '车头照片' },
      { key: 'vehicleDriveLicenseHomepage', value: '车辆行驶证主页' },
      { key: 'vehicleDriveLicenseSecondPositivePage', value: '车辆行驶证副页正面' },
      { key: 'vehicleDriveLicenseBackPage', value: '车辆行驶证副页反面' },
      { key: 'vehicleAffiliation', value: '车辆挂靠协议' },
      { key: 'cyczpfj', value: '承运车辆照片' },
      { key: 'czsmgkfj', value: '企业声明' }
    ];
  }

  get goodsType(): any {
    return freightType;
  }
}
