import { Injectable } from '@angular/core';
import { Condition, orderFill } from 'src/app/shared/model/carModels';
import { REQUEST } from 'src/app/core/config/linkMap';
import { FetchService } from 'src/app/core/services/fetch.service';

@Injectable({
  providedIn: 'root'
})
export class DistManagementService {

  constructor(
    private fetchService: FetchService
  ) { }

  get getBaseInfo(): any {
    return [
      {key: 'carOrderCode', value: '派车单编号'},
      // {key: 'cargoAddrDetail', value: '发货地详细地址'},
      {key: 'sync', value: '订单类型'},
      {key: 'cargo', value: '货物实际重量'},
      {key: 'cargoAddress', value: '发货地详细地址'},
      {key: 'cargoType', value: '货物类型'},
      {key: 'cash', value: '现金金额'},
      {key: 'cname', value: '物流公司'},
      {key: 'consignee', value: '收货人信息'},
      {key: 'consigneePhoneNum', value: '收货人电话'},
      {key: 'contacts', value: '物流公司联系人'},
      {key: 'dcollection', value: '司机收款'},
      // {key: 'deliveryAddrDetail', value: '收货地详细地址'},
      {key: 'deliveryAddress', value: '收货地详细地址'},
      {key: 'diliveryTimeActual', value: '实际发货时间'},
      {key: 'etcMoney', value: 'ETC费用'},
      {key: 'goodsPropertyTypeCode', value: '货物分类'},
      {key: 'invoiceState', value: '开票状态'},
      {key: 'mobile', value: '司机电话'},
      {key: 'name', value: '司机姓名'},
      {key: 'orderCode', value: '订单编号'},
      {key: 'orderMoney', value: '运费金额'},
      {key: 'payState', value: '支付状态'},
      {key: 'phone', value: '物流公司电话'},
      {key: 'plateNum', value: '车牌号'},
      {key: 'prepayment', value: '预付费'},
      {key: 'refuelCardMoney', value: '油卡费用'},
      {key: 'state', value: '状态'},
      {key: 'sfzjdz', value: '司机身份证件地址'},
      {key: 'xbdm', value: '司机性别'},
      {key: 'mzdm', value: '司机民族'},
      {key: 'fzjg', value: '司机身份证发证机关'},
      {key: 'csrq', value: '司机出生日期'}
    ];
  }

  get getLicenseInfo(): any {
    return [
      {key: 'receiptPic', value: '回单照片(入库单)'},
      {key: 'unloadingPic', value: '卸货照片'},
      {key: 'pickupPic', value: '提货照片'}
    ];
  }

  get getImportOptionList(): any {
    return [
      {id:'200102003',name:'派车单批量修改-模板.xlsx',operateType:'批量修改',uploadurl:REQUEST.ORDER.BATCHEDIT, downloadurl: REQUEST.ORDER.DOWNLOAD + '派车单批量修改-模板.xlsx', hide: false},
      {id:'200102002',name:'派车单批量搜索-模板.xlsx',operateType:'批量搜索',uploadurl:REQUEST.ORDER.BATCHSEARCH, downloadurl: REQUEST.ORDER.DOWNLOAD + '派车单批量搜索-模板.xlsx', hide: false}
    ]
  }

  get gettyEvalInfo(): any {
    return [
      {key: 'speed', value: '您对运输速度是否满意'},
      {key: 'accuracy', value: '您对货物的数量规格单据准确性是否满意'},
      {key: 'driverAttitude', value: '您对承运人的服务态度是否满意'},
      {key: 'package', value: '您对到货时外包装情况是否满意'},
      {key: 'service', value: '您对物流公司业务人员的服务是否满意'}
    ]
  }

  get getdrEvalInfo(): any {
    return [
      {key: 'workerAttitude', value: '您对物流公司业务人员的服务是否满意'},
      {key: 'violationFee', value: '物流公司人员是否存在乱收费现象'},
      {key: 'cargoTime', value: '车辆等待装货时间'},
      {key: 'deliveryTime', value: '车辆等待卸货时间'}
    ]
  }

  exportout(params:any) {
    return new Promise<any[]>((resolve, reject) => {
      this.fetchService.get(`${REQUEST.DIST.EXPORT}`, params,(res:any) => {
        resolve(res);
      });
    });
  }
}
