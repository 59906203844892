import { Component } from '@angular/core';

@Component({
  selector: 'app-no-data',
  template: `<div style="display: flex;flex-direction: column;align-items: center;height: 100%;padding: 20px 0;" >
            <img src="./assets/img/no_data.png" alt="" style="width:200px;height:200px;margin-bottom: 20px;">
            <span>暂无相关数据</span>
            </div>`,
})
export class NoDataComponent {
  constructor() {
  }
}
