import { Component, OnInit, Input } from '@angular/core';
import { AmountPanel } from '../../model/carModels';

@Component({
  selector: 'app-amount-template',
  templateUrl: './amount-template.component.html',
  styleUrls: ['./amount-template.component.less']
})
export class AmountTemplateComponent implements OnInit {

  @Input() source: AmountPanel;
  constructor() { }

  ngOnInit() {
  }

}
