import { Injectable } from '@angular/core';
import {Condition} from '../../../../shared/model/carModels';
import { bankType, moneyunit } from '../../../../core/config/dropdown.conf';

@Injectable({
  providedIn: 'root'
})
export class TransferService {

  constructor() { }

  getTransForm(): Condition[] {
    return [
      {type: 19, label: '转账金额：', value: '', key: 'payment', rules: ['isNumber'], placeholder: '请输入', unit: {key: 'unit', value: '元', unitList: JSON.parse(JSON.stringify(moneyunit)), disabled: true}, required:true, min: 0.01, precision: 2},
      // {type: 1, label: '收款账号：', value: '1231231203239', key: 'corporateInAcc', required:true,disabled:true },
      // {type: 1, label: '收款户名：', value: '武威腾宇物流中心有限公司孟津分公司', key: 'corporateInName', required:true, disabled:true, width:'230px' },
      // {type: 1, label: '收款银行：', value: '中国银行股份有限公司孟津支行', key: 'bank', required:true, disabled:true, width:'200px'  },
      {type: 18, label: '选择银行：', value: '0104', key: 'bankCode', required: true,
        option: bankType
      },
      // {type: 3, value: false, key: 'isAgree', required: true, checkText:'我已了解：充值的款项只可用于消费，如需提现，系统只支持原路退回至付款账号。', urlText:'了解更多', click: () => {}}
    ];
  }

  get getBaseInfo(): any {
    return [
      {key: 'transferOrderCode', value: '转账订单号'},
      {key: 'payment', value: '转账金额'},
      {key: 'bankCode', value: '转账银行'},
      {key: 'companyName', value: '转账公司名'},
      {key: 'state', value: '转账状态'},
      {key: 'succDate', value: '到账时间'},
      {key: 'createUserName', value: '创建人姓名'},
      {key: 'createTime', value: '创建时间'},
      {key: 'updateUserName', value: '修改人姓名'},
      {key: 'updateTime', value: '修改时间'},
      {key: 'url', value: '银行支付地址'}
    ];
  }
}
