import { Component, OnInit, ContentChild, TemplateRef, Input } from '@angular/core';

@Component({
  selector: 'base-table-filter',
  template: '<ng-content> </ng-content>',
})
export class BaseTableFilterComponent implements OnInit {
  @Input() field = ''; // 对应域
  @ContentChild(TemplateRef) templateRef: TemplateRef<any>; // 模板

  constructor() {}

  ngOnInit() {}
}
