import { Injectable, TemplateRef, Renderer2, Inject, RendererFactory2 } from '@angular/core';
import { NzMessageService, NzModalService, NzModalRef } from 'ng-zorro-antd';
import { format } from 'date-fns';
// import { QueryGridComponent } from 'src/app/shared/components/query-grid/query-grid.component';
import { REQUEST } from '../config/linkMap';
import { FetchService } from './fetch.service';
import { Base, Condition } from 'src/app/shared/model/carModels';
import { DOCUMENT } from '@angular/common';
import { ExplainComponent } from 'src/app/shared/components/explain/explain.component';
import { PdfViewerComponent } from 'src/app/shared/components/pdf-viewer/pdf-viewer.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ImportFileComponent } from 'src/app/shared/components/import-file/import-file.component';
import { BatchOperateListComponent } from 'src/app/shared/components/batch-operate-list/batch-operate-list.component';


@Injectable({
  providedIn: 'root'
})
export class UtilService {
  private renderer2: Renderer2;
  constructor(
    private modalService: NzModalService,
    private message: NzMessageService,
    private fetchService: FetchService,
    private router: Router,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private doc: Document
  ) {
    this.renderer2 = rendererFactory.createRenderer(null, null);
   }

  /**
   * 确认框
   * @param title
   * @returns {Promise<any>}
   * @param info
   */
  submitConfirm(title: string, info = '',item?:Condition,val?:string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.modalService.confirm({
        nzTitle: title + '提示：',
        nzContent: '确定要' + title + info + '？',
        nzOkText: title,
        nzOkType: 'danger',
        nzOnOk: () => resolve(),
        nzOnCancel: () => {
          this.message.info(title + '操作取消');
          if(item){
            item.value = val;
          }
        }
      });
    });
  }

  /**
   * 时间格式化
   * @param value
   * @param formatString
   */
  dateFormat = (value, formatString: string = 'YYYY-MM-DD HH:mm:ss') => {
    return !!value ? format(value, formatString) : null;
  }

  /**
   * 地址map获取
   * @param url
   */
  getArea(url,param:object): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.fetchService.get(`${url}`, param, (res: any) => {
        if (res.code == 200) {
          resolve(res.data);
        }
        resolve([]);
      });
    });
  }

    /**
   * 获取下拉菜单
   * @param type
   * @returns {Array<any>}
   */
  getDic(type: string): Promise<any[]>{
    return new Promise<any[]>((resolve, reject) => {
      this.fetchService.get(`${REQUEST.DIC}?type=${type}`, {}, (res: any) => {
        if(!res.isError && res.data) {
          const data = res.data;
          resolve(data);
        }
        resolve([]);
      });
    });
  }
  
  /**
   * 下拉菜单转换option
   * @param type
   * @returns {Array<any>}
   */
  switchDic(type:string): any{
    const option = [];
    this.getDic(type).then((data: any) => {
      data.map(item => {
        const info = new Base();
        info.name = item.text;
        if(item.type === '0021' || item.type === '0017' || item.type === '0016'){
          info.value = item.value;
        } else {
          info.value = item.code;
        }
        if (item.text === '汽油' && item.type === '0004') {
          info.disabled = true;
        }
        if(item.text === '河南'){
          return;
        }
        option.push(info);
      });
    })
    return option;
  }

    /**
   * 弹框表格
   * @param title
   * @param titleAction
   * @param modal
   * @param queryGridParams
   * @param rowCheck
   * @returns {NzModalRef<QueryGridComponent>}
   */
  queryGridWin(
    title: string,
    modal: string | TemplateRef<{}>,
    // queryGridParams: any,
    rowCheck?: (e) => void
  ): NzModalRef {
    return this.modalService.create({
      nzTitle: title,
      nzContent: modal,
      nzWidth: 1200,
      nzComponentParams: {
        // queryGridParams,
        rowCheck
      },
      nzFooter: null,
      nzMaskClosable: false,
      nzClosable: true
    });
  }

  exportExcel(data?: any, qrUrl?:string): void {
    const download = this.renderer2.createElement('a');
    this.renderer2.setAttribute(download, 'id', 'downExcel');
    this.renderer2.setAttribute(download, 'download', '');
    this.renderer2.setAttribute(download, 'display', 'none');
    if(!!data){
      this.renderer2.setAttribute(download, 'href', REQUEST.EXPORT + data);
    } else {
      this.renderer2.setAttribute(download, 'href', qrUrl);
    }
    this.renderer2.appendChild(this.doc.body, download);
    download.click();
    this.renderer2.removeChild(this.doc.body, download);
  }

  jumpPage(data): void {
    const download = this.renderer2.createElement('a');
    this.renderer2.setAttribute(download, 'target', '_blank');
    this.renderer2.setAttribute(download, 'display', 'none');
    this.renderer2.setAttribute(download, 'href', data.ePolicyUrl);
    this.renderer2.appendChild(this.doc.body, download);
    download.click();
    this.renderer2.removeChild(this.doc.body, download);
  }

  getUrl(type){
    switch(type){
      case '00059':
        return REQUEST.PRE_DIST.HNADDRESS;
      case '00060':
        return REQUEST.PRE_DIST.TJADDRESS;
      case '00554':
        return REQUEST.PRE_DIST.JCADDRESS;
    }
  }
  
  /**
   * 二维码弹框
   * @param title
   * @param qrImgSrc
   * @returns {Promise<any>}
   */
  qrModal(title: string = '二维码', qrImgSrc: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const modal = this.modalService.create({
        nzTitle: title,
        nzContent: PdfViewerComponent,
        nzMaskClosable: false,
        nzClosable: true,
        nzComponentParams: {
          baseUrl: qrImgSrc,
          isqrcode: true
        },
        nzFooter:  [{
          label: '打印',
          type: 'primary',
          onClick: com => {
           com.iWindow.print();
          }
        }]
      });
    });
  }

  explain(data: any): void {
    this.modalService.create({
      nzTitle: data.title,
      nzContent: ExplainComponent,
      nzComponentParams: {
        text:{
          title: data.title,
          content: data.content.split('\n')
        }
      },
      nzMaskClosable: true,
      nzClosable: true,
      nzWidth: 600,
      nzFooter: null
    });
  }

    /**
   * 页面回退
   * @param value
   * @param route
   */
  back(value: number, route: ActivatedRoute): void {
    const backUrl = new Array<string>(value).fill('../').join('');
    this.router.navigate([backUrl], { relativeTo: route });
  }

  /**
   * 文件导入
   * @param title
   * @param list
   * @returns {Promise<any>}
   */
  // importFile(title: string = '文件上传', list: any, label?: string): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     const modal = this.modalService.create({
  //       nzTitle: title,
  //       nzContent: BatchOperateListComponent,
  //       nzMaskClosable: false,
  //       nzClosable: true,
  //       nzComponentParams: {
  //         list
  //       },
  //       nzFooter: null
  //       // [
  //       //   {
  //       //     label: !!label ? label :'确定',
  //       //     type: 'primary',
  //       //     // disabled:com => !com.fileList[0],
  //       //     onClick: com => {
  //       //       resolve(com);
  //       //       modal.close();
  //       //     }
  //       //   }
  //       // ]
  //     });
  //   });
  // }
}
