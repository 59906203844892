import { Injectable } from '@angular/core';
import { UtilService } from './util.service';
import { Insurance } from '../../shared/model/carModels';
import { ZJCargoType,logType, transType, bankType,newinvoiceState } from '../config/dropdown.conf';

@Injectable({
  providedIn: 'root'
})
export class RendererService {

  constructor(
    private util: UtilService
  ) { }

  isBoolean = (params) => {
    return params.value ? '是' : '否';
  }

  dateFormat(formatString: string) {
    return (params) => this.util.dateFormat(params.value, formatString);
  }

  rendererAddress(type: 'start' | 'end' | 'ssdq') {
    if (type === 'start') {
      return (params) => Array.from(new Set(this.mergeStart(params.data).map(item => item.split('+')[0]))).join(' ');
    } else if(type === 'ssdq'){
      return (params) => this.renderAddress(params.data);
    }else {
      return (params) => Array.from(new Set(this.mergeEnd(params.data).map(item => item.split('+')[0]))).join(' ');
    } 
  }

  mergeStartPre(model: any): string[] {
    const value1 = model.carriageStartPlaceProvince + '+' + model.carriageStartPlaceProvinceCode;
    const value2 = model.carriageStartPlaceCity + '+' + model.carriageStartPlaceCityCode;
    const value3 = model.carriageStartPlaceCounty + '+' + model.carriageStartPlaceCountyCode;
    if (!model.carriageStartPlaceCity) {
      return [value1];
    } else if (!!model.carriageStartPlaceCity && !!model.carriageStartPlaceCounty) {
      return [value1, value2, value3];
    } else if (!model.carriageStartPlaceCounty) {
      return [value1, value2];
    }
  }

  mergeEndPre(model: any): string[] {
    const value1 = model.destinationProvince + '+' + model.destinationProvinceCode;
    const value2 = model.destinationCity + '+' + model.destinationCityCode;
    const value3 = model.destinationCounty + '+' + model.destinationCountyCode;
    if (!model.destinationCity) {
      return [value1];
    } else if (!!model.destinationCity && !!model.destinationCounty) {
      return [value1, value2, value3];
    } else if (!model.destinationCounty) {
      return [value1, value2];
    }
  }

  mergeEndTwoPre(model: any): string[] {
    const value1 = model.destinationTwoProvince + '+' + model.destinationTwoProvinceCode;
    const value2 = model.destinationTwoCity + '+' + model.destinationTwoCityCode;
    const value3 = model.destinationTwoCounty + '+' + model.destinationTwoCountyCode;
    if (!model.destinationTwoCity) {
      return [value1];
    } else if (!!model.destinationTwoCity && !!model.destinationTwoCounty) {
      return [value1, value2, value3];
    } else if (!model.destinationTwoCounty) {
      return [value1, value2];
    }
  }

  mergeStart(model: any): string[] {
    const addressCodeArr = model.startAreaProvCode.split('-');
    const addressNameArr = model.startAreaName.split('-');
    const value1 = addressNameArr[0] + '+' + addressCodeArr[0];
    const value2 = addressNameArr[1] + '+' + addressCodeArr[1];
    const value3 = addressNameArr[2] + '+' + addressCodeArr[2];
    if (!addressNameArr[1]) {
      return [value1];
    } else if (!!addressNameArr[1] && !!addressNameArr[2]) {
      return [value1, value2, value3];
    } else if (!addressNameArr[2]) {
      return [value1, value2];
    }
  }

  mergeEnd(model: any): string[] {
    const addressCodeArr = model.finishAreaProvCode.split('-');
    const addressNameArr = model.finishAreaName.split('-');
    const value1 = addressNameArr[0] + '+' + addressCodeArr[0];
    const value2 = addressNameArr[1] + '+' + addressCodeArr[1];
    const value3 = addressNameArr[2] + '+' + addressCodeArr[2];
    if (!addressNameArr[1]) {
      return [value1];
    } else if (!!addressNameArr[1] && !!addressNameArr[2]) {
      return [value1, value2, value3];
    } else if (!addressNameArr[2]) {
      return [value1, value2];
    }
  }

  mergeEndTwo(model: any): string[] {
    const addressCodeArr = model.transferPlaceAreaProvCode.split('-');
    const addressNameArr = model.transferPlaceAreaName.split('-');
    const value1 = addressNameArr[0] + '+' + addressCodeArr[0];
    const value2 = addressNameArr[1] + '+' + addressCodeArr[1];
    const value3 = addressNameArr[2] + '+' + addressCodeArr[2];
    if (!addressNameArr[1]) {
      return [value1];
    } else if (!!addressNameArr[1] && !!addressNameArr[2]) {
      return [value1, value2, value3];
    } else if (!addressNameArr[2]) {
      return [value1, value2];
    }
  }

  rendererCargoType (params) {
    const state = ZJCargoType.filter(item => item.value.toString() === params.value);
    return state.length > 0 ? state[0].name : '';
  }

  rendererLogType(params) {
    const state = logType.filter(item => item.value === params.value);
    return state.length > 0 ? state[0].name : '';
  }

  rendererTransType(params) {
    const state = transType.filter(item => item.value === params.value);
    return state.length > 0 ? state[0].name : '';
  }

  rendererBankType(params) {
    const state = bankType.filter(item => item.value === params.value);
    return state.length > 0 ? state[0].name : '';
  }

  rendererBillingType(params) {
    const state = newinvoiceState.filter(item => item.value === params.value);
    return state.length > 0 ? state[0].name : '';
  }

  renderAddress(model:any) {
    let ssdqStr =  '';
    model.ssdq.split('_').map(item => {
      ssdqStr += item.split('+')[1];
      
    });
    return ssdqStr;
  }

  mergeAddress(model: any, key:string): string[] {
    // for (let key in model) {
      let parts = [];
      if (model[key]){
        parts = model[key].split('_');
        if(parts[0] === parts[1]){
          return [parts[0]]
        }else{
          return parts;
        }
      }
    // }
  }
}
