import { Injectable } from '@angular/core';
import { REQUEST } from 'src/app/core/config/linkMap';
import { FetchService } from 'src/app/core/services/fetch.service';

@Injectable({
  providedIn: 'root'
})
export class DetailInfoService {

  constructor(
    private fetchService: FetchService
  ) { }

  get getDriverBaseInfo(): any {
    return [
      {key: 'name', value: '姓名'},
      {key: 'idNum', value: '身份证号码'},
      {key: 'identityStartDate', value: '身份证发证日期'},
      {key: 'identityEndDate', value: '身份证截止日期'},
      {key: 'identityForever', value: '身份证是否永久'},
      {key: 'mobile', value: '手机号'},
      {key: 'licenseType', value: '驾照类型'},
      {key: 'driverLicence', value: '驾照号码'},
      {key: 'licenseFirstDetDate', value: '首次签发日期'},
      {key: 'licenseStartDate', value: '有效期开始日期'},
      {key: 'licenseEndDate', value: '有效期结束日期'},
      {key: 'taxpayerName', value: '纳税人姓名'},
      {key: 'taxpayerMobile', value: '纳税人手机号码'},
      {key: 'taxpayerIdentity', value: '纳税人身份证号码'},
      {key: 'bankName', value: '纳税人开户行'},
      {key: 'bankAccout', value: '纳税人银行卡号'},
      {key: 'issuingOrganization', value: '驾驶证签发机关'},
      {key: 'rtqcNum', value: '道路从业资格证号'},
      {key: 'provinceCode', value: '从业资格证所属省份'},
      {key: 'qualificationCertificateValidPeriodFrom', value: '从业资格证有效期自'},
      {key: 'qualificationCertificateValidPeriodTo', value: '从业资格证有效期至'},
      {key: 'sfzjdz', value: '司机身份证件地址'},
      {key: 'xbdm', value: '司机性别'},
      {key: 'mzdm', value: '司机民族'},
      {key: 'fzjg', value: '司机身份证发证机关'},
      {key: 'csrq', value: '司机出生日期'}
    ];
  }

  get getDriverLicenseInfo(): any {
    return [
      {key: 'identityPicFront', value: '身份证正面'},
      {key: 'identityPicBack', value: '身份证反面'},
      {key: 'driverLicencePicMain', value: '驾驶证正面'},
      {key: 'driverLicencePicSecond', value: '驾驶证反面'},
      {key: 'taxpayerIdentityPic', value: '纳税人身份证正面'},
      {key: 'rtqcPic', value: '道路从业资格证正面'}
    ];
  }

  get getCarBaseInfo(): any {
    return [
      { key: 'owner', value: '车主' },
      { key: 'plateNum', value: '车牌号码' },
      { key: 'length', value: '车辆长度' },
      { key: 'capacityTonnage', value: '载重' },
      { key: 'vehicleWidth', value: '车宽' },
      { key: 'normalRoad', value: '长跑地区' },
      { key: 'operationCertificate', value: '道路运输许可证' },
      { key: 'vehcileLicenseCode', value: '行驶证号码' },
      { key: 'carType', value: '车辆类型' },
      { key: 'cypp', value: '承运车辆品牌' },
      { key: 'vehicleIdentityCode', value: '车架号' },
      { key: 'logisticsCompany', value: '挂靠物流公司' },
      { key: 'issuingOrganization', value: '行驶证发证机关' },
      { key: 'registerDate', value: '行驶证注册日期' },
      { key: 'issueDate', value: '行驶证发证日期' },
      { key: 'vehicleEnergyType', value: '车辆能源类型' },
      { key: 'roadTransportCertificateValidPeriodFrom', value: '道路运输证有效期自' },
      { key: 'roadTransportCertificateValidPeriodTo', value: '道路运输证有效期至' }
    ];
  }

  get getCarLicenseInfo(): any {
    return [
      { key: 'operationCertPic', value: '道路运输证' },
      { key: 'vehicleRegistration', value: '车主声明' },
      { key: 'frontPic', value: '车头照片' },
      { key: 'vehicleDriveLicenseHomepage', value: '车辆行驶证主页' },
      { key: 'vehicleDriveLicenseSecondPositivePage', value: '车辆行驶证副页正面' },
      { key: 'vehicleDriveLicenseBackPage', value: '车辆行驶证副页反面' },
      { key: 'vehicleAffiliation', value: '车辆挂靠协议' },
      { key: 'cyczpfj', value: '承运车辆照片' },
      { key: 'czsmgkfj', value: '企业声明' }
    ];
  }
}
