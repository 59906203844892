import { Component, OnInit, Output } from '@angular/core';
import { REQUEST } from '../../core/config/linkMap';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';
import { FetchService } from '../../core/services/fetch.service';
import { AuthenticationService } from '../../core/services/authentication.service';
import { DRIVER_INFO, CAR_INFO, ACCOUNT_INFO } from '../../core/config/pageConfig';
import { Validation } from '../../shared/utils/validation';
import { Condition } from '../../shared/model/carModels';
import { getCond, convert, dateFormat, dateFormat1, deepClone } from '../../shared/utils/adapter';
import { UtilService } from 'src/app/core/services/util.service';
import { ArrayService } from 'src/app/core/services/array.service';

@Component({
  selector: 'app-driver-info-complete',
  templateUrl: './driver-info-complete.component.html',
  styleUrls: ['./driver-info-complete.component.less']
})
export class DriverInfoCompleteComponent implements OnInit {

  // 下面两个字段需要零时保存并返回给服务端
  contractNo: string;
  taxpayerId: number;
  signcontractVerify: string;

  isShowCond = false;
  isDriverShowUpload = false;
  isCarShowUpload = false;
  driverId: string;
  userIdCopy: string;
  carId: string;
  idNum: string;
  step: number;
  skipTime: number;
  status: string;
  errorMsg: string;

  driverFrontPC = {displayPath: '', path: ''};
  driverBackendPC = {displayPath: '', path: ''};
  lisencePC = {displayPath: '', path: ''};
  lisenceBackendPC = {displayPath: '', path: ''};
  vehicleLicenseMainPC = {displayPath: '', path: ''};
  vehicleLicenseFrontPC = {displayPath: '', path: ''};
  vehicleLicenseBackendPC = {displayPath: '', path: ''};
  verhicalRoad = {displayPath: '', path: ''};
  verhicalDock = {displayPath: '', path: ''};
  verhicalRegistration  = {displayPath: '', path: ''};
  taxpayerIdentityPic = {displayPath: '', path: ''};
  rtqcPic = {displayPath: '', path: ''};
  carrierPC = {displayPath: '', path: ''};
  personAttachmentPC = {displayPath: '', path: ''};
  frontPC = {displayPath: '', path: ''};

  errorMsgForDriver: string[];
  errorMsgForCar: string[];

  conditionForDriver: Condition[];
  conditionForCar: Condition[];
  conditionForAccount: Condition[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fetchService: FetchService,
    private authService: AuthenticationService,
    private util: UtilService,
    private message: NzMessageService,
    private arrayService: ArrayService) {
    this.step = 0;
    this.skipTime = 5;
    this.conditionForDriver = JSON.parse(JSON.stringify(DRIVER_INFO));
    this.conditionForCar = JSON.parse(JSON.stringify(CAR_INFO));
    this.getAddress();
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.driverId = params.get('id');
      if (this.driverId) {
        this.fetchService.get(REQUEST.DRIVER.UPDATE + this.driverId, {}, (res: any) => {
          let idNumValue = '';
          this.userIdCopy = res.userId;
          this.contractNo = res.contractNo;
          this.signcontractVerify = res.signcontractVerify;
          this.taxpayerId = res.taxpayerId;
          this.conditionForDriver.forEach((field) => {
            if (field.dValue) {
              field.value = res[field.dValue];
            }
            const key = field.key;
            if (key === 'identityForever') {
              field.value = res[key] + '';
            } else if (field.type === 4 && res[key]) {
              field.value = new Date(res[key]);
            }  else {
              field.value = res[key] ? res[key] : field.value ;
            }
            if (key === 'idNum') {
              idNumValue = res[key];
            }
          });
          const driverLicence = getCond(DRIVER_INFO, 'driverLicence');
          driverLicence.value = idNumValue;
          this.driverId = res['driverId'];
          // 设置上传图片默认展示url
          if (res.identityPicFront) {
            this.driverFrontPC.displayPath = REQUEST.DISPLAY_PIC_BASE + res.identityPicFront;
            this.driverFrontPC.path = res.identityPicFront;
          }
          if (res.identityPicBack) {
            this.driverBackendPC.displayPath = REQUEST.DISPLAY_PIC_BASE + res.identityPicBack;
            this.driverBackendPC.path = res.identityPicBack;
          }
          if (res.driverLicencePicMain) {
            this.lisencePC.displayPath = REQUEST.DISPLAY_PIC_BASE + res.driverLicencePicMain;
            this.lisencePC.path = res.driverLicencePicMain;
          }
          if (res.driverLicencePicSecond) {
            this.lisenceBackendPC.displayPath = REQUEST.DISPLAY_PIC_BASE + res.driverLicencePicSecond;
            this.lisenceBackendPC.path = res.driverLicencePicSecond;
          }
          if (res.taxpayerIdentityPic) {
            this.taxpayerIdentityPic.displayPath = REQUEST.DISPLAY_PIC_BASE + res.taxpayerIdentityPic;
            this.taxpayerIdentityPic.path = res.taxpayerIdentityPic;
          }
          if (res.rtqcPic) {
            this.rtqcPic.displayPath = REQUEST.DISPLAY_PIC_BASE + res.rtqcPic;
            this.rtqcPic.path = res.rtqcPic;
          }
          this.isShowCond = true;
          this.isDriverShowUpload = true;
        });
      }
    });
  }

  getAddress() {
    const option = this.util.switchDic('0021');
    this.conditionForDriver.map((item,index) => {
      if(item.key == 'provinceCode'){
        this.conditionForDriver[index].option = option;
      }
    });
  }

  submitStep1() {
    /*对于身份证是否永久条件会关联截止日期的选择与否*/
    const isIdentityForever = getCond(this.conditionForDriver, 'identityForever');
    const isIdentityEndDate = getCond(this.conditionForDriver, 'identityEndDate');
    isIdentityEndDate.required = true;
    if (isIdentityForever.value === 'true') {
      isIdentityEndDate.required = false;
      isIdentityEndDate.value = '';
    }
    /** 正常其他数据类型校验*/
    this.errorMsgForDriver = Validation(this.conditionForDriver);
    if (!this.errorMsgForDriver.length) {
      if (!this.driverFrontPC.path) {
        this.message.create('warning', '请上传身份证正面照!');
        return;
      }
      if (!this.driverBackendPC.path) {
        this.message.create('warning', '请上传身份证反面照!');
        return;
      }
      if (!this.lisencePC.path) {
        this.message.create('warning', '请上传驾驶证正面照!');
        return;
      }
      if (!this.lisenceBackendPC.path) {
        this.message.create('warning', '请上传驾驶证反面照!');
        return;
      }
      if (!this.taxpayerIdentityPic.path) {
        this.message.create('warning', '请上传纳税人身份证正面照片!');
        return;
      }
      if (!this.rtqcPic.path) {
        this.message.create('warning', '请上传道路从业资格证照片!');
        return;
      }
      const copyCondition = deepClone(this.conditionForDriver);
      // dateFormat1(copyCondition);
      const tempParams = convert(copyCondition);
      /*对于身份证是否永久条件会关联截止日期的选择与否*/
      const identityForever = tempParams['identityForever'];
      const identityEndDate = tempParams['identityEndDate'];
      const identityStartDate = tempParams['identityStartDate'];
      // 首次签发日期
      const licenseFirstDetDate = tempParams['licenseFirstDetDate'];
      // 有效期开始日期
      const licenseStartDate = tempParams['licenseStartDate'];
      // 有效期结束日期
      const licenseEndDate = tempParams['licenseEndDate'];
      if (identityForever === 'true') {
        this.message.create('warning', '由于您选择的身份证为永久，因此身份证截至日期自动失效');
        tempParams['identityEndDate'] = '';
      } else if (identityForever === 'false' && identityEndDate <= identityStartDate) {
        return this.message.create('warning', '身份证截至日期不能早于身份证开始日期');
      }
      if (licenseEndDate <= licenseStartDate) {
        return this.message.create('warning', '有效期结束日期不能早于有效期开始日期');
      }
      // 税登人和司机为同一人校验，姓名校验，身份证号校验
      if(tempParams['name'] !== tempParams['taxpayerName'] ){
        return this.message.create('warning', '司机姓名和纳税人姓名必须一致');
      }
      if(tempParams['idNum'] !== tempParams['taxpayerIdentity'] ){
        return this.message.create('warning', '身份证号码和纳税人身份证号码必须一致');
      }
      tempParams['driverlicence'] = tempParams['idNum'];
      tempParams['identityPicFront'] = this.driverFrontPC.path;
      tempParams['identityPicBack'] = this.driverBackendPC.path;
      tempParams['driverLicencePicMain'] = this.lisencePC.path;
      tempParams['driverLicencePicSecond'] = this.lisenceBackendPC.path;
      tempParams['taxpayerIdentityPic'] = this.taxpayerIdentityPic.path;
      tempParams['rtqcPic'] = this.rtqcPic.path;
      tempParams['userId'] = this.userIdCopy;
      if (this.taxpayerId) {
        tempParams['taxpayerId'] = this.taxpayerId;
      }
      if (this.contractNo) {
        tempParams['contractNo'] = this.contractNo;
      }
      if (this.signcontractVerify) {
        tempParams['signcontractVerify'] = this.signcontractVerify;
      }
      this.idNum = tempParams['idNum'];
      this.createNow(tempParams, REQUEST.DRIVER.UPDATE + this.driverId + '/anlian').then(() => {
        this.fetchService.get(REQUEST.DRIVER.GET_CAR + this.driverId, {}, (rel) => {
          if (rel) {
            this.fetchService.get(REQUEST.CAR.EDIT + rel, {}, this.formatCarCond.bind(this));
          } else {
            this.message.create('error', '车辆绑定异常，请稍后重试!');
          }
        });
      });
    }
  }

  formatCarCond(res) {
    this.carId = res.carId;
    this.getEnergyType();
    this.conditionForCar.forEach((field) => {
      const key = field.key;
      if (field.type === 1 && field.unit && key === 'cargoNum') {
        field.unit.value = res[field.unit.key];
        field.value = res[key];
      } else if (field.type === 4 && res[key]) {
        field.value = new Date(res[key]);
      } else if (field.type === 10 && res[key]) {
        const labelArr = [];
        const parts = res[key + '_'].split('_');
        parts.forEach((part: string) => {
          if (part && part.indexOf('+') > -1) {
            labelArr.push(part.split('+')[1]);
          }
        });
        field.value = parts;
      } else {
        field.value = res[key] !== undefined ? res[key] + '' : null;
      }
    });
    if (res.vehicleDriveLicenseHomepage) {
      this.vehicleLicenseMainPC.displayPath = REQUEST.DISPLAY_PIC_BASE + res.vehicleDriveLicenseHomepage;
      this.vehicleLicenseMainPC.path = res.vehicleDriveLicenseHomepage;
    }
    if (res.vehicleDriveLicenseSecondPositivePage) {
      this.vehicleLicenseFrontPC.displayPath = REQUEST.DISPLAY_PIC_BASE + res.vehicleDriveLicenseSecondPositivePage;
      this.vehicleLicenseFrontPC.path = res.vehicleDriveLicenseSecondPositivePage;
    }
    if (res.vehicleDriveLicenseBackPage) {
      this.vehicleLicenseBackendPC.displayPath = REQUEST.DISPLAY_PIC_BASE + res.vehicleDriveLicenseBackPage;
      this.vehicleLicenseBackendPC.path = res.vehicleDriveLicenseBackPage;
    }
    if (res.operationCertPic) {
      this.verhicalRoad.displayPath = REQUEST.DISPLAY_PIC_BASE + res.operationCertPic;
      this.verhicalRoad.path = res.operationCertPic;
    }
    if (res.vehicleAffiliation) {
      this.verhicalDock.displayPath = REQUEST.DISPLAY_PIC_BASE + res.vehicleAffiliation;
      this.verhicalDock.path = res.vehicleAffiliation;
    }
    if (res.vehicleRegistration) {
      this.verhicalRegistration.displayPath = REQUEST.DISPLAY_PIC_BASE + res.vehicleRegistration;
      this.verhicalRegistration.path = res.vehicleRegistration;
    }
    if (res.cyczpfj) {
      this.carrierPC.displayPath = REQUEST.DISPLAY_PIC_BASE + res.cyczpfj;
      this.carrierPC.path = res.cyczpfj;
    }
    if (res.czsmgkfj) {
      this.personAttachmentPC.displayPath = REQUEST.DISPLAY_PIC_BASE + res.czsmgkfj;
      this.personAttachmentPC.path = res.czsmgkfj;
    }
    if (res.frontPic) {
      this.frontPC.displayPath = REQUEST.DISPLAY_PIC_BASE + res.frontPic;
      this.frontPC.path = res.frontPic;
    }
    this.step = 1;
    this.isCarShowUpload = true;
  }

  //获取能源类型
  getEnergyType() {
    const option = this.util.switchDic('0017');
    this.conditionForCar.map((item, index) => {
      if(item.key === 'vehicleEnergyType') {
        item.option = option;
      }
    });
  }

  submitStep2() {
    this.errorMsgForCar = Validation(this.conditionForCar);
    if (!this.errorMsgForCar.length) {
      if (!this.vehicleLicenseMainPC.path) {
        this.message.create('warning', '请上传车辆行驶证主页!');
        return;
      }
      if (!this.vehicleLicenseFrontPC.path) {
        this.message.create('warning', '请上传车辆行驶证副页正面!');
        return;
      }
      if (!this.vehicleLicenseBackendPC.path) {
        this.message.create('warning', '请上传车辆行驶证副页反面!');
        return;
      }
      if (!this.verhicalRoad.path) {
        this.message.create('warning', '请上传车辆道路运输证!');
        return;
      }
      if (!this.verhicalRegistration.path) {
        this.message.create('warning', '请上传车辆机动车登记证书!');
        return;
      }
      if (!this.carrierPC.path) {
        this.message.create('warning', '请上传承运车辆照片!');
        return;
      }
      if (!this.personAttachmentPC.path) {
        this.message.create('warning', '请上传车辆所有人为个人附件照片!');
        return;
      }
      if (!this.frontPC.path) {
        this.message.create('warning', '请上传车头照片!');
        return;
      }
      const copyCondition = deepClone(this.conditionForCar);
      // dateFormat2(copyCondition);
      const tempParams = convert(copyCondition);
      const today = new Date();
      if (tempParams['registerDate'] >= today) {
        return this.message.create('warning', '行驶证注册日期应当小于当前时间');
      }
      if (tempParams['issueDate'] >= today) {
        return this.message.create('warning', '行驶证发证日期应当小于当前时间');
      }
      tempParams['vehicleDriveLicenseHomepage'] = this.vehicleLicenseMainPC.path;
      tempParams['vehicleDriveLicenseSecondPositivePage'] = this.vehicleLicenseFrontPC.path;
      tempParams['vehicleDriveLicenseBackPage'] = this.vehicleLicenseBackendPC.path;
      tempParams['operationCertPic'] = this.verhicalRoad.path;
      tempParams['vehicleAffiliation'] = this.verhicalDock.path;
      tempParams['vehicleRegistration'] = this.verhicalRegistration.path;
      tempParams['cyczpfj'] = this.carrierPC.path;
      tempParams['czsmgkfj'] = this.personAttachmentPC.path;
      tempParams['frontPic'] = this.frontPC.path;
      this.fetchService.put(REQUEST.CAR.UPDATE + this.carId + '/' + this.authService.profile['companyId'], tempParams, (res: any) => {
        if (!res.isError) {
          this.fetchService.post(REQUEST.DRIVER.SAVE, {idNum: this.idNum, plateNum: tempParams['plateNum']}, (res1) => {
            if (res1) {
              this.step = 2;
              this.status = 'success';
              const int = setInterval(() => {
                this.skipTime--;
                if (this.skipTime <= 0) {
                  clearInterval(int);
                  this.skipNow();
                }
              }, 1000);
            } else {
              this.message.create('error', '车辆绑定失败，请稍后重试!');
            }
          });
        } else {
          this.message.create('error', '车辆绑定失败，请稍后重试!');
        }
      });
    }
  }

  reset() {
    switch (this.step) {
      case 0: this.conditionForDriver = JSON.parse(JSON.stringify(DRIVER_INFO)); this.getAddress(); break;
      case 1: this.conditionForCar = JSON.parse(JSON.stringify(CAR_INFO)); this.getEnergyType(); break;
      case 2: this.conditionForAccount = JSON.parse(JSON.stringify(ACCOUNT_INFO)); break;
    }
  }

  createNow(params = {}, url) {
    return new Promise((resolve, reject) => {
      this.fetchService.put(url, params, (res: any) => {
        if (!res.isError) {
          resolve();
        } else {
          reject(res.errorMsg);
        }
      });
    });
  }

  skipNow () {
    this.router.navigate(['/driverManagement/unAuditList']);
  }

  backNow () {
    this.step = 0;
  }
}
