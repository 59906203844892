import {Component, OnInit, ViewChild, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import { FetchService } from '../../core/services/fetch.service';
import { NzMessageService, UploadFile, UploadFilter, NzModalRef, NzModalService } from 'ng-zorro-antd';
import { Observable, Observer } from 'rxjs';
import { REQUEST } from '../../core/config/linkMap';
import { Condition, OilCardRecord, PreCharge } from '../../shared/model/carModels';
import {OILCARD_COND, PAYMENT_COND} from '../../core/config/pageConfig';
import {dateFormat, getCond, convert} from 'src/app/shared/utils/adapter';
import {AuthenticationService} from '../../core/services/authentication.service';
import { OilcardRecordService } from './service/oilcard-record.service';
import { UtilService } from 'src/app/core/services/util.service';
import { TableColumnService } from 'src/app/core/services/table-column.service';
import { BaseTableColumn, BaseTableData, BaseTableRow } from 'src/app/shared/components/base-table/interface';
import { Validation } from 'src/app/shared/utils/validation';

@Component({
  selector: 'app-oilcard-record',
  templateUrl: './oilcard-record.component.html',
  styleUrls: ['./oilcard-record.component.less']
})
export class OilcardRecordComponent implements OnInit {

  @Input() isUpload: any;
  @Input() uploadUrl: string;
  //公司ID
  //company_id = 1;
  //登录用户id
  //user_id = 1;
  //付款人姓名
  //user_name = '秀琴';

  //上传路径
  //nzAction = 'https://api.test.0935e.com/v1/files';

  pageIndex = 1;
  pageSize = 5;
  total = 1;
  loading = false;

  payType = 'oil';
  curStep = 0;
  cardNum: string;


  isOkLoading = false;
  isVisible = false;
  private superSearchParams:any;

  preChargeClassName = 'pre-charge-modal';
  bindingVisible = false;//绑定油卡
  licensePlateNum:string;//车牌号双向绑定
  topupMoney:string;//充值金额双向绑定
  OilCardNumModel:string;//油卡id
  OilCardNumModelCar:string;//油卡卡号
  bindingsuccessVisible = false;//绑定成功
  bindingerrVisible = false;//绑定失败
  topupVisible = false ;//输入充值金额
  //请输入支付密码
  paypasspordVisible: boolean = false;
  pwdTipClose: boolean;
  pwdTipMsg: string;
  inputSix:boolean = false;
  password: string;
  paypassporddrVisible:boolean = false;
  nosufficientfundsVisible:boolean = false;
  typeMessage:string = ''
  //验证码
  verificationCode:string;
  //验证码图片地址
  payPasspordCode:string;
  //限制请求时间
  payPasspordCodeNum:number


  searchMoreClassName = 'super-search';
  revertClassName = 'revert-modal';
  //点击充值时button loading
  paypasspordVisibleLoading:boolean = false;

  isRevertVisible = false;
  isLoadingHui = false; //确认回收buttonloading
  condition: Condition[];
  curRecord: OilCardRecord;
  isSearchMoreVisible = false;

  dataSource: any[];
  isLoadingbinding:boolean = false;

  //模糊搜索车牌号
  selectedValue = '';
  listOfOption: Array<{ value: string, text: string  }> = [];
  nzFilterOption = () => true;

  /*  表格选择参数 */
  allChecked = false;
  indeterminate = false;
  selectRecords = [];

  // 表单变量
  oilcardAddForm: Condition[];
  rechargeApplyForm: Condition[];
  errorMsg: string[];
  @ViewChild('oilcardAddEditor')
  private oilcardAddEditor: TemplateRef<{}>;
  @ViewChild('rechargeApplyEditor')
  private rechargeApplyEditor: TemplateRef<{}>;
  private tplModal: NzModalRef;
  @ViewChild('driverModal')
  private driverModal: TemplateRef<{}>;
  private tplDriverModal: NzModalRef;
  // 弹出框表格变量
  driverDataSource: BaseTableData;
  driverPhoneNumber: string;
  driverName: string;
  driverCodeOptions = [];
  driverColumns: BaseTableColumn[] = [];
  selectdrivers: BaseTableRow[] = [];
  oilType: any[];
  ThirdType: any[];
  queryParams: { [key: string]: any } = {};

  constructor(private fetchService: FetchService, private message: NzMessageService, private authService: AuthenticationService, private oilcardRecordService: OilcardRecordService, private modalService: NzModalService, private util: UtilService, private tableColumn: TableColumnService) {
    this.condition = JSON.parse(JSON.stringify(OILCARD_COND));
    this.dataSource = [];
  }

  searchPlateNums(value: string){
    return new Promise((resolve, reject) => {
      this.fetchService.get(REQUEST.OIL.SEARCH_PLATE + '/'+value, {}, (res: any) => {
        const listOfOption: Array<{ value: string, text: string  }> = [];
        if(res && res._embedded) {
          res._embedded.carResourceList.forEach(item => {
            listOfOption.push({
              value: item.plateNum,
              text : item.plateNum
            });
          })
        }
        this.listOfOption = listOfOption;
        resolve();
      });
    });
  }

  // 上传文件

  beforeUpload = (file) => {
    const isxlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    const istxt = 'text/plain';
    if(file.type !== isxlsx && file.type !== istxt) {
      this.message.error('上传的文件格式不正确!');
      return false;
    }
  }

  fileTypeChange(type) {
    if( type.type == 'success' ) {
      const path = type.file.response.path
      this.fetchService.get(REQUEST.OIL.ALL + '/file/' + this.authService.profile['companyId'] , {
        file:path
      }, (res: any) => {
        //this.message.success('上传成功!');
        this.searchData();
      });
    }else if (type.type == 'error') {
      this.message.error('上传文件失败');
    }
  }

  ngOnInit() {
    this.searchData();
  }

  searchData(reset: boolean = false): void {
    if (reset) {
      this.pageIndex = 1;
    }
    this.searchNow(this.superSearchParams)
  }

  /* 请求调用 */
  searchNow(params = {}) {
    params['companyId'] = this.authService.profile['companyId'];
    params['page'] = this.pageIndex;
    params['per_page'] = this.pageSize;
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.dataSource = [];
      this.total = 0;
      this.fetchService.get(REQUEST.OIL.ALL, params, (res: any) => {
        this.loading = false;
        if (res._embedded) {
          this.dataSource = res._embedded.oilCardResourceList || [];
        }
        if (res.page) {
          this.total = res.page.totalElements;
        }
        resolve();
      });
    });
  }

  //高级搜索
  superSearch() {
    this.isOkLoading = true;
    const cardNum = getCond(this.condition, 'oilCardNum');
    const driverName = getCond(this.condition, 'driverName');
    const mobile = getCond(this.condition, 'phoneNum');
    const plateNum = getCond(this.condition, 'carNum');
    // const state = getCond(this.condition, 'status');
    const params = {};
    if (cardNum.value) {
      params['card_num'] = cardNum.value;
    }
    if (driverName.value) {
      params['name'] = driverName.value;
    }
    if (mobile.value) {
      params['mobile'] = mobile.value;
    }
    if (plateNum.value) {
      params['plate_num'] = plateNum.value;
    }
    // if (state.value) {
    //   params['state'] = state.value;
    // }
    this.superSearchParams = params;
    this.pageIndex = 1;
    this.searchNow(params).then(() => {
      this.isOkLoading = false;
      this.isVisible = false;
      this.condition = JSON.parse(JSON.stringify(OILCARD_COND));

    });
  }

  clear() {
    this.superSearchParams = {};
    this.searchData(true);
  }

  currentPageDataChange($event: Array<{ name: string; age: number; address: string; checked: boolean; disabled: boolean; }>): void {
    this.refreshStatus();
  }

  refreshStatus(): void {
    const allChecked = this.dataSource.every(value => value.checked === true);
    const allUnChecked = this.dataSource.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);

    /* 选择数据同步 */
    this.selectRecords = this.dataSource.filter(value => value.checked);
  }

  checkAll(value: boolean): void {
    this.dataSource.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

//充值申请
  rechargeApply() {
    if (this.selectRecords.length !== 1) {
      this.message.warning('请选择一条油卡记录！');
      return;
    }
    const oilcardRecord: OilCardRecord = <OilCardRecord>this.selectRecords[0];
    this.rechargeApplyForm = this.oilcardRecordService.getRechargeApplyForm(oilcardRecord);
    this.tplModal = this.modalService.create({
      nzTitle:'充值申请',
      nzContent: this.rechargeApplyEditor,
      nzMaskClosable: false,
      nzClosable: true,
      nzWidth: 750,
      nzFooter: [
        {
          label: '取消',
          onClick: () => this.tplModal.destroy()
        },
        {
          label: '确定',
          type: 'primary',
          onClick: com => {
            this.rechargeSubmit(convert(this.rechargeApplyForm));
           
          }
        }
      ]
    })
  }
  
  rechargeSubmit(form: any) {
    this.errorMsg = Validation(this.rechargeApplyForm);
    if (this.errorMsg.length > 0) {
      this.errorMsg.forEach(item => this.message.warning(item));
      return;
    }
    let message = form.offer === 'charge' ? '充值' : '扣款';
    this.fetchService.post(`${REQUEST.OIL.CHARGE}/${form.cardId}?offer=${form.offer}&payment=${form.money}`, null, (res: any) => {
      if(!res.error) {
        this.message.success('油卡'+ message +'申请成功');
        this.searchData();
        this.tplModal.destroy();
      }
    })
  }

// 添加油卡
  create() {
    const oilcardRecord: OilCardRecord = new OilCardRecord();
    this.oilcardAddForm = this.oilcardRecordService.getAddCardForm(oilcardRecord,this.showDirvelModal)
    this.tplModal = this.modalService.create({
      nzTitle:'添加油卡',
      nzContent: this.oilcardAddEditor,
      nzMaskClosable: false,
      nzClosable: true,
      nzWidth: 750,
      nzFooter: [
        {
          label: '取消',
          onClick: () => this.tplModal.destroy()
        },
        {
          label: '确定',
          type: 'primary',
          onClick: com => {
            this.submit(convert(this.oilcardAddForm));
          }
        }
      ]
    })
  }

  submit(form:any) {
    this.errorMsg = Validation(this.oilcardAddForm);
    if (this.errorMsg.length > 0) {
      this.errorMsg.forEach(item => this.message.warning(item));
      return;
    }
    
    this.fetchService.post(`${REQUEST.OIL.ALL}`, form, (res:any) => {
      if(!res.error && res.data) {
        this.message.success('新增油卡成功');
        this.searchData();
        this.tplModal.destroy();
      }else {
        this.message.error(res.message);
      }
    })
  }

  showDirvelModal = () => {
    this.selectdrivers = [];
    this.driverColumns = this.tableColumn.getReviewDriverColumns()
    this.tplDriverModal = this.util.queryGridWin(
      '司机列表', 
      this.driverModal, 
      this.selectChange
    );
  }

  driverSearchData(value: any, name:any): void {
    this.queryParams = {mobile: value, name: name};
  }

  /* 请求调用 */
  driverSearchNow(params: object) {
    /* 增加分页参数 */
    params['company_id'] = this.authService.profile['companyId'];
    params['per_page'] = params['size'];
    return new Promise((resolve, reject) => {
      this.driverDataSource = { data: [], totalSize: 0 };
      this.fetchService.get(REQUEST.DRIVER.ALL_DRIVERS, params, (res) => {
        if (res._embedded) {
          this.driverDataSource = {
            data: res._embedded.driverResourceList || [],
            totalSize: res.page.totalElements
          };
        }
        resolve();
      });
    });
  }

  selectDriver() {
    if (this.selectdrivers.length !== 1) {
      this.message.warning('请选择一个需要操作的司机！');
      return;
    }
    const driver = this.selectdrivers[0];
    this.oilcardAddForm.map((item: Condition) => {
      if (item.key === 'name') {
        item.value = driver.name;
      }
      if (item.key === 'driverId') {
        item.value = driver.driverId;
      }
    });
    this.tplDriverModal.close();
  }

  // 用户输入完成
  onInput() {
    if (this.driverPhoneNumber) {
      const params = {mobile: this.driverPhoneNumber};
      const reqUrl = REQUEST.DRIVER.RETRIEVAL;
      this.fetchService.get(reqUrl, params, (res: any) => {
        this.driverCodeOptions = res;
      });
    } else {
      this.driverSearchData(null, null);
    }
  }

  // 下拉选择完成
  selectChange() {
    this.driverSearchData(this.driverPhoneNumber, null);
  }

  //所有的取消
  close() {
    this.bindingVisible = false;
    this.paypasspordVisibleLoading = false;
    this.topupVisible = false;
    this.OilCardNumModel = '';
    this.licensePlateNum = '';
    this.selectedValue = '';
    this.topupMoney = '';
    this.inputSix = false;
    this.paypasspordVisible = false;
    this.verificationCode = ''
    this.nosufficientfundsVisible = false;
    this.isRevertVisible = false;
    this.paypassporddrVisible = false;
    this.typeMessage = ''
    this.isLoadingbinding = false;
  }

  driversearch(drNum,drNam) {
    if(drNum == null && drNam == null){
      this.message.warning('请至少填写一个查询项');
      return;
    }
    this.driverSearchData(drNum,drNam);
  }
}
