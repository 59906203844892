import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FetchService } from '../../core/services/fetch.service';
import { NzModalService, NzMessageService, NzModalRef } from 'ng-zorro-antd';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { REQUEST, BASE } from '../../core/config/linkMap';
import { Condition, OrderInfo, PreTableRecord } from '../../shared/model/carModels';
import { PRE_DIST_COND, QRTIME } from '../../core/config/pageConfig';
import { getCond, filterRecord, getRecord, dateFormat, convert } from '../../shared/utils/adapter';
import {AuthenticationService} from '../../core/services/authentication.service';
import { UtilService } from 'src/app/core/services/util.service';
import { Validation } from 'src/app/shared/utils/validation';

@Component({
  selector: 'app-pre-dist-management',
  templateUrl: './pre-dist-management.component.html',
  styleUrls: ['./pre-dist-management.component.less']
})
export class PreDistManagementComponent implements OnInit {

  pageIndex = 1;
  pageSize = 5;
  total = 0;
  loading = false;

  allChecked = false;
  indeterminate = false;

  orderCodeValue: string;
  orderCodeOptions = [];
  isVisible = false;

  className = 'super-search';
  errorMsg: string[];
  condition: Condition[];
  dataSource: any[];
  private superSearchParams: any;
  orderTypeValue: string;
  
  @ViewChild('qrModal')
  private qrModal: TemplateRef<{}>;
  private tplQRModal: NzModalRef;
  qrCondition: Condition[];
  qrImgSrc: string;

  constructor(
    private router: Router,
    private message: NzMessageService,
    private modalService: NzModalService,
    private fetchService: FetchService,
    private util: UtilService,
    private authService: AuthenticationService) {
    this.condition = JSON.parse(JSON.stringify(PRE_DIST_COND));
    this.dataSource = [];
  }

  ngOnInit() {
    this.searchData();
  }

  searchData(reset: boolean = false): void {
    if (reset) {
      this.pageIndex = 1;
    }
    this.searchNow(this.superSearchParams);
  }

  // 用户输入完成
  onInput() {
    if (this.orderCodeValue) {
      const params = {orderCode: this.orderCodeValue};
      const reqUrl = REQUEST.ORDER.RETRIEVAL;
      this.fetchService.get(reqUrl, params, (res: any) => {
        this.orderCodeOptions = res;
      });
    } else {
      this.searchData(true);
    }
  }

  // 下拉选择完成
  selectChange() {
    this.superSearchParams = {order_code: this.orderCodeValue};
    this.searchNow({order_code: this.orderCodeValue});
  }

  showSuperSearch(){
    this.isVisible = true;
    this.getorderTypeSync();
  }

  //获取订单类型及服务方地区
  getorderTypeSync() {
    const option = this.util.switchDic('0010');
    this.condition.map((item, index) => {
      if(item.key === 'sync') {
        this.condition[index].option = option;
        this.orderTypeValue = this.condition[index].value;
        item.change = (value) => {
          if(!!value){
            this.orderTypeValue = value;
          }
          const url = this.util.getUrl(value);
          this.util.getArea(url,{}).then((area) => {
            this.condition.map((item1,index1) => {
              if(item1.key == 'cargo_address' || item1.key == 'delivery_address'){
                this.condition[index1].value = '';
                this.condition[index1].option = area;
              }
            });
          });
        }
      }
      if(item.key == 'cargo_address' || item.key == 'delivery_address'){
        item.focus = () =>{
          if(this.orderTypeValue === ''){
            this.message.warning('请先选择订单类型，再选择地址');
          }
        }
      }
    });
  }

  superSearch() {
    const orderCode = getCond(this.condition, 'order_code');
    const goodsPropertyTypeCode = getCond(this.condition, 'goods_property_type_code');
    const cargoAddress = getCond(this.condition, 'cargo_address');
    const deliveryAddress = getCond(this.condition, 'delivery_address');
    const pickupStartTime = getCond(this.condition, 'pickup_start_time');
    const unloadEndTime = getCond(this.condition, 'unload_end_time');
    const sync = getCond(this.condition, 'sync');
    const params = {};
    if (orderCode.value) {
      params['order_code'] = orderCode.value;
    }
    if (goodsPropertyTypeCode.value) {
      params['goods_property_type_code'] = goodsPropertyTypeCode.value;
    }
    if (cargoAddress.value) {
      const province = cargoAddress.value[0].split('+')[1];
      const rgion = cargoAddress.value[1].split('+')[1];
      const city = cargoAddress.value[2].split('+')[1];
      params['cargo_address'] = province + rgion + city;
    }
    if (deliveryAddress.value) {
      const province = deliveryAddress.value[0].split('+')[1];
      const rgion = deliveryAddress.value[1].split('+')[1];
      const city = deliveryAddress.value[2].split('+')[1];
      params['delivery_address'] = province + rgion + city;
    }
    if (pickupStartTime.value) {
      params['pickup_start_time'] = this.util.dateFormat(pickupStartTime.value) + ',' + this.util.dateFormat(unloadEndTime.value);
    }
    if(sync.value) {
      params['sync'] = sync.value
    }
    this.superSearchParams = params;
    this.pageIndex = 1; // 每次查询都要重置pageIndex
    this.searchNow(params).then(() => {
      this.isVisible = false;
    });
  }

  searchNow(params = {}, url = REQUEST.PRE_DIST.ALL) {
    params['company_id'] = this.authService.profile['companyId'];
    params['page'] = this.pageIndex;
    params['per_page'] = this.pageSize;
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.dataSource = [];
      this.fetchService.get(url, params, (res: any) => {
        this.loading = false;
        if (res._embedded) {
          this.dataSource = res._embedded.orderResourceList || [];
        } else {
          this.message.create('warning', '暂无相关数据');
        }
        if (res.page) {
          this.total = res.page.totalElements;
        }
        resolve();
      });
    });
  }

  currentPageDataChange($event: Array<{ name: string; age: number; address: string; checked: boolean; disabled: boolean; }>): void {
    this.dataSource = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    const allChecked = this.dataSource.every(value => value.checked === true);
    const allUnChecked = this.dataSource.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  checkAll(value: boolean): void {
    this.dataSource.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  edit() {
    const selectAllData = this.dataSource.filter((order) => {
      return order.checked;
    });
    const selectAvilData = this.dataSource.filter((order) => {
      return order.checked && [0, 1].indexOf(order.orderState) > -1;
    });
    if (selectAllData.length !== 1) {
      return this.message.create('warning', '请选择一个预派车单编辑');
    } else if (selectAvilData.length === 0) {
      return this.message.create('warning', '您选择的预派车单不能被编辑，请重新选择');
    }
    const oId = selectAvilData[0].oId;
    const orderCode = selectAvilData[0].orderCode;
    this.router.navigate(['/orderRecord/preDistManagement/create/' + oId + '_' + orderCode]);
  }

  delete() {
    const selectAllData = this.dataSource.filter((order) => {
      return order.checked;
    });
    const selectAvilData = this.dataSource.filter((order) => {
      return order.checked && [0, 1].indexOf(order.orderState) > -1;
    });
    if (selectAllData.length !== 1) {
      return this.message.create('warning', '请选择一个预派车单删除');
    } else if (selectAvilData.length === 0) {
      return this.message.create('warning', '您选择的预派车单因关联了拉运车辆而不能被删除，请重新选择');
    }
    const oId = selectAvilData[0].oId;
    this.fetchService.get(REQUEST.PRE_DIST.PULL_VERHICAL, {orderId: oId}, (res) => {
      if (!res || !res._embedded) {
        this.fetchService.delete(REQUEST.PRE_DIST.ALL + '/' + oId, {}, (res1) => {
          if (!res1 || !res1.isError) {
            this.message.create('success', '预派车单删除成功！');
            this.searchData(true);
          }
        });
      } else {
        return this.message.create('warning', '当前预派车单不能被删除');
      }
    });
  }

  clear() {
    this.orderCodeValue = '';
    this.superSearchParams = {};
    this.condition = JSON.parse(JSON.stringify(PRE_DIST_COND));
    this.searchData(true);
  }

  qrcode(){
    const selectAllData = this.dataSource.filter((order) => {
      return order.checked;
    });
    if (selectAllData.length !== 1) {
      return this.message.create('warning', '请选择一个预派车单生成二维码');
    }
    const oId = selectAllData[0].oId;
    this.showQRmodal(this.generateQR, oId);
  }

  showQRmodal(cb: (form: any,id: any) => void, id: any){
    this.qrCondition = JSON.parse(JSON.stringify(QRTIME));
    this.tplQRModal = this.modalService.create({
      nzTitle: '生成二维码',
      nzContent: this.qrModal,
      nzMaskClosable: true,
      nzClosable: true,
      nzWidth: 500,
      nzFooter: [
        {
          label: '取消',
          onClick: () => this.tplQRModal.destroy()
        },
        {
          label: '生成',
          type: 'primary',
          onClick: com => {
            cb(convert(this.qrCondition),id);
          }
        }
      ]
    })
  }

  generateQR = (form:any, id: any) => {
    this.errorMsg = Validation(this.qrCondition);
    if (this.errorMsg.length > 0) {
      this.errorMsg.forEach(item => this.message.warning(item));
      return;
    }
    this.qrImgSrc = `${REQUEST.PRE_DIST.QRCODE}`+'?content='+BASE + '/v1/orders/doQRC?order=' + `${id}&size=` + `${form.size}&day=` +`${form.day}`;
    this.tplQRModal.destroy();
    this.util.qrModal('订单二维码',this.qrImgSrc);
  }
}
