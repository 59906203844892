import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BreadService } from '../../core/services/bread.service';
import { Menu } from 'src/app/shared/model/carModels';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import { AuthenticationService} from '../../core/services/authentication.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less']
})
export class MenuComponent implements OnInit, OnDestroy  {
  menus: Menu[];
  url: string;
  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private breadService: BreadService
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.url = this.router.url.substr(1, this.router.url.length);
      this.menus = this.filterMenus(this.breadService.getMenu());
      this.setExpand(this.menus);
    });
  }

  ngOnInit() {}

  filterMenus(menus: Menu[]): Menu[] {
    menus.map((item: Menu) => {
      if (item.permissions) {
        item.isShow = this.authService.isPermissions(item.permissions);
      } else {
        item.isShow = Promise.resolve(true);
      }
      if (item.hasSub) {
        this.filterMenus(item.subMenus);
      }
    });
    return menus;
  }

  setExpand(sources: Menu[]) {
    for (const source of sources) {
      if (this.url.indexOf(source.id) > -1) {
        source.expand = true;
        source.selected = true;
        if (source.hasSub) {
          this.setExpand(source.subMenus);
        }
      } else {
        source.expand = false;
        source.selected = false;
      }
    }
  }

  openChange(menu: Menu) {
    menu.selected = !menu.selected;
    menu.expand = !menu.expand;
    this.menus.forEach(item => {
      item.expand = menu.id === item.id;
      item.selected = menu.id === item.id;
      if (item.hasSub) {
        item.subMenus.forEach(subItem => {
          subItem.selected = false;
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
