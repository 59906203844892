import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { FetchService } from '../../core/services/fetch.service';
import { AuthenticationService } from '../../core/services/authentication.service';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { Router } from '@angular/router';
import { REQUEST } from '../../core/config/linkMap';
import { getCond } from '../../shared/utils/adapter';
import { CAR_MANAGEMENT_COND } from '../../core/config/pageConfig';
import { Condition } from '../../shared/model/carModels';
import { DetailInfoComponent } from 'src/app/shared/components/detail-info/detail-info.component';
import { CarManagementService } from './service/car-management.service';

@Component({
  selector: 'app-car-management',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './car-management.component.html',
  styleUrls: ['./car-management.component.less']
})

export class CarManagementComponent implements OnInit {

  pageIndex = 1;
  pageSize = 5;
  total = 1;
  loading = false;

  allChecked = false;
  indeterminate = false;

  isVisible = false;
  isOkLoading = false;

  className = 'super-search';
  plateNum: string;

  plateNumOptions = [];
  condition: Condition[];
  dataSource: any[];
  selectRecords: any;

  isShowModalInfo = false;
  modalType: string;
  modalMsg: string;
  private superSearchParams: any;

  constructor(private message: NzMessageService,
    private router: Router,
    private modalService: NzModalService,
    private authService: AuthenticationService,
    private fetchService: FetchService,
    private carManagementService: CarManagementService) {
    this.condition = JSON.parse(JSON.stringify(CAR_MANAGEMENT_COND));
  }

  ngOnInit() {
    this.dataSource = [];
    this.selectRecords = [];
    this.searchData();
  }

  searchData(reset: boolean = false): void {
    if (reset) {
      this.pageIndex = 1;
    }
    this.searchNow(this.superSearchParams, REQUEST.CAR.ALL);
  }

  onInput() {
    if (this.plateNum) {
      const reqUrl = REQUEST.CAR.PLATE_NUM_SEARCH + this.plateNum;
      this.fetchService.get(reqUrl, {}, (res: any) => {
        if (res._embedded) {
          this.plateNumOptions = res._embedded.carResourceList || [];
        }
      });
    } else {
      this.searchData(true);
    }
  }

  selectChange() {
    this.pageIndex = 1; // 每次查询都要重置pageIndex
    this.superSearchParams = {plateNum: this.plateNum};
    this.searchNow({plateNum: this.plateNum}, REQUEST.CAR.ALL);
  }

  superSearch() {
    const plateNum = getCond(this.condition, 'plateNum');
    const companyName = getCond(this.condition, 'companyName');
    const carType = getCond(this.condition, 'carType');
    const normalRoad = getCond(this.condition, 'normalRoad');
    const verifyState = getCond(this.condition, 'verifyState');
    const params = {};
    if (plateNum.value) {
      params['plateNum'] = plateNum.value;
    }
    if (companyName.value) {
      params['companyName'] = companyName.value;
    }
    if (carType.value) {
      params['carType'] = carType.value;
    }
    if (normalRoad.value) {
      params['normalRoad'] = normalRoad.value;
    }
    if (verifyState.value) {
      params['verifyState'] = verifyState.value;
    }
    params['companyId'] = this.authService.profile['companyId'];
    this.superSearchParams = params;
    this.pageIndex = 1; // 每次查询都要重置pageIndex
    this.searchNow(params, REQUEST.CAR.ALL).then(() => {
      this.isOkLoading = false;
      this.isVisible = false;
      // this.condition = JSON.parse(JSON.stringify(CAR_MANAGEMENT_COND));
    });
  }

  searchNow(params = {}, url) {
    params['companyId'] = this.authService.profile['companyId'];
    params['page'] = this.pageIndex;
    params['per_page'] = this.pageSize;
    return new Promise((resolve, reject) => {
      /* this.dataSource = CAT_MANAGEMENT_RECORD; */
      this.loading = true;
      this.dataSource = [];
      this.fetchService.get(url, params, (res: any) => {
        this.loading = false;
        if (res._embedded) {
          this.dataSource = res._embedded.carResourceList || [];
        }
        if (res.page) {
          this.total = res.page.totalElements;
        }
        resolve();
      });
    });
  }

  currentPageDataChange($event: Array<{ name: string; age: number; address: string; checked: boolean; disabled: boolean; }>): void {
    this.dataSource = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    const allChecked = this.dataSource.every(value => value.checked === true);
    const allUnChecked = this.dataSource.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
    /* 选择数据同步 */
    this.selectRecords = this.dataSource.filter(value => value.checked);
  }

  checkAll(value: boolean): void {
    this.dataSource.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  clear() {
    this.plateNum = '';
    this.superSearchParams = {};
    this.condition = JSON.parse(JSON.stringify(CAR_MANAGEMENT_COND));
    this.searchData(true);
  }

  // 牵引车绑定挂车
  bindCar() {
    if (this.selectRecords.length === 1) {
      if (this.selectRecords[0].carType.indexOf('牵引车') === -1) {
        this.message.create('warning', '车辆类型必须包含牵引车！');
        return;
      }
      this.fetchService.carBindSource = null;
      this.fetchService.get(REQUEST.CAR.CAR_QUERY + this.selectRecords[0].carId, '', (res: { isError: any; }) => {
        if (res && !res.isError) {
          this.fetchService.carBindSource = res;
          this.router.navigate(['/carManagement/carList/carBind', this.selectRecords[0].carId + '_' + this.selectRecords[0].plateNum]);
        }
      });
    } else if (this.selectRecords.length === 0) {
      this.message.create('warning', '请选择一个车辆来绑定挂车！');
    } else  {
      this.message.create('warning', '只能选择一个车辆来绑定挂车！');
    }
  }

  // 牵引车绑定挂车解绑
  unbindCar() {
    if (this.selectRecords.length === 1) {
      if (this.selectRecords[0].carType.indexOf('牵引车') === -1) {
        this.message.create('warning', '只能选择牵引车来解绑！');
        return;
      }
      this.fetchService.get(REQUEST.CAR.CAR_QUERY + this.selectRecords[0].carId, {}, (res: { isError: any; car: { plateNum: any; }; }) => {
        if (res && !res.isError) {
          this.fetchService.delete(REQUEST.CAR.CAR_REL_DELETE, {
            'header': this.selectRecords[0].plateNum,
            'body': res.car.plateNum
          // tslint:disable-next-line: no-shadowed-variable
          }, (res) => {
            if (res && !res.isError) {
              this.message.create('success', '解绑成功！');
              this.router.navigate(['/carManagement/carList']);
            } else {
              this.message.create('error', '解绑失败，请稍后重试！');
            }
          });
        } else {
          this.message.create('error', '您选择的车辆暂未查询到挂车信息，请在App端新增车辆约束关系！');
        }
      });
    } else if (this.selectRecords.length === 0) {
      this.message.create('warning', '请选择一个车辆来解绑挂车！');
    } else  {
      this.message.create('warning', '只能选择一个车辆来解绑挂车！');
    }
  }

  edit() {
    if (this.selectRecords.length === 1) {
      this.router.navigate(['/carManagement/carInfoEdit', this.selectRecords[0].carId]);
    } else if (this.selectRecords.length === 0) {
      this.message.create('warning', '请选择一个编辑项！');
    } else  {
      this.message.create('warning', '只能选择一个编辑项！');
    }
  }

  // 司机解绑
  unbindDriver() {
    if (this.selectRecords.length >= 1) {
      const selectCarId = [];
      this.selectRecords.forEach((selectRecord: { carId: any; }) => {
        if (selectRecord.carId) {
          selectCarId.push(selectRecord.carId);
        }
      });
      this.loading = true;
      this.fetchService.delete(REQUEST.CAR.DELETE_DRIVER + selectCarId, '', (res: any) => {
        this.loading = false;
        if (!res.isError) {
          this.message.create('success', '操作成功！');
        }
        this.searchData();
      });
    } else if (this.selectRecords.length === 0) {
      this.message.create('warning', '请至少选择一个解绑项！');
    }
  }

  checkCarDetail(data){
    this.carManagementService.getCarModel(data.carId).then( (res:any) =>{
      if (!res.isError) {
        this.showCarDetailModal(res);
      }
    });
   
  }

  showCarDetailModal(data){
    const modal = this.modalService.create({
      nzTitle: '车辆详情',
      nzContent: DetailInfoComponent,
      nzMaskClosable: false,
      nzClosable: true,
      nzWidth: 1350,
      nzComponentParams: {
        type: 'car',
        infoModel: data
        
      },
      nzFooter:  null
    });
  }
}
