import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CreditService {

  constructor(
  ) { }

  get getCompanyMainInfo(): Array<{ key: string, value: string }> {
    return [
      {key: 'enterpriseName', value: '企业名称'},
      {key: 'enterpriseRegistrationAddress', value: '通讯地址'},
      {key: 'businessScope', value: '经营范围'}
    ];
  }

  get getCompanyAllowInfo(): Array<{ key: string, value: string }> {
    return [
      {key: 'permitNumber', value: '许可证编号'},
      {key: 'enterpriseName', value: '企业名称'},
      {key: 'enterpriseRegistrationAddress', value: '地点/位置'},
      {key: 'businessScope', value: '经营范围'},
      {key: 'permitGrantDate', value: '许可证发放日期'},
      {key: 'periodStartDate', value: '期限开始日期'},
      {key: 'periodEndDate', value: '期限结束日期'},
      {key: 'certificationUnit', value: '发证单位'}
    ];
  }

  get getDriverMainInfo(): Array<{ key: string, value: string }> {
    return [
      {key: 'nameOfPerson', value: '姓名'},
      {key: 'gender', value: '性别'}
    ];
  }

  get getDriverAllowInfo(): Array<{ key: string, value: string }> {
    return [
      {key: 'qualificationCertificateNumber', value: '从业资格证号'},
      {key: 'qualificationCertificateCategoryCode', value: '从业资格类别'},
      {key: 'certificationUnit', value: '发证单位'},
      {key: 'licenseInitialReleaseDate', value: '初次发证日期'},
      {key: 'periodStartDate', value: '期限开始日期'},
      {key: 'periodEndDate', value: '期限结束日期'}
    ];
  }

  get getCarMainInfo(): Array<{ key: string, value: string }> {
    return [
      {key: 'vehicleNumber', value: '车牌号码'},
      {key: 'licensePlateTypeCode', value: '牌照类型'},
      {key: 'roadTransportCertificateNumber', value: '道路运输证号'},
      {key: 'vehicleCorporationName', value: '所属企业'},
      {key: 'netState', value: '针对半挂牵引车和重型载货骑车(总质量为12吨及以上的普通货运车辆)在全国道路货运车辆公共监管与服务平台是否入网'}
    ];
  }

  get getCarTransInfo(): Array<{ key: string, value: string }> {
    return [
      {key: 'roadTransportCertificateNumber', value: '道路运输证号'},
      {key: 'vehicleWidth', value: '车辆宽度'},
      {key: 'vehicleHeight', value: '车辆高度'},
      {key: 'businessScope', value: '经营范围'},
      {key: 'issuingDate', value: '发证日期'},
      {key: 'periodStartDate', value: '期限开始日期'},
      {key: 'periodEndDate', value: '期限结束日期'},
      {key: 'certificationUnit', value: '发证单位'},
      {key: 'businessState', value: '运营状态'},
      {key: 'checkStateExpireDate', value: '年审有效期至'}
    ];
  }

  get getCarPlyInfo(): Array<{ key: string, value: string }> {
    return [
      {key: 'vehicleClassification', value: '车辆类型'},
      // {key: 'own', value: '所有人'},
      // {key: 'carErengyCode', value: '车辆发动机号'},
      // {key: 'registDate', value: '注册日期'},
      {key: 'issuingDate', value: '发证日期'},
      {key: 'certificationUnit', value: '发证单位'},
      {key: 'periodStartDate', value: '期限开始日期'},
      {key: 'periodEndDate', value: '期限结束日期'},
      {key: 'vinNo', value: '车架号'},
      // {key: 'totalWeight', value: '满载车辆质量(总质量)'},
      {key: 'vehicleTonnage', value: '车辆载质量(核定载质量)'},
      {key: 'vehicleMaximumTractionWeight', value: '准牵引总质量(吨)'},
    ];
  }

  get explainText():{ title, content} {
    return {
      title:'信用数据服务使用说明',
      content:`1、信用数据服务是为各物流相关企业、各级政府管理单位和社会公众提供运输企业、运输车辆和从业人员的信用记录查询的应用服务。
      2、信用数据服务目前数据来源包括全国运政系统、浙江省运政系统等。
      3、信用数据服务匿名用户可查询运输企业、运输车辆和从业人员基本信用信息；注册用户可查询运输企业、运输车辆和从业人员详细信用信息。
      4、在使用信用数据服务所提供的查询功能之前，请确保被查询方已同意您对其证件信息进行查询；未经被查询方同意，不得将查询结果信息透露给第三方，或用于其它用途。
      5、信用数据服务所提供的查询结果仅供参考，提供方不承担任何法律责任。`
    }
  }
}