import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd';

@Component({
  selector: 'app-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.less']
})
export class ModalInfoComponent implements OnInit {

  @Input() type: string;
  @Input() message: string;
  @Input() isShowModalInfo: boolean;
  constructor(private modalService: NzModalService) { }

  ngOnInit() {
    if (this.isShowModalInfo) {
      switch (this.type) {
        case 'info' : this.info(); break;
        case 'success' : this.success(); break;
        case 'error' : this.error(); break;
        case 'warning' : this.warning(); break;
      }
    }
  }

  info(): void {
    this.modalService.info({
      nzTitle: '通知信息：',
      nzContent: `<p>${this.message}</p>`,
      nzOnOk: () => this.isShowModalInfo = false
    });
  }

  success(): void {
    this.modalService.success({
      nzTitle: '成功信息：',
      nzContent: `<p>${this.message}</p>`,
      nzOnOk: () => this.isShowModalInfo = false
    });
  }

  error(): void {
    this.modalService.error({
      nzTitle: '错误信息：',
      nzContent: `<p>${this.message}</p>`,
      nzOnOk: () => this.isShowModalInfo = false
    });
  }

  warning(): void {
    this.modalService.warning({
      nzTitle: '警告信息：',
      nzContent: `<p>${this.message}</p>`,
      nzOnOk: () => this.isShowModalInfo = false
    });
  }
}
