import { Injectable } from '@angular/core';
import { FetchService } from '../../../core/services/fetch.service';
import { Condition } from '../../../shared/model/carModels';
import { newinvoiceState } from '../../../core/config/dropdown.conf';
import { RendererService } from '../../../core/services/renderer.service';

@Injectable({
  providedIn: 'root'
})
export class BillingRecordService {

  constructor(
  ) { }
  
  get initBaseSearch(): {drawee_name, state, invoice_code, delivery_code, apply_btm, perform_btm, apply_etm, perform_etm} {
    return {
      drawee_name: null,
      state: null,
      invoice_code: null,
      delivery_code: null,
      apply_btm: null,
      perform_btm: null,
      apply_etm: null,
      perform_etm: null
    };
  }

  getSearchForm(model): Condition[] {
    return [
      {
        type: 1,
        label: '受票方',
        value: model.drawee_name,
        key: 'drawee_name',
        placeholder: '请输入',
      },
      {
        type: 2,
        label: '开票状态',
        value: model.state,
        key: 'state',
        option: newinvoiceState,
        placeholder: '请选择'
      },
      {
        type: 1,
        label: '发票代码',
        value: model.invoice_code,
        key: 'invoice_code',
        placeholder: '请输入'
      },
      {
        type: 1,
        label: '运单号',
        value: model.delivery_code,
        key: 'delivery_code',
        placeholder: '请输入'
      },
      {
        type: 4,
        label: '申请时间起',
        value: model.apply_btm,
        key: 'apply_btm',
        rules: ['isDate'],
        placeholder: '请选择时间'
      },
      {
        type: 4,
        label: '开票时间起',
        value: model.perform_btm,
        key: 'perform_btm',
        rules: ['isDate'],
        placeholder: '请选择时间'
      },
      {
        type: 4,
        label: '申请时间止',
        value: model.apply_etm,
        key: 'apply_etm',
        rules: ['isDate'],
        placeholder: '请选择时间'
      },
      {
        type: 4,
        label: '开票时间止',
        value: model.perform_etm,
        key: 'perform_etm',
        rules: ['isDate'],
        placeholder: '请选择时间'
      },
    ];
  }
}
