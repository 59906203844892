import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.error(err));

const body = document.querySelector('body');
const preloader = document.querySelector('.preloader');
body.style.overflow = 'hidden';
const remove = () => {
  if (!preloader) {
    return;
  }
  preloader.addEventListener('transitionend', () => {
    preloader.className = 'preloader-hidden';
  });
  preloader.className += ' preloader-hidden-add preloader-hidden-add-active';
};
const appBootstrap = () => {
  setTimeout(() => {
    remove();
    body.style.overflow = '';
  }, 500);
};

const bootstrap = () => {
  return platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
};

bootstrap().then(() => {
  if (appBootstrap) {
    appBootstrap();
  }
});
