import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {NzMessageService, NzModalRef, NzModalService} from 'ng-zorro-antd';
import {AuthenticationService} from '../../core/services/authentication.service';
import {FetchService} from '../../core/services/fetch.service';
import {REQUEST} from '../../core/config/linkMap';
import {UserManagementService} from './service/user-management.service';
import {Condition, User} from '../../shared/model/carModels';
import {convert} from '../../shared/utils/adapter';
import {Validation, spaceValidator} from '../../shared/utils/validation';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.less']
})
export class UserManagementComponent implements OnInit {
  // 表格变量
  pageIndex = 1;
  pageSize = 5;
  total = 0;
  loading = false;
  allChecked = false;
  indeterminate = false;
  dataSource: User[];
  selectRecords = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  // 表单变量
  userForm: Condition[];
  errorMsg: string[];
  private tplModal: NzModalRef;
  @ViewChild('userEditor')
  private userEditor: TemplateRef<{}>;
  searchParams: any;

  constructor(
    private router: Router,
    private modalService: NzModalService,
    private authService: AuthenticationService,
    private fetchService: FetchService,
    private message: NzMessageService,
    private userManagementService: UserManagementService
  ) {
    this.dataSource = [];
    this.selectRecords = [];
  }

  ngOnInit() {
    this.searchData();
  }

  searchData(reset: boolean = false): void {
    if (reset) {
      this.pageIndex = 1;
    }
    this.searchNow();
  }

  /* 请求调用 */
  searchNow(params= {}) {
    /* 增加分页参数 */
    params['user_name'] = this.searchParams;
    params['company_id'] = this.authService.profile['companyId'];
    params['page'] = this.pageIndex;
    params['per_page'] = this.pageSize;
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.dataSource = [];
      this.fetchService.get(REQUEST.USER.ALL, params, (res) => {
        this.loading = false;
        if (res._embedded) {
          this.dataSource = res._embedded.userResourceList || [];
        }
        if (res.page) {
          this.total = res.page.totalElements;
        }
        resolve();
      });
    });
  }

  currentPageDataChange(data: any[]): void {
    this.refreshStatus();
  }

  refreshStatus(): void {
    /* 选择数据同步 */
    this.selectRecords = this.dataSource.filter(item => this.mapOfCheckedId[item.userId]);
    this.allChecked = this.dataSource.every(item => this.mapOfCheckedId[item.userId]);
    this.indeterminate = this.dataSource.some(item => this.mapOfCheckedId[item.userId]) && !this.allChecked;
  }

  checkAll(value: boolean): void {
    this.dataSource.forEach(item => this.mapOfCheckedId[item.userId] = value);
    this.refreshStatus();
  }

  userEditorForm(model: User, cb: (form: any) => void) {
    this.userManagementService.getAllRoles()
      .then((roles) => {
      return !model.userId ? {roles, oldRoles: null} :
        this.userManagementService.getUserRoles(model.userId)
          .then((oldRoles) => ({roles, oldRoles}));
    }).then(({roles, oldRoles}) => {
      this.userForm = this.userManagementService.getUserForm(model, oldRoles, roles);
      this.tplModal = this.modalService.create({
        nzTitle: !!model.userId ? '编辑用户' : '新增用户',
        nzContent: this.userEditor,
        nzMaskClosable: false,
        nzClosable: true,
        nzWidth: 800,
        nzFooter: [
          {
            label: '取消',
            onClick: () => this.tplModal.destroy()
          },
          {
            label: '确定',
            type: 'primary',
            onClick: com => {
              cb(convert(this.userForm));
            }
          }
        ]
      });
    });
  }

  submit(user: User, form: any) {
    this.errorMsg = Validation(this.userForm);
    if (this.errorMsg.length > 0) {
      this.errorMsg.forEach(item => this.message.warning(item));
      return;
    }
    if (form.password !== form.password1) {
      this.message.warning('密码前后不一致！');
      return;
    }
    const roles = form.roles;
    if (roles.length <= 0) {
      this.message.warning('请至少选择一个角色');
      return;
    }
    delete form.password1; // 删除不需要的确认密码
    delete form.roles; // 删除角色
    const body = Object.assign(form, {companyId: this.authService.profile['companyId']});
    let message = '';
    if (user.userId) {
      delete form.password;
      this.fetchService.patch(`${REQUEST.USER.ALL}/${user.userId}`, body, (res: any) => {
        if (!res.isError) {
          message = '编辑用户成功';
          this.updateRole(user, roles, message);
        }
      });
    } else {
      this.fetchService.post(`${REQUEST.USER.ALL}`, body, (res: any) => {
        if (!res.isError) {
          const backUser = res as User;
          message = '新增用户成功';
          this.updateRole(backUser, roles, message);
        }
      });
    }
  }

  updateRole(user: User, roles: number[], message: string) {
    this.fetchService.put(`${REQUEST.USER.ALL}/${user.userId}/roles`, roles, (res: any) => {
      if (!res.isError) {
        this.message.success(message);
        this.tplModal.destroy();
        this.searchData();
      }
    });
  }

  create() {
    const user: User = new User();
    this.userEditorForm(user, ((form: any) => this.submit(user, form)));
  }

  update() {
    if (this.selectRecords.length !== 1) {
      this.message.warning('请选择一条记录编辑！');
      return;
    }
    const user: User = this.selectRecords[0];
    this.userEditorForm(user, ((form: any) => this.submit(user, form)));
  }

  remove() {
    if (this.selectRecords.length <= 0) {
      this.message.warning('请选择要删除的记录！');
      return;
    }
    const ids = this.selectRecords.map(item => item.userId);
    this.fetchService.delete(`${REQUEST.USER.ALL}/${ids}`, null, (res: any) => {
      if (!res || !res.isError) {
        this.message.success('删除用户成功！');
        this.searchData();
      }
    });
  }

}
