
/*    非空检查   */
export function isNotNull(field, errorMsg?: string[]) {
  let flag = true;
  if (!field.value || field.value === 'none' || field.value === 'all') {
    flag = false;
    if(field.type === 3){
      errorMsg.push('请先阅读并同意'+ field.urlText);
    }else{
      const tLabel = field.label ? field.label : field.subLabel;
      errorMsg.push(tLabel + '输入不能为空，请重新输入！');
    }
  }
  return flag;
}

/*  数字检查 非负校验 */
export function isNumber(field, errorMsg?: string[]) {
  let result = true;
  // const myNumber = /^(([1-9][0-9]*)|(([0]\.\d{1,4}|[1-9][0-9]*\.\d{1,4})))$/;
  const myNumber =/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/
  if (!myNumber.test(field.value)) {
    errorMsg.push(field.label + '输入有误，只能输入非负数字！');
    result = false;
  }
  return result;
}

/*  日期检查 */
export function isDate(field, errorMsg?: string[]) {
  let result = true;
  const fValue = field.value;
  const value = typeof fValue === 'string' ? fValue : (fValue.year + '-' + fValue.month + '-' + fValue.day);
  const myDate = /^\d{4}(\-|\/|\.)\d{1,2}\1\d{1,2}$/;
  if (!myDate.test(value)) {
    errorMsg.push(field.label + '输入有误，请输入正确的日期格式！');
    result = false;
  }
  return result;
}

/*  电话检查 */
export function isPhone(field, errorMsg?: string[]) {
  let result = true;
  // const myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
  const myreg = /^[0-9][0-9][0-9]{9}$/;
  const myreg1 = /\d{3,4}-\d{7,8}$/;
  if (myreg.test(field.value) || myreg1.test(field.value)) {
    
  } else {
    errorMsg.push(field.label + '输入有误，请输入正确的电话号码！');
    result = false;
  }
  return result;
}

export function isPlateNum(field, errorMsg?: string[]) {
  let result = true;
  // const myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
  // const myreg = /^[0-9][0-9][0-9]{9}$/;
  // const myreg1 = /\d{3,4}-\d{7,8}$/;
  const len = field.value;
  if (len.length !== 7) {
    errorMsg.push(field.label + '输入有误，请输入正确的车牌号！');
    result = false;
  }
  return result;
}

/*  邮箱检查 */
export function isEmail(field, errorMsg?: string[]) {
  let result = true;
  const myemail = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
  if (!myemail.test(field.value)) {
    errorMsg.push(field.label + '输入有误，请输入正确的邮箱！');
    result = false;
  }
  return result;
}

/*  身份证检查 */
export function isID(field, errorMsg?: string[]) {
  let result = true;
  const myid = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X|x)$/;
  if (!myid.test(field.value)) {
    errorMsg.push(field.label + '输入有误，请输入正确的身份证号码！');
    result = false;
  }
  return result;
}

/*  纳税人识别号校验 */
export function isTaxCode(field, errorMsg?: string[]) {
  let result = true;
  const myTaxCode = /(^[\w]{15}$)|(^[\w]{18}$)|(^[\w]{20}$)/;//匹配位数为15，18，20的纳税人识别号
  if (!myTaxCode.test(field.value)) {
    errorMsg.push(field.label + '输入有误，请输入位数为15或18或20的纳税人识别号！');
    result = false;
  }
  return result;
}

// 必传图验证
export function isUpdateImg(fieldList) {
  if (!fieldList.length) {
    return;
  }
  const errorMsg = [];
  fieldList.map(field => {
    if (!field.required || field.isHide) {
      return;
    }
    if (!field.imgPath.path) {
      // || field.imgPath.displayPath.indexOf('undefined')>-1
      errorMsg.push('请上传'+ field.imgTit);
      return;
    }
  });
  return errorMsg;
}

// 空格校验
export const spaceValidator = (val: string): boolean => {
  if (val.indexOf(' ') === -1) {
    return false;
  } else {
    return true;
  }
};

export function Validation (fieldList) {
  if (!fieldList || !fieldList.length) {
    return;
  }
  const errorMsg = [];
  fieldList.map((field) => {
    if (!field.required || field.isHide) {
      return;
    }
    
    /*  rule可以默认不配置，表示默认非空检查判断  */
    if (!isNotNull(field, errorMsg)) {
      return;
    }
    if (field.rules && field.rules.length) {
      if (field.rules instanceof Array) {
        field.rules.map((rule) => {
          switch (rule) {
            case 'isNumber' : isNumber(field, errorMsg); break;
            /* case 'isDate' : isDate(field, errorMsg); break; */
            case 'isPhone' : isPhone(field, errorMsg); break;
            case 'isEmail' : isEmail(field, errorMsg); break;
            case 'isID' : isID(field, errorMsg); break;
            case 'isTaxCode': isTaxCode(field, errorMsg); break;
            case 'isPlateNum': isPlateNum(field, errorMsg); break;
          }
        });
      } else if (field.rules instanceof Object) {
        /** 可以继续完善 */
        const keys = Object.keys(field.rules);
        keys.map((key) => {
          if (field.rules.hasOwnProperty(key)) {
            /* switch (key) {
              case 'isNumber' : this.isNumber(); break;
              case 'isDate' : this.isDate(); break;
              case 'isPhone' : this.isPhone(); break;
              case 'isEmail' : this.isEmail(); break;
              case 'isID' : this.isID(); break;
            } */
          }
        });
      }
    }
  });
  return errorMsg;
}
