import { Menu, Condition, AmountPanel, QuicklyLink, Base } from '../../shared/model/carModels';
import { carType, carTypeByHangup, freightType, freightSort, licenseType, invoiceState, payState, unit, businessType, newpayState, newinvoiceState, zxType, sexType, ethnicType } from './dropdown.conf';

/*  左侧菜单表格 */
export const Menus: Menu[] = [
  {
    id: 'userInformation',
    name: '用户信息',
    path: '/userInformation',
    icon: 'user-o',
    hasSub: false
  },
  {
    id: 'safety',
    path: '/safety',
    name: '安全管理',
    icon: 'safety-certificate',
    hasSub: true,
    permissions: ['200801001', '200802001'],
    subMenus: [
      { id: 'userManagement', name: '用户管理', path: '/safety/userManagement', icon: 'user-o', permissions: ['200801001'], hasSub: false, },
      { id: 'roleManagement', name: '角色管理', path: '/safety/roleManagement', icon: 'safety-certificate', permissions: ['200802001'], hasSub: false, },
    ]
  },
  {
    id: 'carManagement',
    path: '/carManagement',
    name: '车辆管理',
    icon: 'car',
    hasSub: true,
    permissions: ['200601001', '200602001'],
    subMenus: [
      { id: 'carList', name: '车辆列表', path: '/carManagement/carList', icon: 'car', permissions: ['200602001'], hasSub: false, },
      { id: 'carHangup', name: '车辆挂靠申请', path: '/carManagement/carHangup', icon: 'car', permissions: ['200601001'], hasSub: false, },
    ]
  },
  {
    id: 'driverManagement',
    name: '司机管理',
    path: '/driverManagement',
    icon: 'usergroup-delete',
    hasSub: true,
    permissions: ['200701001', '200702001'],
    subMenus: [
      { id: 'auditList', name: '已审核司机', path: '/driverManagement/auditList', icon: 'usergroup-delete', permissions: ['200701001'], hasSub: false, },
      { id: 'unAuditList', name: '未审核司机', path: '/driverManagement/unAuditList', icon: 'usergroup-delete', permissions: ['200702001'], hasSub: false, },
    ]
  },
  {
    id: 'orderRecord',
    path: '/orderRecord',
    name: '订单管理',
    icon: 'ordered-list',
    hasSub: true,
    permissions: ['200101002', '200102002'],
    subMenus: [
      { id: 'preDistManagement', name: '预派车单管理', path: '/orderRecord/preDistManagement', icon: 'file-text-o', permissions: ['200101002'], hasSub: false, },
      { id: 'distManagement', name: '派车单管理', path: '/orderRecord/distManagement', icon: 'file-text-o', permissions: ['200102002'], hasSub: false, }
    ]
  },
  {
    id: 'valueAddService',
    path: '/valueAddService',
    name: '增值服务',
    icon: 'file-text-o',
    hasSub: true,
    permissions: ['200201001', '200202001'],
    subMenus: [
      { id: 'etcRecord', name: 'ETC记录', icon: 'car', path: '/valueAddService/etcRecord', permissions: ['200202001'], hasSub: false, },
      { id: 'oilcardRecord', name: '油卡记录', icon: 'idcard', path: '/valueAddService/oilcardRecord', permissions: ['200201001'], hasSub: false, },
      // {id: 'positionRecord', name: '定位管理', icon: 'map-marker', path: '/valueAddService/positionRecord'}
    ]
  },
  {
    id: 'billingRecord',
    name: '开票管理',
    path: '/billingRecord',
    icon: 'printer',
    hasSub: false,
    permissions: ['200400001']
  },
  {
    id: 'balanceRecord',
    path: '/balanceRecord',
    name: '结算管理',
    icon: 'account-book',
    hasSub: true,
    permissions: ['200302001', '200301001'],
    subMenus: [
      { id: 'tradeDetail', name: '交易明细', path: '/balanceRecord/tradeDetail', icon: 'account-book', permissions: ['200302001'], hasSub: false, },
      // {id: 'errorDetail', name: '错误记录', path: 'balanceRecord/errorDetail'},
      { id: 'paymentRecord', name: '付款记录', path: '/balanceRecord/paymentRecord', icon: 'account-book', permissions: ['200301001'], hasSub: false, }]
  },
  {
    id: 'insurance',
    name: '保单管理',
    path: '/insurance',
    icon: 'insurance',
    hasSub: false,
    permissions: ['200900001']
  },
  {
    id: 'partner',
    name: '合作伙伴',
    path: '/partner',
    icon: 'contacts',
    hasSub: false,
    permissions: ['201000001']
  },
  {
    id: 'logManagement',
    path: '/logManagement',
    name: '日志管理',
    icon: 'file-text-o',
    hasSub: false,
    permissions: ['201100001']
  },
  {
    id: 'creditService',
    name: '信用服务',
    path: '/creditService',
    icon: 'file-text-o',
    hasSub: false,
    permissions: ['201200001']
  },
  // {
  //   id: 'orderInsurance',
  //   name: '运单保险',
  //   path: '/orderInsurance',
  //   icon: 'insurance',
  //   hasSub: false,
  //   permissions: ['200900001']//TODO
  // },
];

/*  面包线配置 */
export const ROUTER_BASE = [
  { path: 'userInformation', name: '用户信息' },
  { path: 'carManagement', name: '车辆管理' },
  { path: 'carList', name: '车辆列表' },
  { path: 'carBind', name: '挂车绑定' },
  { path: 'carInfoEdit', name: '车辆信息编辑' },
  { path: 'carHangup', name: '车辆挂靠申请' },
  { path: 'driverManagement', name: '司机管理' },
  { path: 'auditList', name: '已审核司机' },
  { path: 'unAuditList', name: '未审核司机' },
  { path: 'driverInfoEdit', name: '司机信息编辑' },
  { path: 'driverInfoComplete', name: '司机绑定' },
  { path: 'orderRecord', name: '订单管理' },
  { path: 'preDistManagement', name: '预派车单管理' },
  { path: 'create', name: '新建预派车单' },
  { path: 'distManagement', name: '派车单管理' },
  { path: 'valueAddService', name: '增值服务' },
  { path: 'oilcardRecord', name: '油卡记录' },
  { path: 'etcRecord', name: 'ETC记录' },
  { path: 'positionRecord', name: '定位管理' },
  { path: 'billingRecord', name: '开票管理' },
  { path: 'detail', name: '开票详情' },
  { path: 'balanceRecord', name: '结算管理' },
  { path: 'tradeDetail', name: '交易明细' },
  // {path: 'errorDetail', name: '错误记录'},
  { path: 'paymentRecord', name: '付款记录' },
  { path: 'userSetting', name: '个人设置' },
  { path: 'userManagement', name: '用户管理' },
  { path: 'roleManagement', name: '角色管理' },
  { path: 'insurance', name: '保单管理' },
  { path: 'partner', name: '合作伙伴' },
  { path: 'safety', name: '安全管理' },
  { path: 'logManagement', name: '日志管理' },
  { path: 'creditService', name: '信用服务' },
  { path: 'orderInsurance', name: '运单保险' },
];

/*  订单记录搜索条件 */
export const ORDER_COND: Condition[] = [
  { type: 1, label: '运单号：', value: '', key: 'key1', placeholder: '请输入运单号...' },
  { type: 1, label: '司机姓名：', value: '', key: 'key2', placeholder: '请输入司机姓名...' },
  { type: 1, label: '司机手机号：', value: '', key: 'key3', placeholder: '请输入司机手机号...' },
  { type: 1, label: '车牌号：', value: '', key: 'key4', placeholder: '请输入车牌号...' },
  {
    type: 2, label: '支付状态：', value: 'all', key: 'key5',
    option: [
      { name: '未支付', value: '1' },
      { name: '风控核实中', value: '2' },
      { name: '核实中', value: '3' },
      { name: '业务审核', value: '4' },
      { name: '预申请', value: '5' },
      { name: '已申请', value: '6' },
      { name: '部分支付', value: '7' },
      { name: '已支付', value: '8' },
    ]
  },
  {
    type: 2, label: '开票状态：', value: 'all', key: 'key6',
    option: [
      { name: '未申请', value: '1' },
      { name: '申请中', value: '2' },
      { name: '已开票', value: '3' },
      { name: '可开票信息不足', value: '4' }
    ]
  },
  {
    type: 4, label: '建单时间：', key: 'key7',
    option: [
      { name: 'start', value: '' },
      { name: 'end', value: '' }
    ]
  },
  {
    type: 2, label: '跟踪状态：', value: 'all', key: 'key8',
    option: [
      { name: '议价中', value: '1' },
      { name: '未追踪', value: '2' },
      { name: '追踪中', value: '3' },
      { name: '已终结', value: '4' }
    ]
  },
  {
    type: 2, label: '受票方：', value: 'all', key: 'key9',
    option: [
      { name: '公司1', value: '1' },
      { name: '公司2', value: '2' }
    ]
  },
  {
    type: 5, label: '运输路线：', key: 'key10',
    option: [
      { name: 'start', value: '', placeholder: '起始位置...' },
      { name: 'end', value: '', placeholder: '结束位置...' }
    ]
  },
  {
    type: 4, label: '回单上传时间：', key: 'key13',
    option: [
      { name: 'start', value: '' },
      { name: 'end', value: '' }
    ]
  },
  { type: 3, label: '是否有油卡：', key: 'key11', value: '' },
  { type: 3, label: '是否有ETC：', key: 'key12', value: '' },
];

/*  定位管理搜索条件 */
export const POSITION_COND: Condition[] = [
  { type: 1, label: '', value: '', key: 'key1', placeholder: '请输入您要查找的车牌号' },
  {
    type: 2, label: '', value: '1', key: 'key5',
    option: [
      { name: '获取时间', value: '1' },
    ]
  }
];

/*  派车单管理条件项 */
export const CAR_MANAGEMENT_COND: Condition[] = [
  { type: 1, label: '车牌号', value: '', key: 'plateNum', placeholder: '请输入您要查找的车牌号' },
  { type: 1, label: '所属公司', value: '', key: 'companyName', placeholder: '请输入车辆所属的公司' },
  { type: 2, label: '车辆类型', value: '', key: 'carType', placeholder: '请选择车辆类型', option: JSON.parse(JSON.stringify(carType)) },
  { type: 1, label: '常运线路', value: '', key: 'normalRoad', placeholder: '请选择常运线路' },
  {
    type: 11, label: '是否审核通过', value: '', key: 'verifyState', placeholder: '请选择运输线路',
    option: [
      { name: '是', value: '1' },
      { name: '否', value: '0' },
    ]
  }
];

/*  订单管理-预派车单高级搜索条件 */
export const PRE_DIST_COND: Condition[] = [
  { type: 2, label: '订单类型：', value: '', key: 'sync', option: [], required: true, change: (): void => { } },
  { type: 1, label: '运单编号：', value: '', key: 'order_code', required: true, placeholder: '请输入' },
  {
    type: 2, label: '货物类型：', value: '', key: 'goods_property_type_code', required: true, placeholder: '请输入',
    option: JSON.parse(JSON.stringify(freightType))
  },
  {
    type: 13, label: '始发地：', value: '', key: 'cargo_address', required: true, placeholder: '请选择',
    option: [], focus: (): void => { }
  },
  {
    type: 13, label: '目的地：', value: '', key: 'delivery_address', required: true, placeholder: '请选择',
    option: [], focus: (): void => { }
  },
  { type: 4, label: '最早起运：', value: '', key: 'pickup_start_time', rules: ['isDate'], required: true, placeholder: '请选择时间' },
  { type: 4, label: '最晚到达：', value: '', key: 'unload_end_time', rules: ['isDate'], required: true, placeholder: '请选择时间' },
];

/*  订单管理-预派车单--创建--条件 */
export const PRE_DIST_COND_CREATE: Condition[] = [
  { type: 2, label: '订单类型：', value: '00554', key: 'sync', option: [], required: true, change: (): void => { } },
  { type: 1, label: '货物名称：', value: '', key: 'title', required: true, placeholder: '请输入' },
  { type: 17, label: '运费金额：', value: '0', key: 'totalCharge', rules: ['isNumber'], required: true, placeholder: '请输入', min: 0, precision: 2 },
  { type: 17, label: '装车预付费：', value: '0', key: 'prepayment', rules: ['isNumber'], required: true, placeholder: '请输入', min: 0, precision: 2 },
  {
    type: 1, label: '货物重量：', value: '', key: 'cargoNum', rules: ['isNumber'], required: true, placeholder: '请输入', unit: {
      key: 'unit', value: '吨',
      unitList: JSON.parse(JSON.stringify(unit))
    }
  },
  {
    type: 1, label: '单位重量：', value: '', key: 'unitWight', rules: ['isNumber'], required: true, placeholder: '请输入', unit: {
      key: 'unit1', value: '公斤/千克',
      unitList: JSON.parse(JSON.stringify(unit)), disabled: true
    }
  },
  { type: 14, label: '提货最晚时间：', value: '', key: 'pickupEndTime', rules: ['isDate'], required: true, placeholder: '请选择' },
  { type: 14, label: '预计卸货时间：', value: '', key: 'unloadEndTime', rules: ['isDate'], required: true, placeholder: '请选择' },
  {
    type: 2, label: '货物类型：', value: '', key: 'cargoType', required: true, placeholder: '请选择',
    // option: JSON.parse(JSON.stringify(freightSort))
  },
  {
    type: 2, label: '货物分类：', value: '', key: 'goodsPropertyTypeCode', required: true, placeholder: '请选择',
    option: JSON.parse(JSON.stringify(freightType))
  },
  { type: 2, label: '收货人信息：', value: '', key: 'anlianConsigneeId', required: true, placeholder: '请选择' },
  { type: 2, label: '发货人信息：', value: '', key: 'anlianConsignerId', required: true, placeholder: '请选择' },
  { type: 14, label: '发单时间：', value: '', key: 'fdsj', isHide: true, rules: ['isDate'], required: true, placeholder: '请选择' },
  { type: 1, label: '发单方式：', value: 'PC', key: 'fdfs', isHide: true, required: true, placeholder: 'PC', disabled: true },
  { type: 1, label: '货物所属行业：', value: '', key: 'codename', required: true, placeholder: '请选择' },
  { type: 1, label: '货物所属行业：', value: '', key: 'hwsshy', required: true, isHide: true, placeholder: '请选择' },
  {
    type: 10, label: '装货地点：', value: '', key: 'cargoAddress', required: true, placeholder: '请选择',
    option: [], isSearch: true
  },
  { type: 12, label: '', subLabel: '装货地点详细地址', value: '', key: 'cargoAddrDetail', required: true, placeholder: '请输入详细地址（如门牌号等）' },
  {
    type: 10, label: '卸货地点：', value: '', key: 'deliveryAddress', required: true, placeholder: '请选择',
    option: [], isSearch: true
  },
  { type: 12, label: '', subLabel: '卸货地点详细地址', value: '', key: 'deliveryAddrDetail', required: true, placeholder: '请输入详细地址（如门牌号等）' },
];

/*  ETC记录搜索条件 */
export const ETC_COND: Condition[] = [
  { type: 1, label: 'ETC卡号：', value: '', key: 'etcNum', placeholder: '请输入ETC卡号...' },
  { type: 1, label: '司机姓名：', value: '', key: 'driverName', placeholder: '请输入司机姓名...' },
  { type: 1, label: '手机号码：', value: '', key: 'mobile', placeholder: '请输入手机号码...' },
  { type: 1, label: '车牌号码：', value: '', key: 'plateNum', placeholder: '请输入手机号码...' }
];

/*  司机资料完善 */
export const DRIVER_MANAGE_INFO: Condition[] = [
  { type: 1, label: '司机姓名：', value: '', key: 'name', required: true, placeholder: '请输入司机姓名' },
  { type: 1, label: '手机号码：', value: '', key: 'mobile', required: true, placeholder: '请输入手机号码' },
  { type: 1, label: '驾驶证号码：', value: '', key: 'driverLicence', required: true, placeholder: '请输入车牌号码' },
  {
    type: 2, label: '驾照证类型：', value: '', key: 'licenseType', required: true, placeholder: '驾照证类型',
    option: JSON.parse(JSON.stringify(licenseType))
  }
];

/*  挂车资料完善 */
export const HANGUP_CAR_COND: Condition[] = [
  { type: 1, label: '车牌号：', value: '', key: 'plateNum', required: true, placeholder: '请输入车牌号' },
  { type: 1, label: '车辆识别代码：', value: '', key: 'vehicleIdentityCode', required: true, placeholder: '请输入车辆识别代码' },
  // {type: 1, label: '核定载质量(公斤)：', value: '', key: 'capacityTonnage', required: true, placeholder: '请输入核定载质量'},
  { type: 2, label: '标准车辆类型', value: '', key: 'carType', required: true, placeholder: '请选择车辆类型', option: JSON.parse(JSON.stringify(carTypeByHangup)) },
  { type: 1, label: '所有人：', value: '', key: 'owner', required: true, placeholder: '请输入所有人' },
  { type: 1, label: '行驶证号码：', value: '', key: 'vehcileLicenseCode', required: true, placeholder: '请输入' },
  {
    type: 1, label: '车辆长度：', value: '', key: 'length', required: true, unit: {
      key: 'unit', value: '米', unitList:
        JSON.parse(JSON.stringify(unit)), disabled: true
    }, rules: ['isNumber'], placeholder: '请输入'
  },
  {
    type: 1, label: '车辆载重：', value: '', key: 'capacityTonnage', required: true, unit: {
      key: 'unit', value: '公斤/千克',
      unitList: JSON.parse(JSON.stringify(unit)), disabled: true
    }, rules: ['isNumber'], placeholder: '请输入'
  },
  {
    type: 1, label: '车辆宽度：', value: '', key: 'vehicleWidth', unit: {
      key: 'unit', value: '米',
      unitList: JSON.parse(JSON.stringify(unit)), disabled: true
    },
    rules: ['isNumber'], placeholder: '请输入'
  },
  { type: 1, label: '承运车辆品牌：', value: '', key: 'cypp', required: true, placeholder: '请输入' }
];

/*  司机资料完善 */
export const DRIVER_INFO: Condition[] = [
  { type: 1, label: '注册手机号：', required: true, value: '', key: 'mobile', disabled: true, placeholder: '请输入' },
  /* {type: 6, label: '手机验证码：', required: true, value: '', key: 'phoneValidCode', rules: ['isNumber'], placeholder: '',
  timer: 60, btnText: '获取验证码', disabled: false, url: ''},
  {type: 7, label: '登陆密码：', required: true, value: '123', key: 'password1', placeholder: '请输入'},
  {type: 7, label: '确认密码：', required: true, value: '123', key: 'password2', placeholder: '请输入'},*/
  { type: 1, label: '司机姓名：', required: true, value: '', key: 'name', placeholder: '请输入' },
  {
    type: 1, label: '身份证号码：', required: true, value: '', key: 'idNum', disabled: true, rules: ['isID'],
    placeholder: '请输入十八位身份证号码'
  },
  {
    type: 2, label: '身份证是否永久：', required: true, value: 'false', key: 'identityForever', placeholder: '请选择',
    option: [
      { name: '是', value: 'true' },
      { name: '否', value: 'false' },
    ]
  },
  { type: 4, label: '身份证发证日期：', required: true, value: null, key: 'identityStartDate', rules: ['isDate'], placeholder: '请选择' },
  { type: 4, label: '身份证截至日期：', required: true, value: null, key: 'identityEndDate', rules: ['isDate'], placeholder: '请选择' },
  { type: 1, label: '驾驶证号码：', required: true, value: '', key: 'driverLicence', placeholder: '请输入驾驶证号码' },
  {
    type: 2, label: '驾驶证类型：', required: true, value: '', key: 'licenseType', placeholder: '请选择',
    option: JSON.parse(JSON.stringify(licenseType))
  },
  { type: 1, label: '纳税人姓名：', required: true, value: '', key: 'taxpayerName', placeholder: '请输入', dValue: 'name' },
  { type: 1, label: '纳税人身份证号码：', required: true, value: '', key: 'taxpayerIdentity', rules: ['isID'], placeholder: '请输入十八位身份证号码', dValue: 'idNum' },
  { type: 1, label: '纳税人手机号：', required: true, value: '', key: 'taxpayerMobile', placeholder: '请输入', dValue: 'mobile' },
  { type: 1, label: '税登银行：', required: true, value: '', key: 'bankName', placeholder: '请输入' },
  { type: 1, label: '税登银行账号：', required: true, value: '', key: 'bankAccout', placeholder: '请输入' },
  { type: 1, label: '道路从业资格证号:', required: true, value: '', key: 'rtqcNum', placeholder: '请输入' },
  { type: 2, label: '从业资格证所属省份:', value: '', key: 'provinceCode', placeholder: '请选择', option: [], required: true },
  { type: 4, label: '从业资格证有效期自:', required: true, value: null, key: 'qualificationCertificateValidPeriodFrom', rules: ['isDate'], placeholder: '请选择' },
  { type: 4, label: '从业资格证有效期至:', required: true, value: null, key: 'qualificationCertificateValidPeriodTo', rules: ['isDate'], placeholder: '请选择' },
  { type: 1, label: '身份证件地址:', required: true, value: '', key: 'sfzjdz', placeholder: '请输入' },
  { type: 2, label: '性别代码:', required: true, value: '', key: 'xbdm', placeholder: '请选择', option: JSON.parse(JSON.stringify(sexType)) },
  { type: 2, label: '民族代码:', required: true, value: '', key: 'mzdm', placeholder: '请选择', option: JSON.parse(JSON.stringify(ethnicType)) },
  { type: 1, label: '发证机关:', required: true, value: '', key: 'fzjg', placeholder: '请输入' },
  { type: 4, label: '出生日期:', required: true, value: null, key: 'csrq', rules: ['isDate'], placeholder: '请选择' },
  { type: 4, label: '首次签发日期：', required: false, value: null, key: 'licenseFirstDetDate', rules: ['isDate'], placeholder: '请选择' },
  { type: 4, label: '有效期开始日期：', required: false, value: null, key: 'licenseStartDate', rules: ['isDate'], placeholder: '请选择' },
  { type: 4, label: '有效期结束日期：', required: false, value: null, key: 'licenseEndDate', rules: ['isDate'], placeholder: '请选择' },
  { type: 1, label: '驾驶证签发机关:', required: false, value: '', key: 'issuingOrganization', placeholder: '请输入' },


];

/*  车辆资料完善 */
export const CAR_INFO: Condition[] = [
  { type: 1, label: '车主：', value: '', key: 'owner', required: true, placeholder: '请输入' },
  { type: 1, label: '车牌号码：', value: '', key: 'plateNum', required: true, placeholder: '请输入' },

  {
    type: 1, label: '车辆载重：', value: '', key: 'capacityTonnage', required: true, unit: {
      key: 'unit', value: '公斤/千克',
      unitList: JSON.parse(JSON.stringify(unit)), disabled: true
    }, rules: ['isNumber'], placeholder: '请输入'
  },

  { type: 1, label: '常跑地区：', value: '', key: 'normalRoad', required: true, placeholder: '请输入' },
  { type: 1, label: '道路许可运输证：', value: '', key: 'operationCertificate', required: true, placeholder: '请输入' },
  { type: 1, label: '行驶证号码：', value: '', key: 'vehcileLicenseCode', required: true, placeholder: '请输入' },
  { type: 2, label: '车辆类型：', value: '', key: 'carType', required: true, placeholder: '请选择', option: JSON.parse(JSON.stringify(carType)) },
  { type: 1, label: '车架号：', value: '', key: 'vehicleIdentityCode', required: true, placeholder: '请选择' },
  { type: 2, label: '车辆能源类型：', value: '', key: 'vehicleEnergyType', placeholder: '请选择', option: [], required: true },
  { type: 4, label: '道路运输证有效期自：', required: true, value: null, key: 'roadTransportCertificateValidPeriodFrom', rules: ['isDate'], placeholder: '请选择' },
  { type: 4, label: '道路运输证有效期至：', required: true, value: null, key: 'roadTransportCertificateValidPeriodTo', rules: ['isDate'], placeholder: '请选择' },
  {
    type: 1, label: '车辆长度：', value: '', key: 'length', required: false, unit: {
      key: 'unit', value: '米', unitList:
        JSON.parse(JSON.stringify(unit)), disabled: true
    }, rules: ['isNumber'], placeholder: '请输入'
  },
  {
    type: 1, label: '车辆宽度：', value: '', key: 'vehicleWidth', unit: {
      key: 'unit', value: '米',
      unitList: JSON.parse(JSON.stringify(unit)), disabled: true
    },
    rules: ['isNumber'], placeholder: '请输入'
  },
  { type: 1, label: '承运车辆品牌：', value: '', key: 'cypp', required: false, placeholder: '请输入' },
  { type: 1, label: '行驶证发证机关：', value: '', key: 'issuingOrganization', required: false, placeholder: '请输入' },
  { type: 4, label: '行驶证注册日期：', required: false, value: null, key: 'registerDate', rules: ['isDate'], placeholder: '请选择' },
  { type: 4, label: '行驶证发证日期：', required: false, value: null, key: 'issueDate', rules: ['isDate'], placeholder: '请选择' },
];

/*  收款信息完善 */
export const ACCOUNT_INFO: Condition[] = [
  { type: 1, label: '收款人姓名：', value: '', key: 'key1', required: true, placeholder: '请输入' },
  { type: 1, label: '身份证号码：', value: '', key: 'key2', required: true, rules: ['isID'], placeholder: '请输入十八位身份证号码' },
  { type: 1, label: '开户行：', value: '', key: 'key2', required: true, placeholder: '请输入' },
  { type: 1, label: '联系手机号：', value: '', key: 'key2', required: true, rules: ['isPhone'], placeholder: '请输入' },
  {
    type: 6, label: '手机验证码：', required: true, value: '', key: 'key1', rules: ['isNumber'], placeholder: '',
    timer: 60, btnText: '获取验证码', disabled: false
  },
  { type: 1, label: '收款账号：', value: '', key: 'key2', required: true, placeholder: '请输入' },
  { type: 1, label: '收款银行：', value: '', key: 'key2', required: true, placeholder: '请输入' },
  { type: 1, label: '开户行省份：', value: '', key: 'key2', required: true, placeholder: '请输入' }
];

/*  用户信息账户余额、充值金额、消费金额 */
export const USER_AMOUNT_LIST: AmountPanel[] = [
  { id: '22', name: '账户余额', amount: '0', key: 'key1', fontIcon: 'dollar', backgroundImg: '' },
  { id: '17', name: '充值金额', amount: '0', key: 'key2', fontIcon: 'dollar', backgroundImg: '' },
  { id: '3', name: '消费金额', amount: '0', key: 'key2', fontIcon: 'dollar', backgroundImg: '' }
];

/*  发布订单、增加车辆等配置 */
export const QUICKLY_LINK_LIST: QuicklyLink[] = [
  { id: '200101001', name: '新建订单', action: '新建', fontIcon: 'plus', routePath: '/orderRecord/preDistManagement/create' },
  { id: '200602001', name: '查看车辆', action: '查看', fontIcon: 'search', routePath: '/carManagement/carList' },
  { id: '200201001', name: '油卡充值', action: '充值', fontIcon: 'edit', routePath: '/valueAddService/oilcardRecord' },
  { id: '200202001', name: 'ETC充值', action: 'ETC', fontIcon: 'edit', routePath: '/valueAddService/etcRecord' },
  { id: '200400001', name: '开具发票', action: '发票', fontIcon: 'edit', routePath: '/billingRecord' },
  // {id: '200503000', name: '公对公转账', action: '转账', fontIcon: 'dollar', routePath: '/userSetting/2'},
  // {id: '006', name: '车辆定位', action: '定位', fontIcon: 'edit', routePath: '/valueAddService/positionRecord'}
];

/*  发布订单、增加车辆等配置 */
export const CHOOSE_CAR: Condition[] = [
  { type: 1, label: '车牌号码：', value: '', key: 'car_identity', placeholder: '请输入' },
  /*   {type: 1, label: '车辆长度：', value: '', key: 'car_len', rules: ['isNumber'], placeholder: '请输入'},
    {type: 2, label: '货物类型：', value: '', key: 'cargo_type', placeholder: '请选择', option: JSON.parse(JSON.stringify(freightType))}, */
  { type: 1, label: '当前位置：', value: '', key: 'location', placeholder: '请输入' },
  { type: 3, label: '显示签到司机：', key: 'sign', value: false },
];

/*  车辆挂靠申请过滤条件 */
export const CAR_HANGUP: Condition[] = [
  { type: 1, value: '', key: 'driver_phoneNum', rules: ['isPhone'], placeholder: '请输入您要查找的手机号' },
  { type: 1, value: '', key: 'driver_name', placeholder: '请输入司机姓名' }
];

/*  派车单管理条件项 */
export const DIST_COND: Condition[] = [
  { type: 2, label: '订单类型：', value: '', key: 'sync', option: [], change: (): void => { } },
  { type: 1, label: '运单编号：', value: '', key: 'car_order_code', placeholder: '请输入' },
  { type: 1, label: '司机姓名：', value: '', key: 'name', placeholder: '请输入' },
  { type: 1, label: '车牌号：', value: '', key: 'plate_num', placeholder: '请输入' },
  { type: 2, label: '支付状态：', value: '', key: 'pay_state', placeholder: '请选择', option: JSON.parse(JSON.stringify(payState)) },
  { type: 2, label: '开票状态：', value: '', key: 'invoice_state', placeholder: '请选择', option: JSON.parse(JSON.stringify(invoiceState)) },
  { type: 4, label: '建单时间：', value: '', key: 'dilivery_time_actual', placeholder: '请选择' },
  {
    type: 10, label: '运输线路起：', value: '', key: 'cargo_address', placeholder: '请选择',
    option: [], focus: (): void => { }
  },
  {
    type: 10, label: '运输线路止：', value: '', key: 'delivery_address', placeholder: '请选择',
    option: [], focus: (): void => { }
  },
  // {type: 9, label: '时间区间：', value: [], key: 'time_inteval', placeholder: '请选择'}
];

/*  开票管理条件项 */
export const BILLING_COND: Condition[] = [
  { type: 1, label: '受票方：', value: '', key: 'drawee_name', placeholder: '请输入' },
  { type: 2, label: '开票状态：', value: '', key: 'state', placeholder: '请选择', option: JSON.parse(JSON.stringify(newinvoiceState)) },
  { type: 1, label: '发票代码：', value: '', key: 'invoice_code', placeholder: '请输入' },
  { type: 1, label: '运单号：', value: '', key: 'delivery_code', placeholder: '请输入' },
  {
    type: 4, label: '申请时间起：', value: '', key: 'apply_btm', placeholder: '请选择', rules: ['isDate'],
    option: [
      { name: '你好', value: '2' }
    ]
  },
  {
    type: 4, label: '开票时间起：', value: '', key: 'perform_btm', placeholder: '请选择', rules: ['isDate'],
    option: [
      { name: '你好', value: '2' }
    ]
  },
  {
    type: 4, label: '申请时间止：', value: '', key: 'apply_etm', placeholder: '请选择', rules: ['isDate'],
    option: [
      { name: '你好', value: '2' }
    ]
  },
  {
    type: 4, label: '开票时间止：', value: '', key: 'perform_etm', placeholder: '请选择', rules: ['isDate'],
    option: [
      { name: '你好', value: '2' }
    ]
  }
];

/*  交易明细条件项 */
export const DEAL_COND: Condition[] = [
  { type: 1, label: '对方户名：', value: '', key: 'opposite_account_name', placeholder: '请输入' },
  { type: 1, label: '对方账户：', value: '', key: 'opposite_account', placeholder: '请输入' },
  { type: 1, label: '支付单号：', value: '', key: 'offer_order_code', placeholder: '请输入' },
  { type: 1, label: '操作人：', value: '', key: 'payer', placeholder: '请输入' },
  {
    type: 4, label: '支付时间起：', value: '', key: 'offer_date_btm', placeholder: '请选择', rules: ['isDate'],
    option: [
      { name: '你好', value: '2' }
    ]
  },
  {
    type: 4, label: '支付时间止：', value: '', key: 'offer_date_etm', placeholder: '请选择', rules: ['isDate'],
    option: [
      { name: '你好', value: '2' }
    ]
  },
  { type: 2, label: '业务类型：', value: '', key: 'offer_type', placeholder: '请选择', option: JSON.parse(JSON.stringify(businessType)) },

];

/*  付款记录条件项 */
export const PAYMENT_COND: Condition[] = [
  { type: 1, label: '订单编号：', value: '', key: 'car_order_code', placeholder: '请输入' },
  { type: 1, label: '司机姓名：', value: '', key: 'name', placeholder: '请输入' },
  { type: 1, label: '司机手机号：', value: '', key: 'mobile', placeholder: '请输入' },
  { type: 1, label: '申请人姓名：', value: '', key: 'proposer', placeholder: '请输入' },
  { type: 2, label: '业务类型：', value: '', key: 'offer_type', placeholder: '请选择', option: JSON.parse(JSON.stringify(businessType)) },
  {
    type: 4, label: '建单时间起：', value: '', key: 'build_order_date_b', placeholder: '请选择', rules: ['isDate'],
    option: [
      { name: '你好', value: '2' }
    ]
  },
  {
    type: 4, label: '建单时间止：', value: '', key: 'build_order_date_e', placeholder: '请选择', rules: ['isDate'],
    option: [
      { name: '你好', value: '2' }
    ]
  },
  {
    type: 4, label: '申请时间起：', value: '', key: 'application_date_b', placeholder: '请选择', rules: ['isDate'],
    option: [
      { name: '你好', value: '2' }
    ]
  },
  {
    type: 4, label: '申请时间止：', value: '', key: 'application_date_e', placeholder: '请选择', rules: ['isDate'],
    option: [
      { name: '你好', value: '2' }
    ]
  },
  { type: 1, label: '运输开始位置：', value: '', key: 'cargo_address', placeholder: '请输入' },
  { type: 1, label: '运输完成位置：', value: '', key: 'delivery_address', placeholder: '请输入' },
  { type: 2, label: '支付状态：', value: '', key: 'offer_state', placeholder: '请选择', option: JSON.parse(JSON.stringify(newpayState)) },
];

/*  油卡记录条件项 */
export const OILCARD_COND: Condition[] = [
  { type: 1, label: '油卡卡号：', value: '', key: 'oilCardNum', placeholder: '请输入' },
  { type: 1, label: '司机姓名：', value: '', key: 'driverName', placeholder: '请输入' },
  { type: 1, label: '手机号码：', value: '', key: 'phoneNum', placeholder: '请输入' },
  { type: 1, label: '车牌号码：', value: '', key: 'carNum', placeholder: '请输入' },
  // {type: 2, label: '油卡状态：', value: '', key: 'status', placeholder: '请选择',
  //   option: [
  //     {name: '未绑定', value: '2'},
  //     {name: '绑定', value: '1'},
  //     {name: '丢失', value: '3'},
  //     {name: '回收', value: '4'},
  //   ]
  // }
];

/*  油卡记录条件项 */
// export const FILL_WAYBILL: Condition[] = [
//   {type: 1, label: '货物重量：', value: '', key: 'cargo',
//     unit: {key: 'cargUnit', value: '', unitList: JSON.parse(JSON.stringify(unit))}, required: true, placeholder: '请输入'},
//   {type: 1, label: '运费金额：', value: '', key: 'cash', required: true, placeholder: '请输入'},
//   {type: 1, label: '运费扣减：', value: '', key: 'orderDeducting', placeholder: '请输入'},
//   {type: 1, label: '运费增补：', value: '', key: 'orderAddtional', placeholder: '请输入'},
//   {type: 1, label: '回单押金：', value: '', key: 'receiptDeposit', placeholder: '请输入'},
//   {type: 1, label: '预付费：', value: '', key: 'prepayment', placeholder: '请输入'},
//   {type: 1, label: '油卡金额：', value: '', key: 'refuelCardMoney', placeholder: '请输入'},
//   {type: 1, label: 'ETC金额：', value: '', key: 'etcMoney', placeholder: '请输入'},
//   {type: 11, label: '司机收款：', value: '', key: 'dcollection', option: [{name: '是', value: '0'}, {name: '否', value: '1'}]},
// ];


/*  填写派车单 */
export const FILL_WAYBILL: Condition[] = [
  {
    type: 1, label: '货物实际重量：', value: '0', key: 'cargo', rules: ['isNumber'],
    unit: { key: 'cargUnit', value: '吨', unitList: JSON.parse(JSON.stringify(unit)), disabled: true }, required: true, placeholder: '请输入'
  },
  { type: 17, label: '剩余运费：', value: '0', key: 'cash', rules: ['isNumber'], required: true, placeholder: '请输入', min: 0, precision: 2 },
  /*{type: 1, label: '运费扣减：', value: '0', key: 'orderDeducting', placeholder: '请输入'},
  {type: 1, label: '运费增补：', value: '0', key: 'orderAddtional', placeholder: '请输入'},*/
  { type: 17, label: '回单押金：', value: '0', key: 'receiptDeposit', rules: ['isNumber'], placeholder: '请输入', min: 0, precision: 2 },
  { type: 17, label: '预付费：', value: '0', key: 'prepayment', rules: ['isNumber'], placeholder: '请输入', min: 0, precision: 2 },
  { type: 17, label: '油卡金额：', value: '0', key: 'refuelCardMoney', rules: ['isNumber'], placeholder: '请输入', min: 0, precision: 2 },
  { type: 17, label: 'ETC金额：', value: '0', key: 'etcMoney', rules: ['isNumber'], placeholder: '请输入', min: 0, precision: 2 },
  {
    type: 11, label: '司机收款：', value: '1', key: 'dcollection', option: [{ name: '是', value: 1 }, { name: '否', value: 0 }],
    permissions: '200102008'
  },
  // {type: 1, label: '货运险金额：', value: '', key: 'totalActualPremium', required: true, placeholder: '请输入'},
  // {type: 2, label: '货物所属行业：', value: '', key: 'hwsshy', required: true, placeholder: '请选择'},
  // {type: 1, label: '接单方式：', value: '', key: 'jdfs', required: true, placeholder: 'PC', disabled: true},
  // {type: 2, label: '装卸方式：', value: '', key: 'zxfs', required: true, placeholder: '请选择', option:JSON.parse(JSON.stringify(zxType))},
  // {type: 1, label: '平台服务费：', value: '', key: 'serviceCharge', required: true, placeholder: '请输入'},
  // {type: 1, label: '货主折扣：', value: '', key: 'zk', required: true, placeholder: '请输入'},
  // {type: 1, label: '收款人姓名：', value: '', key: 'oppositeAccountName', required: true, placeholder: '请输入'},
];

export const ENTERPRISE_FIELDS: Condition[] = [
  { type: 1, label: 'id：', value: '', key: 'id_', isHide: true },
  { type: 1, label: '企业名称：', value: '', key: 'name', required: true, placeholder: '请输入' },
  { type: 1, label: '纳税人识别号：', value: '', key: 'taxNumber', required: true, placeholder: '请输入' },
  { type: 1, label: '联系电话：', value: '', key: 'phone', required: true, rules: ['isPhone'], placeholder: '请输入' },
  { type: 1, label: '联系人员：', value: '', key: 'contacts', required: true, placeholder: '请输入' },
  { type: 1, label: '公司地址：', value: '', key: 'addr', required: true, placeholder: '请输入' },
  { type: 1, label: '所属园区：', value: '', key: 'park', required: true, placeholder: '请输入' }
];

export const QRTIME: Condition[] = [
  { type: 17, label: '有效天数：', value: 3, key: 'day', rules: ['isNumber'], placeholder: '请输入', min: 1, max: 7, precision: 0 },
  { type: 17, label: '二维码宽度:', value: 300, key: 'size', rules: ['isNumber'], placeholder: '请输入', min: 200, max: 450, precision: 0 },
];