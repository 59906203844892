import { Component, OnInit, } from '@angular/core';
import { FetchService } from '../../core/services/fetch.service';
import { AuthenticationService } from '../../core/services/authentication.service';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { REQUEST } from '../../core/config/linkMap';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Condition } from '../../shared/model/carModels';
import { DRIVER_INFO, CAR_INFO, ACCOUNT_INFO } from '../../core/config/pageConfig';
import { Validation } from '../../shared/utils/validation';
import { convert, dateFormat, dateFormat1, deepClone } from '../../shared/utils/adapter';
import { UtilService } from 'src/app/core/services/util.service';
import { BASE } from '../../core/config/linkMap';

@Component({
  selector: 'app-car-info-edit',
  templateUrl: './car-info-edit.component.html',
  styleUrls: ['./car-info-edit.component.less']
})
export class CarInfoEditComponent implements OnInit {

  isShowUpload = false;
  carId: string;
  companyId = 2;
  errorMsg: string[];
  conditionCopy: Condition[];
  condition: Condition[];
  vehicleLicenseMainPC = { displayPath: '', path: '' };
  vehicleLicenseFrontPC = { displayPath: '', path: '' };
  vehicleLicenseBackendPC = { displayPath: '', path: '' };
  verhicalRoad = { displayPath: '', path: '' };
  verhicalDock = { displayPath: '', path: '' };
  verhicalRegistration = { displayPath: '', path: '' };
  carrierPC = { displayPath: '', path: '' };
  personAttachmentPC = { displayPath: '', path: '' };
  frontPC = { displayPath: '', path: '' };
  vehicleDriveAnnualAudit = { displayPath: '', path: '' };
  trailerLicenseCert = { displayPath: '', path: '' };
  trailerRoadTransportPermit = { displayPath: '', path: '' };
  trailerAnnualReview = { displayPath: '', path: '' };
  pickUp = { displayPath: '', path: '' };
  nightPickUp = { displayPath: '', path: '' };
  discharge = { displayPath: '', path: '' };
  nightDischarge = { displayPath: '', path: '' };
  uploadUrl = BASE + '/v1/files/origin';


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fetchService: FetchService,
    private authService: AuthenticationService,
    private message: NzMessageService,
    private util: UtilService) {
    this.condition = JSON.parse(JSON.stringify(CAR_INFO));
    this.getEnergyType();
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.carId = params.get('id');
      this.fetchService.get(REQUEST.CAR.EDIT + this.carId, {}, (res: any) => {
        this.companyId = res['companyId'];
        this.condition.forEach((field) => {
          const key = field.key;
          if (field.type === 4 && res[key]) {
            field.value = new Date(res[key]);
          } else {
            field.value = res[key] !== undefined ? res[key] + '' : null;
          }
        });
        console.log(res)
        if (res.vehicleDriveLicenseHomepage) {
          this.vehicleLicenseMainPC.displayPath = REQUEST.DISPLAY_PIC_BASE + res.vehicleDriveLicenseHomepage;
          this.vehicleLicenseMainPC.path = res.vehicleDriveLicenseHomepage;
        }
        if (res.vehicleDriveLicenseSecondPositivePage) {
          this.vehicleLicenseFrontPC.displayPath = REQUEST.DISPLAY_PIC_BASE + res.vehicleDriveLicenseSecondPositivePage;
          this.vehicleLicenseFrontPC.path = res.vehicleDriveLicenseSecondPositivePage;
        }
        if (res.vehicleDriveLicenseBackPage) {
          this.vehicleLicenseBackendPC.displayPath = REQUEST.DISPLAY_PIC_BASE + res.vehicleDriveLicenseBackPage;
          this.vehicleLicenseBackendPC.path = res.vehicleDriveLicenseBackPage;
        }
        if (res.operationCertPic) {
          this.verhicalRoad.displayPath = REQUEST.DISPLAY_PIC_BASE + res.operationCertPic;
          this.verhicalRoad.path = res.operationCertPic;
        }
        if (res.vehicleAffiliation) {
          this.verhicalDock.displayPath = REQUEST.DISPLAY_PIC_BASE + res.vehicleAffiliation;
          this.verhicalDock.path = res.vehicleAffiliation;
        }
        if (res.vehicleRegistration) {
          this.verhicalRegistration.displayPath = REQUEST.DISPLAY_PIC_BASE + res.vehicleRegistration;
          this.verhicalRegistration.path = res.vehicleRegistration;
        }
        if (res.cyczpfj) {
          this.carrierPC.displayPath = REQUEST.DISPLAY_PIC_BASE + res.cyczpfj;
          this.carrierPC.path = res.cyczpfj;
        }
        // 新增四个图片上传 开始
        if (res.vehicleDriveAnnualAudit) {
          this.vehicleDriveAnnualAudit.displayPath = REQUEST.DISPLAY_PIC_BASE + res.vehicleDriveAnnualAudit;
          this.vehicleDriveAnnualAudit.path = res.vehicleDriveAnnualAudit;
        }
        if (res.trailerLicenseCert) {
          this.trailerLicenseCert.displayPath = REQUEST.DISPLAY_PIC_BASE + res.trailerLicenseCert;
          this.trailerLicenseCert.path = res.trailerLicenseCert;
        }
        if (res.trailerRoadTransportPermit) {
          this.trailerRoadTransportPermit.displayPath = REQUEST.DISPLAY_PIC_BASE + res.trailerRoadTransportPermit;
          this.trailerRoadTransportPermit.path = res.trailerRoadTransportPermit;
        }
        if (res.trailerAnnualReview) {
          this.trailerAnnualReview.displayPath = REQUEST.DISPLAY_PIC_BASE + res.trailerAnnualReview;
          this.trailerAnnualReview.path = res.trailerAnnualReview;
        }
        // 新增四个图片上传 结束

        if (res.czsmgkfj) {
          this.personAttachmentPC.displayPath = REQUEST.DISPLAY_PIC_BASE + res.czsmgkfj;
          this.personAttachmentPC.path = res.czsmgkfj;
        }
        //承运车辆照片与车头照片保持一致
        if (res.frontPic) {
          this.frontPC.displayPath = REQUEST.DISPLAY_PIC_BASE + res.frontPic;
          this.frontPC.path = res.frontPic;
          this.carrierPC.displayPath = REQUEST.DISPLAY_PIC_BASE + res.frontPic;
          this.carrierPC.path = res.frontPic;
        }
        if (res.pickUp) {
          this.pickUp.displayPath = REQUEST.DISPLAY_PIC_BASE + res.pickUp;
          this.pickUp.path = res.pickUp;
        }
        if (res.nightPickUp) {
          this.nightPickUp.displayPath = REQUEST.DISPLAY_PIC_BASE + res.nightPickUp;
          this.nightPickUp.path = res.nightPickUp;
        }
        if (res.discharge) {
          this.discharge.displayPath = REQUEST.DISPLAY_PIC_BASE + res.discharge;
          this.discharge.path = res.discharge;
        }
        if (res.nightDischarge) {
          this.nightDischarge.displayPath = REQUEST.DISPLAY_PIC_BASE + res.nightDischarge;
          this.nightDischarge.path = res.nightDischarge;
        }
        this.isShowUpload = true;
      });
    });
  }

  //获取能源类型
  getEnergyType() {
    const option = this.util.switchDic('0017');
    this.condition.map((item, index) => {
      if (item.key === 'vehicleEnergyType') {
        item.option = option;
      }
    });
  }

  reset() {
    this.condition = JSON.parse(JSON.stringify(CAR_INFO));
    this.getEnergyType();
  }

  submit() {
    this.errorMsg = [];
    setTimeout(() => {
      /** 正常其他数据类型校验*/
      this.errorMsg = Validation(this.condition);
      if (!this.errorMsg.length) {
        /*提交*/
        if (!this.vehicleLicenseMainPC.path) {
          this.message.create('warning', '请上传车辆行驶证主页!');
          return;
        }
        if (!this.vehicleLicenseFrontPC.path) {
          this.message.create('warning', '请上传车辆行驶证副页正面!');
          return;
        }
        if (!this.vehicleLicenseBackendPC.path) {
          this.message.create('warning', '请上传车辆行驶证副页反面!');
          return;
        }
        if (!this.verhicalRoad.path) {
          this.message.create('warning', '请上传车辆道路运输证!');
          return;
        }
        if (!this.verhicalRegistration.path) {
          this.message.create('warning', '请上传车辆机动车登记证书!');
          return;
        }
        if (!this.carrierPC.path) {
          this.message.create('warning', '请上传承运车辆照片!');
          return;
        }
        if (!this.personAttachmentPC.path) {
          this.message.create('warning', '请上传车辆所有人为个人附件照片!');
          return;
        }
        if (!this.frontPC.path) {
          this.message.create('warning', '请上传车头照片!');
          return;
        }
        let copyCondition = deepClone(this.condition);
        // dateFormat2(copyCondition);
        const tempParams = convert(copyCondition);
        // 车架号17为长度的校验
        if (tempParams['vehicleIdentityCode'].length !== 17) {
          return this.message.create('warning', '车架号必须是17位');
        }
        const today = new Date();
        if (tempParams['registerDate'] >= today) {
          return this.message.create('warning', '行驶证注册日期应当小于当前时间');
        }
        if (tempParams['issueDate'] >= today) {
          return this.message.create('warning', '行驶证发证日期应当小于当前时间');
        }
        tempParams['vehicleDriveLicenseHomepage'] = this.vehicleLicenseMainPC.path;
        tempParams['vehicleDriveLicenseSecondPositivePage'] = this.vehicleLicenseFrontPC.path;
        tempParams['vehicleDriveLicenseBackPage'] = this.vehicleLicenseBackendPC.path;
        tempParams['operationCertPic'] = this.verhicalRoad.path;
        tempParams['vehicleAffiliation'] = this.verhicalDock.path;
        tempParams['vehicleRegistration'] = this.verhicalRegistration.path;
        tempParams['cyczpfj'] = this.carrierPC.path;
        tempParams['czsmgkfj'] = this.personAttachmentPC.path;
        //承运车辆照片与车头照片保持一致
        tempParams['frontPic'] = this.frontPC.path;
        tempParams['cyczpfj'] = this.frontPC.path;
        tempParams['pickUp'] = this.pickUp.path;
        tempParams['nightPickUp'] = this.nightPickUp.path;
        tempParams['discharge'] = this.discharge.path;
        tempParams['nightDischarge'] = this.nightDischarge.path;
        this.fetchService.put(REQUEST.CAR.UPDATE + this.carId + '/' + this.companyId, tempParams, (res: any) => {
          if (!res.isError) {
            this.message.create('success', '操作成功！');
            this.router.navigate(['/carManagement/carList']);
          } else {
            this.message.create('error', '车辆信息更新失败，请稍后重试！');
          }
        });
      }
    }, 0);
  }
}
