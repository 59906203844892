import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FetchService } from '../../core/services/fetch.service';
import { REQUEST } from '../../core/config/linkMap';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { NzMessageService, UploadFile, NzModalService, NzModalRef } from 'ng-zorro-antd';
import { BillingDetailService } from './service/billing-detail.service';
import { newinvoiceState, rejectNumType } from 'src/app/core/config/dropdown.conf';
import { BaseTableData, BaseTableColumn, BaseTableRow } from 'src/app/shared/components/base-table/interface';
import { BaseTableComponent } from 'src/app/shared/components/base-table/base-table.component';
import { measureScrollX } from 'src/app/shared/utils/measure-scrollbar';
import { TableColumnService } from 'src/app/core/services/table-column.service';

@Component({
  selector: 'app-bill-detail',
  templateUrl: './bill-detail.component.html',
  styleUrls: ['./bill-detail.component.less']
})
export class BillDetailComponent implements OnInit {
  // 传过来的id
  invoiceId;
  state: number;
  loading = false;
  editMark = '';

  // 图片
  previewImageUrl: string;
  suppleInfoPic = { displayPath: '', path: '' };

  // 查看轨迹
  isTrackVisible = false;
  modalType = 'list';
  trackList = [];
  isInfoData: any;

  //详情基础变量
  invoiceModel: any;
  baseInfoList: Array<{ key: string, value: string }>;

  // 基础表格变量
  tableData: BaseTableData;
  columns: BaseTableColumn[] = [];
  scrollX: string;
  queryParams: { [key: string]: any } = {};
  @ViewChild('infoImgModal')
  private infoImgModal: TemplateRef<{}>;

  constructor(
    private route: ActivatedRoute,
    private message: NzMessageService,
    private fetchService: FetchService,
    private billingDetailService: BillingDetailService,
    private tableColumn: TableColumnService,
    private modalService: NzModalService,
  ) {
    this.columns = this.tableColumn.getCarTransColumns();
    this.route.params.subscribe((data) => {
      this.invoiceId = data.id;
    });
  }

  ngOnInit() {
    this.scrollX = measureScrollX(this.columns);
    this.trackList = [];
    this.modalType = 'list';
  }

  search(value: any) {
    this.queryParams = value;
  }

  /* 请求调用详情数据 */
  searchInfo(params: object) {
    this.loading = true;
    this.tableData = { data: [], totalSize: 0 };
    params['invoice_id'] = this.invoiceId;
    this.fetchService.get(REQUEST.MAKE_INVOICE.INVOICE_INFO, params, (res: any) => {
      this.loading = false;
      if (!res.error) {
        if (res.dispatchCar) {
          this.tableData = {
            data: res.dispatchCar || [],
            totalSize: res.dispatchCar.length
          };
        }
        if (res.invoice) {
          this.invoiceModel = res.invoice;
          this.state = res.invoice.state;
          this.editMark = res.invoice.mark;
          this.suppleInfoPic.path = res.invoice.suppleInfoPic;
          this.suppleInfoPic.displayPath = REQUEST.DISPLAY_PIC_BASE + res.invoice.suppleInfoPic;
          if (this.state === 2) {
            this.baseInfoList = this.billingDetailService.getBaseIfo.filter(item => item.key !== 'rejectNum');
          } else if (this.state === 3) {
            this.baseInfoList = this.billingDetailService.getBaseIfo.filter(item => item.key !== 'invoiceCode' && item.key !== 'mark');
          } else {
            this.baseInfoList = this.billingDetailService.getBaseIfo.filter(item => item.key !== 'invoiceCode' && item.key !== 'mark' && item.key !== 'rejectNum');
          }
        }
      } else {
        this.goOut();
      }
    });
  }

  rendererInfo = (key: string): string => {
    if (key === 'state') {
      let option = newinvoiceState.filter(item => item.value === this.invoiceModel[key]);
      return option.length > 0 ? option[0].name : '';
    }
    if (key === 'rejectNum') {
      let option = rejectNumType.filter(item => item.value == this.invoiceModel[key]);
      return option.length > 0 ? option[0].name : '';
    }
    return this.invoiceModel[key];
  }

  // 返回
  goOut() {
    history.go(-1);
  }

  // 提交材料
  subImg() {
    return new Promise((resolve, reject) => {
      this.fetchService.put(REQUEST.MAKE_INVOICE.SUBMIT_MATERIAL + '?invoice_id=' + this.invoiceId + '&supplementary_pic=' + this.suppleInfoPic.path + '&mark=' + this.editMark, {}, (res: any) => {
        if (res) {
          this.message.create('success', '修改信息成功');
          setTimeout(() => {
            this.goOut();
          }, 1000);
        }
        resolve();
      });
    });
  }

  // 查看原始凭证
  infoImg(data) {
    this.previewImageUrl = REQUEST.DISPLAY_PIC_BASE + data.recPic;
    const modal = this.modalService.create({
      nzTitle: '原始凭证',
      nzContent: this.infoImgModal,
      nzWidth: 650,
      nzMaskClosable: false,
      nzClosable: true,
      nzFooter:  [{
        label: '确定',
        type: 'primary',
        onClick: () => modal.close()
      }]
    })
  }

  // 查看轨迹
  infomap(data) {
    this.isTrackVisible = true;
    this.modalType = 'list';
    this.isInfoData = data;
    this.searchTrack();
  }
  switch() {
    this.modalType = this.modalType === 'list' ? 'map' : 'list';
    this.trackList = [];
    this.searchTrack();
  }
  searchTrack() {
    this.trackList = [];
    if (this.modalType === 'map') {
      const btm = this.isInfoData.deliveryBtm;
      const etm = this.isInfoData.deliveryEtm;
      const car_identity = this.isInfoData.plate_num;
      this.fetchService.get(REQUEST.DIST.CAR_TRACK_MAP + car_identity + '/' + btm + '/' + etm, {}, (res) => {
        if (!res.isError && res._embedded) {
          this.trackList = res._embedded.gpsResourceList || [];
        } else {
          this.trackList = [];
        }
      });
    } else {
      this.fetchService.get(REQUEST.DIST.CAR_TRACK_LIST + this.isInfoData.orderId, {}, (res) => {
        this.loading = false;
        const tempTrackList = [];
        if (!res.isError && res) {
          res.forEach((item) => {
            tempTrackList.push({
              distributeDatetime: new Date(item.distributeDatetime)['Format']('yyyy-MM-dd hh:mm:ss'),
              act: item.act,
              operators: item.operators
            });
          });
          this.trackList = tempTrackList;
        } else {
          this.trackList = [];
          this.message.create('error', '定位获取失败，请稍后重试');
        }
      });
    }
  }
}
