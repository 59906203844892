import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../core/services/authentication.service';
import { MessageService } from '../../core/services/message.service';
import { FetchService } from 'src/app/core/services/fetch.service';
import { REQUEST } from '../../core/config/linkMap';
import { OAuthService } from 'angular-oauth2-oidc';
import { UtilService } from 'src/app/core/services/util.service';

@Component({
  selector: 'app-user-setting',
  templateUrl: './user-setting.component.html',
  styleUrls: ['./user-setting.component.less']
})
export class UserSettingComponent implements OnInit {

  userName: string;
  userRole: string;
  alarmNum: number;
  count = 0;
  token: string;
  constructor(
    private authService: AuthenticationService,
    private msgTip: MessageService,
    private fetchService: FetchService,
    private oAuthService: OAuthService,
    private util: UtilService,
  ) {
    this.msgTip.count.subscribe((data: number) => this.count = +data);
  }

  ngOnInit() {
    this.token = this.oAuthService.getAccessToken();
    this.userName = this.authService.profile['userName'];
    this.userRole = '系统管理员';
    this.alarmNum = 2;
  }

  logout() {
    this.util.submitConfirm('退出系统').then(() => {
      this.fetchService.post(`${REQUEST.LOGOUT}`, '', (res: any) => {
        if (!res || !res.isError) {
        }
      });
      this.msgTip.close();
      this.authService.logout(true);
    });
  }
}
