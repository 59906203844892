import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Condition } from 'src/app/shared/model/carModels';
import { CreditInfoInquiryService } from './service/credit-info-inquiry.service';
import { Validation } from 'src/app/shared/utils/validation';
import { NzMessageService } from 'ng-zorro-antd';
import { UtilService } from 'src/app/core/services/util.service';
import { convert } from 'src/app/shared/utils/adapter';
import { FetchService } from 'src/app/core/services/fetch.service';
import { REQUEST } from 'src/app/core/config/linkMap';

@Component({
  selector: 'app-credit-info-inquiry',
  templateUrl: './credit-info-inquiry.component.html',
  styleUrls: ['./credit-info-inquiry.component.less']
})
export class CreditInfoInquiryComponent implements OnInit {

  searchForm: Condition[];
  baseSearch: any;
  errorMsg: string[];
  @Input() type : 'company' | 'car' | 'driver';
  @Output() infoEvent = new EventEmitter;
  baseModel = { netState: '' };

  constructor(
    private creditInfoInquiryService: CreditInfoInquiryService,
    private message: NzMessageService,
    private util: UtilService,
    private fetchService: FetchService
  ) {
  }

  ngOnInit(): void {
    this.initBaseSearch();
  }

  initBaseSearch() {
    
    switch (this.type) {
      case 'company':
        const options = this.util.switchDic('0021');
        this.baseSearch = this.creditInfoInquiryService.initBaseComSearch;
        this.searchForm = this.creditInfoInquiryService.getCompanyForm(this.baseSearch,options);
        break;
      case 'car':
        this.baseSearch = this.creditInfoInquiryService.initBaseSearch;
        this.searchForm = this.creditInfoInquiryService.getCarForm(this.baseSearch);
        break;
      case 'driver':
        const provinceOptions = this.util.switchDic('0021');
        this.baseSearch = this.creditInfoInquiryService.initBaseDriverSearch;
        this.searchForm = this.creditInfoInquiryService.getDriverForm(this.baseSearch,provinceOptions);
        break;
      default:
        break;
    }
  }

  search() {
    this.errorMsg = Validation(this.searchForm);
    if (this.errorMsg.length > 0) {
      this.errorMsg.forEach(item => this.message.warning(item));
      return;
    }

    //参数处理
    let body = convert(this.searchForm);
    for(let key in body){
      if(key === 'isAgree'){
        delete body[key];
      }
      //人员查询从业资格证号和身份证号必传其一
      if(key === 'licenseType'){
        if(body[key] === '1'){
          body['qualificationCertificateNumber']  = body['LicenseNum'];
        } else {
          body['identityDocumentNumber']  = body['LicenseNum'];
        }
        delete body['LicenseNum'];
        delete body[key];
      }
    }

    const url = this.creditInfoInquiryService.getUrl(this.type);
    if(this.type === 'car'){
      this.fetchService.post(REQUEST.CREDIT.CARINTERNET, body, (res: any) => {
        if (!res.isError) {
          if(res.resultCode !== '0'){
            this.message.error(res.resultMsg);
          } else {
            this.baseModel = {
              netState: res.data
            }
          }
        }
      });
    }

    // 请求
    this.fetchService.post(url, body, (res: any) => {
      if (!res.isError) {
        if(res.resultCode !== '0'){
          this.message.error(res.resultMsg);
        } else {
          if(this.type === 'car'){
            this.infoEvent.emit({
              baseModel: Object.assign(res.data,this.baseModel)
            })
          }else{
            this.infoEvent.emit({
              baseModel: res.data
            })
          }
        }
      }
    });
  }

  reset() {
    this.initBaseSearch();
  }
}
