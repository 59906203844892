import { Component, OnInit, ViewChild } from '@angular/core';
import { FetchService } from '../../core/services/fetch.service';
import { AuthenticationService } from '../../core/services/authentication.service';
import { NzMessageService } from 'ng-zorro-antd';
import { BASE, REQUEST } from '../../core/config/linkMap';
import { Condition, CarHangupRecord } from '../../shared/model/carModels';
import { CAR_HANGUP } from '../../core/config/pageConfig';
import { getCond} from '../../shared/utils/adapter';

@Component({
  selector: 'app-car-hangup',
  templateUrl: './car-hangup.component.html',
  styleUrls: ['./car-hangup.component.less']
})
export class CarHangupComponent implements OnInit {

  pageIndex = 1;
  pageSize = 5;
  total = 1;
  loading = false;

  isVisible = false;
  isShowUpload = false;
  params = {};
  allChecked = false;
  indeterminate = false;
  condition: Condition[];
  dataSource: any[];
  searchParams: any;

  // 查看的图片信息
  lisencePC = {displayPath: ''};
  roadTransLicense = {displayPath: ''};
  idPC = {displayPath: ''};

  constructor(
    private authService: AuthenticationService,
    private fetchService: FetchService,
    private message: NzMessageService) {
    this.condition = JSON.parse(JSON.stringify(CAR_HANGUP));
    this.dataSource = [];
  }

  ngOnInit() {
    this.searchData();
  }

  searchData(reset: boolean = false): void {
    if (reset) {
      this.pageIndex = 1;
    }
    this.searchNow(this.searchParams);
  }

  query() {
    const params = {};
    const driverMobile = getCond(this.condition, 'driver_phoneNum');
    const driverName = getCond(this.condition, 'driver_name');
    if (driverMobile.value) {
      params['driverPhoneNum'] = driverMobile.value;
    }
    if (driverName.value) {
      params['driverName'] = driverName.value;
    }
    this.searchParams = params;
    this.searchNow(params);
  }

  searchNow(params = {}) {
    params['page'] = this.pageIndex;
    params['per_page'] = this.pageSize;
    params['companyId'] = this.authService.profile['companyId'];
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.dataSource = [];
      this.total = 0;
      this.fetchService.get(REQUEST.CAR_HANGUP.ALL, params, (res) => {
        this.loading = false;
        if (res._embedded) {
          this.dataSource = res._embedded.carResourceList || [];
        }
        if (res.page) {
          this.total = res.page.totalElements;
        }
        resolve();
      });
    });
  }

  clear() {
    this.condition = JSON.parse(JSON.stringify(CAR_HANGUP));
    this.searchParams = {};
    this.searchData(true);
  }

  operate(data: any, action: number) {
    const reqUrl = REQUEST.CAR_HANGUP.OPERATE + data.carId + '/' + this.authService.profile['companyId'] + '/' + action;
    // const reqUrl = REQUEST.CAR_HANGUP.OPERATE + '327965/' + this.companyId + '/' + action;
    this.loading = true;
    this.fetchService.put(reqUrl, {}, (res: any) => {
      this.loading = false;
      if (!res.isError) {
        this.message.create('success', '操作成功！');
      }
      this.searchData();
    });
  }

  check(carId: String) {
    this.loading = true;
    this.isVisible = true;
    this.isShowUpload = false;
    this.fetchService.get(REQUEST.CAR_HANGUP.CHECK, {carId: carId}, (res: any) => {
      this.loading = false;
      if (res && res.length && res[0] && res[0].length) {
        this.lisencePC.displayPath = REQUEST.DISPLAY_PIC_BASE + res[0][0];
        this.roadTransLicense.displayPath = REQUEST.DISPLAY_PIC_BASE + res[0][1];
        this.idPC.displayPath = REQUEST.DISPLAY_PIC_BASE + res[0][2];
        this.isShowUpload = true;
      }
    });
  }
}
