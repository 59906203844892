import { Component, OnInit, ViewChild } from '@angular/core';
import {Condition, TradeDetailRecord} from '../../shared/model/carModels';
import { getCond, filterRecord } from '../../shared/utils/adapter';
import { REQUEST } from '../../core/config/linkMap';
import {CAR_MANAGEMENT_COND, DEAL_COND} from '../../core/config/pageConfig';
import {Router} from '@angular/router';
import {NzMessageService, NzModalService} from 'ng-zorro-antd';
import {FetchService} from '../../core/services/fetch.service';
import {AuthenticationService} from '../../core/services/authentication.service';
import { UtilService } from 'src/app/core/services/util.service';

@Component({
  selector: 'app-trade-detail',
  templateUrl: './trade-detail.component.html',
  styleUrls: ['./trade-detail.component.less']
})
export class TradeDetailComponent implements OnInit {
  //公司ID
  //company_id = 1;

  /*  分页参数 */
  pageIndex = 1;
  pageSize = 5;
  total = 1;
  loading = false;

  isOkLoading = false;
  applyTotal = 0;
  settleTotal = 0;

  isVisible = false;
  searchMoreClassName = 'super-search';
  condition: Condition[];
  dataSource: any[];

  /*  表格选择参数 */
  allChecked = false;
  indeterminate = false;

  search: string;
  type: string;

  selectRecords: any;

  newDom:any;

  private superSearchParams:any;
  constructor(private router: Router, private modalService: NzModalService, private fetchService: FetchService, private authService: AuthenticationService, private util: UtilService,) {
    this.condition = JSON.parse(JSON.stringify(DEAL_COND));
  }

  ngOnInit() {
    this.dataSource = [];
    this.selectRecords = [];
    this.searchData();
  }
  dxtab(n) {
    if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n))
      return "数据非法";
    var unit = "千百拾亿千百拾万千百拾元角分", str = "";
    n += "00";
    var p = n.indexOf('.');
    if (p >= 0)
      n = n.substring(0, p) + n.substr(p+1, 2);
    unit = unit.substr(unit.length - n.length);
    for (var i=0; i < n.length; i++)
      str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i);
    return str.replace(/零(千|百|拾|角)/g, "零").replace(/(零)+/g, "零").replace(/零(万|亿|元)/g, "$1").replace(/(亿)万|壹(拾)/g, "$1$2").replace(/^元零?|零分/g, "").replace(/元$/g, "元整");
  }

  searchData(reset: boolean = false): void {
    if (reset) {
      this.pageIndex = 1;
    }
    this.searchNow(this.superSearchParams);
  }

  /* 高级搜索 */
  superSearch() {
    this.isOkLoading = true;
    const opposite_account_name = getCond(this.condition, 'opposite_account_name');
    const opposite_account = getCond(this.condition, 'opposite_account');
    const offer_order_code = getCond(this.condition, 'offer_order_code');
    const payer = getCond(this.condition, 'payer');
    const offer_type = getCond(this.condition, 'offer_type');
    const offer_date_btm = getCond(this.condition, 'offer_date_btm');
    const offer_date_etm = getCond(this.condition, 'offer_date_etm');
    const params = {};
    if (opposite_account_name.value) {
      params['opposite_account_name'] = opposite_account_name.value;
    }
    if (opposite_account.value) {
      params['opposite_account'] = opposite_account.value;
    }
    if (offer_order_code.value) {
      params['offer_order_code'] = offer_order_code.value;
    }
    if (payer.value) {
      params['payer'] = payer.value;
    }
    if (offer_type.value) {
      params['offer_type'] = offer_type.value;
    }
    if (offer_date_btm.value) {
      params['payment_date'] = this.util.dateFormat(offer_date_btm.value) + ',' + this.util.dateFormat(offer_date_etm.value);
    }
    this.pageIndex = 1;
    this.superSearchParams = params;
    this.searchNow(params).then(() => {
      this.isOkLoading = false;
      this.isVisible = false;
      this.condition = JSON.parse(JSON.stringify(DEAL_COND));
    });
  }

  /* 请求调用 */
  searchNow(params = {}) {
    params['company_id'] = this.authService.profile['companyId'];
    params['page'] = this.pageIndex;
    params['per_page'] = this.pageSize;
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.dataSource = [];
      this.total = 0;
      this.fetchService.get(REQUEST.CLOSE_AN_ACCOUNT.TRANSACTION_DETAIL, params, (res: any) => {
        this.loading = false;
        if (res._embedded) {
          this.dataSource = res._embedded.orderOfferLogResourceList || [];
        }
        if (res.page) {
          this.total = res.page.totalElements;
        }
        this.calculationNum()
        resolve();
      });
    });

  }

  clear() {
    this.superSearchParams = {};
    this.searchData(true);
  }
  // currentPageDataChange($event: Array<{ name: string; age: number; address: string; checked: boolean; disabled: boolean; }>): void {
  //   this.dataSource = $event;
  //   this.refreshStatus();
  // }
  currentPageDataChange(){
    this.refreshStatus();
  }

  refreshStatus(): void {
    const allChecked = this.dataSource.every(value => value.checked === true);
    const allUnChecked = this.dataSource.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);

    /* 选择数据同步 */
    this.selectRecords = this.dataSource.filter(value => value.checked);
    this.calculationNum()
  }

  checkAll(value: boolean): void {
    this.dataSource.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
    this.calculationNum()
  }

  //合计借、合计贷
  calculationNum() {
    let newapplyTotal = 0;
    let newsettleTotal = 0;
    this.dataSource.forEach(item => {
      if(item.checked) {
        if(item.offerType == 5) {
          newapplyTotal += parseFloat(item.obj.paymentAmount)
        }else {
          newsettleTotal += parseFloat(item.obj.paymentAmount)
        }
      }
    })
    // .toFixed(2)
    this.applyTotal = Number(newapplyTotal.toFixed(2));
    this.settleTotal = Number(newsettleTotal.toFixed(2))
  }

  //打印
  printData(data) {
    let nData = data;
    let oftype = '';
    if(nData.obj.offerType == 1){
      oftype = '运单支付';
    }else if(nData.obj.offerType == 3){
      oftype = '预充油卡费用';
    }else if(nData.obj.offerType == 6){
      oftype = '油卡支付';
    }else if(nData.obj.offerType == 7){
      oftype = '定位费用';
    }else if(nData.obj.offerType == 8){
      oftype = 'ETC费用';
    }
    let dxMoney = this.dxtab(nData.obj.paymentAmount)
    this.newDom = `
        <div style="width:100%;">
          <h4 style="text-align: center;">结算凭证</h4>
          <div style="width:100%;height:30px;display: flex;justify-content: space-between;">
            <p style="width:50%;height:30px;line-height:30px;margin:0;padding:0;font-size: 14px;">支付单：${nData.obj.offerOrderCode}</p>
            <p style="width:50%;height:30px;line-height:30px;margin:0;padding:0;font-size: 14px;">付款时间：${this.util.dateFormat(nData.obj.paymentDate)}</p>
          </div>
          <ul style="border:1px solid #000;width: 100%;margin:0;padding:0;">
            <li style="width:100%;line-height: 35px;display: flex;justify-content: space-between;margin:0;padding:0;border-bottom:1px solid #000;">
              <div style="width:20%;line-height: 70px;border-right:1px solid #000;text-align: center;">
                支付单摘要
              </div>
              <div style="width:80%;font-size: 16px;padding-left:10px;">
                <p style="margin:0;padding:0;">支付类型：${oftype}</p>
                <p style="margin:0;padding:0;">承运车辆：${nData.car_plateNum}，${nData.obj.oppositeAccountName}，${nData.driver_mobile}</p>
              </div>
            </li>
            <li style="width:100%;line-height: 30px;display: flex;justify-content: space-between;margin:0;padding:0;border-bottom:1px solid #000;">
              <div style="width:20%;line-height: 60px;border-right:1px solid #000;text-align: center;">
                收款人
              </div>
              <div style="width:80%;font-size: 16px;padding-left:10px;">
                <p style="margin:0;padding:0;line-height: 60px;">${nData.obj.oppositeAccountName}&nbsp;&nbsp;&nbsp;&nbsp;${nData.obj.oppositeAccount}</p>
              </div>
            </li>
            <li style="width:100%;line-height:40px;height:80px;display: flex;justify-content: space-between;margin:0;padding:0;border-bottom:1px solid #000;">
              <div style="width:20%;border-right:1px solid #000;line-height: 80px;text-align: center;">
                金额
              </div>
              <div style="width:80%;font-size: 16px;height:100%;padding-left:10px;">
                <div style="width:100%;height:100%;display: flex;justify-content: space-between;">
                  <div style="width:20%;height:80px;line-height: 80px;font-size:16px;">人民币(大写)</div>
                  <div style="padding-top:30px;border-bottom:1px solid #000;width:45%;height:24px;line-height: 24px;text-align: center;">${dxMoney}</div>
                  <div style="width:5%;line-height: 80px;text-align: center;height:100%;">¥</div>
                  <div style="padding-top:30px;border-bottom:1px solid #000;width:30%;height:24px;line-height: 24px;text-align: center;">${nData.obj.paymentAmount}</div>
                </div>
              </div>
            </li>
            <li style="width:100%;line-height: 35px;display: flex;justify-content: space-between;margin:0;padding:0;">
              <div style="width:19.6%;line-height: 70px;border-right:1px solid #000;text-align: center;">
                领款人签章
              </div>
              <div style="width:80%;"></div>
            </li>
          </ul>
          <div style="width: 100%;display: flex;justify-content: center;height:36px;"> 
            <div style="width:20%;height:36px;line-height: 36px;font-size:18px;">经理:</div>
            <div style="width:20%;height:36px;line-height: 36px;font-size:18px;">会计:</div>
            <div style="width:20%;height:36px;line-height: 36px;font-size:18px;">出纳:</div>
            <div style="width:20%;height:36px;line-height: 36px;font-size:18px;">主管:</div>
            <div style="width:20%;height:36px;line-height: 36px;font-size:18px;">制单人:</div>
          </div>
        </div>    
    `
    this.print()
  }

  print(){

    // //判断iframe是否存在，不存在则创建iframevar iframe=document.getElementById("print-iframe");//null
    if(iframe) {
      document.body.removeChild(iframe);
    }
    var el = document.getElementById("printcontent");//null
    var iframe = document.createElement('IFRAME');
    var doc = null;
    iframe.setAttribute("id", "print-iframe");
    iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:-500px;top:-500px;');
    document.body.appendChild(iframe);
    let niframe = document.getElementById("print-iframe");
    var iWindow = (<HTMLIFrameElement> niframe).contentWindow;
    doc = iWindow.document;
    //这里可以自定义样式
    doc.write("<LINK rel='stylesheet' type='text/css' href='./trade-detail.component.less'>");
    doc.write(this.newDom);
    doc.close();
    iWindow.focus();
    iWindow.print();
  }
}