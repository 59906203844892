import { Component, OnInit, Input } from '@angular/core';
import { Dic } from '../../model/carModels';
import { UtilService } from 'src/app/core/services/util.service';
import { DetailInfoService } from './service/detail-info.service';
import { ethnicType, sexType } from 'src/app/core/config/dropdown.conf';

@Component({
  selector: 'app-detail-info',
  templateUrl: './detail-info.component.html',
  styleUrls: ['./detail-info.component.less']
})
export class DetailInfoComponent implements OnInit {
  @Input() type: string;
  @Input() infoModel: any;
  baseInfoList: Array<{ key: string, value: string }>;
  licenseInfoList: Array<{ key: string, value: string }>;
  baseInfoTitle: string;
  licenseInfoTitle: string;
  provinceDic: Dic[] = [];
  energyTypeDic: Dic[] = [];

  constructor(
    private detailInfoService: DetailInfoService,
    private util: UtilService
  ) {}

  ngOnInit(){
    this.getBaseOptions();
  }

  getBaseOptions() {
    switch (this.type) {
      case 'car':
        this.baseInfoTitle = '车辆信息';
        this.licenseInfoTitle = '证件信息';
        this.baseInfoList = this.detailInfoService.getCarBaseInfo;
        this.licenseInfoList = this.detailInfoService.getCarLicenseInfo;
        this.energyTypeDic = this.util.switchDic('0017');
        break;
      case 'reviewedDriver':
        this.baseInfoTitle = '司机信息';
        this.licenseInfoTitle = '证件信息';
        this.baseInfoList = this.detailInfoService.getDriverBaseInfo;
        this.licenseInfoList = this.detailInfoService.getDriverLicenseInfo;
        this.provinceDic = this.util.switchDic('0021');
        break;
      case 'unreviewdDriver':
        this.baseInfoTitle = '司机信息';
        this.licenseInfoTitle = '证件信息';
        this.baseInfoList = this.detailInfoService.getDriverBaseInfo;
        this.licenseInfoList = this.detailInfoService.getDriverLicenseInfo;
        this.provinceDic = this.util.switchDic('0021');
        break;
      default:
        break;
    }
  }

  rendererInfo = (key: string): string => {
    if(this.type === 'car'){
      if (key === 'affiFlag') {
        return this.infoModel[key] === 1 ? '是' : '否';
      }
      if (key === 'vehicleEnergyType') {
        let option = this.energyTypeDic.filter(item => item.value === this.infoModel[key]);
        return option.length > 0 ? option[0].name : '';
      }
      if (key === 'registerDate' || key === 'issueDate' || key === 'roadTransportCertificateValidPeriodFrom' || key === 'roadTransportCertificateValidPeriodTo') {
        if(!!this.infoModel[key]){
          const datestr = new Date(this.infoModel[key]);
          return this.util.dateFormat(datestr, 'YYYY-MM-DD');
        }
      }
      if(key === 'capacityTonnage'){
        return this.infoModel[key] + '(公斤/千克)';
      }
      if(key === 'length' || key === 'vehicleWidth'){
        return this.infoModel[key] + '米';
      }
    }
    if(this.type === 'reviewedDriver' || this.type === 'unreviewdDriver'){
      if (key === 'identityForever') {
        return !!this.infoModel[key] ? '是' : '否';
      }
      if (key === 'provinceCode') {
        let option = this.provinceDic.filter(item => item.value === this.infoModel[key]);
        return option.length > 0 ? option[0].name : '';
      }
      if (key === 'qualificationCertificateValidPeriodTo' || key === 'qualificationCertificateValidPeriodFrom') {
        if (!!this.infoModel[key]) {
          const datestr = new Date(this.infoModel[key]);
          return this.util.dateFormat(datestr, 'YYYY-MM-DD');
        }
      }
      if (key === 'xbdm') {
        const result = sexType.filter((item) => item.value === this.infoModel[key]);
        return result.length > 0 ? result[0].name : '';
      }
      if (key === 'mzdm') {
        const result = ethnicType.filter((item) => item.value === this.infoModel[key]);
        return result.length > 0 ? result[0].name : '';
      }
    }
    return this.infoModel[key];
  }
}
