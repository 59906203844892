import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import {User} from '../../shared/model/carModels';
import {NgxPermissionsService} from 'ngx-permissions';

export interface SettingsNotify {
  type: 'user' | 'roles' | 'operations';
  value: any;
}
export const USER = 'user';

export const OPERATIONS = 'operations';

export const ROLES = 'roles';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private roles: Array<any> = [];
  private operations: Array<any> = [];
  private user: User = null;
  private notify$ = new Subject<SettingsNotify>();

  constructor(
    private oAuthService: OAuthService,
    private permService: NgxPermissionsService,
  ) {
  }

  get notify(): Observable<SettingsNotify> {
    return this.notify$.asObservable();
  }

  private get(key: string) {
    return JSON.parse(localStorage.getItem(key) || 'null') || null;
  }

  private set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  get profile(): User {
    if (!this.user) {
      this.user = {...this.get(USER)};
      this.set(USER, this.user);
    }
    return this.user as User;
  }

  get getRoles(): Array<any> {
    if (!this.roles) {
      this.roles = {...this.get(ROLES)};
      this.set(ROLES, this.roles);
    }
    return this.roles;
  }

  get getOperations(): Array<any> {
    if (!this.operations) {
      this.operations = {...this.get(OPERATIONS)};
      this.set(OPERATIONS, this.operations);
    }
    return this.operations;
  }

  setUser(value) {
    this.user = value;
    this.set(USER, value);
    this.notify$.next({type: 'user', value});
    return true;
  }

  setRoles(value) {
    this.roles = value;
    this.set(ROLES, value);
    this.notify$.next({type: 'roles', value});
    return true;
  }

  setOperations(value) {
    this.operations = value;
    this.set(OPERATIONS, value);
    this.notify$.next({type: 'operations', value});
    return true;
  }

  clearSetting() {
    localStorage.removeItem(USER);
    localStorage.removeItem(OPERATIONS);
    localStorage.removeItem(ROLES);
  }

  /**
   * Logout
   */
  public logout(refresh?: boolean): void {
    this.oAuthService.logOut();
    this.clearSetting();
    if (refresh) {
      location.reload(true);
    }
  }

  /**
   * 是否拥有某个权限
   * @param permissions
   * @returns {Promise<boolean>}
   */
  isPermissions(permissions: string | string[]): Promise<boolean> {
    return this.permService.hasPermission(permissions);
  }
}
