const carType = [
  {name: '重型普通货车', value: '重型普通货车'},
  {name: '重型厢式货车', value: '重型厢式货车'},
  {name: '重型封闭货车', value: '重型封闭货车'},
  {name: '重型罐式货车', value: '重型罐式货车'},
  {name: '重型平板货车', value: '重型平板货车'},
  {name: '重型集装箱车', value: '重型集装箱车'},
  {name: '重型自卸货车', value: '重型自卸货车'},
  {name: '重型特殊结构货车', value: '重型特殊结构货车'},
  {name: '中型普通货车', value: '中型普通货车'},
  {name: '中型厢式货车', value: '中型厢式货车'},
  {name: '中型封闭货车', value: '中型封闭货车'},
  {name: '中型罐式货车', value: '中型罐式货车'},
  {name: '中型平板货车', value: '中型平板货车'},
  {name: '中型集装箱车', value: '中型集装箱车'},
  {name: '中型自卸货车', value: '中型自卸货车'},
  {name: '中型特殊结构货车', value: '中型特殊结构货车'},
  {name: '轻型普通货车', value: '轻型普通货车'},
  {name: '轻型厢式货车', value: '轻型厢式货车'},
  {name: '轻型封闭货车', value: '轻型封闭货车'},
  {name: '轻型罐式货车', value: '轻型罐式货车'},
  {name: '轻型平板货车', value: '轻型平板货车'},
  {name: '轻型自卸货车', value: '轻型自卸货车'},
  {name: '轻型特殊结构货车', value: '轻型特殊结构货车'},
  {name: '微型普通货车', value: '微型普通货车'},
  {name: '微型厢式货车', value: '微型厢式货车'},
  {name: '微型封闭货车', value: '微型封闭货车'},
  {name: '微型罐式货车', value: '微型罐式货车'},
  {name: '微型自卸货车', value: '微型自卸货车'},
  {name: '微型特殊结构货车', value: '微型特殊结构货车'},
  {name: '低速普通货车', value: '低速普通货车'},
  {name: '低速厢式货车', value: '低速厢式货车'},
  {name: '低速罐式货车', value: '低速罐式货车'},
  {name: '低速自卸货车', value: '低速自卸货车'},
  {name: '重型半挂牵引车', value: '重型半挂牵引车'},
  {name: '中型半挂牵引车', value: '中型半挂牵引车'},
  {name: '轻型半挂牵引车', value: '轻型半挂牵引车'},
  {name: '正三轮载货摩托车', value: '正三轮载货摩托车'},
  {name: '三轮农用运输', value: '三轮农用运输'},
  {name: '四轮农用普通货车', value: '四轮农用普通货车'},
  {name: '四轮农用厢式货车', value: '四轮农用厢式货车'},
  {name: '四轮农用罐式货车', value: '四轮农用罐式货车'},
  {name: '四轮农用自卸货车', value: '四轮农用自卸货车'},
  {name: '大型轮式拖拉机', value: '大型轮式拖拉机'},
  {name: '小型轮式拖拉机', value: '小型轮式拖拉机'},
  {name: '重型普通全挂牵引车', value: '重型普通全挂牵引车'},
  {name: '重型普通半挂牵引车', value: '重型普通半挂牵引车'},
  {name: '重型仓栏式货车', value: '重型仓栏式货车'},
  {name: '轻仓栅式货车', value: '轻仓栅式货车'},
  {name: '轻型专项作业车', value: '轻型专项作业车'},
  {name: '重型车辆运输车', value: '重型车辆运输车'},
  {name: '其他车', value: '其他车'}
];

const carTypeByHangup = [
  {name: '重型仓栅式半挂车', value: '重型仓栅式半挂车'},
  {name: '重型罐式半挂车', value: '重型罐式半挂车'},
  {name: '重型普通半挂车', value: '重型普通半挂车'},
  {name: '重型集装箱半挂车', value: '重型集装箱半挂车'},
  {name: '重型厢式半挂车', value: '重型厢式半挂车'},
  {name: '重型低平板半挂车', value: '重型低平板半挂车'},
  {name: '重型平板半挂车', value: '重型平板半挂车'},
  {name: '重型自卸半挂车', value: '重型自卸半挂车'},
  {name: '重型罐式半挂车', value: '重型罐式半挂车'},
  {name: '重型车辆运输半挂车', value: '重型车辆运输半挂车'}
];

const freightSort = [
  {name: '普货', value: 10},
  {name: '冷冻品', value: 20},
];
//装卸方式
const zxType = [
  {name: '一装一卸', value: '一装一卸'},
  {name: '一装两卸', value: '一装两卸'}
]

const freightType = [
  {name: '动物类产品', value: 10},
  {name: '植物产品', value: 20},
  {name: '动植物油及制品', value: 30},
  {name: '烟酒食品饮料', value: 40},
  {name: '矿产品', value: 50},
  {name: '化工品', value: 60},
  {name: '塑料橡胶及制品', value: 70},
  {name: '皮类及制品', value: 80},
  {name: '木制及稻草木制品', value: 90},
  {name: '木浆纤维纸类', value: 100},
  {name: '纺织制品', value: 110},
  {name: '鞋帽伞杖鞭及羽毛制品', value: 120},
  {name: '石料水泥陶瓷玻璃等制品', value: 130},
  {name: '珠宝首饰', value: 140},
  {name: '金属及制品', value: 150},
  {name: '机械电气音响设备及零件', value: 160},
  {name: '车辆船舶航空器及有关运输设备', value: 170},
  {name: '精密仪器及设备', value: 180},
  {name: '武器弹药及零件', value: 190},
  {name: '杂项制品', value: 200},
  {name: '艺术品收藏品及古物', value: 210},
  {name: '特殊交易品及未分类商品', value: 220},
];

/* 驾驶证类型 */
const licenseType = [
  {name: 'A1', value: 'A1'},
  {name: 'A2', value: 'A2'},
  {name: 'A3', value: 'A3'},
  {name: 'B1', value: 'B1'},
  {name: 'B2', value: 'B2'},
];

const sexType = [
  {name: '未知的性别', value: '0', },
  {name: '男', value: '1'},
  {name: '女', value: '2'},
  {name: '未说明的性别', value: '9'},
]

const ethnicType = [
  {name: '汉族', value: '01', },
  {name: '蒙古族', value: '02'},
  {name: '回族', value: '03'},
  {name: '藏族', value: '04'},
  {name: '维吾尔族', value: '05', },
  {name: '苗族', value: '06'},
  {name: '彝族', value: '07'},
  {name: '壮族', value: '08'},
  {name: '布依族', value: '09', },
  {name: '朝鲜族', value: '10'},
  {name: '满族', value: '11'},
  {name: '侗族', value: '12'},
  {name: '瑶族', value: '13', },
  {name: '白族', value: '14'},
  {name: '土家族', value: '15'},
  {name: '哈尼族', value: '16'},
  {name: '哈萨克族', value: '17', },
  {name: '傣族', value: '18'},
  {name: '黎族', value: '19'},
  {name: '傈僳族', value: '20'},
  {name: '佤族', value: '21', },
  {name: '畲族', value: '22'},
  {name: '高山族', value: '23'},
  {name: '拉祜族', value: '24'},
  {name: '水族', value: '25', },
  {name: '东乡族', value: '26'},
  {name: '纳西族', value: '27'},
  {name: '景颇族', value: '28'},
  {name: '柯尔克孜族', value: '29', },
  {name: '土族', value: '30'},
  {name: '达斡尔族', value: '31'},
  {name: '仫佬族', value: '32'},
  {name: '羌族', value: '33', },
  {name: '布朗族', value: '34'},
  {name: '撒拉族', value: '35'},
  {name: '毛南族', value: '36'},
  {name: '仡佬族', value: '37', },
  {name: '锡伯族', value: '38'},
  {name: '阿昌族', value: '39'},
  {name: '普米族', value: '40'},
  {name: '塔吉克族', value: '41', },
  {name: '怒族', value: '42'},
  {name: '乌孜别克族', value: '43'},
  {name: '俄罗斯族', value: '44'},
  {name: '鄂温克族', value: '45', },
  {name: '德昂族', value: '46'},
  {name: '保安族', value: '47'},
  {name: '裕固族', value: '48'},
  {name: '京族', value: '49', },
  {name: '塔塔尔族', value: '50'},
  {name: '独龙族', value: '51'},
  {name: '鄂伦春族', value: '52'},
  {name: '赫哲族', value: '53', },
  {name: '门巴族', value: '54'},
  {name: '珞巴族', value: '55'},
  {name: '基诺族', value: '56'}
]

/* 开票状态 */
const invoiceState = [
  {name: '未开票', value: '0'},
  {name: '开票中', value: '1'},
  {name: '已开票', value: '2'}
];

/* 支付状态 */
const payState = [
  {name: '未支付', value: 0},
  {name: '预申请', value: 1},
  {name: '已到账', value: 2},
  {name: '已申请', value: 3},
  {name: '已完成', value: 4}
];


/* 业务类型 */
const businessType = [
  {name: '运单支付', value: '1'},
  // {name: '服务费', value: '2,4'},
  {name: '预充油卡费用', value: '3'},
  {name: '服务费', value: '4'},
  {name: '账户充值', value: '5'},
  {name: '油卡支付', value: '6'},
  {name: '定位费用', value: '7'},
  {name: 'ETC费用', value: '8'}
];

/* 付款记录支付状态 */
const newpayState = [
  {name: '预申请', value: '0'},
  {name: '已申请', value: '2'},
  {name: '已到账', value: '1'},
];


/* new开票状态 */
const newinvoiceState = [
  {name: '已申请', value: 0},
  {name: '开票中', value: 1},
  {name: '申请成功', value: 2},
  {name: '已拒绝', value: 3},
];

/* 拒绝理由 */
export const rejectNumType = [
  {value: '0', name: '车辆北斗轨迹不全'},
  {value: '1', name: '车辆北斗轨迹无法获取'},
  {value: '2', name: '申诉未通过'},
  {value: '3', name: '车辆GPS轨迹不全'},
  {value: '4', name: '车辆GPS轨迹无法获取'},
  {value: '5', name: '车辆审核未通过'},
  {value: '6', name: '司机审核未通过'}
];

/* 数量单位 */
const unit = [
  {name: '立方米', value: '立方米'},
  {name: '公斤/千克', value: '公斤/千克'},
  {name: '米', value: '米'},
  {name: '吨', value: '吨'},
  {name: '方', value: '方'},
  {name: '车', value: '车'},
  {name: '件', value: '件'}
];

/* 金额单位 */
export const moneyunit = [
  {name: '元', value: '元'},
];

export {carType, zxType, carTypeByHangup, freightType, freightSort, licenseType, sexType, ethnicType, invoiceState, payState, unit, businessType, newpayState, newinvoiceState};

export const messageTypeValue = [
  {value: [210, 211], name: '开票审核结果'},
  {value: [221, 222], name: '车辆审核结果'},
  {value: [231, 232], name: '司机审核结果'},
  {value: [240], name: '充值通知'},
  {value: [250], name: '订单提货通知'},
  {value: [251], name: '订单卸货通知'},
  {value: [253], name: '订单派单拒绝通知'},
  {value: [311], name: '投诉处理通知'},
];

export const messageState = [
  {value: 0, name: '未推送'},
  {value: 1, name: '已推送/未处理'},
  {value: 2, name: '已处理'}
];

export const orderState = [
  {value: 0, name: '未发货'},
  {value: 1, name: '物流公司提货'},
  {value: 2, name: '司机提货'},
  {value: 3, name: '司机卸货'},
  {value: 4, name: '司机签收'},
  {value: 5, name: '司机回单'},
  {value: 6, name: '物流公司签收'}
];

export const ZJCargoTypePre = [
  {value: -1, name: '煤炭/钢材/矿石'},
  {value: 0, name: '普通货物'},
  {value: 4, name: '易碎品'},
  {value: 5, name: '生鲜/粮食/化肥'}
];

export const ZJCargoType = [
  {value: 0, name: '散货'},
  {value: 1, name: '普货'},
  {value: 2, name: '生鲜'},
  {value: 3, name: '易碎'}
];

export const logType = [
  {value: 2, name: '登录日志'},
  {value: 1, name: '操作日志'}
];

export const orderType = [
  {value: '00059', name: '河南'},
  {value: '00060', name: '天津'},
  {value: '00554', name: '金昌'}
];

export const bankType = [
  {value: '0104', src: '../../assets/img/1.png', name:'中国银行'}, 
  {value: '0102', src: '../../assets/img/2.png', name:'工商银行'},
  {value: '0103', src: '../../assets/img/3.png', name:'农业银行'},
  {value: '0301', src: '../../assets/img/4.png', name:'交通银行'},
  // {value: '4', src: '@/assets/img/5.png', name:'广发银行'},
  {value: '0105', src: '../../assets/img/7.png', name:'建设银行'},
  {value: '0310', src: '../../assets/img/8.png', name:'浦发银行'},
  {value: '0308', src: '../../assets/img/10.png', name:'招商银行'},
  {value: '0100', src: '../../assets/img/11.png', name:'邮政银行'},
  {value: '0305', src: '../../assets/img/12.png', name:'民生银行'},
  {value: '0309', src: '../../assets/img/13.png', name:'兴业银行'},
  {value: '0303', src: '../../assets/img/19.png', name:'光大银行'},
  {value: '4012900', src: '../../assets/img/21.png', name:'上海银行'},
  {value: '0307', src: '../../assets/img/28.png', name:'平安银行'},
  {value: '0302', src: '../../assets/img/timg.jpg', name:'中信银行'},
  // {value: '04504520', src: '../../assets/img/timg.jpg', name:'青岛银行'}
]

export const transType = [
  {value: 0, name: '未支付'},
  {value: 1, name: '转账成功'},
  {value: 2, name: '转账失败'},
  {value: 3, name: '成功-但发生退款'},
  {value: 4, name: '交易关闭'},
  {value: 5, name: '进行中'}
]