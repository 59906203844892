import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NzMessageService, UploadFile } from 'ng-zorro-antd';
import { Observable, Observer } from 'rxjs';
import { REQUEST } from '../../core/config/linkMap';
import { FetchService } from '../../core/services/fetch.service';
import { AuthenticationService } from '../../core/services/authentication.service';
import { FILL_WAYBILL } from '../../core/config/pageConfig';
import { Validation, isNumber } from '../../shared/utils/validation';
import { Condition, orderFill } from '../../shared/model/carModels';
import { getCond, convert, deepClone } from '../../shared/utils/adapter';

@Component({
  selector: 'app-fill-waybill',
  templateUrl: './fill-waybill.component.html',
  styleUrls: ['./fill-waybill.component.less']
})
export class FillWaybillComponent implements OnInit {

  @Output() dispatchFillDone = new EventEmitter<string>();
  @Input() orderId: string;
  @Input() orderCode: string;
  @Input() carId: string;
  @Input() did: string;
  @Input() version: string;
  @Input() mode: string;
  @Input() licenseInfoList: Array<{key: string, value: string}>;
  @Input() orderType: string;

  isDisabled = true;
  isShowUpload = false;
  errorMsg: string[];
  pickupPC = {displayPath: '',path: ''};
  unloadPC = {displayPath: '',path: ''};
  receiptPC = {displayPath: '',path: ''};
  thdfjPC = {displayPath: '',path: ''};
  rkdfjPC = {displayPath: '',path: ''};
  condition: Condition[];
  conditionCopy: Condition[];
  waybillRes: any;
  carRes: any;

  constructor(
    private fetchService: FetchService,
    private authService: AuthenticationService,
    private message: NzMessageService) {
      this.condition = JSON.parse(JSON.stringify(FILL_WAYBILL));
  }

  ngOnInit() {
    // 运单编辑
    if (this.did) {
      this.searchNow(REQUEST.PRE_DIST.EDIT_WAYBILL + this.did).then((res:any) => {
        this.waybillRes = res;
        // 条件值预填充
        this.condition.forEach((field) => {
          const key = field.key;
          if (field.type === 1 && field.unit) {
            field.unit.value = res[field.unit.key];
            field.value = res[key];
          } else if (field.type === 4 && res[key]) {
            field.value = new Date(res[key]);
          } else if (field.type === 10 && res[key]) {
            const labelArr = [];
            const parts = res[key + '_'].split('_');
            parts.forEach((part: string) => {
              if (part && part.indexOf('+') > -1) {
                labelArr.push(part.split('+')[1]);
              }
            });
            field.value = parts;
          } else {
            field.value = res[key] !== undefined ? res[key] + '' : null;
          }
          // if (field.type === 1 && field.unit) {
          //   field.unit.value = res[field.unit.key] ? res[field.unit.key] : '';
          // }
          /*if (field.key === 'orderDeducting' || field.key === 'orderAddtional') {
            field.isHide = true;
          }*/
          // 编辑运单的时候运费金额不能被编辑
          /*if (!this.isRestEnable && field.key === 'cash') {
            field.disabled = true;
          }*/
        });
        if (res.pickupPic) {
          this.pickupPC.displayPath = REQUEST.DISPLAY_PIC_BASE + res.pickupPic;
          this.pickupPC.path = res.pickupPic;
        }
        if (res.unloadingPic) {
          this.unloadPC.displayPath = REQUEST.DISPLAY_PIC_BASE + res.unloadingPic;
          this.unloadPC.path = res.unloadingPic;
        }
        if (res.receiptPic) {
          this.receiptPC.displayPath = REQUEST.DISPLAY_PIC_BASE + res.receiptPic;
          this.receiptPC.path = res.receiptPic;
        }
        if (res.thdfj) {
          this.thdfjPC.displayPath = REQUEST.DISPLAY_PIC_BASE + res.thdfj;
          this.thdfjPC.path = res.thdfj;
        }
        if (res.rkdfj) {
          this.rkdfjPC.displayPath = REQUEST.DISPLAY_PIC_BASE + res.rkdfj;
          this.rkdfjPC.path = res.rkdfj;
        }
        this.isShowUpload = true;
      });
    } else {
      // 运单新建
      // 新建运单的时候不能显示运费增补和运费扣减
      /*this.condition.map((cond) => {
        if (cond.key === 'orderDeducting' || cond.key === 'orderAddtional') {
          cond.isHide = true;
        }
      });*/
      this.isShowUpload = true;
    }
    this.getCarInfo();
  }

  searchNow(url, params = {}) {
    return new Promise((resolve, reject) => {
      this.fetchService.get(url, params, (res: any) => {
        resolve(res);
      });
    });
  }

  //获取车辆信息
  getCarInfo(){
    this.fetchService.get(REQUEST.CAR.EDIT + this.carId, {}, (res: any) => {
      if (!res || !res.isError) {
        this.carRes = res;
      }
    });
  }

  reset() {
    this.condition = JSON.parse(JSON.stringify(FILL_WAYBILL));
  }

  submit() {
    this.errorMsg = Validation(this.condition);
    if (!this.errorMsg.length) {
      const params = convert(this.condition);
      if (!params['cargUnit']) {
        return this.message.create('error', '请选择单位!');
      }
      params['orderId'] = this.orderId;
      params['orderCode'] = this.orderCode;
      params['carId'] = this.carId;
      if (typeof this.version !== 'undefined') {
        params['preDispatchCarVersion'] = this.version;
      }
      params['loginUserName'] = this.authService.profile['userName'];
      params['creator'] = this.authService.profile['userName'];
      let method = 'post';
      if (this.did) {
        method = 'put';
        params['carOrderCode'] = this.waybillRes.carOrderCode;
        params['did'] = this.did;
        params['state'] = this.waybillRes.state;
      }
      // if (!this.pickupPC.path) {
      //   this.message.create('warning', '请上传回单照片!');
      //   return;
      // }
      // if (!this.unloadPC.path) {
      //   this.message.create('warning', '请上传卸货照片!');
      //   return;
      // }
      // if (!this.receiptPC.path) {
      //   this.message.create('warning', '请上传提货照片!');
      //   return;
      // }
      // if (!this.thdfjPC.path) {
      //   this.message.create('warning', '请上传提货单!');
      //   return;
      // }
      // if (!this.rkdfjPC.path) {
      //   this.message.create('warning', '请上传入库单!');
      //   return;
      // }
      // params['pickupPic'] = this.pickupPC.path;
      // params['unloadingPic'] = this.unloadPC.path;
      // params['receiptPic'] = this.receiptPC.path;
      // params['thdfj'] = this.thdfjPC.path;
      // params['rkdfj'] = this.rkdfjPC.path;
      if(params['cargo'] > this.carRes.capacityTonnage/1000){
        return this.message.warning(`货物实际重量不得大于车辆载重,当前车辆载重为${this.carRes.capacityTonnage/1000}吨`);
      }
      const myNumber = /^(([0]*)|([1-9][0-9]*)|(([0]\.\d{1,4}|[1-9][0-9]*\.\d{1,4})))$/;
      if (params['cash'] && !myNumber.test(params['cash'])) {
        return this.message.create('warning', '剩余运费输入有误，请输入正确的金额');
      }
      if (params['receiptDeposit'] && !myNumber.test(params['receiptDeposit'])) {
        return this.message.create('warning', '回单押金输入有误，请输入正确的金额');
      }
      if (params['prepayment'] && !myNumber.test(params['prepayment'])) {
        return this.message.create('warning', '预付费输入有误，请输入正确的金额');
      }
      if (params['refuelCardMoney'] && !myNumber.test(params['refuelCardMoney'])) {
        return this.message.create('warning', '油卡金额输入有误，请输入正确的金额');
      }
      if (params['etcMoney'] && !myNumber.test(params['etcMoney'])) {
        return this.message.create('warning', 'ETC金额输入有误，请输入正确的金额');
      }
      params['sync'] = this.orderType;
      this.fetchService[method](REQUEST.DIST.ALL, params, (res: any) => {
        if (!res.isError) {
          let msg = '派车单填写成功！';
          if (this.did) {
            msg = '派车单编辑成功!';
          }
          this.message.create('success', msg);
          this.dispatchFillDone.emit();
        }
      });
    }
  }
}
