import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contract-sign-success',
  templateUrl: './contract-sign-success.component.html',
  styleUrls: ['./contract-sign-success.component.less']
})
export class ContractSignSuccessComponent implements OnInit {

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }

  close() {
    history.go(-1);
  }
}
