import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Condition, Insurance } from '../../shared/model/carModels';
import { NzMessageService, NzModalRef, NzModalService } from 'ng-zorro-antd';
import { AuthenticationService } from '../../core/services/authentication.service';
import { FetchService } from '../../core/services/fetch.service';
import { REQUEST } from '../../core/config/linkMap';
import { convert, dateFormat, deepClone } from '../../shared/utils/adapter';
import { Validation } from '../../shared/utils/validation';
import { InsuranceService } from './service/insurance.service';
import { PdfViewerComponent } from '../../shared/components/pdf-viewer/pdf-viewer.component';
import { UtilService } from '../../core/services/util.service';
import { BaseTableColumn, BaseTableData, BaseTableRow } from '../../shared/components/base-table/interface';
import { BaseTableComponent } from '../../shared/components/base-table/base-table.component';
import { measureScrollX } from '../../shared/utils/measure-scrollbar';
import { TableColumnService } from '../../core/services/table-column.service';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.less']
})
export class InsuranceComponent implements OnInit {
  // 表单变量
  insuranceForm: Condition[];
  searchForm: Condition[];
  exportForm: Condition[];
  errorMsg: string[];
  // 弹出框表格变量
  orderDataSource: any[] = [];
  orderPageIndex = 1;
  orderPageSize = 5;
  orderTotal = 0;
  orderCodeValue: string;
  orderCodeOptions = [];
  // 基础表格变量
  loading = false;
  tableData: BaseTableData;
  columns: BaseTableColumn[] = [];
  selectRecords: BaseTableRow[] = [];
  scrollX: string;
  queryParams: { [key: string]: any } = {};
  @ViewChild('baseTable')
  private baseTable: BaseTableComponent;
  @ViewChild('insuranceEditor')
  private insuranceEditor: TemplateRef<{}>;
  private tplModal: NzModalRef;
  @ViewChild('orderModal')
  private orderModal: TemplateRef<{}>;
  private tplOrderModal: NzModalRef;
  @ViewChild('searchModal')
  private searchModal: TemplateRef<{}>;
  private tplSearchModal: NzModalRef;
  @ViewChild('exportModal')
  private exportModal: TemplateRef<{}>;
  private tplExportModal: NzModalRef;
  baseSearch: { car_order_code, risk_start_date };
  totalInsuredAmount = 0;

  constructor(
    private modalService: NzModalService,
    private authService: AuthenticationService,
    private fetchService: FetchService,
    private message: NzMessageService,
    private insuranceService: InsuranceService,
    private util: UtilService,
    private tableColumn: TableColumnService
  ) {
    this.columns = this.tableColumn.getInsuranceColumns();
    this.baseSearch = this.insuranceService.initBaseSearch;
    this.searchForm = this.insuranceService.getSearchForm(this.baseSearch);
  }

  ngOnInit() {
    this.scrollX = measureScrollX(this.columns);
  }

  /* 请求调用 */
  load(params: object) {
    /* 增加分页参数 */
    params['company_id'] = this.authService.profile['companyId'];
    params['per_page'] = params['size'];
    this.loading = true;
    this.tableData = { data: [], totalSize: 0 };
    this.fetchService.get(REQUEST.INSURANCE.ALL, params, (res) => {
      this.loading = false;
      if (res._embedded) {
        this.tableData = {
          data: res._embedded.insuranceZJResourceList || [],
          totalSize: res.page.totalElements
        };
        this.totalInsuredAmount = res.sumPremium;
      }
    });
  }

  search(value: any) {
    this.queryParams = value;
    this.tplSearchModal.destroy();
  }

  // 查询重置
  reset = () => {
    this.queryParams = {};
    this.baseSearch = this.insuranceService.initBaseSearch;
    this.searchForm = this.insuranceService.getSearchForm(this.baseSearch);
  }

  insuranceEditorForm(model: Insurance, cb: (form: any) => void) {
    this.insuranceService.getArea().then((area: any[]) => {
      this.insuranceForm = this.insuranceService.getInsuranceForm(model, area, this.showOrderModal);
      this.tplModal = this.modalService.create({
        nzTitle: !!model.insuranceId ? '编辑保单' : '新增保单',
        nzContent: this.insuranceEditor,
        nzMaskClosable: false,
        nzClosable: true,
        nzWidth: 750,
        nzFooter: [
          {
            label: '取消',
            onClick: () => this.tplModal.destroy()
          },
          {
            label: '确定',
            type: 'primary',
            onClick: com => {
              dateFormat(this.insuranceForm);
              cb(convert(this.insuranceForm));
            }
          }
        ]
      });
    });
  }

  submit(insurance: Insurance, form: any) {
    this.errorMsg = Validation(this.insuranceForm);
    if (this.errorMsg.length > 0) {
      this.errorMsg.forEach(item => this.message.warning(item));
      return;
    }
    // 数据校验
    let body: Insurance = Object.assign(form, { companyId: this.authService.profile['companyId'] });
    if (body.riskStartDate <= new Date() || body.riskStartDate > body.riskEndDate) {
      this.message.warning('保单开始时间必须大于当前时间且小于保单结束时间!');
      return;
    }
    // 根据货物分类校验保额取值范围
    const messages = this.insuranceService.isTypeMin(body);
    if (messages.length > 0) {
      messages.forEach(item => this.message.warning(item));
      return;
    }
    // 解析地址以及地址编码
    body = this.insuranceService.splitStartAndEnd(body);
    body = Object.assign(insurance, body);
    let message = '';
    if (insurance.insuranceId) {
      this.fetchService.put(`${REQUEST.INSURANCE.ALL}`, body, (res: any) => {
        if (!res.isError) {
          message = '编辑保单成功';
          this.showMessage(message);
        }
      });
    } else {
      this.fetchService.post(`${REQUEST.INSURANCE.ALL}`, body, (res: any) => {
        if (!res.isError) {
          message = '新增保单成功';
          this.showMessage(message);
        }
      });
    }
  }

  showMessage(message: string) {
    this.message.success(message);
    this.tplModal.destroy();
    this.baseTable.refresh();
  }

  superSearch = () => {
    let params: { [key: string]: any } = {};
    this.tplSearchModal = this.modalService.create({
      nzTitle: '高级搜索',
      nzContent: this.searchModal,
      nzMaskClosable: false,
      nzClosable: true,
      nzWidth: 600,
      nzFooter: [
        {
          label: '取消',
          onClick: () => this.tplSearchModal.close()
        },
        {
          label: '确定',
          type: 'primary',
          onClick: com => {
            let query = deepClone(this.searchForm);
            query.map(item => {
              if (item.key === 'risk_start_date') {
                if (item.value !== [] && item.value.length > 0) {
                  let btm = this.util.dateFormat(item.value[0], 'YYYY-MM-DD HH:mm:ss');
                  let etm = this.util.dateFormat(item.value[1], 'YYYY-MM-DD HH:mm:ss');
                  item.value = btm + ',' + etm;
                }
              }
            });
            params = convert(query);
            if (params.risk_start_date.length === 0) {
              delete params.risk_start_date;
            }
            this.search(params);
          }
        }
      ]
    })
  }

  create() {
    const insurance: Insurance = new Insurance();
    this.insuranceEditorForm(insurance, ((form: any) => this.submit(insurance, form)));
  }

  update() {
    if (this.selectRecords.length !== 1) {
      this.message.warning('请选择一条记录编辑！');
      return;
    }
    const insurance: Insurance = <Insurance>this.selectRecords[0];
    if (insurance.syncZj) {
      this.message.warning('只能修改未投递的保单!');
      return;
    }
    this.insuranceEditorForm(insurance, ((form: any) => this.submit(insurance, form)));
  }

  remove() {
    if (this.selectRecords.length <= 0) {
      this.message.warning('请选择要删除的记录！');
      return;
    }
    const ids = this.selectRecords.map(item => item.insuranceId);
    if (this.selectRecords.filter((item: Insurance) => item.syncZj).length > 0) {
      this.message.warning('只能删除未投递的保单！');
      return;
    }
    this.util.submitConfirm('删除保单').then(() => {
      this.fetchService.delete(`${REQUEST.INSURANCE.ALL}/${ids}`, null, (res: any) => {
        if (!res || !res.isError) {
          this.message.success('删除保单成功！');
          this.baseTable.refresh();
        }
      });
    });
  }

  submitIns() {
    if (this.selectRecords.length !== 1) {
      this.message.warning('请选择要投保的一条记录！');
      return;
    }
    const insurance: Insurance = <Insurance>this.selectRecords[0];
    if (insurance.syncZj) {
      this.message.warning('只能提交未投递的保单!');
      return;
    }
    this.util.submitConfirm(
      '投递保单',
      `编号为:${insurance.insuranceId}, 保额为:${insurance.totalInsuredAmount}的保单`
    ).then(() => {
      this.fetchService.put(`${REQUEST.INSURANCE.SYNC}${insurance.insuranceId}`, null, (res: any) => {
        if (!res || !res.isError) {
          this.message.success('保单投递成功！');
          this.baseTable.refresh();
        }
      });
    });
  }

  showPDF() {
    if (this.selectRecords.length !== 1) {
      this.message.warning('请选择一条已投保成功的记录！');
      return;
    }
    const insurance: Insurance = <Insurance>this.selectRecords[0];
    if (!insurance.syncZj) {
      this.message.warning('只有确认成功的保险单才能查看!');
      return;
    }
    this.fetchService.get(`${REQUEST.INSURANCE.POLICY}${insurance.insuranceId}`, null, (res: any) => {
      if (!res.isError) {
        this.modalService.create({
          nzTitle: '保单预览',
          nzContent: PdfViewerComponent,
          nzComponentParams: {
            baseUrl: res.data,
          },
          nzMaskClosable: true,
          nzClosable: true,
          nzWidth: 800,
          nzFooter: null
        });
      }
    });
  }

  exportout() {
    let params: { [key: string]: any } = {};
    this.exportForm = this.insuranceService.getExportForm({ risk_start_date: [] });
    this.tplExportModal = this.modalService.create({
      nzTitle: '提示:不选时间段点击确定默认导出全部保单记录',
      nzContent: this.exportModal,
      nzMaskClosable: false,
      nzClosable: true,
      nzWidth: 600,
      nzFooter: [
        {
          label: '取消',
          onClick: () => this.tplExportModal.close()
        },
        {
          label: '确定',
          type: 'primary',
          onClick: com => {
            let query = deepClone(this.exportForm)
            query.map(item => {
              if (item.value !== []) {
                if (item.value.length > 0) {
                  let btm = this.util.dateFormat(item.value[0], 'YYYY-MM-DD HH:mm:ss');
                  let etm = this.util.dateFormat(item.value[1], 'YYYY-MM-DD HH:mm:ss');
                  item.value = btm + ',' + etm;
                }
              }
            });
            params = convert(query);
            if (params.risk_start_date.length === 0) {
              params = {};
            }
            this.requestExport(params);
          }
        }
      ]
    })
  }

  requestExport(params) {
    this.insuranceService.exportout(params).then((res: any) => {
      if (!res.error) {
        this.util.exportExcel(res.path);
        this.exportForm = this.insuranceService.getExportForm({ risk_start_date: [] });
        this.tplExportModal.destroy();
      }
    });
  }

  showOrderModal = () => {
    this.tplOrderModal = this.modalService.create({
      nzTitle: '可投保派车单列表',
      nzContent: this.orderModal,
      nzMaskClosable: true,
      nzClosable: true,
      nzWidth: 1200,
      nzFooter: null
    });
    this.orderSearchData(true);
  }

  selectOrder() {
    const selectOrders = this.orderDataSource.filter((item: any) => item.checked);
    if (selectOrders.length !== 1) {
      this.message.warning('请选择一条需要操作的订单！');
      return;
    }
    const order = selectOrders[0];
    this.insuranceForm.map((item: Condition) => {
      if (item.key === 'dispatchOrder') {
        item.value = order.carOrderCode;
      }
      if (item.key === 'dispatchOrderId') {
        item.value = order.did;
      }
      if (item.key === 'vno') {
        item.value = order.plateNum;
      }
      if (item.key === 'cargoName') {
        item.value = order.title;
      }
    });
    this.tplOrderModal.close();
  }

  orderSearchData(reset: boolean = false): void {
    if (reset) {
      this.orderPageIndex = 1;
    }
    this.orderSearchNow();
  }

  /* 请求调用 */
  orderSearchNow(params = {}) {
    /* 增加分页参数 */
    params['company_id'] = this.authService.profile['companyId'];
    params['page'] = this.orderPageIndex;
    params['per_page'] = this.orderPageSize;
    return new Promise((resolve, reject) => {
      this.orderDataSource = [];
      this.fetchService.get(REQUEST.INSURANCE.ORDER, params, (res) => {
        if (res._embedded) {
          this.orderDataSource = res._embedded.dispatchCarResourceList || [];
        }
        if (res.page) {
          this.orderTotal = res.page.totalElements;
        }
        resolve();
      });
    });
  }

  // 用户输入完成
  onInput() {
    if (this.orderCodeValue) {
      const params = { dispatchCarCode: this.orderCodeValue };
      const reqUrl = REQUEST.ORDER.RETRIEVAL1;
      this.fetchService.get(reqUrl, params, (res: any) => {
        this.orderCodeOptions = res;
      });
    } else {
      this.orderSearchData(true);
    }
  }

  // 下拉选择完成
  selectChange() {
    this.orderSearchNow({ car_order_code: this.orderCodeValue });
  }
}
