import { Injectable } from '@angular/core';
import { FetchService } from '../../../core/services/fetch.service';
import { Condition, OilCardRecord } from '../../../shared/model/carModels';
import { ZJCargoType } from '../../../core/config/dropdown.conf';
import { REQUEST } from '../../../core/config/linkMap';
import { RendererService } from '../../../core/services/renderer.service';
import { UtilService } from 'src/app/core/services/util.service';
import { NzMessageService } from 'ng-zorro-antd';

@Injectable({
  providedIn: 'root'
})
export class OilcardRecordService {

  constructor(
    private fetchService: FetchService,
    private util: UtilService,
    private message: NzMessageService,
  ) { }

  getAddCardForm(model: OilCardRecord, fun: () => void): Condition[] {
    return [
      {
        type: 2,
        label: '油卡类型',
        value: null,
        key: 'oilType',
        required: true,
        placeholder: '请选择油卡类型',
        option: this.util.switchDic('0004'),
      },
      {
        type: 2,
        label: '油卡提供方',
        value: null,
        key: 'thirdType',
        required: true,
        placeholder: '请选择油卡提供方',
        option: this.util.switchDic('0008'),
      },
      {
        type: 1,
        label: '司机',
        value: null,
        key: 'name',
        required: true,
        placeholder: '请选择司机',
        readOnly: true,
        click: fun
      },
      {
        type: 1,
        label: '司机ID',
        value: null,
        key: 'driverId',
        isHide: true,
        required: true,
      }
    ];
  }

  getRechargeApplyForm(model: OilCardRecord): Condition[] {
    return [
      {
        type: 11,
        label: '操作',
        value: 'charge',
        key: 'offer',
        required: true,
        isHide: true,
        option: [
          {name:'充值', value:'charge'},
          // {name:'扣款', value:'deduct'}
        ]
      },
      {
        type: 1,
        label: '油卡卡号',
        value: model.cardNum,
        key: 'cardNum',
        required: true,
      },
      {
        type: 17,
        label: '充值金额',
        value: '',
        key: 'money',
        required: true,
        placeholder: '请输入金额',
        rules:['isNumber'],
        min: 0.01
      },
      {
        type: 1,
        label: '油卡ID',
        value: model.cardId,
        key: 'cardId',
        required: true,
        isHide: true
      }
    ];
  }
}
