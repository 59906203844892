import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Condition, Partner, addressParams} from '../../shared/model/carModels';
import {NzMessageService, NzModalRef, NzModalService} from 'ng-zorro-antd';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../core/services/authentication.service';
import {FetchService} from '../../core/services/fetch.service';
import {REQUEST} from '../../core/config/linkMap';
import {convert, dateFormat, convertImg} from '../../shared/utils/adapter';
import {Validation, isUpdateImg} from '../../shared/utils/validation';
import {PartnerService} from './service/partner.service';
import { BaseTableComponent } from 'src/app/shared/components/base-table/base-table.component';
import { TableColumnService } from 'src/app/core/services/table-column.service';
import { BaseTableColumn, BaseTableData, BaseTableRow } from 'src/app/shared/components/base-table/interface';
import { measureScrollX } from 'src/app/shared/utils/measure-scrollbar';
import { UtilService } from 'src/app/core/services/util.service';
import { ArrayService } from '../../core/services/array.service';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.less']
})
export class PartnerComponent implements OnInit {
  // 表单变量
  partnerForm: Condition[];
  partnerLicenseForm: Condition[];
  errorMsg: string[];
  // 基础表格变量
  loading = false;
  tableData: BaseTableData;
  columns: BaseTableColumn[] = [];
  addressParams:addressParams;
  selectRecords: BaseTableRow[] = [];
  scrollX: string;
  queryParams: { [key: string]: any } = {};
  @ViewChild('baseTable')
  private baseTable: BaseTableComponent;
  @ViewChild('partnerEditor')
  private partnerEditor: TemplateRef<{}>;
  private tplModal: NzModalRef;
  picErrorMsg: any[];
  isShowUpload: true;

  @ViewChild('taxOfficeModal')
  private taxOfficeModal: TemplateRef<{}>;
  private tplTaxOfficeModal: NzModalRef;

  // 弹出框表格变量
  taxOfficeValue: string;
  taxOfficeOptions = [];
  taxOfficeDataSource: BaseTableData;
  taxOfficeColumns: BaseTableColumn[] = [];
  selectTaxOffices: BaseTableRow[] = [];
  taxOfficeQueryParams: { [key: string]: any } = {};
  @ViewChild('taxOfficeBaseTable')
  private taxOfficeBaseTable: BaseTableComponent;
  searchParams: any;

  constructor(
    private router: Router,
    private modalService: NzModalService,
    private authService: AuthenticationService,
    private fetchService: FetchService,
    private message: NzMessageService,
    private partnerService: PartnerService,
    private tableColumn: TableColumnService,
    private util: UtilService,
    private arrayService: ArrayService
  ) {
    this.columns = this.tableColumn.getPartnerColumns();
    this.addressParams = this.partnerService.addressUrlParams;
  }

  ngOnInit() {
    this.scrollX = measureScrollX(this.columns);
  }

  search(value: any) {
    this.queryParams = {connector: value};
  }

  /* 请求调用 */
  load(params = {}) {
    /* 增加分页参数 */
    params['company_id'] = this.authService.profile['companyId'];
    params['per_page'] = params['size'];
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.tableData = { data: [], totalSize: 0 };
      this.fetchService.get(REQUEST.PARTNER.ALL, params, (res) => {
        this.loading = false;
        if (res._embedded) {
          this.tableData = {
            data: res._embedded.companyPartnerResourceList || [],
            totalSize: res.page.totalElements
          };
        }
        resolve();
      });
    });
  }

  partnerEditorForm(model: Partner, cb: (form: any, Licenseform: any) => void) {
    this.util.getArea(this.addressParams.url, {}).then((data: any[]) => {
      this.arrayService.visitTree(data, item => {
        if (item.isLeaf) {
          item.disabled = true;
        }
      });
      this.partnerForm = this.partnerService.getPartnerForm(model, data, this.showTaxOfficeModal);
      this.partnerLicenseForm = this.partnerService.getLicenseFormJson(model);
      this.tplModal = this.modalService.create({
        nzTitle: !!model._id ? '编辑合作伙伴' : '新增合作伙伴',
        nzContent: this.partnerEditor,
        nzMaskClosable: false,
        nzClosable: true,
        nzWidth: 800,
        nzFooter: [
          {
            label: '取消',
            onClick: () => this.tplModal.destroy()
          },
          {
            label: '确定',
            type: 'primary',
            onClick: com => {
              dateFormat(this.partnerForm);
              cb(convert(this.partnerForm), convertImg(this.partnerLicenseForm));
            }
          }
        ]
      });
    });
  }

  submit(partner: Partner, form: any, Licenseform: any) {
    //基础校验
    this.errorMsg = Validation(this.partnerForm);
    if (this.errorMsg.length > 0) {
      this.errorMsg.forEach(item => this.message.warning(item));
      return;
    }
    //图片校验
    this.picErrorMsg = isUpdateImg(this.partnerLicenseForm);
    if (this.picErrorMsg.length > 0) {
      this.picErrorMsg.forEach(item => this.message.warning(item));
      return;
    }
    let body: Partner = Object.assign(form, {compnayId: this.authService.profile['companyId'], _id: partner._id, fbfuuid:''});
    //数据校验
    const today = new Date()['Format']('yyyy-MM-dd hh:mm:ss');
    if (body.slrq > today) {
      this.message.warning('成立日期应小于今天');
      return;
    }
    // 解析地址以及地址编码
    body = this.partnerService.splitAddress(body);
    if(!body.ssdq){
      this.message.warning('请选择所属地区精确到市');
      return;
    }
    const bodyparams: Partner = Object.assign(body, Licenseform);
    let message = '';
    if (partner._id) {
      this.fetchService.put(`${REQUEST.PARTNER.ALL}`, bodyparams, (res: any) => {
        if (!res.isError) {
          message = '编辑成功';
          this.showMessage(message);
        }
      });
    } else {
      this.fetchService.post(`${REQUEST.PARTNER.ALL}`, bodyparams, (res: any) => {
        if (!res.isError) {
          message = '新增成功';
          this.showMessage(message);
        }
      });
    }
  }

  showMessage(message: string) {
    this.message.success(message);
    this.tplModal.destroy();
    this.baseTable.refresh();
  }

  create() {
    const partner: Partner = new Partner();
    this.partnerEditorForm(partner, ((form: any, Licenseform: any) => this.submit(partner, form, Licenseform)));
  }

  update() {
    if (this.selectRecords.length !== 1) {
      this.message.warning('请选择一条记录编辑！');
      return;
    }
    const partner: Partner = <Partner>this.selectRecords[0];
    this.partnerEditorForm(partner, ((form: any, Licenseform: any) => this.submit(partner, form, Licenseform)));
  }

  remove() {
    if (this.selectRecords.length <= 0) {
      this.message.warning('请选择要删除的记录！');
      return;
    }
    const ids = this.selectRecords.map(item => item._id);
    this.util.submitConfirm('删除合伙人').then(() => {
      this.fetchService.delete(`${REQUEST.PARTNER.ALL}/${ids}`, null, (res: any) => {
        if (!res || !res.isError) {
          this.message.success('删除合伙人成功！');
          this.baseTable.refresh();
        }
      });
    });
  }

  /* 表单弹框 */
  showTaxOfficeModal = () => {
    this.taxOfficeColumns = this.tableColumn.getTaxOfficeColumns();
    this.tplTaxOfficeModal = this.modalService.create({
      nzTitle: '主管税务机关列表',
      nzContent: this.taxOfficeModal,
      nzMaskClosable: true,
      nzClosable: true,
      nzWidth: 1200,
      nzFooter: null
    })
  }

  selectTaxOffice() {
    if (this.selectTaxOffices.length !== 1) {
      this.message.warning('请选择一条税务机关记录！');
      return;
    }
    const taxOffice = this.selectTaxOffices[0];
    this.partnerForm.map((item: Condition) => {
      if (item.key === 'zgswjgname') {
        item.value = taxOffice.mc;
      }
      if(item.key === 'zgswjg') {
        item.value = taxOffice.dm
      }
    });
    this.tplTaxOfficeModal.close();
  }


  /* 请求调用 */
  taxOfficeSearchNow(params: object) {
    /* 增加分页参数 */
    params['per_page'] = params['size'];
    this.loading = true;
    this.taxOfficeDataSource = { data: [], totalSize: 0 };
    this.fetchService.get(REQUEST.PARTNER.TAXOFFICE, params, (res) => {
      this.loading = false;
      if (res._embedded) {
        this.taxOfficeDataSource = {
          data: res._embedded.jSONObjectList || [],
          totalSize: res.page.totalElements
        }
      }
    });
  }

  // 用户输入完成
  onInput() {
    if (this.taxOfficeValue) {
      const params = {mc: this.taxOfficeValue};
      const reqUrl = REQUEST.PARTNER.TAXOFFICE;
      this.fetchService.get(reqUrl, params, (res: any) => {
        if(res._embedded){
          const data = !res._embedded.jSONObjectList ? [] : res._embedded.jSONObjectList;
          data.map(item => {
            this.taxOfficeOptions.push(item.mc);
          }) ;
        }
      });
    } else {
      this.taxOfficeSearchNow({});
    }
  }

  searchTaxOffice(){
    this.taxOfficeSearchNow({mc: this.taxOfficeValue});
  }
}
