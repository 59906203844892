import { Component, OnInit } from '@angular/core';
import { BreadService} from '../../core/services/bread.service';
import { ROUTER_BASE } from 'src/app/core/config/pageConfig';

@Component({
  selector: 'app-bread',
  templateUrl: './bread.component.html',
  styleUrls: ['./bread.component.less']
})
export class BreadComponent implements OnInit {

  routerList: any;
  constructor(private breadService: BreadService) {
  }

  ngOnInit() {
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngDoCheck() {
    if (location.pathname) {
      this.getCurrentRouter();
    }
  }

  getCurrentRouter() {
    this.routerList = [];
    const pathName = location.pathname.split('/');
    pathName.map((path) => {
      const filter = ROUTER_BASE.filter((base) => {
        return path === base.path;
      });
      if (filter && filter.length === 1) {
        this.routerList.push(filter[0]);
      }
    });
    if (this.routerList && this.routerList.length) {
      this.routerList.map((router, index) => {
        if (index === 0) {
          router.allPath = '/' + router.path;
        } else {
          router.allPath = this.routerList[index - 1].allPath + '/' + router.path;
        }
      });
    }
  }

  navClick(item) {
    const selectMenu = item.allPath.split('/')[1].toString();
    this.breadService.selectMenuChange(selectMenu);
  }
}
