import { NgModule } from '@angular/core';
import { PagesRoutingModule } from 'src/app/pages/pages-routing.module';
import { UserInformationComponent } from 'src/app/pages/user-information/user-information.component';
import { CarManagementComponent } from 'src/app/pages/car-management/car-management.component';
import { CarBindComponent } from 'src/app/pages/car-bind/car-bind.component';
import { CarHangupComponent } from 'src/app/pages/car-hangup/car-hangup.component';
import { DriverManagementComponent } from 'src/app/pages/driver-management/driver-management.component';
import { UnauditListComponent } from 'src/app/pages/unaudit-list/unaudit-list.component';
import { DriverInfoCompleteComponent } from 'src/app/pages/driver-info-complete/driver-info-complete.component';
import { PreDistManagementComponent } from 'src/app/pages/pre-dist-management/pre-dist-management.component';
import { FillWaybillComponent } from 'src/app/pages/fill-waybill/fill-waybill.component';
import { CarChooseModalComponent } from 'src/app/pages/car-choose-modal/car-choose-modal.component';
import { DistManagementComponent } from 'src/app/pages/dist-management/dist-management.component';
import { OilcardRecordComponent } from 'src/app/pages/oilcard-record/oilcard-record.component';
import { EtcRecordComponent } from 'src/app/pages/etc-record/etc-record.component';
import { EtcCardModalComponent } from 'src/app/pages/etc-card-modal/etc-card-modal.component';
import { BillingRecordComponent } from 'src/app/pages/billing-management/billing-record.component';
import { BillDetailComponent } from 'src/app/pages/bill-detail/bill-detail.component';
import { PositionRecordComponent } from 'src/app/pages/position-management/position-record.component';
import { TradeDetailComponent } from 'src/app/pages/trade-detail/trade-detail.component';
import { PreDistCreateComponent } from 'src/app/pages/pre-dist-create/pre-dist-create.component';
import { CarInfoEditComponent } from 'src/app/pages/car-info-edit/car-info-edit.component';
import { DriverInfoEditComponent } from 'src/app/pages/driver-info-edit/driver-info-edit.component';
import { SysSettingComponent } from 'src/app/pages/sys-setting/sys-setting.component';
import { PaymentRecordComponent } from 'src/app/pages/payment-record/payment-record.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { RoleManagementComponent } from './role-management/role-management.component';
import { Exception403Component } from './exception/403.component';
import { Exception404Component } from './exception/404.component';
import { Exception500Component } from './exception/500.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { LoginComponent } from './login/login.component';
import { SharedModule } from '../shared/shared.module';
import { InsuranceComponent } from './insurance/insurance.component';
import { PartnerComponent } from './partner/partner.component';
import { LogManagementComponent } from './log-management/log-management.component';
import { CreditInfoInquiryComponent } from './credit-service/credit-info-inquiry/credit-info-inquiry.component';
import { CreditServiceComponent } from './credit-service/credit-service.component';
import { DetailListComponent } from './credit-service/detail-list/detail-list.component';
import { TransferComponent } from './sys-setting/transfer/transfer.component';
import { TransRecordComponent } from './sys-setting/trans-record/trans-record.component';
import { PrivacyAgreementComponent } from './privacy-agreement/privacy-agreement.component';
import { UserAgreementComponent } from './user-agreement/user-agreement.component';
import { ContractVertifyComponent } from './contract-vertify/contract-vertify.component';
import { ContractSignSuccessComponent } from './contract-sign-success/contract-sign-success.component';
import { OrderInsuranceComponent } from './order-insurance/order-insurance.component';
import { MingshengSignSuccessComponent } from './mingsheng-sign-success/mingsheng-sign-success.component';

@NgModule({
  declarations: [
    LoginComponent,
    UserInformationComponent,
    CarManagementComponent,
    CarBindComponent,
    OilcardRecordComponent,
    EtcRecordComponent,
    BillingRecordComponent,
    PositionRecordComponent,
    TradeDetailComponent,
    EtcCardModalComponent,
    PreDistManagementComponent,
    DistManagementComponent,
    DriverInfoCompleteComponent,
    CarChooseModalComponent,
    CarHangupComponent,
    BillDetailComponent,
    DriverManagementComponent,
    UnauditListComponent,
    PreDistCreateComponent,
    CarInfoEditComponent,
    DriverInfoEditComponent,
    FillWaybillComponent,
    SysSettingComponent,
    PaymentRecordComponent,
    UserManagementComponent,
    RoleManagementComponent,
    Exception403Component,
    Exception404Component,
    Exception500Component,
    InsuranceComponent,
    PartnerComponent,
    LogManagementComponent,
    CreditInfoInquiryComponent,
    CreditServiceComponent,
    DetailListComponent,
    TransferComponent,
    TransRecordComponent,
    PrivacyAgreementComponent,
    UserAgreementComponent,
    ContractVertifyComponent,
    ContractSignSuccessComponent,
    OrderInsuranceComponent,
    MingshengSignSuccessComponent
  ],
  imports: [
    SharedModule,
    PagesRoutingModule,
    NgxPermissionsModule.forChild()
  ],
})
export class PagesModule {
}
