import { NgModule } from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {BasePageComponent} from './base-page/base-page.component';
import {UserSettingComponent} from './user-setting/user-setting.component';
import {BreadComponent} from './bread/bread.component';
import {MenuComponent} from './menu/menu.component';
import {NgxPermissionsModule} from 'ngx-permissions';

@NgModule({
  declarations: [
    BasePageComponent,
    UserSettingComponent,
    BreadComponent,
    MenuComponent
  ],
  imports: [
    SharedModule,
    NgxPermissionsModule.forChild()
  ],
  exports: [
    BasePageComponent
  ]
})
export class LayoutModule { }
