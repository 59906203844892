import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UtilService } from 'src/app/core/services/util.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-batch-operate-list',
  templateUrl: './batch-operate-list.component.html',
  styleUrls: ['./batch-operate-list.component.less']
})
export class BatchOperateListComponent implements OnInit {
  
  @Input() list:Array<{id: string, name: string, operateType: string,url:string,hide:boolean}>;
  @Output() propagateChangeData: EventEmitter<any> = new EventEmitter();

  constructor(
    private util: UtilService,
    private permService: NgxPermissionsService
  ) { }

  ngOnInit() {
    const permissions = Object.keys(this.permService.getPermissions());
    this.list.map(item =>{
      item.hide = !!item.hide;
      if(item.hide === false && item.id && permissions.indexOf(item.id) === -1){
        item.hide = true;
      }
    });
  }

  download(item){
    this.util.exportExcel('',item.downloadurl);
  }

  propagateChange(e){
    this.propagateChangeData.emit(e)
  }

}
