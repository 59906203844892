import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { TransferService } from './service/transfer.service';
import { Condition } from 'src/app/shared/model/carModels';
import { REQUEST } from 'src/app/core/config/linkMap';
import { FetchService } from 'src/app/core/services/fetch.service';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { UtilService } from 'src/app/core/services/util.service';
import { Validation } from 'src/app/shared/utils/validation';
import { convert } from 'src/app/shared/utils/adapter';


@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.less']
})
export class TransferComponent implements OnInit {
  // 表单变量
  transForm: Condition[];
  errorMsg: string[];
  selectedIndex: number;

  @ViewChild('transModal')
  private transModal: TemplateRef<{}>;
  @ViewChild('scanModal')
  private scanModal: TemplateRef<{}>;

  constructor(
    private transferService: TransferService,
    private fetchService: FetchService,
    private message: NzMessageService,
    private util: UtilService,
    private modalService: NzModalService
  ) {}

  ngOnInit() {
    this.transForm = this.transferService.getTransForm();
  }

  // 立即转账
  transfer() {
    //基础校验
    this.errorMsg = Validation(this.transForm);
    if (this.errorMsg.length > 0) {
      this.errorMsg.forEach(item => this.message.warning(item));
      return;
    }
    const params = convert(this.transForm);
    this.fetchService.post(REQUEST.USER_SETTING.NEW_TRANSFER, params, (res) => {
      if (!res.isError) {
        let url = res.data;
        window.open(url);
        this.selectedIndex = 2;
        // this.showTransModal();
      }
    });
  }

  selectChange(index){
    this.selectedIndex = index;
  }

  // 打开支付地址浏览器选择
  showbankScanModal() {
    const modal = this.modalService.create({
      nzTitle: '转账银行打开方式',
      nzContent: this.scanModal,
      nzMaskClosable: true,
      nzClosable: true,
      nzFooter: null
    })
  }

  //充值跳转弹框
  showTransModal() {
    const modal = this.modalService.create({
      nzTitle: '充值',
      nzContent: this.transModal,
      nzMaskClosable: true,
      nzClosable: true,
      nzFooter: [
        {
          label: '充值成功',
          type: 'primary',
          onClick: () => {
            modal.close();
          }
        },
        {
          label: '充值失败',
          type: 'default',
          onClick: () => {
            modal.close();
          }
        }
      ]
    })
  }

  
}
