import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Condition, TradeDetailRecord, Dic, ImportOption } from '../../shared/model/carModels';
import { getCond, filterRecord } from '../../shared/utils/adapter';
import { REQUEST } from '../../core/config/linkMap';
import { PAYMENT_COND } from '../../core/config/pageConfig';
import { Router } from '@angular/router';
import { NzModalService, NzModalRef } from 'ng-zorro-antd';
import { FetchService } from '../../core/services/fetch.service';
import { NzMessageService } from 'ng-zorro-antd';
import { AuthenticationService } from '../../core/services/authentication.service';
import { PaymentRecordService } from './service/payment-record.service';
import { UtilService } from 'src/app/core/services/util.service';
import { ethnicType, sexType } from 'src/app/core/config/dropdown.conf';

@Component({
  selector: 'app-payment-record',
  templateUrl: './payment-record.component.html',
  styleUrls: ['./payment-record.component.less']
})
export class PaymentRecordComponent implements OnInit {

  @Input() payType: string;
  //@Input() cardNum: string;
  @Output() nzOnCancel = new EventEmitter();

  //公司ID
  //company_id = 1;
  //登录用户id
  //user_id = 1;
  //付款人姓名
  //user_name = '秀琴'

  //图片域名地址
  imgUrl = REQUEST.DISPLAY_PIC_BASE;

  /*  分页参数 */
  pageIndex = 1;
  pageSize = 5;
  total = 1;
  loading = false;
  pageSizeOptions = [5, 10, 20, 50];

  isOkLoading = false;
  willTotal = 0;
  applyTotal = 0;
  paymentTotal = 0;
  accountTotal = 0;
  checkTotal = 0;

  isVisible = false;
  searchMoreClassName = 'super-search';
  condition: Condition[];
  selectRecords = [];
  dataSource: any[];

  /*  表格选择参数 */
  allChecked = false;
  indeterminate = false;

  search: string;
  type: string;


  // 请输入支付密码
  paypasspordVisible: boolean = false;
  paypasspordVisibleY: boolean = false;
  paysuccessVisible: boolean = false;
  paypassporddrVisible: boolean = false;
  nosufficientfundsVisible: boolean = false;
  refuseVisibleLoading: boolean = false;
  refuseVisible: boolean = false;
  preChargeClassName = 'pre-charge-modal';
  cardNum: string;
  pwdTipClose: boolean;
  pwdTipMsg: string;
  password: string;
  inputSix: boolean = false;
  //点击支付按钮的loading
  preChargeSubmitLoading: boolean = false;
  errMessage: string
  //验证码
  verificationCode: string;
  //验证码图片地址
  payPasspordCode: string;
  //限制请求时间
  payPasspordCodeNum: number

  //查看图片
  infoImgVisible: boolean = false;
  infoImgurl: string;

  //是否有勾选到油卡
  ishasYid: boolean = false;
  //申请人
  sName = this.authService.profile['userName']
  //运单详情
  @ViewChild('showDetail')
  private showDetail: TemplateRef<any>;
  orderCodeInfo: any;
  baseInfoList: Array<{ key: string, value: string }>;
  licenseInfoList: Array<{ key: string, value: string }>;
  orderTypeOptions: Dic[] = [];
  goodsTypeOptions: Dic[] = [];

  importList: ImportOption[];
  @ViewChild('importModal')
  private importModal: TemplateRef<{}>;
  private tplImportModal: NzModalRef
  //高级搜索
  private superSearchParams: any;
  cargoTypeOption=[];
  constructor(private router: Router,
    private message: NzMessageService,
    private modalService: NzModalService,
    private fetchService: FetchService,
    private authService: AuthenticationService,
    private paymentRecordService: PaymentRecordService,
    private util: UtilService,
  ) {
    this.condition = JSON.parse(JSON.stringify(PAYMENT_COND));
    this.baseInfoList = this.paymentRecordService.getBaseIfo;
    this.licenseInfoList = this.paymentRecordService.getLicenseInfo;
  }

  ngOnInit() {
    this.dataSource = [];
    this.searchData();
    this.selectRecords = [];
    this.getAccount();
  }

  searchData(reset: boolean = false): void {
    if (reset) {
      this.pageIndex = 1;
    }
    this.searchNow(this.superSearchParams);
  }

  //获取各金额
  getAccount() {
    this.fetchService.get(REQUEST.CLOSE_AN_ACCOUNT.ACCOUNT, '', (res: any) => {
      if (!res.isError) {
        this.paymentTotal = res.data[0];
        this.applyTotal = res.data[1];
        this.willTotal = res.data[2];
      }
    })
  }

  /* 高级搜索 */
  superSearch() {
    this.isOkLoading = true;
    const car_order_code = getCond(this.condition, 'car_order_code');
    const name = getCond(this.condition, 'name');
    const mobile = getCond(this.condition, 'mobile');
    const proposer = getCond(this.condition, 'proposer');
    const offer_type = getCond(this.condition, 'offer_type');
    const build_order_date_b = getCond(this.condition, 'build_order_date_b');
    const build_order_date_e = getCond(this.condition, 'build_order_date_e');
    const application_date_b = getCond(this.condition, 'application_date_b');
    const application_date_e = getCond(this.condition, 'application_date_e');
    const cargo_address = getCond(this.condition, 'cargo_address');
    const delivery_address = getCond(this.condition, 'delivery_address');
    const offer_state = getCond(this.condition, 'offer_state');
    const params = {};
    if (car_order_code.value) {
      params['car_order_code'] = car_order_code.value;
    }
    if (name.value) {
      params['name'] = name.value;
    }
    if (mobile.value) {
      params['mobile'] = mobile.value;
    }
    if (proposer.value) {
      params['proposer'] = proposer.value;
    }

    if (offer_type.value) {
      params['offer_type'] = offer_type.value;
    }
    if (build_order_date_b.value) {
      params['jdsj'] = this.util.dateFormat(build_order_date_b.value) + ',' + this.util.dateFormat(build_order_date_e.value);
    }
    if (application_date_b.value) {
      params['application_date'] = this.util.dateFormat(application_date_b.value) + ',' + this.util.dateFormat(application_date_e.value);
    }
    if (cargo_address.value) {
      params['cargo_address'] = cargo_address.value;
    }
    if (delivery_address.value) {
      params['delivery_address'] = delivery_address.value;
    }
    if (offer_state.value) {
      params['offer_state'] = offer_state.value;
    }
    this.superSearchParams = params;
    this.pageIndex = 1;
    this.searchNow(params).then(() => {
      this.isOkLoading = false;
      this.isVisible = false;
      this.condition = JSON.parse(JSON.stringify(PAYMENT_COND));
    });
  }

  /* 请求调用 */
  searchNow(params = {}) {
    params['company_id'] = this.authService.profile['companyId'];
    params['page'] = this.pageIndex;
    params['per_page'] = this.pageSize;
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.dataSource = [];
      this.total = 0;
      this.fetchService.get(REQUEST.CLOSE_AN_ACCOUNT.PAYMENT_RECORD, params, (res: any) => {
        this.loading = false;
        if (res._embedded) {
          this.dataSource = res._embedded.orderOfferLogResourceList || [];
        }
        if (res.page) {
          this.total = res.page.totalElements;
        }
        resolve();
      });
    });
  }

  clear() {
    this.superSearchParams = {};
    this.pageSizeOptions = [5, 10, 20, 50];
    this.pageSize = 5;
    this.searchData(true);
  }

  currentPageDataChange($event: Array<{ name: string; age: number; address: string; checked: boolean; disabled: boolean; }>): void {
    this.refreshStatus();
  }

  refreshStatus(): void {
    const allChecked = this.dataSource.every(value => value.checked === true);
    const allUnChecked = this.dataSource.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);

    /* 选择数据同步 */
    this.selectRecords = this.dataSource.filter(value => value.checked && value.offerState != 1);
    this.checkAmount(this.selectRecords);
  }

  checkAll(value: boolean): void {
    this.dataSource.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  //选择金额合计
  checkAmount(selectRecords) {
    this.checkTotal = 0;
    selectRecords.forEach(data => {
      this.checkTotal += data.paymentAmount;
    });
    this.checkTotal = Number(this.checkTotal.toFixed(2));
  }


  //获取图片验证码
  getImgCode() {
    this.fetchService.get(REQUEST.OIL.ALL + '/getValidatePic/112/30', {}, (res: any) => {
      if (res.data) { this.payPasspordCode = res.data }
      setTimeout(() => {
        this.payPasspordCodeNum = 1
      }, 3000)
    });
  }

  //点击图片验证码重新获取
  imgCodeChange() {
    if (this.payPasspordCodeNum == 1) {
      this.payPasspordCodeNum = 0
      this.getImgCode();
    }
  }

  //支付
  activePayment() {
    if (!this.selectRecords.length) {
      this.modalService.warning({
        nzTitle: '警告信息：',
        nzContent: `<p>请选择至少一条需要支付的信息</p>`
      });
      return false;
    } else {
      this.selectRecords.forEach(item => {
        if (item.offerType == 3) {
          this.ishasYid = true;
        }
      })
    }
    this.inputSix = true;
    let isMessage = 0;
    let paymentMoney = 0;
    if (this.ishasYid) {
      this.paypasspordVisibleY = true;
      this.getImgCode();
    } else {
      this.paypasspordVisible = true;
    }
  }

  passwordChange(password: string) {
    this.password = password;
    this.pwdTipClose = true;
    if (!password) {
      this.pwdTipClose = false;
      this.pwdTipMsg = '您输入的密码为空或者输入的密码格式有误！';
    }
  }

  //确定支付
  preChargeSubmit() {
    this.passwordChange(this.password);
    if (this.password) {
      if (this.ishasYid) {
        if (!/^[0-9]*$/.test(this.verificationCode)) {
          this.message.create('error', '请输入验证码！');
          return false;
        }
      }
      //调用支付接口
      let company_id = this.authService.profile['companyId'];
      let user_id = this.authService.profile['userId'];
      let user_name = this.authService.profile['userName'];
      let password = this.password;
      //let password = '123'
      let ids = [];
      this.selectRecords.forEach(item => {
        ids.push(item.orderOfferLogId)
      })
      this.preChargeSubmitLoading = true;
      if (this.ishasYid) {
        this.fetchService.put(REQUEST.CLOSE_AN_ACCOUNT.TO_PAYMENT + '?company_id=' + company_id + '&user_id=' + user_id + '&validate_code=' + this.verificationCode + '&user_name=' + user_name + '&pay_pwd=' + password, ids, (res: any) => {
          this.paypasspordVisibleY = false;
          if (!res || !res.isError) {
            this.message.create('success', '支付成功！');
          } else if (res.code == 422) {
            this.errMessage = res.message;
            this.nosufficientfundsVisible = true;
          }
          this.searchData();
          this.preChargeSubmitLoading = false;
        });
      } else {
        this.fetchService.put(REQUEST.CLOSE_AN_ACCOUNT.TO_PAYMENT + '?company_id=' + company_id + '&user_id=' + user_id + '&user_name=' + user_name + '&pay_pwd=' + password, ids, (res: any) => {
          this.paypasspordVisible = false;
          if (!res || !res.isError) {
            this.message.create('success', '支付成功！');
          } else if (res.code == 422) {
            this.errMessage = res.message;
            this.nosufficientfundsVisible = true;
          }
          this.searchData();
          this.preChargeSubmitLoading = false;
        });
      }
    }
  }
  //取消支付
  close() {
    this.password = '';
    this.verificationCode = '';
    this.inputSix = false;
    this.paypasspordVisible = false;
    this.paypasspordVisibleY = false;
    this.paysuccessVisible = false;
    this.refuseVisibleLoading = false;
    this.paypassporddrVisible = false;
    this.nosufficientfundsVisible = false;
    this.refuseVisible = false;
    this.infoImgVisible = false;
    this.infoImgurl = ''
  }

  //拒绝
  activeDown() {
    if (!this.selectRecords.length) {
      this.modalService.warning({
        nzTitle: '警告信息：',
        nzContent: `<p>请选择至少一条需要拒绝的信息</p>`
      });
      return false;
    }
    this.refuseVisible = true;
  }

  //确定拒绝
  refuseSubmit() {
    this.refuseVisibleLoading = true;
    //调用支付接口
    let company_id = this.authService.profile['companyId'];
    let user_name = this.authService.profile['userName'];
    let ids = [];
    this.selectRecords.forEach(item => {
      ids.push(item.orderOfferLogId)
    })

    this.fetchService.put(REQUEST.CLOSE_AN_ACCOUNT.TO_REPULSE + '?company_id=' + company_id + '&user_name=' + user_name, ids, (res: any) => {
      if (!res || !res.isError) {
        this.message.create('success', '拒绝成功！');
      }
      this.refuseVisible = false;
      this.refuseVisibleLoading = false;
      this.searchData()
    });
  }

  //
  // 图片点击放大
  infoImg(data) {
    let infoimg = data;
    this.infoImgurl = infoimg;
    this.infoImgVisible = true;
  }

  /**
   * 查看运单详情
   * @param order
   */
  orderCodeDetail(order: any): void {
    const carorderid = order.carOrderId;
    this.fetchService.get(REQUEST.CLOSE_AN_ACCOUNT.CARCODE_INFO + '/' + carorderid, {}, (res: any) => {
      if (!res || !res.isError) {
        this.orderCodeInfo = res;
        this.openOrderCodeDetail();
      } else {
        this.message.create('error', '运单详情获取失败，请稍后重试');
      }
    });
    this.getDetailSwichData();
  }

  /**
   * 获取详情特殊字段的字典
   */
  getDetailSwichData = () => {
    this.orderTypeOptions = this.util.switchDic('0010');
    this.goodsTypeOptions = this.paymentRecordService.goodsType;
  }

  /**
   * 打开运单详情model
   */
  openOrderCodeDetail(): void {
   // 获取货物类型字典
    this.cargoTypeOption = this.util.switchDic('0016');
    this.modalService.create({
      nzTitle: '运单详情',
      nzContent: this.showDetail,
      nzMaskClosable: true,
      nzClosable: true,
      nzWidth: 1500,
      nzFooter: null
    });
  }

  /**
 * 特殊信息渲染
 * @param key
 * @returns {string | any}
 */
  rendererInfo = (key: string): string => {
    if (key === 'sync') {
      let option = this.orderTypeOptions.filter(item => item.value === this.orderCodeInfo[key]);
      return option.length > 0 ? option[0].name : '';
    }
    if (key === 'goodsPropertyTypeCode') {
      let option = this.goodsTypeOptions.filter(item => item.value == this.orderCodeInfo[key]);
      return option.length > 0 ? option[0].name : '';
    }
    if (key === 'cargoType') {
      const result = this.cargoTypeOption.filter((item) => item.value === this.orderCodeInfo[key]);
      return result.length > 0 ? result[0].name: '';
    }
    if (key === 'cargoNum' || key === 'cargo') {
      return this.orderCodeInfo[key] + this.orderCodeInfo['cargUnit'];
    }
    if (key === 'unitWight') {
      return this.orderCodeInfo[key] + '千克';
    }
    if (key === 'cash' || key === 'orderMoney' || key === 'prepayment' || key === 'receiptDeposit' || key === 'etcMoney' || key === 'refuelCardMoney') {
      return this.orderCodeInfo[key] + '元';
    }
    if (key === 'dcollection') {
      return this.orderCodeInfo[key] === 1 ? '是' : '否';
    }
    if (key === 'cargoAddress') {
      return this.orderCodeInfo[key] + this.orderCodeInfo['cargoAddrDetail'];
    }
    if (key === 'deliveryAddress') {
      return this.orderCodeInfo[key] + this.orderCodeInfo['deliveryAddrDetail'];
    }
    if (key === 'xbdm') {
      const result = sexType.filter((item) => item.value === this.orderCodeInfo[key]);
      return result.length > 0 ? result[0].name : '';
    }
    if (key === 'mzdm') {
      const result = ethnicType.filter((item) => item.value === this.orderCodeInfo[key]);
      return result.length > 0 ? result[0].name : '';
    }
    return this.orderCodeInfo[key];
  }

  batchSearch() {
    this.importList = this.paymentRecordService.getImportOptionList;
    this.tplImportModal = this.modalService.create({
      nzTitle: '导入',
      nzContent: this.importModal,
      nzMaskClosable: false,
      nzClosable: true,
      nzWidth: 600,
      nzFooter: null
    });
  }

  propagateChangeData(data) {
    this.tplImportModal.close();
    if (data.response._embedded) {
      this.dataSource = data.response._embedded;
      this.total = this.dataSource.length;
      this.pageSize = 50;
      this.pageSizeOptions = [50];
      if(data.response.message.indexOf('成功') === -1 ){
        this.message.create('warning', data.response.message);
      }else{
        this.message.create('success', data.response.message);
      }
    }
  }
}
