import { Component, OnInit } from '@angular/core';
import { REQUEST } from '../../core/config/linkMap';
import { NzMessageService } from 'ng-zorro-antd';
import { FetchService } from '../../core/services/fetch.service';
import { AuthenticationService } from '../../core/services/authentication.service';
import { AmountPanel, QuicklyLink } from '../../shared/model/carModels';
import { USER_AMOUNT_LIST, QUICKLY_LINK_LIST } from '../../core/config/pageConfig';

@Component({
  selector: 'app-user-information',
  templateUrl: './user-information.component.html',
  styleUrls: ['./user-information.component.less']
})
export class UserInformationComponent implements OnInit {

  companyId = '2';
  seriesList = [];
  amountSource: AmountPanel[];
  quicklySource: QuicklyLink[];
  options: Object;

  constructor(
    private fetchService: FetchService,
    private message: NzMessageService,
    private authService: AuthenticationService) { }

  ngOnInit() {
    this.amountSource = USER_AMOUNT_LIST;
    this.quicklySource = QUICKLY_LINK_LIST;
    this.getStatValue();
    this.getAmountValue();
  }

  // 订单交易通计量
  initChart() {
    this.options = {
      title : {
        text: '订单交易通计量'
      },
      /* color: ['rgb(10, 95, 199)'], */
      tooltip : {
        trigger: 'axis'
      },
      xAxis : [
        {
            type : 'category',
            data : ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            axisLabel: {
              interval: 0
            }
        }
      ],
      yAxis : [
        {
            type : 'value'
        }
      ],
      series : [
          {
            name: '交易量',
            type: 'bar',
            data: this.seriesList
          }
      ]
    };
  }

  // 订单交易通计量
  getStatValue () {
    this.fetchService.get(REQUEST.USER_SETTING.GET_STAT + this.authService.profile['companyId'], {}, (res) => {
      if (res && !res.isError) {
        this.seriesList = res;
        this.initChart();
      } else {
        this.message.create('error', '订单交易通计量获取失败，请稍后重试');
      }
    });
  }

  // 获取账户金额
  getAmountValue () {
    this.fetchService.get(REQUEST.USER_SETTING.GET_AMOUNT + this.authService.profile['companyId'], {}, (res) => {
      if (!!res && !res.isError) {
        USER_AMOUNT_LIST[1].amount = res[1] || 0;
        USER_AMOUNT_LIST[2].amount = res[0] || 0;
      }
    });
    this.fetchService.get(REQUEST.USER_SETTING.VIRTUAL_ACCOUNT + this.authService.profile['companyId'], {}, (res) => {
      if (!!res && !res.isError) {
        USER_AMOUNT_LIST[0].amount = res.account || 0;
      }
    });
  }
}
