import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Condition } from '../../shared/model/carModels';
import { NzMessageService, NzModalRef, NzModalService } from 'ng-zorro-antd';
import { AuthenticationService } from '../../core/services/authentication.service';
import { FetchService } from '../../core/services/fetch.service';
import { REQUEST } from '../../core/config/linkMap';
import { convert, dateFormat1 } from '../../shared/utils/adapter';
import { BillingRecordService } from './service/billing-record.service';
import { BaseTableColumn, BaseTableData, BaseTableRow } from '../../shared/components/base-table/interface';
import { BaseTableComponent } from '../../shared/components/base-table/base-table.component';
import { measureScrollX } from '../../shared/utils/measure-scrollbar';
import { TableColumnService } from '../../core/services/table-column.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-billing-record',
  templateUrl: './billing-record.component.html',
  styleUrls: ['./billing-record.component.less']
})
export class BillingRecordComponent implements OnInit {
  // 表单变量
  searchForm: Condition[];
  // 弹出框表格变量
  orderDataSource: any[] = [];
  orderPageIndex = 1;
  orderPageSize = 5;
  orderTotal = 0;
  orderCodeValue: string;
  orderCodeOptions = [];
  // 基础表格变量
  loading = false;
  tableData: BaseTableData;
  columns: BaseTableColumn[] = [];
  selectRecords: BaseTableRow[] = [];
  scrollX: string;
  queryParams: { [key: string]: any } = {};
  @ViewChild('baseTable')
  private baseTable: BaseTableComponent;
  @ViewChild('orderModal')
  private orderModal: TemplateRef<{}>;
  private tplOrderModal: NzModalRef;
  @ViewChild('searchModal')
  private searchModal: TemplateRef<{}>;
  private tplSearchModal: NzModalRef;
  baseSearch: { drawee_name, state, invoice_code, delivery_code, apply_btm, perform_btm, apply_etm, perform_etm };
  applyTotal: number;
  settleTotal: number;
  checkedDataList: any[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NzModalService,
    private authService: AuthenticationService,
    private fetchService: FetchService,
    private message: NzMessageService,
    private billingRecordService: BillingRecordService,
    private tableColumn: TableColumnService
  ) {
    this.columns = this.tableColumn.getBillingRecordColumns();
    this.baseSearch = this.billingRecordService.initBaseSearch;
    this.searchForm = this.billingRecordService.getSearchForm(this.baseSearch);
  }

  ngOnInit() {
    this.scrollX = measureScrollX(this.columns);
  }

  /* 请求调用 */
  load(params: object) {
    /* 增加分页参数 */
    params['company_id'] = this.authService.profile['companyId'];
    params['per_page'] = params['size'];
    this.loading = true;
    this.tableData = { data: [], totalSize: 0 };
    this.fetchService.get(REQUEST.MAKE_INVOICE.ALL, params, (res) => {
      this.loading = false;
      if (res._embedded) {
        this.tableData = {
          data: res._embedded.invoiceResourceList || [],
          totalSize: res.page.totalElements
        };
        this.applyTotal = 0;
        this.settleTotal = 0;
        this.tableData.data.forEach((item) => {
          if (item.state === 0 || item.state === 1) {
            if (item.invoiceMoney) {
              this.applyTotal += item.invoiceMoney;
            }
          } else if (item.state === 2) {
            if (item.invoiceMoney) {
              this.settleTotal += item.invoiceMoney;
            }
          }
        });
        this.applyTotal = Number(this.applyTotal.toFixed(2));
        this.settleTotal = Number(this.settleTotal.toFixed(2));
      }
    });
  }

  search(value: any) {
    this.queryParams = value;
    this.tplSearchModal.destroy();
  }

  // 查询重置
  reset = () => {
    this.queryParams = {};
    this.baseSearch = this.billingRecordService.initBaseSearch;
    this.searchForm = this.billingRecordService.getSearchForm(this.baseSearch);
  }

  checkDetail = (data) => {
    this.router.navigate(['../billingRecord/detail', data.invoiceId], { relativeTo: this.route });
  }

  superSearch = () => {
    let params: { [key: string]: any } = {};
    this.tplSearchModal = this.modalService.create({
      nzTitle: '高级搜索',
      nzContent: this.searchModal,
      nzMaskClosable: false,
      nzClosable: true,
      nzWidth: 740,
      nzFooter: [
        {
          label: '取消',
          onClick: () => this.tplSearchModal.close()
        },
        {
          label: '确定',
          type: 'primary',
          onClick: com => {
            dateFormat1(this.searchForm);
            params = convert(this.searchForm);
            for (let key in params) {
              if (key == 'apply_btm' && params['apply_btm']) {
                params['apply_date_time'] = params['apply_btm'] + ',' + params['apply_etm'];
                delete params['apply_etm'];
                delete params['apply_btm'];
              }
              if (key == 'perform_btm' && params['perform_btm']) {
                params['perform_date_time'] = params['perform_btm'] + ',' + params['perform_etm'];
                delete params['perform_etm'];
                delete params['perform_btm'];
              }
            }
            this.search(params);
          }
        }
      ]
    })
  }

  applyBilling = () => {
    this.checkedDataList = [];
    this.tplOrderModal = this.modalService.create({
      nzTitle: '可开票派车单列表',
      nzContent: this.orderModal,
      nzMaskClosable: true,
      nzClosable: true,
      nzWidth: 1200,
      nzFooter: null
    });
    this.orderSearchData(true);
  }
  unique(arr) {
    let hash = {};//缓存对象数组里的某一个属性
    arr = arr.reduce((item, next, index, data) => {
      hash[next.did] ? '' : hash[next.did] = true && item.push(next);
      return item
    }, [])
    return arr
  }

  invoice() {
    const selectOrders = this.unique(this.checkedDataList);
    console.log(selectOrders)
    if (selectOrders.length === 0) {
      this.message.warning('请至少选择一条需要操作的订单！');
      return;
    }
    if (selectOrders.some(data => data.title !== selectOrders[0].title)) {
      this.message.warning('请选择货物名称一致的订单！');
      return;
    }
    const params = {
      res: {
        companyId: this.authService.profile['companyId'],
        serviceName: '运输服务'
      },
      ids: selectOrders.map(item => item.did)
    };
    this.fetchService.post(REQUEST.DIST.APPLY_BILL, params, (res) => {
      if (!res.isError) {
        this.message.create('success', '您的申请开票成功');
        this.tplOrderModal.close();
        this.search(null);
      }
    });
  }

  checkOrder(order) {
    if (order.checked) {
      this.checkedDataList.push(order)
    } else {
      this.checkedDataList = this.checkedDataList.filter(item => item.did !== order.did)
    }
    console.log(this.checkedDataList)
  }
  
  orderSearchData(reset: boolean = false): void {
    if (reset) {
      this.orderPageIndex = 1;
    }
    this.orderSearchNow();
  }

  /* 请求调用 */
  orderSearchNow(params = {}) {
    /* 增加分页参数 */
    params['company_id'] = this.authService.profile['companyId'];
    params['page'] = this.orderPageIndex;
    params['per_page'] = this.orderPageSize;
    params['pay_state'] = 2; //筛选出已到账的派车单


    return new Promise((resolve, reject) => {
      this.orderDataSource = [];
      this.fetchService.get(REQUEST.MAKE_INVOICE.ORDERLIST, params, (res) => {
        if (res._embedded) {
          this.orderDataSource = res._embedded.dispatchCarResourceList || [];
          this.orderDataSource.map(item => {
            this.checkedDataList.map(ite => {
              if (item.did == ite.did) {
                item.checked = true
              }
            })
            return item
          })
        }
        if (res.page) {
          this.orderTotal = res.page.totalElements;
        }
        resolve();
      });
    });
  }

  // 用户输入完成
  onInput() {
    if (this.orderCodeValue) {
      const params = { dispatchCarCode: this.orderCodeValue };
      const reqUrl = REQUEST.ORDER.RETRIEVAL1;
      this.fetchService.get(reqUrl, params, (res: any) => {
        this.orderCodeOptions = res;
      });
    } else {
      this.orderSearchData(true);
    }
  }

  // 下拉选择完成
  selectChange() {
    this.orderSearchNow({ car_order_code: this.orderCodeValue });
  }
}
