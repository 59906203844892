import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BillingDetailService {

  constructor(
  ) { }
  
  get getBaseIfo(): any {
    return [
      {key: 'invoiceCode', value: '发票代码'},
      {key: 'draweeIdentity', value: '受票方纳税识别号'},
      {key: 'draweeName', value: '受票方名称'},
      {key: 'address', value: '地址'},
      {key: 'phone', value: '联系电话'},
      {key: 'bankName', value: '开户行'},
      {key: 'bankAccount', value: '开户账号'},
      {key: 'khrxm', value: '开户人姓名'},
      {key: 'khrsfzjhm', value: '开户人身份证号'},
      {key: 'serviceName', value: '货物或应税劳务、服务名称'},
      {key: 'state', value: '审核状态'},
      {key: 'rejectNum', value: '拒绝理由'},
      {key: 'mark', value: '备注'}
    ];
  }
}
