import { Component, OnInit, Input, Output, EventEmitter,ElementRef } from '@angular/core';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.less']
})
export class PasswordComponent implements OnInit {

  @Output() passwordChange = new EventEmitter<string>();

  p1: string;
  p2: string;
  p3: string;
  p4: string;
  p5: string;
  p6: string;
  password: string[];

  constructor(private el:ElementRef) {
    this.resetPwd();
  }
  ngOnInit() {
  }
  isInputVal(value,n,e) {
    let keycode = window.event ? e.keyCode : e.which;
    if(keycode == 8) {
      if(n == 1) {return false;}
      let newN = 'p' + n;
      this[newN] = '';
      let newElen = '#pswd' + (n-1)
      this.el.nativeElement.querySelector(newElen).focus()
      return false
    }
    let isOk = false;
    if(value === '1' || value === '2' || value === '3' || value === '4' || value === '5' || value === '6' || value === '7' || value === '8' || value === '9' || value === '0') {
      isOk = true;
    }

    return isOk;
  }
  isInputValid(pwds: string, e): boolean {
    let keycode = window.event ? e.keyCode : e.which;
    if(keycode == 8) {
      this.p6 = '';
      this.el.nativeElement.querySelector('#pswd5').focus()
      return true
    }
    this.password = [this.p1, this.p2, this.p3, this.p4, this.p5, this.p6];
    let okFlag = true;
    this.password.map((item) => {
      if (okFlag && (item.trim() === '' || isNaN(Number(item)) || Number(item) < 0 || Number(item) > 9)) {
        okFlag = false;
      }
    });
    if (!okFlag) {
      this.resetPwd();
      this.password = [this.p1, this.p2, this.p3, this.p4, this.p5, this.p6];
    }
    this.passwordChange.emit(this.password.join('').toString());
    return okFlag;
  }

  resetPwd() {
    this.p1 = '';
    this.p2 = '';
    this.p3 = '';
    this.p4 = '';
    this.p5 = '';
    this.p6 = '';
  }

}
