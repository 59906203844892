import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UtilService } from 'src/app/core/services/util.service';
import { NzMessageService } from 'ng-zorro-antd';
import { switchMap, takeUntil } from 'rxjs/operators';
import { FetchService } from 'src/app/core/services/fetch.service';
import { REQUEST } from 'src/app/core/config/linkMap';
import { ContractVertifyService } from './service/contract-vertify.service';
import { Condition } from 'src/app/shared/model/carModels';
import { Validation } from 'src/app/shared/utils/validation';

@Component({
  selector: 'app-contract-vertify',
  templateUrl: './contract-vertify.component.html',
  styleUrls: ['./contract-vertify.component.less']
})
export class ContractVertifyComponent implements OnInit {
  conModelImg = [];
  password: any;
  codeForm: Condition[];
  @ViewChild('afterTem')
  private afterTem: TemplateRef<void>;
  title = '获取验证码';
  time = 60;
  errorMsg: string[];
  randomStr: string;
  imgSrc = [];
  id: any;
  mobile: any;

  constructor(
    private route: ActivatedRoute,
    private util: UtilService,
    private router: Router,
    private msg: NzMessageService,
    private fetchService: FetchService,
    private contractVertifyService: ContractVertifyService
  ) {
    this.route.queryParams.subscribe((params) => {
      console.log(params);
      this.id = params.id;
      this.randomStr = params.uuid;
      this.mobile = params.mobile;
      this.imgSrc = params.img.split(',');
      this.imgSrc.forEach( (item, index) => {
        this.conModelImg.push(REQUEST.DISPLAY_PIC_BASE + item);
      });
    });
  }

  ngOnInit(): void {
    this.codeForm = this.contractVertifyService.getCodeForm(this.afterTem,this.mobile);
  }

  agreeSign() {
    this.errorMsg = Validation(this.codeForm);
    if (this.errorMsg.length > 0) {
      this.errorMsg.forEach(item => this.msg.warning(item));
      return;
    }
    const code = this.codeForm[1].value;
    this.fetchService.outpost(`${REQUEST.SIGN.SIGNOFFLINE}?id=${this.id}&code=${code}&uuid=${this.randomStr}`, {}, (res) => {
      if (res && !res.isError) {
        this.msg.success('签署成功');
        this.router.navigate(['contractSignSuccess']);
      } else {
        this.msg.error(res.message);
      }
    });
  }

  getValidateCode(): void {
    if(this.codeForm[0].value === null){
      this.msg.warning('请输入手机号');
      return;
    }
    if (this.time !== 60) {
      this.msg.warning('验证码已发送,请不要重复点击');
      return;
    }
    
    this.fetchService.post(`${REQUEST.SIGN.VERCODEE}`, { mobile: this.codeForm[0].value, biz: 'sign' }, (res: any) => {
      if (!res.error) {
        this.msg.success('验证码已发送');
        const interval = setInterval(() => {
          this.time--;
          if (this.time === 0) {
            clearInterval(interval);
            this.time = 60;
            this.title = '获取验证码';
          } else {
            this.title = '已发送(' + this.time + ')';
          }
        }, 1000);
      }
    });
  }
}
