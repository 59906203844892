import {Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef} from '@angular/core';
import { OrderInfo } from '../../model/carModels';
import { NzMessageService } from 'ng-zorro-antd';
import { FetchService } from 'src/app/core/services/fetch.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { REQUEST } from 'src/app/core/config/linkMap';

@Component({
  selector: 'app-order-info-process',
  templateUrl: './order-info-process.component.html',
  styleUrls: ['./order-info-process.component.less']
})
export class OrderInfoProcessComponent implements OnInit {

  @Output() refreshOrderList = new EventEmitter<string>();
  @Input() order: any;
  @Output() showOrder: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkOrder: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    public fetch: FetchService,
    public message: NzMessageService,
    private authService: AuthenticationService
  ) { }

  ngOnInit() {
    document.getElementById('detailPart').addEventListener('click', this.hidePanel.bind(this));
  }

  hidePanel () {
    if (event.target['parentElement'] && event.target['parentElement'].parentElement.classList.toString().indexOf('current-step') === -1) {
      this.order.isProcessShow = false;
    }
  }

  singByCompany() {
    // 北斗定位安联同步
    this.fetch.get(REQUEST.PRE_DIST.BEIDOU, {car_order_code: this.order.carOrderCode}, (res) => {
      if (!res.isError) {
        this.refreshOrderList.emit();
        this.message.success('物流公司签收成功！');
      } else {
        this.message.error('物流公司签收失败！');
      }
    });
  }

  showDetail(order: any): void {
    this.showOrder.emit(order);
  }
  checkClick(order) {
    this.checkOrder.emit(order);
  }
}
