import { Injectable } from '@angular/core';
import { freightType } from '../../../core/config/dropdown.conf';
import { FetchService } from 'src/app/core/services/fetch.service';
import { REQUEST } from 'src/app/core/config/linkMap';

@Injectable({
  providedIn: 'root'
})
export class PaymentRecordService {

  constructor(
    private fetchService: FetchService
  ) { }

  get getBaseIfo(): any {
    return [
      { key: 'carOrderCode', value: '运单编号' },
      { key: 'sync', value: '订单类型' },
      { key: 'title', value: '货物名称' },
      { key: 'cargoNum', value: '货物重量' },
      { key: 'unitWight', value: '单位重量' },
      { key: 'cargo', value: '货物实际重量' },
      { key: 'cargoType', value: '货物类型' },
      { key: 'goodsPropertyTypeCode', value: '货物分类' },
      { key: 'consignee', value: '收货人' },
      { key: 'consigneePhoneNum', value: '收货人电话' },
      { key: 'consigner', value: '发货人' },
      { key: 'fdsj', value: '发单时间' },
      { key: 'fdfs', value: '发单方式' },
      { key: 'codename', value: '货物所属行业' },
      { key: 'pickupEndTime', value: '提货最晚时间' },
      { key: 'unloadEndTime', value: '预计卸货时间' },
      { key: 'mobile', value: '司机手机号' },
      { key: 'name', value: '司机姓名' },
      { key: 'plateNum', value: '车牌号码' },
      { key: 'cargoAddress', value: '始发地详细地址' },
      { key: 'deliveryAddress', value: '目的地详细地址' },
      { key: 'dcollection', value: '司机收款' },
      { key: 'cash', value: '剩余费用' },
      { key: 'orderMoney', value: '运费金额' },
      { key: 'prepayment', value: '装车预付费' },
      { key: 'receiptDeposit', value: '回单押金' },
      { key: 'creator', value: '建单人' },
      { key: 'etcMoney', value: 'ETC金额' },
      { key: 'refuelCardMoney', value: '油卡金额' },
      { key: 'sfzjdz', value: '司机身份证件地址' },
      { key: 'xbdm', value: '司机性别' },
      { key: 'mzdm', value: '司机民族' },
      { key: 'fzjg', value: '司机身份证发证机关' },
      { key: 'csrq', value: '司机出生日期' },
    ];
  }

  get getLicenseInfo(): any {
    return [
      { key: 'receiptPic', value: '回单照片(入库单)' },
      { key: 'unloadingPic', value: '卸货照片' },
      { key: 'pickupPic', value: '提货照片' }
    ];
  }

  get goodsType(): any {
    return freightType;
  }

  get getImportOptionList(): any {
    return [
      { id: '200301001', name: '付款记录批量搜索-模板.xlsx', operateType: '批量搜索', uploadurl: REQUEST.CLOSE_AN_ACCOUNT.BATCHSEARCH, downloadurl: REQUEST.CLOSE_AN_ACCOUNT.DOWNLOAD + '付款记录批量搜索-模板.xlsx' }
    ]
  }

  /**
   * 导入查询条件
   * @param id
   */
  eleImport(url, body) {
    return new Promise<any[]>((resolve, reject) => {
      this.fetchService.post(url, body, (res: any) => {
        resolve(res);
      });
    });
  }

  /**
   * 批量查询
   * @param id
   */
  eleImportSearch(path) {
    return new Promise<any[]>((resolve, reject) => {
      this.fetchService.get(REQUEST.ORDER.ALL, path, (res: any) => {
        resolve(res);
      });
    });
  }
}
