import { Component, OnInit, Input } from '@angular/core';
import { QuicklyLink } from '../../model/carModels';
import { BreadService} from '../../../core/services/bread.service';

@Component({
  selector: 'app-quickly-link',
  templateUrl: './quickly-link.component.html',
  styleUrls: ['./quickly-link.component.less']
})
export class QuicklyLinkComponent implements OnInit {

  @Input() source: QuicklyLink;
  constructor(private breadService: BreadService) { }

  ngOnInit() {
  }

  quicklyLinkClick(routerSource): void {
    //this.breadService.clearNavList();
    const routerList = routerSource.split('/').filter((item) => {
      return item;
    });
    //this.breadService.addNavItem(routerList[routerList.length - 1]);
  }
}
