import { Injectable } from '@angular/core';
import {FetchService} from '../../../core/services/fetch.service';
import {Condition, Partner, addressParams} from '../../../shared/model/carModels';
import {REQUEST} from '../../../core/config/linkMap';
import { RendererService } from '../../../core/services/renderer.service';
import { NzMessageService } from 'ng-zorro-antd';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  constructor(
    private fetchService: FetchService,
    private renderer: RendererService,
    private message: NzMessageService,
  ) { }

  getPartnerForm(model: Partner, area, showTaxOffice: () => void): Condition[] {
    return [
      {
        type: 1,
        label: '联系人',
        value: model.connector,
        key: 'connector',
        required: true,
        placeholder: '请填写联系人'
      },
      {
        type: 1,
        label: '联系电话',
        value: model.mobile,
        key: 'mobile',
        isHide: false,
        required: true,
        placeholder: '请输入联系电话',
        rules: ['isPhone']
      },
      {
        type: 1,
        label: '单位名称',
        value: model.name,
        key: 'name',
        required: true,
        placeholder: '请输入单位名称'
      },
      {
        type: 1,
        label: '纳税人识别号',
        value: model.taxNum,
        key: 'taxNum',
        required: true,
        placeholder: '请输入纳税人识别号',
        rules: ['isTaxCode']
      },
      {
        type: 1,
        label: '注册资本',
        value: !!model._id ? model.zczb.slice(0,model.zczb.lastIndexOf('万')) : null,
        key: 'zczb',
        required: true,
        rules: ['isNumber'],
        unit: {
          key: 'unit', 
          value: '万元',
          unitList: [
            {name: '万元', value: '万元'}
          ]
        }
      },
      {
        type: 14,
        label: '成立日期',
        value: model.slrq,
        key: 'slrq',
        required: true,
        rules: ['isDate'],
        placeholder: '请选择成立日期'
      },
      {
        type: 1,
        label: '主管税务机关',
        value: model.zgswjgname,
        key: 'zgswjgname',
        required: true,
        placeholder: '请输入主管税务机关',
        click: showTaxOffice
      },
      {
        type: 1,
        label: '主管税务机关',
        value: model.zgswjg,
        key: 'zgswjg',
        required: true,
        isHide: true,
      },
      {
        type: 2,
        label: '企业类型',
        value: model.nsrlx,
        key: 'nsrlx',
        required: true,
        option: [
          {value: '0', name: '一般'},
          {value: '1', name: '小规模'},
        ]
      },
      {
        type: 1,
        label: '公司地址',
        value: model.gsdz,
        key: 'gsdz',
        required: true,
        placeholder: '请输入公司地址'
      },
      {
        type: 1,
        label: '详细地址',
        value: model.xxdz,
        key: 'xxdz',
        required: true,
        placeholder: '请输入详细地址'
      },
      {
        type: 10,
        label: '所属地区',
        // value: model.ssdq,
        value: !!model._id ? this.renderer.mergeAddress(model, 'ssdq') : null,
        key: 'ssdq',
        option: area,
        required: true,
        placeholder: '请选择地区',
      },
      {
        type: 15,
        label: '经营范围',
        value: model.jyfw,
        key: 'jyfw',
        required: true,
        placeholder: '请输入经营范围',
        maxlen: 300
      },
    ];
  }

  getLicenseFormJson(model: Partner): Condition[] {
    return [
      {
        type: 16,
        imgTit: '营业执照照片',
        imgPath:{
          displayPath: !!model.yyzzfj ? REQUEST.DISPLAY_PIC_BASE + model.yyzzfj : null,
          path: !!model._id ? model.yyzzfj : null
        },
        key: 'yyzzfj',
        required: true,
      }
    ];
  }

  splitAddress(body: Partner): Partner {
    for (let key in body){
      if(key === 'zczb') {
        body.zczb = body.zczb + body.unit;
        delete body.unit;
      }
      if(key === 'ssdq'){
        const address = body[key];
        if (address.length === 1) {
          body.ProvinceCode = address[0].split('+')[0];
          body.Province = address[0].split('+')[1];
        }
        if (address.length === 2) {
          body.ProvinceCode = address[0].split('+')[0];
          body.Province = address[0].split('+')[1];
          body.CityCode = address[1].split('+')[0];
          body.City = address[1].split('+')[1];
        }
        if (address.length === 3) {
          body.ProvinceCode = address[0].split('+')[0];
          body.Province = address[0].split('+')[1];
          body.CityCode = address[1].split('+')[0];
          body.City = address[1].split('+')[1];
          body.CountyCode = address[2].split('+')[0];
          body.County = address[2].split('+')[1];
        }
        body.ssdq = body.CityCode;
        delete body.ProvinceCode;
        delete body.Province;
        delete body.CityCode;
        delete body.City;
        delete body.CountyCode;
        delete body.County;
        delete body.ssdqaddress;
      }
    }
    return body;
  }

  get addressUrlParams(): addressParams{
    return {
      url:REQUEST.PRE_DIST.TJADDRESS
    }
  }
}
