import { Injectable } from '@angular/core';
import { Condition } from 'src/app/shared/model/carModels';
import { RendererService } from 'src/app/core/services/renderer.service';
import { UtilService } from 'src/app/core/services/util.service';
import { CreditService } from '../../service/credit-service.service';
import { REQUEST } from 'src/app/core/config/linkMap';

@Injectable({
  providedIn: 'root'
})
export class CreditInfoInquiryService {

  constructor(
      private renderer: RendererService,
      private creditService: CreditService,
      private utils:UtilService
  ) { }

  get initBaseSearch(): {vno, businessLicense, province, isAgree} {
    return {
      vno: null,
      businessLicense: null,
      province:[],
      isAgree:false
    };
  }

  get initBaseComSearch(): {companyName, businessLicense, province, isAgree} {
    return {
      companyName: null,
      businessLicense: null,
      province:[],
      isAgree:false
    };
  }

  get initBaseDriverSearch(): {driverName, licenseType, LicenseNum, province, isAgree} {
    return {
      driverName: null,
      licenseType: null,
      LicenseNum:null,
      province:[],
      isAgree:false
    };
  }

  getCarForm(model: any): Condition[] {
    return [
      {
        type: 1,
        label: '车牌号码：',
        value: model.vehicleNumber,
        key: 'vehicleNumber',
        required: true,
        placeholder: '请填写车牌号码',
      },
      {
        type: 1,
        label: '道路运输证号：',
        value: model.roadTransportCertificateNumber,
        key: 'roadTransportCertificateNumber',
        placeholder: '请输入道路运输证号'
      },
      {
        type: 2,
        label: '车牌类型：',
        value: model.licensePlateTypeCode,
        key: 'licensePlateTypeCode',
        required: true,
        placeholder: '请选择车牌颜色',
        option: [
          {value: 1, name: '蓝色'},
          {value: 2, name: '黄色'},
        ]
      },
      {
        type: 3,
        value: model.isAgree,
        key: 'isAgree',
        required: true,
        checkText:'已阅读并接受',
        urlText:'信用数据服务使用说明',
        click: () => this.utils.explain(this.creditService.explainText)
      },
    ];
  }

  getCompanyForm(model: any,area: any[]): Condition[] {
    return [
      {
        type: 1,
        label: '公司名称：',
        value: model.enterpriseName,
        key: 'enterpriseName',
        required: true,
        placeholder: '请填写公司名称',
      },
      {
        type: 1,
        label: '经营许可证：',
        value: model.permitNumber,
        key: 'permitNumber',
        placeholder: '请输入经营许可证'
      },
      {
        type: 2,
        label: '省份名称：',
        value: model.provinceCode,
        key: 'provinceCode',
        option: area,
        required: true,
        placeholder: '请选择省份'
      },
      {
        type: 3,
        value: model.isAgree,
        key: 'isAgree',
        required: true,
        checkText:'已阅读并接受',
        urlText:'信用数据服务使用说明',
        click: () => this.utils.explain(this.creditService.explainText)
      },
    ];
  }

  getDriverForm(model: any,area: any[]): Condition[] {
    return [
      {
        type: 1,
        label: '姓名',
        value: model.nameOfPerson,
        key: 'nameOfPerson',
        required: true,
        placeholder: '请填写姓名',
      },
      {
        type: 2,
        label: '证件类型：',
        value: model.licenseType,
        key: 'licenseType',
        required: true,
        placeholder: '请选择证件类型',
        option: [
          {value: 1, name: '从业资格证'},
          {value: 2, name: '身份证'},
        ]
      },
      {
        type: 1,
        label: '证件号码：',
        value: model.LicenseNum,
        key: 'LicenseNum',
        required: true,
        placeholder: '请输入证件号码'
      },
      {
        type: 2,
        label: '发证省份：',
        value: model.provinceCode,
        key: 'provinceCode',
        option: area,
        required: true,
        placeholder: '请选择省份'
      },
      {
        type: 3,
        value: model.isAgree,
        key: 'isAgree',
        required: true,
        checkText:'已阅读并接受',
        urlText:'信用数据服务使用说明',
        click: () => this.utils.explain(this.creditService.explainText)
      },
    ];
  }

  getUrl(type){
    switch(type){
      case 'company':
        return REQUEST.CREDIT.COMSEARCH;
      case 'car':
        return REQUEST.CREDIT.CARSEARCH;
      case 'driver':
        return REQUEST.CREDIT.DRIVERSEAECH;
    }
  }
}
