import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../core/auth.guard';
import { NgxPermissionsGuard } from 'ngx-permissions';

import { BasePageComponent } from '../layout/base-page/base-page.component';
import { UserInformationComponent } from 'src/app/pages/user-information/user-information.component';
import { CarManagementComponent } from 'src/app/pages/car-management/car-management.component';
import { CarInfoEditComponent } from 'src/app/pages/car-info-edit/car-info-edit.component';
import { DriverInfoEditComponent } from 'src/app/pages/driver-info-edit/driver-info-edit.component';
import { CarHangupComponent } from 'src/app/pages/car-hangup/car-hangup.component';
import { DriverManagementComponent } from 'src/app/pages/driver-management/driver-management.component';
import { UnauditListComponent } from 'src/app/pages/unaudit-list/unaudit-list.component';
import { DriverInfoCompleteComponent } from 'src/app/pages/driver-info-complete/driver-info-complete.component';
import { PreDistManagementComponent } from 'src/app/pages/pre-dist-management/pre-dist-management.component';
import { PreDistCreateComponent } from 'src/app/pages/pre-dist-create/pre-dist-create.component';
import { DistManagementComponent } from 'src/app/pages/dist-management/dist-management.component';
import { OilcardRecordComponent } from 'src/app/pages/oilcard-record/oilcard-record.component';
import { EtcRecordComponent } from 'src/app/pages/etc-record/etc-record.component';
import { PositionRecordComponent } from 'src/app/pages/position-management/position-record.component';
import { BillingRecordComponent } from 'src/app/pages/billing-management/billing-record.component';
import { BillDetailComponent } from 'src/app/pages/bill-detail/bill-detail.component';
import { TradeDetailComponent } from 'src/app/pages/trade-detail/trade-detail.component';
import { SysSettingComponent } from 'src/app/pages/sys-setting/sys-setting.component';
import { PaymentRecordComponent } from 'src/app/pages/payment-record/payment-record.component';
import {CarBindComponent} from './car-bind/car-bind.component';
import {UserManagementComponent} from './user-management/user-management.component';
import {RoleManagementComponent} from './role-management/role-management.component';
import {Exception500Component} from './exception/500.component';
import {Exception403Component} from './exception/403.component';
import {Exception404Component} from './exception/404.component';
import {LoginComponent} from './login/login.component';
import {InsuranceComponent} from './insurance/insurance.component';
import {PartnerComponent} from './partner/partner.component';
import { LogManagementComponent } from './log-management/log-management.component';
import { CreditServiceComponent } from './credit-service/credit-service.component';
import { PrivacyAgreementComponent } from './privacy-agreement/privacy-agreement.component';
import { UserAgreementComponent } from './user-agreement/user-agreement.component';
import { ContractVertifyComponent } from './contract-vertify/contract-vertify.component';
import { ContractSignSuccessComponent } from './contract-sign-success/contract-sign-success.component';
import { OrderInsuranceComponent } from './order-insurance/order-insurance.component';
import { MingshengSignSuccessComponent } from './mingsheng-sign-success/mingsheng-sign-success.component';

const routes: Routes = [
  {
    path: '',
    component: BasePageComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: '', redirectTo: 'userInformation', pathMatch: 'full' },
      { path: 'userInformation', component: UserInformationComponent },
      // 车辆管理
      { path: 'carManagement/carList', component: CarManagementComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['200602001'],
            redirectTo: '/403'
          }
        }
      },
      { path: 'carManagement/carList/carBind/:id', component: CarBindComponent },
      { path: 'carManagement/carInfoEdit/:id', component: CarInfoEditComponent },
      { path: 'carManagement/carHangup', component: CarHangupComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['200601001'],
            redirectTo: '/403'
          }
        }
      },
      { path: 'carManagement/driverInfoComplete', component: DriverInfoCompleteComponent },
      // 司机管理
      { path: 'driverManagement/auditList', component: DriverManagementComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['200701001'],
            redirectTo: '/403'
          }
        }
      },
      { path: 'driverManagement/auditList/driverInfoEdit/:id', component: DriverInfoEditComponent },
      { path: 'driverManagement/unAuditList', component: UnauditListComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['200702001'],
            redirectTo: '/403'
          }
        }
      },
      { path: 'driverManagement/unAuditList/driverInfoEdit/:id', component: DriverInfoEditComponent },
      { path: 'driverManagement/unAuditList/driverInfoComplete/:id', component: DriverInfoCompleteComponent },
      // 订单管理
      { path: 'orderRecord/preDistManagement', component: PreDistManagementComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['200101002'],
            redirectTo: '/403'
          }
        }
      },
      { path: 'orderRecord/preDistManagement/create', component: PreDistCreateComponent },
      { path: 'orderRecord/preDistManagement/create/:id', component: PreDistCreateComponent },
      { path: 'orderRecord/distManagement', component: DistManagementComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['200102002'],
            redirectTo: '/403'
          }
        }
      },
      { path: 'valueAddService/oilcardRecord', component: OilcardRecordComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['200201001'],
            redirectTo: '/403'
          }
        }
      },
      { path: 'valueAddService/etcRecord', component: EtcRecordComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['200202001'],
            redirectTo: '/403'
          }
        }
      },
      // { path: 'valueAddService/positionRecord', component: PositionRecordComponent,
      //   data: {
      //     permissions: {
      //       only: ['200203001'],
      //       redirectTo: '/403'
      //     }
      //   }
      // },
      { path: 'billingRecord', component: BillingRecordComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['200400001'],
            redirectTo: '/403'
          }
        }
      },
      { path: 'billingRecord/detail/:id', component: BillDetailComponent },
      { path: 'balanceRecord/tradeDetail', component: TradeDetailComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['200302001'],
            redirectTo: '/403'
          }
        }
      },
      { path: 'balanceRecord/paymentRecord', component: PaymentRecordComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['200301001'],
            redirectTo: '/403'
          }
        }
      },
      { path: 'userSetting/:id', component: SysSettingComponent },
      /*---------------------------------------安全管理---------------------------------------*/
      { path: 'safety/userManagement', component: UserManagementComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['200801001'],
            redirectTo: '/403'
          }
        }
      },
      { path: 'safety/roleManagement', component: RoleManagementComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['200802001'],
            redirectTo: '/403'
          }
        }
      },
      { path: 'insurance', component: OrderInsuranceComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['200900001'],
            redirectTo: '/403'
          }
        }
      },
      // //运单保险
      // { path: 'orderInsurance', component: OrderInsuranceComponent,
      //   canActivate: [NgxPermissionsGuard],
      //   data: {
      //     permissions: {
      //       only: ['200900001'],//TODO
      //       redirectTo: '/403'
      //     }
      //   }
      // },
      // 合作伙伴
      { path: 'partner', component: PartnerComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['201000001'],
            redirectTo: '/403'
          }
        }
      },
      /*---------------------------------------日志管理---------------------------------------*/
      { path: 'logManagement', component: LogManagementComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['201100001'],
            redirectTo: '/403'
          }
        }
      },
       /*---------------------------------------信用服务---------------------------------------*/
      { path: 'creditService', component: CreditServiceComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['201200001'],
            redirectTo: '/403'
          }
        }
     },
      /*----------------------------------------异常页面--------------------------------------*/
      { path: '403', component: Exception403Component },
      { path: '404', component: Exception404Component },
      { path: '500', component: Exception500Component }
    ]
  },
  { path: 'login',
    component: LoginComponent,
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['GUEST'],
        redirectTo: '/'
      }
    },
  },
  { path: 'privacyAgreement',
    component: PrivacyAgreementComponent,
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['GUEST'],
        redirectTo: '/'
      }
    },
  },
  { path: 'userAgreement',
    component: UserAgreementComponent,
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['GUEST'],
        redirectTo: '/'
      }
    },
  },
   { path: 'signContract',
    component: ContractVertifyComponent,
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['GUEST'],
        redirectTo: '/'
      }
    },
  },
   { path: 'contractSignSuccess',
    component: ContractSignSuccessComponent,
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['GUEST'],
        redirectTo: '/'
      }
    },
  },
  { path: 'mingshengSignResult',
  component: MingshengSignSuccessComponent,
  canActivate: [NgxPermissionsGuard],
  data: {
    permissions: {
      only: ['GUEST'],
      redirectTo: '/'
    }
  },
},
  {path: '**', redirectTo: '404'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})

export class PagesRoutingModule {}
