import { AuthConfig } from 'angular-oauth2-oidc';
import {BASE} from './linkMap';

export const authConfig: AuthConfig = {
  /**
   * The client's id as registered with the auth server
   */
  clientId: 'lee',

  /**
   * Some auth servers don't allow using password flow
   * w/o a client secreat while the standards do not
   * demand for it. In this case, you can set a password
   * here. As this passwort is exposed to the public
   * it does not bring additional security and is therefore
   * as good as using no password.
   */
  dummyClientSecret: 'lee_secret',

  /**
   * If true, the lib will try to check whether the user
   * is still logged in on a regular basis as described
   * in http://openid.net/specs/openid-connect-session-1_0.html#ChangeNotification
   */
  sessionChecksEnabled: false,

  /**
   * Defines whether to use OpenId Connect during
   * implicit flow.
   */
  oidc: false,

  /**
   * The client's redirectUri as registered with the auth server
   */
  redirectUri: null,

  /**
   * The logout url.
   */
  logoutUrl: null,

  /**
   * Url of the token endpoint as defined by OpenId Connect and OAuth 2.
   */
  tokenEndpoint: `${BASE}/uaa/oauth/token`,

  /**
   * Url of the userinfo endpoint as defined by OpenId Connect.
   *
   */
  userinfoEndpoint: `${BASE}/uaa/oauth/profile`,

  showDebugInformation: false,

  useHttpBasicAuthForPasswordFlow: true,
  requireHttps: false
};
