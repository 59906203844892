import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Condition } from '../../model/carModels';
import { getCond } from '../../utils/adapter';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-condition',
  templateUrl: './condition.component.html',
  styleUrls: ['./condition.component.less']
})
export class ConditionComponent implements OnInit, OnChanges {

  @Input() errorMsg: string[];
  @Input() condition: Condition[];
  @Output() showModelList: EventEmitter<any> = new EventEmitter();

  constructor(
    private permService: NgxPermissionsService
  ) {
  }

  ngOnInit() {
    // debugger;
    /* this.condition.map((cond) => {
      if (cond.type === 1 && cond.unit && cond.value && typeof cond.value === 'string') {
        cond[cond.unit.key] = cond.value.split('_unit')[1];
      }
    }); */
  }

  showModel(key: string){
    this.showModelList.emit(key);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // 处理表单权限控制问题
    if (changes.condition) {
      if (!!changes.condition.currentValue && changes.condition.currentValue.length > 0) {
        const permissions = Object.keys(this.permService.getPermissions());
        this.condition.map((cond: Condition) => {
          cond.isHide = !!cond.isHide;
          if (cond.isHide === false && cond.permissions && permissions.indexOf(cond.permissions) === -1) {
            cond.isHide = true;
          }
        });
      }
    }
  }

  getAuthCode (cond: Condition, env) {
    let timer = cond.timer;
    cond.disabled = true;
    cond.placeholder = timer + 's后重新发送';
    const interval = setInterval(() => {
      cond.placeholder = --timer + 's后重新发送';
      if (timer < 0) {
        clearInterval(interval);
        cond.placeholder = '';
        cond.disabled = false;
      }
    }, 1000);
    /* 发送手机验证码请求 */
  }

  // 司机信息编辑时候针对身份证开始日期/结束日期，有效期开始日期/结束日期做的更改
  datePickerModelChange(date: Date, key) {
    let selectDate = date['Format']('yyyy-MM-dd').split('-');
    if(selectDate && selectDate.length) {
      if(key === 'identityStartDate') {
        selectDate[0] = selectDate[0]/1 + 20;
        const endDate = selectDate.join('-');
        const identityEndDate = getCond(this.condition, 'identityEndDate');
        if (identityEndDate) {
          identityEndDate.value = endDate;
        }
      }
      if(key === 'licenseStartDate') {
        selectDate[0] = selectDate[0]/1 + 10;
        const endDate = selectDate.join('-');
        const licenseEndDate = getCond(this.condition, 'licenseEndDate');
        if (licenseEndDate) {
          licenseEndDate.value = endDate;
        }
      }
    }
  }

  /*//  车辆资料完善:车辆类型值变化的时候
  selectChange(cond) {
    if (cond.label === '车辆类型：' && cond.value.indexOf('牵引车') > -1 && cond.isRelated) {
      this.condition.push({type: 15, label: '', value: '', key: '', placeholder: '补充挂车信息'});
    }
  }

  // 选择牵引车之后的弹框事件
  openModal() {
    this.openBindModal.emit('openBindModal');
  }*/

  handleTip = (val: string, len: number) => {
    if ( val === undefined ) return; 
    if ( val.length >= len ) {
      return true;
    } else {
      return false;
    }
  }

  onChanges(values: any): void {
    console.log(values);
  }
}
