import { Component, OnInit, Output, Input, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { FetchService } from '../../core/services/fetch.service';
import { REQUEST } from '../../core/config/linkMap';
import { NzMessageService, NzModalService} from 'ng-zorro-antd';
import { Condition, CarBaseModel, Dic } from '../../shared/model/carModels';
import { CHOOSE_CAR  } from '../../core/config/pageConfig';
import { getCond} from '../../shared/utils/adapter';
import { UtilService } from 'src/app/core/services/util.service';
import { CarChooseModalService } from './service/car-choose-modal.service';


@Component({
  selector: 'app-car-choose-modal',
  templateUrl: './car-choose-modal.component.html',
  styleUrls: ['./car-choose-modal.component.less']
})
export class CarChooseModalComponent implements OnInit {

  @Input() carFiltration: string;
  @Input() orderId: number;
  @Output() refreshCarList = new EventEmitter();
  pageIndex = 1;
  pageSize = 5;
  total = 1;
  loading = false;

  allChecked = false;
  indeterminate = false;

  params = {};
  errorMsg: string[];
  condition: Condition[];
  dataSource = [];
  selectRecords = [];
  private superSearchParams: any;

   //车辆详情
   @ViewChild('showDetail')
   private showDetail: TemplateRef<any>;
   carInfo: any;
   baseInfoList: Array<{key: string, value: string}>;
   licenseInfoList: Array<{key: string, value: string}>;
   energyTypeDic: Dic[] = [];
  //  goodsTypeOptions: Dic[] = [];

  constructor(
    private fetchService: FetchService, 
    private message: NzMessageService, 
    private modalService: NzModalService,
    private util: UtilService,
    private carChooseModalService:CarChooseModalService,
    ) {
    this.condition = JSON.parse(JSON.stringify(CHOOSE_CAR));
    this.baseInfoList = this.carChooseModalService.getBaseIfo;
    this.licenseInfoList = this.carChooseModalService.getLicenseInfo;
  }

  ngOnInit() {
    this.searchData();
  }

  searchData(reset: boolean = false): void {
    if (reset) {
      this.pageIndex = 1;
    }
    this.searchNow(this.superSearchParams);
  }

  query() {
    this.params = {};
    const carIdentity = getCond(this.condition, 'car_identity');
    // const carLen = getCond(this.condition, 'car_len');
    // const cargoType = getCond(this.condition, 'cargo_type');
    const currentPlace = getCond(this.condition, 'location');
    const sign = getCond(this.condition, 'sign');
    if (carIdentity.value) {
      this.params['plateNum'] = carIdentity.value;
    }
    /* if (carLen.value) {
      this.params['carLen'] = carLen.value;
    }
    if (cargoType.value) {
      this.params['cargoType'] = cargoType.value;
    } */
    if (currentPlace.value) {
      this.params['location'] = currentPlace.value;
    }
    if (sign.value) {
      this.params['sign'] = sign.value;
    }
    this.superSearchParams = this.params;
    this.pageIndex = 1; // 每次查询都要重置pageIndex
    this.searchNow();
  }

  searchNow(url = REQUEST.PRE_DIST.SELECT_CAR) {
    this.loading = true;
    this.dataSource = [];
    this.total = 0;
    if (this.carFiltration && this.carFiltration.length) {
      this.params['carFiltration'] = this.carFiltration.toString();
    }
    this.params['page'] = this.pageIndex;
    this.params['per_page'] = this.pageSize;
    this.fetchService.get(url, this.params, (res) => {
      this.loading = false;
      if (res._embedded) {
        this.dataSource = res._embedded.carResourceList || [];
      }
      if (res.page) {
        this.total = res.page.totalElements;
      }
    });
  }

  currentPageDataChange($event: Array<{ name: string; age: number; address: string; checked: boolean; disabled: boolean; }>): void {
    this.dataSource = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    const allChecked = this.dataSource.every(value => value.checked === true);
    const allUnChecked = this.dataSource.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
    /* 选择数据同步 */
    this.selectRecords = this.dataSource.filter(value => value.checked);
  }

  checkAll(value: boolean): void {
    this.dataSource.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  clear() {
    this.params = {};
    this.superSearchParams = {};
    this.condition = JSON.parse(JSON.stringify(CHOOSE_CAR));
    this.searchData(true);
  }

  // 联系司机
  contactDriver() {
    const carIds = [];
    this.selectRecords.forEach((record) => {
      if (record.carId) {
        carIds.push(Number(record.carId));
      }
    });
    if (!carIds.length) {
      this.message.warning('请至少选择一个司机！');
      return;
    }
    const tempParams = {};
    tempParams['carIds'] = carIds;
    tempParams['orderId'] = this.orderId;
    tempParams['state'] = 2;
    this.fetchService.post(REQUEST.PRE_DIST.CONTACT_DRIVER, tempParams, (res) => {
      if (!res.isError) {
        this.message.create('success', '联系司机完成！');
        this.refreshCarList.emit();
      }
    });
  }

    /**
   * 查看详情
   */
  checkDetail(car: any): void {
    const carId = car.carId;
    this.fetchService.get(REQUEST.CAR.EDIT+carId, {}, (res: any) => {
      if (!res || !res.isError) {
        this.carInfo = res;
        this.openCarDetail();
      }else{
        this.message.create('error', '车辆详情获取失败，请稍后重试');
      }
    });
    this.getDetailSwichData();
  }
 
  /**
   * 获取特殊字段字典
   */
  getDetailSwichData = () => {
    this.energyTypeDic = this.util.switchDic('0017');
  }

  /**
   * 打开详情model
   */
  openCarDetail():void{
    this.modalService.create({
      nzTitle: '车辆详情',
      nzContent: this.showDetail,
      nzMaskClosable: true,
      nzClosable: true,
      nzWidth: 1500,
      nzFooter: null
    });
  }

    /**
   * 特殊信息渲染
   * @param key
   * @returns {string | any}
   */
  rendererInfo = (key: string): string => {
    if (key === 'affiFlag') {
      return this.carInfo[key] === 1 ? '是' : '否';
    }
    if (key === 'vehicleEnergyType') {
      let option = this.energyTypeDic.filter(item => item.value === this.carInfo[key]);
      return option.length > 0 ? option[0].name : '';
    }
    if (key === 'registerDate' || key === 'issueDate' || key === 'roadTransportCertificateValidPeriodFrom' || key === 'roadTransportCertificateValidPeriodTo') {
      if(!!this.carInfo[key]){
        const datestr = new Date(this.carInfo[key]);
        return this.util.dateFormat(datestr, 'YYYY-MM-DD');
      }
    }
    if(key === 'capacityTonnage'){
      return this.carInfo[key] + '(公斤/千克)';
    }
    if(key === 'length' || key === 'vehicleWidth'){
      return this.carInfo[key] + '米';
    }
    return this.carInfo[key];
  }
}
