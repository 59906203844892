/*    搜索条件的接口模型   */
import { BaseTableRow } from '../components/base-table/interface';
import { TemplateRef } from '@angular/core';

export class Base {
  name: string;
  value: string | number;
  placeholder?: string;
  disabled?: boolean;
}

export class Option {
  isDouble?: boolean;
  optionList: any;
}

export class UnitBase {
  key: string;
  value: string;
  disabled?: boolean;
  unitList: Base[];
}

/*
  type: 1  输入框
  type: 2  下拉框
  type: 3  复选框
  type: 4  时间范围
  type: 5  一组相关的输入框（始发地 && 目的地）
  type: 6 区域选择联动组件
*/
export class Condition {
  type: number;
  label?: string;
  subLabel?: string;
  value?: any;
  key: string;
  required?: boolean;
  unit?: UnitBase;
  placeholder?: string;
  rules?: string[];
  isHide?: boolean;
  dValue?: string;
  option?: any;
  timer?: number;
  btnText?: string;
  disabled?: boolean;
  isSearch?: boolean;
  url?: string;
  mode?: 'multiple' | 'tags' | 'default'; // 下拉选择方式
  textType?: string; // 文本类型（password）
  readOnly?: boolean; // 只读
  permissions?: string; // 权限控制
  imgTit?: string;
  imgPath?: picPath;
  code?: string;
  maxlen?: number;
  max?: number;
  min?: number;
  precision?: number;
  width?:string;
  checkText?:string;
  urlText?:string;
  after?: TemplateRef<void>;
  click?: () => void = () => {}; // 单击事件
  change?: (val: string) => void = () => {};
  focus?: () => void = () => {}; // 获取焦点事件
}
export class picPath {
  displayPath: string;
  path: string;
}
export class addressParams {
  url: string;
  queryParams?: object;
  columnDefs?: Array<any>;
  listType?: string;
  [key: string]: any
}
/*    菜单导航模型   */
export class Menu {
  id: string;
  name: string;
  path?: string;
  /*控制子菜单的显示与否*/
  isShow?: boolean | Promise<boolean>;
  icon?: string;
  hasSub?: boolean;
  selected?: boolean;
  expand?: boolean;
  subMenus?: Menu[];
  permissions?: string | string[];
}

/*   汽车管理表格模型   */
export interface CarMangementTableRecord {
  carId: number;
  plateNum: string;
  logisticsCompany: string;
  carType: string;
  location: string;
  driverMobile: string;
  normalRoad: string;
  registryDate: string;
}

/*   ETC表格模型   */
export interface EtcTableRecord {
  id: number;
  etcCardNum: string;
  driverMobile: string;
  plateNum: string;
  driverName: string;
  etcBalance: number;
  preBalance: number;
}

/*   油卡记录表格模型   */
export class OilCardRecord {
  id: number;
  cardId: number;
  cardNum: string;
  oilCardNum: string;
  status: string;
  phoneNum: string;
  carNum: string;
  driverName: string;
  oilCardBalance: number;
  preBalance: number;
  offer: any;
  money: number;
  oilType: string;
  ThirdType: string;
}

/*   ETC表格模型   */
export interface EtcCardRecord {
  id: number;
  time: string;
  category: string;
  amount: string;
}

/*   ETC预充功能模型   */
export interface PreCharge {
  cardNum: string;
  amount: string;
  time: string;
  password: string;
}

/*   用户信息账户余额、充值金额、消费金额 模型    */
export interface AmountPanel {
  id: string;
  name: string;
  amount: string;
  key: string;
  fontIcon: string;
  backgroundImg: string;
}

/*   发布订单、增加车辆等配置    */
export interface QuicklyLink {
  id: string;
  name: string;
  action: string;
  fontIcon: string;
  routePath: string;
}

/*   新建预派车单表格数据结构    */
export interface PreTableRecord {
  id: string;
  carNum: string;
  carStatus: string;
}

/* 选择车辆弹框表格数据模型 */
export interface CarBaseModel {
  carId?: string;
  plateNum?: string;
  identityPicFront?: string;
  isOnNet?: string;
  isBdOnline?: string;
  isGps?: string;
  operationCertificate?: string;
  operationCertPic?: string;
  carType?: string;
  volume?: string;
  length?: string;
  ownerMobile?: string;
  ownerId?: string;
  mainDriverId?: string;
  deputyDriverId?: string;
  capacityTonnage?: string;
  companyId?: string;
  normalRoad?: string;
  verifyState?: string;
  etcId?: string;
  tonnage?: string;
  plateColor?: string;
  compulsoryInsurance?: string;
  frontPic?: string;
  vehicleIdentityCode?: string;
  registryDate?: string;
  modifyDate?: string;
  vehicleDirveLicenseHomepage?: string;
  vehicleDriveLicenseSecondPositivePage?: string;
  vehicleDriveLicenseBackPage?: string;
  vehicleWidth?: string;
  vehicleAffiliation?: string;
  owner?: string;
  affiFlag?: string;
  driverName?: string;
  currentPlace?: string;
  logisticsCompany?: string;
  location?: string;
  driverMobile?: string;
  currentPlaceTimeString?: string;
  companyPhone?: string;
}

/* 订单管理-预派车单：订单记录详情数据模型 */
export interface OrderInfo {
  id?: string;
  did?: string;
  carId?: string;
  orderMoney?: string;
  orderCode: string;
  title?: string;
  totalWeight?: string;
  cargoAddress?: string;
  pickupCountrySubdivisionCode?: string;
  cargoAddrDetail?: string;
  deliveryAddress?: string;
  unloadCountrySubdivisionCode?: string;
  deliveryAddrDetail?: string;
  consignee?: string;
  consigneePhoneNum?: number;
  consigneeTaxNum?: string;
  totalEtcMoney?: string;
  totalRefuelCardMoney?: string;
  serviceCharge?: string;
  totalCharge?: string;
  orderState?: number;
  mark?: string;
  companyId?: string;
  unit?: string;
  goodsPropertyTypeCode?: string;
  cargoType?: string;
  prepayment?: string;
  applicant?: string;
  drawee?: string;
  cargoNum?: string;
  reminder?: number;
  pickupStartTime?: string;
  pickupEndTime?: string;
  unloadStartTime?: string;
  unloadEndTime?: string;
  consignerId?: string;
  isProcessShow?: boolean;
  unitWight?: number;
  [key: string]: any;
}

/* 订单管理-预派车单：订单记录详情数据模型 */
export interface OrderInfo1 {
  orderId: string;
  status: string;
  from: string;
  to: string;
  time: string;
  receiptDeposit: string;
  preOilCard: string;
  preETC: string;
  goodsName: string;
  goodsNum: string;
  prePayment: string;
  reminder?: number;
  total: string;
}

// 派车单编辑数据模型
export class orderFill {
  cargo: number;
  cash: number;
  receiptDeposit: number;
  prepayment: number;
  refuelCardMoney: number;
  etcMoney: number;
  dcollection: string;
  fdsj: string;
  fdfs: string;
  hwsshy: string;
  serviceCharge: string;
  zk: string;
  sync: string;
}

/*   汽车管理表格模型   */
export interface PosTableRecord {
  id: number;
  carNum: string;
  phoneNum: string;
  driverName: string;
  parkTime: string;
  location: string;
  createTime: string;
}

/*   车辆挂靠申请   */
export interface CarHangupRecord {
  id: number;
  driverName: string;
  driverMobile: string;
  mainDriverId: string;
}

/*   交易明细   */
export interface TradeDetailRecord {
  payId: string;
  id: string;
  type: string;
  amount: string;
  status: string;
  account: string;
  time: string;
}

/*   开票管理   */
export interface BillingRecord {
  id: string;
  code: string;
  customer: string;
  amount: string;
  status: string;
  applyTime: string;
  billingTime: string;
  realPay: string;
  remark: string;
}

/*   受票方信息   */
export interface CustomerInfo {
  customer: string;
  taxId: string;
  address: string;
  contactPhone: string;
  depositBank: string;
  account: string;
  serviceName: string;
  specification: string;
  workPlace: string;
  quantity: string;
  unitPrice: string;
  remark: string;
}

/*   车辆运输记录   */
export interface BillDetailTable {
  seq: number;
  waybillNum: string;
  carInfo: string;
  routeInfo: string;
  name: string;
  quantity: string;
  transportTime: string;
  transportFreight: string;
  deliverFreight: string;
  settleAmt: string;
}

/*   车辆运输记录   */
export interface TrackInfo {
  distributeDatetime: string;
  act: string;
  operators: string;
  gpsTime: string;
  address: string;
}

/*   司机管理记录   */
export interface DriverManagementRecord {
  id: string;
  driverName: string;
  rgisterMobile: string;
  driverID: string;
  licenceType: string;
  licenceNum: string;
  carNum: string;
  carType: string;
}

/*消息管理*/
export class MessageType {
  title: string;
  desc: string;
  code: string;
}

export class Message {
  messageId: number;
  receiver: number;
  content: number;
  state: number;
  messageType: MessageType;
  data: object;
  createTime: Date;
  pushTime: Date;
  type: string;
  detail: string;
}

export class LoginBackMessage {
  error: string;
  message: string;
  path: string;
  status: number;
  timestamp: Date;
}

export class User {
  userId: number;
  loginId: string;
  userName: string;
  userStatus = 1;
  userEmail: string;
  userMobile: string;
  userPhone: string;
  memo: string;
  createTime: Date;
  modifiedTime: Date;
  companyId: string;
  isAdmin?: boolean;
}

export class Role {
  roleId: number;
  roleType: number;
  roleName: string;
  roleDesc: string;
  memo: string;
  companyId: string;
}

export class Insurance extends BaseTableRow {
  insuranceId: number;
  dispatchOrderId: number;
  totalInsuredAmount: number;
  vno: string;
  vc: number;
  cargoName: string;
  cargoType: number;
  carriageStartPlaceProvince: string;
  carriageStartPlaceCity: string;
  carriageStartPlaceCounty: string;
  carriageStartPlaceProvinceCode: string;
  carriageStartPlaceCityCode: string;
  carriageStartPlaceCountyCode: string;
  destinationProvince: string;
  destinationCity: string;
  destinationCounty: string;
  destinationProvinceCode: string;
  destinationCityCode: string;
  destinationCountyCode: string;
  destinationTwoProvince: string;
  destinationTwoCity: string;
  destinationTwoCounty: string;
  destinationTwoProvinceCode: string;
  destinationTwoCityCode: string;
  destinationTwoCountyCode: string;
  riskStartDate: Date;
  riskEndDate: Date;
  syncZj: boolean;
  versionCode: number;
  downType: number;
  type: number;
}

export class Partner extends BaseTableRow {
  _id: string;
  fbfuuid: string;
  connector: string;
  mobile: string;
  name: string;
  taxNum: string;
  companyId: string;
  Province: string;
  City: string;
  County: string;
  ProvinceCode: string;
  CityCode: string;
  CountyCode: string;
  gsdz: string; //
  xxdz: string;
  zczb: string;
  slrq: Date;
  ssdqaddress: string; //
  ssdq: string;
  jyfw: string;
  ptzcsj: Date;
  zgswjg: string;
  zgswjgname: string;
  nsrlx: number;
  yyzzfj: string;
  [key: string]: any;
  versionCode: number;
}

export class Dic {
  value: string | number;
  code: string;
  text: string;
  type: string;
  name: string;
  [key: string]: any;
}

export class ImportOption {
  id: number;
  name: string;
  operateType: string;
  uploadurl: string;
  downloadurl: string;
  hide: boolean;
  [key: string]: any;
}