import { Injectable } from '@angular/core';
import {Condition, Message, MessageType} from '../../../shared/model/carModels';

@Injectable({
  providedIn: 'root'
})
export class SysSettingService {

  constructor() { }

  static getPwdForm(): Condition[] {
    return [
      {type: 1, label: '旧密码', value: '', key: 'oldPassword', required: true, textType: 'password', placeholder: '请输入旧密码'},
      {type: 1, label: '新密码', value: '', key: 'password', required: true, textType: 'password', placeholder: '请选择新密码'}
    ];
  }

  getMsgForm(model: Message): Condition[] {
    return [
      {
        type: 2,
        label: '消息类型',
        value: model.type,
        key: 'type',
        required: true,
        option: [
          {value: 150, name: '投诉消息'},
        ]
      },
      {
        type: 15,
        label: '消息内容',
        value: model.detail,
        key: 'detail',
        required: true,
        placeholder: '请输入消息内容',
        // maxlen: 300
      },
    ];
  }
}
