import { Component } from '@angular/core';
import { NotifyPushService } from './core/services/push.service';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`
})
export class AppComponent {
  constructor(
    private _nps: NotifyPushService
  ) {
    // 请求允许浏览器通知
    this._nps.requestPermission();
  }
}
