import { Component, OnInit, Input } from '@angular/core';
import { NzMessageService, UploadFile } from 'ng-zorro-antd';
import { Observable, Observer } from 'rxjs';
import { BASE } from '../../../core/config/linkMap';
import { OAuthService } from 'angular-oauth2-oidc';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.less']
})
export class FileUploadComponent implements OnInit {

// {path: 上传服务返回的path, displayPath: 已经上传的文件的默认展示路径}
  @Input() isNecessary = true;
  @Input() file: any;
  @Input() title: string;
  @Input() uploadUrl: string;
  @Input() isDisabled: boolean;
  @Input() isMultiple: boolean;
  @Input() params: any;
  @Input() carId: any;

  defaultUploadUrl = BASE + '/v1/files';
  previewImage: string;
  previewVisible = false;
  fileList = [];
  requestHeader = {'Authorization': ''};

  constructor(
    private msg: NzMessageService,
    private oAuthService: OAuthService) {}

  ngOnInit() {
    this.requestHeader.Authorization =  'Bearer ' + this.oAuthService.getAccessToken();
    // debugger;
    // console.log(this.file);
    if (this.file.displayPath) {
      this.fileList = [{url: this.file.displayPath || ''}];
    }
  }

  beforeUpload = (file: File) => {
    this.requestHeader.Authorization =  'Bearer ' + this.oAuthService.getAccessToken();
    this.defaultUploadUrl = !!this.uploadUrl ?  this.uploadUrl : this.defaultUploadUrl;
    return new Observable((observer: Observer<boolean>) => {
    const isJPG = file.type === 'image/jpeg';
      if (!isJPG) {
        this.msg.error('您只能上传JPG格式文件!');
        observer.complete();
        return;
      }
      const isLimitj = file.size / 1024 / 1024 < 10;
      if (!isLimitj) {
        this.msg.error('图片大小应小于10MB!');
        observer.complete();
        return;
      }
      // let reads = new FileReader();
      // reads.readAsDataURL(file);
      // reads.onload = (e) => {
      //   let image = new Image();
      //   image.src = e.target['result'].toString();
      //   image.onload = (e) => {
      //     if(e['path'][0].width * e['path'][0].height >= 600*400){
      //       this.msg.error('图片尺寸应小于等于600px*400px!');
      //       return;
      //     }
      //   }
      // };
      observer.next(isJPG && isLimitj);
      observer.complete();
    });
  }

  handlePreview = (file: UploadFile) => {
    this.previewImage = file.url || file.thumbUrl;
    this.previewVisible = true;
  }

  handleChange(info: { file: UploadFile }): void {
    if (info.file.status === 'done') {
      this.file.path = info.file.response.path;
    } else if (info.file.status === 'removed') {
      this.file.path = '';
    } else if (info.file.status === 'error') {
      this.msg.error(info.file.error.error.message);
    }
  }

  isRemoveChange(file: UploadFile) {
    return !this.isDisabled;
  }
}
