import { Injectable } from '@angular/core';
import { FetchService } from '../../../core/services/fetch.service';
import { Condition, Insurance } from '../../../shared/model/carModels';
import { ZJCargoType } from '../../../core/config/dropdown.conf';
import { REQUEST } from '../../../core/config/linkMap';
import { RendererService } from '../../../core/services/renderer.service';

@Injectable({
  providedIn: 'root'
})
export class InsuranceService {

  constructor(
    private fetchService: FetchService,
    private renderer: RendererService,
  ) { }
  
  get initBaseSearch(): {car_order_code, risk_start_date} {
    return {
      car_order_code: null,
      risk_start_date: []
    };
  }

  getSearchForm(model):Condition[] {
    return [
      {
        type: 1,
        label: '派车单号',
        value: model.car_order_code,
        key: 'car_order_code',
        placeholder: '输入派车单单号',
        width:'300px'
      },
      {
        type: 9,
        label: '上传时间',
        value: model.risk_start_date,
        key: 'risk_start_date',
      },
    ]
  }

  getExportForm(model):Condition[] {
    return [
      {
        type: 9,
        label: '下单时间',
        value: model.risk_start_date,
        key: 'risk_start_date',
      },
    ]
  }

  getInsuranceForm(model: Insurance, area: any[], fun: () => void): Condition[] {
    return [
      {
        type: 1,
        label: '派车单',
        value: model.carOrderCode,
        key: 'dispatchOrder',
        required: true,
        placeholder: '请选择派车单',
        readOnly: true,
        click: fun
      },
      {
        type: 1,
        label: '派车单编号',
        value: model.dispatchOrderId,
        key: 'dispatchOrderId',
        isHide: true,
        required: true,
        readOnly: true,
        placeholder: '请选择派车单'
      },
      {
        type: 1,
        label: '车牌号',
        value: model.vno,
        key: 'vno',
        required: true,
        readOnly: true,
        placeholder: '请输入车牌号'
      },
      {
        type: 1,
        label: '货物名称',
        value: model.cargoName,
        key: 'cargoName',
        required: true,
        readOnly: true,
        placeholder: '请输入货物名称'
      },
      {
        type: 2,
        label: '车牌颜色',
        value: model.vc,
        key: 'vc',
        required: true,
        placeholder: '请输入车牌颜色',
        option: [
          {value: 1, name: '蓝色'},
          {value: 2, name: '黄色'},
        ]
      },
      {
        type: 2,
        label: '货物分类',
        value: model.cargoType,
        key: 'cargoType',
        required: true,
        placeholder: '请选择货物分类',
        option: ZJCargoType
      },
      {
        type: 1,
        label: '保额',
        value: model.totalInsuredAmount,
        key: 'totalInsuredAmount',
        rules: ['isNumber'],
        required: true,
        placeholder: '请输入保额'
      },
      {
        type: 14,
        label: '开始时间：',
        value: model.riskStartDate,
        key: 'riskStartDate',
        rules: ['isDate'],
        required: true,
        placeholder: '请选择时间'
      },
      {
        type: 14,
        label: '结束时间：',
        value: model.riskEndDate,
        key: 'riskEndDate',
        rules: ['isDate'],
        required: true,
        placeholder: '请选择时间'
      },
      {
        type: 10,
        label: '起运地址',
        value: !!model.insuranceId ? this.renderer.mergeStart(model) : null,
        key: 'start',
        option: area,
        required: true,
        placeholder: '请选择地址'
      },
      {
        type: 10,
        label: '停运地址',
        value: !!model.insuranceId ? this.renderer.mergeEnd(model) : null,
        key: 'end',
        option: area,
        required: true,
        placeholder: '请选择地址'
      },
    ];
  }

  splitStartAndEnd(body: Insurance): Insurance {
    const start = <string[]>body.start;
    const end = <string[]>body.end;
    if (start.length === 1) {
      body.carriageStartPlaceProvince = start[0].split('+')[0];
      body.carriageStartPlaceProvinceCode = start[0].split('+')[1];
    }
    if (start.length === 2) {
      body.carriageStartPlaceProvince = start[0].split('+')[0];
      body.carriageStartPlaceProvinceCode = start[0].split('+')[1];
      body.carriageStartPlaceCity = start[1].split('+')[0];
      body.carriageStartPlaceCityCode = start[1].split('+')[1];
    }
    if (start.length === 3) {
      body.carriageStartPlaceProvince = start[0].split('+')[0];
      body.carriageStartPlaceProvinceCode = start[0].split('+')[1];
      body.carriageStartPlaceCity = start[1].split('+')[0];
      body.carriageStartPlaceCityCode = start[1].split('+')[1];
      body.carriageStartPlaceCounty = start[2].split('+')[0];
      body.carriageStartPlaceCountyCode = start[2].split('+')[1];
    }
    if (end.length === 1) {
      body.destinationProvince = end[0].split('+')[0];
      body.destinationProvinceCode = end[0].split('+')[1];
    }
    if (end.length === 2) {
      body.destinationProvince = end[0].split('+')[0];
      body.destinationProvinceCode = end[0].split('+')[1];
      body.destinationCity = end[1].split('+')[0];
      body.destinationCityCode = end[1].split('+')[1];
    }
    if (end.length === 3) {
      body.destinationProvince = end[0].split('+')[0];
      body.destinationProvinceCode = end[0].split('+')[1];
      body.destinationCity = end[1].split('+')[0];
      body.destinationCityCode = end[1].split('+')[1];
      body.destinationCounty = end[2].split('+')[0];
      body.destinationCountyCode = end[2].split('+')[1];
    }
    delete body.start;
    delete body.end;
    return body;
  }

  isTypeMin(model: Insurance): string[] {
    const messages = [];
    if ((model.cargoType === -1 || model.cargoType === 0) &&
      (model.totalInsuredAmount < 1 || model.totalInsuredAmount > 3000000)) {
      messages.push('煤炭/钢材/矿石或普通货物的保额范围为1~300万');
    }
    if ((model.cargoType === 4 || model.cargoType === 5) &&
      (model.totalInsuredAmount < 1 || model.totalInsuredAmount > 500000)) {
      messages.push('生鲜/粮食/化肥或易碎品的保额范围为1~50万');
    }
    return messages;
  }

  getArea(): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.fetchService.get(`${REQUEST.INSURANCE.AREA}`, {}, (res: any) => {
        if (!res.isError) {
          const message = JSON.parse(res.message);
          resolve(message);
        }
        resolve([]);
      });
    });
  }

  exportout(params:any) {
    return new Promise<any[]>((resolve, reject) => {
      this.fetchService.get(`${REQUEST.INSURANCE.EXPORTLIST}`, params,(res:any) => {
        resolve(res);
      });
    });
  }
}
