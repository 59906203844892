import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NzMessageService,NzModalService } from 'ng-zorro-antd';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../core/services/authentication.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { LoginBackMessage, User } from '../../shared/model/carModels';
import { StartupService } from '../../core/services/startup.service';
import {BASE} from '../../core/config/linkMap';
import {Base64} from 'js-base64';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit, OnDestroy {

  isLoading = false;
  btnSize = 'large';
  // userName: string;
  // password: string;
  codes: string;
  // telephone: string;
  verifyCodes = [];
  codeColor = [];
  arrayTest: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
  force = 0;

  public model: any = { username: '', password: '' };

  constructor(
    private message: NzMessageService,
    private router: Router,
    private authService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private oAuthService: OAuthService,
    private startupService: StartupService,
    private modalService: NzModalService,
  ) { }

  ngOnInit() {
    this.genUniqueCode();
  }

  ngOnDestroy(): void {
  }

  // 获取随机数字
  genUniqueCode() {
    this.verifyCodes = [];
    for (let i = 0; i < 4; i++) {
      const num = Math.floor(Math.random() * this.arrayTest.length);
      const code = this.arrayTest[num];
      this.verifyCodes.push(code);
      this.codeColor.push(this.color());
    }
  }

  // 获取随机数字
  login() {
    this.isLoading = true;
    if (!this.model.username || !this.model.username.trim()) {
      this.isLoading = false;
      this.messageBox('error', '请输入账号！');
      return;
    }
    if (!this.model.password || !this.model.password.trim()) {
      this.isLoading = false;
      this.messageBox('error', '请输入密码！');
      return;
    }
    if (!this.codes || !this.codes.trim()) {
      this.isLoading = false;
      this.messageBox('error', '请输入验证码！');
      return;
    } else if (this.codes && this.codes !== this.verifyCodes.join('')) {
      this.isLoading = false;
      this.messageBox('error', '验证码输入有误，请重新输入！');
      this.genUniqueCode();
      return;
    }
    this.model.password = Base64.encode(this.model.password);
    console.log(this.model.password);
    if(this.force === 1){
      this.oAuthService.tokenEndpoint = `${BASE}/uaa/oauth/token?force=1&isBase=1`;
    }else{
      this.oAuthService.tokenEndpoint = `${BASE}/uaa/oauth/token?isBase=1`;
    }
    this.oAuthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(this.model.username, this.model.password).then((user: User) => {
      this.isLoading = false;
      this.genUniqueCode();
      if (!!user.userId) {
        if (+user.companyId <= 0) {
          this.message.error('非物流公司用户不能登录');
          this.oAuthService.logOut();
          return;
        }
        this.authService.setUser(user);
        this.startupService.load().then(() => {
          this.router.navigateByUrl('/');
        });
      } else {
        this.message.error('用户信息获取失败');
      }
    }).catch((error: any) => {
      this.isLoading = false;
      const data = error.error as (LoginBackMessage | any);
      if (data.message.indexOf('已登录') > -1) {
        if (this.force === 1) {
          this.force = 0;
          this.message.error('登录失败');
          return;
        } else {
          this.modalService.confirm({
            nzTitle: '登录提示',
            nzContent: data.message,
            nzOkText: '去登录',
            nzOkType: 'danger',
            nzOnOk: () => {
              this.force = 1;
              this.login();
              return;
            },
            nzOnCancel: () => {
              this.message.info('取消登录');
            }
          });
        }
      }else{
        this.genUniqueCode();
      }
      if (!!data.error_description) {
        this.message.error(error.error_description);
        return;
      }
      this.message.error(data.message);
    });
  }

  // 生成随机颜色
  color(): any {
    return 'rgb(' + Math.floor(Math.random() * 255) + ',' +
      Math.floor(Math.random() * 255) + ',' + Math.floor(Math.random() * 255) + ')';
  }

  // getVerificationCode(): void {
  //   this.isLoading = true;
  //   if (!this.telephone || !this.telephone.trim()) {
  //     this.isLoading = false;
  //     this.messageBox('error', '请输入手机号！');
  //     return;
  //   }
  //   setTimeout(() => {
  //     this.isLoading = false;
  //   }, 5000);
  // }

  messageBox(type: string, info: string): void {
    this.message[type](info);
  }
}
