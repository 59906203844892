import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2
} from '@angular/core';
import {REQUEST} from '../../../core/config/linkMap';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.less']
})
export class PdfViewerComponent implements AfterViewInit, OnDestroy {
  @Input() baseUrl: string;
  @Input() isqrcode? = false;
  private element: HTMLElement;
  private showUrl: string; // base64(remove data:application/pdf;base64,) or url
  private container: HTMLElement;
  private iframe: HTMLElement;
  iWindow: any
  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.element = this.el.nativeElement as HTMLElement;
  }

  private static base64ToArrayBuffer(data: string) {
    data = data.replace(/[\n\r]/g, '');
    const binaryString = window.atob(data);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.container = this.element.querySelector('#pdfView');
      this.iframe = this.renderer.createElement('iframe');
      this.renderer.setProperty(this.iframe, 'height', this.container.clientHeight);
      this.renderer.setProperty(this.iframe, 'width', this.container.clientWidth);
      if(this.isqrcode){
        this.container = this.element.querySelector('#codeView');
        this.renderer.setProperty(this.iframe, 'height', this.container.clientHeight);
        this.renderer.setProperty(this.iframe, 'width', this.container.clientWidth);
        this.renderer.appendChild(this.container, this.iframe);
        this.iWindow = (<HTMLIFrameElement> this.iframe).contentWindow;
        const doc = this.iWindow.document;
        doc.write(`<div style="text-align:center;padding-top:30px;">
                    <img src="${this.baseUrl}"/>
                  </div>
                  `);
      } else {
        const url = this.isPDF ? this.converterPDFBase64ParaBlob() : `${REQUEST.DISPLAY_PIC_BASE}${this.baseUrl}`;
        this.renderer.setProperty(this.iframe, 'src', url);
        this.renderer.appendChild(this.container, this.iframe);
      }
    });
  }

  private converterPDFBase64ParaBlob() {
    const arrBuffer = PdfViewerComponent.base64ToArrayBuffer(this.baseUrl);
    const newBlob = new Blob([arrBuffer], { type: 'application/pdf' });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }
    this.showUrl = window.URL.createObjectURL(newBlob);
    return this.showUrl;
  }

  private get isPDF(): boolean {
    return this.baseUrl.startsWith('JVBE') || this.baseUrl.startsWith('0M8R');
  }

  ngOnDestroy(): void {
    this.renderer.removeChild(this.container, this.iframe);
    // 释放已创建的URL对象(防止内存泄露) https://developer.mozilla.org/zh-CN/docs/Web/API/URL/revokeObjectURL
    if (this.showUrl) {
      window.URL.revokeObjectURL(this.showUrl);
    }
  }
}
