import { Condition } from 'src/app/shared/model/carModels';
import 'src/app/shared/utils/expand.ts';

export function getCond (conds, key) {
  let filterCond = [];
  if (Array.isArray(conds)) {
    filterCond = conds.filter((cond) => {
      return cond.key === key;
    });
  }
  return filterCond[0];
}

export function getRecord(records, conds) {
  const recordsCopy = JSON.parse(JSON.stringify(records));
  if (Array.isArray(recordsCopy) && Array.isArray(conds)) {
    return recordsCopy.filter((record) => {
      return conds.every((cond) => {
        const key = cond.key;
        const value = cond.value;
        const label = cond.label;
        const fValue = record[key];
        const type = cond.type;
        let result = false;
        if (type === 1) {
          result = fValue.indexOf(value) > -1 ? true : false;
        } else {
          result = true;
        }
        /* if (!result && type === 2) {
          return result = value && fValue === value ? true : false;
        } */
        return result;
      });
    });
  }
}

export function filterRecord(records, cate, key, value) {
  const recordsCopy = JSON.parse(JSON.stringify(records));
  if (Array.isArray(recordsCopy)) {
    return recordsCopy.filter((record) => {
      const fValue = record[key];
      if (cate === 'equal') {
        return fValue === value ? true : false;
      } else if (cate === 'include') {
        return fValue.indexOf(value) > -1 ? true : false;
      }
    });
  }
}

export function convert(conditions: Condition[]) {
  const reqParam = {};
  if (Array.isArray(conditions)) {
    conditions.forEach((cond) => {
      reqParam[cond.key] = cond.value;
      if (cond.type === 1 && cond.unit) {
        reqParam[cond.unit.key] = cond.unit.value;
      }
      if(cond.type === 1 && cond.code) {
        reqParam[cond.key] = cond.code;
      }
    });
  }
  return reqParam;
}

export function convertImg(conditions: Condition[]) {
  const reqParam = {};
  if (Array.isArray(conditions)) {
    conditions.forEach((cond) => {
      reqParam[cond.key] = cond.value;
      if (cond.type === 16 && cond.imgPath) {
        reqParam[cond.key] = cond.imgPath.path;
      }
    });
  }
  return reqParam;
}

export function dateFormat(conditions: Condition[]) {
  const reqParam = {};
  if (Array.isArray(conditions)) {
    conditions.forEach((cond) => {
      if (cond.type === 14) {
        cond.value = new Date(cond.value + '')['Format']('yyyy-MM-dd hh:mm:ss');
      }
    });
  }
  return reqParam;
}

export function dateFormat1(conditions: Condition[]) {
  const reqParam = {};
  if (Array.isArray(conditions)) {
    conditions.forEach((cond) => {
      if (cond.value && cond.type === 4) {
        cond.value = new Date(cond.value + '')['Format']('yyyy-MM-dd');
      }
    });
  }
  return reqParam;
}

// clone方法
export function deepClone(condition: any) {
  if (!condition || 'object' !== typeof condition) {
      return condition;
  }
  if (condition instanceof Date) {
      const copy = new Date();
      copy.setTime(condition.getTime());
      return copy;
  }
  if (condition instanceof Array) {
      const conditionCopy =  [];
      for (let i = 0; i < condition.length; i++) {
         const condItem = condition[i];
         conditionCopy.push(deepClone(condItem));
      }
      return conditionCopy;
  } else {
      const conditionCopy = {};
      for (const key in condition) {
          if (condition.hasOwnProperty(key)) {
              const condValue = condition[key];
              conditionCopy[key] = deepClone(condValue);
          }
      }
      return conditionCopy;
  }
}

/**
 * 用于校验 <ng-content> 是否为空，自定义组件是可以使用
 * @param element
 * @returns {boolean}
 */
export const isEmpty = (element: HTMLElement): boolean => {
  const nodes = element.childNodes;
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes.item(i);
    if (node.nodeType === 1 && (node as HTMLElement).outerHTML.toString().trim().length !== 0) {
      return false;
    } else if (node.nodeType === 3 && node.textContent.toString().trim().length !== 0) {
      return false;
    }
  }
  return true;
};
