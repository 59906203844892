import { Injectable } from '@angular/core';
import { REQUEST } from 'src/app/core/config/linkMap';
import { FetchService } from 'src/app/core/services/fetch.service';

@Injectable({
  providedIn: 'root'
})
export class CarManagementService {

  constructor(
    private fetchService: FetchService
  ) { }

    /**
   * 获取车辆信息
   * @param id
   */
  getCarModel(id: number){
    return new Promise<any[]>((resolve, reject) => {
      this.fetchService.get(REQUEST.CAR.EDIT + id, {},(res:any) => {
        resolve(res);
      });
    });
  }
}
