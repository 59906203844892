import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgZorroAntdModule } from 'ng-zorro-antd';
import { ImgViewerModule } from 'ng-picture-viewer';
import { NgxEchartsModule } from 'ngx-echarts';
import { AbmModule } from 'angular-baidu-maps';

import { AmountTemplateComponent } from './components/amount-template/amount-template.component';
import { ConditionComponent } from './components/condition/condition.component';
import { DetailListComponent } from './components/detail-list/detail-list.component';
import { ExceptionComponent } from './components/exception/exception.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { ImportFileComponent } from './components/import-file/import-file.component';
import { ModalInfoComponent } from './components/modal-info/modal-info.component';
import { OrderInfoComponent } from './components/order-info/order-info.component';
import { OrderInfoProcessComponent } from './components/order-info-process/order-info-process.component';
import { PasswordComponent } from './components/password/password.component';
import { PaymentComponent } from './components/payment/payment.component';
import { QuicklyLinkComponent } from './components/quickly-link/quickly-link.component';
import { TrackComponent } from './components/track/track.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { DirectiveModule } from './directive/directive.module';
import { PipeModule } from './pipe/pipe.module';
import { SmartTextComponent } from './components/smart-text/smart-text.component';
import { BaseTableCellComponent } from './components/base-table/component/base-table-cell.component';
import { BaseTableFilterComponent } from './components/base-table/component/base-table-filter.component';
import { BaseTableComponent } from './components/base-table/base-table.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { ExplainComponent } from './components/explain/explain.component';
import { LoadingPageComponent } from './components/loading-page/loading-page.component';
import { DetailInfoComponent } from './components/detail-info/detail-info.component';
import { BatchOperateListComponent } from './components/batch-operate-list/batch-operate-list.component';
// import { QueryGridComponent } from  './components/query-grid/query-grid.component';

const COMPONENTS = [
  AmountTemplateComponent,
  ConditionComponent,
  DetailListComponent,
  ExceptionComponent,
  FileUploadComponent,
  ImportFileComponent,
  ModalInfoComponent,
  OrderInfoComponent,
  OrderInfoProcessComponent,
  PasswordComponent,
  PaymentComponent,
  QuicklyLinkComponent,
  TrackComponent,
  PdfViewerComponent,
  BaseTableCellComponent,
  BaseTableFilterComponent,
  BaseTableComponent,
  SmartTextComponent,
  NoDataComponent,
  ExplainComponent,
  LoadingPageComponent,
  DetailInfoComponent,
  BatchOperateListComponent
  // QueryGridComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgZorroAntdModule,
    ImgViewerModule,
    AbmModule,
    DirectiveModule,
    PipeModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgZorroAntdModule,
    ImgViewerModule,
    NgxEchartsModule,
    AbmModule,
    DirectiveModule,
    PipeModule,
    ...COMPONENTS
  ],
  entryComponents: [
    PdfViewerComponent,
    ExplainComponent,
    DetailInfoComponent,
    ImportFileComponent
  ]
})
export class SharedModule {
}
