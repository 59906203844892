import { Injectable } from '@angular/core';
import {FetchService} from '../../../core/services/fetch.service';
import {Condition, Role} from '../../../shared/model/carModels';
import {REQUEST} from '../../../core/config/linkMap';

@Injectable({
  providedIn: 'root'
})
export class RoleManagementService {

  constructor() {

  }

  getRoleForm(model: Role): Condition[] {
    return [
      {type: 1, label: '角色名称', value: model.roleName, key: 'roleName', required: true, placeholder: '请输入角色名称'},
      {type: 1, label: '角色类型', value: 3, key: 'roleType', required: true, isHide: true, placeholder: '请选择角色类型'},
      {type: 1, label: '角色描述', value: model.roleDesc, key: 'roleDesc', required: false, placeholder: '请输入角色描述'},
      {type: 1, label: '角色备注', value: model.memo, key: 'memo', required: false, placeholder: '请输入角色备注'},
    ];
  }
}
