import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { BaseTableColumn, BaseTableData, BaseTableRow } from 'src/app/shared/components/base-table/interface';
import { BaseTableComponent } from 'src/app/shared/components/base-table/base-table.component';
import { measureScrollX } from 'src/app/shared/utils/measure-scrollbar';
import { TableColumnService } from 'src/app/core/services/table-column.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { FetchService } from 'src/app/core/services/fetch.service';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { UtilService } from 'src/app/core/services/util.service';
import { REQUEST } from 'src/app/core/config/linkMap';
import { TransferService } from '../transfer/service/transfer.service';
import { bankType, transType } from 'src/app/core/config/dropdown.conf';

@Component({
  selector: 'app-trans-record',
  templateUrl: './trans-record.component.html',
  styleUrls: ['./trans-record.component.less']
})
export class TransRecordComponent implements OnInit {
    // 基础表格变量
    loading = false;
    tableData: BaseTableData;
    columns: BaseTableColumn[] = [];
    selectRecords: BaseTableRow[] = [];
    scrollX: string;
    queryParams: { [key: string]: any } = {};
    @ViewChild('baseTable')
    private baseTable: BaseTableComponent;
    searchParams: any;

    @ViewChild('showDetail')
    private showDetail: TemplateRef<any>;
    transOrderInfo: any;
    baseInfoList: Array<{key: string, value: string}>;

  constructor(
    private tableColumn: TableColumnService,
    private authService: AuthenticationService,
    private fetchService: FetchService,
    private message: NzMessageService,
    private util: UtilService,
    private modalService: NzModalService,
    private transferService: TransferService,
  ) { 
    this.columns = this.tableColumn.getTransColumns();
  }

  ngOnInit() {
    this.scrollX = measureScrollX(this.columns);
  }

  search(value: any) {
    this.queryParams = { transfer_order_code: value };
  }

  /* 请求调用 */
  load(params = {}) {
    /* 增加分页参数 */
    params['company_id'] = this.authService.profile['companyId'];
    params['per_page'] = params['size'];
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.tableData = { data: [], totalSize: 0 };
      this.fetchService.get(REQUEST.USER_SETTING.NEW_TRANSFER, params, (res) => {
        this.loading = false;
        if (res._embedded) {
          this.tableData = {
            data: res._embedded.transferOrderResourceList || [],
            totalSize: res.page.totalElements
          };
        }
        resolve();
      });
    });
  }

  review(){
    if (this.selectRecords.length !== 1) {
      this.message.warning('请选择一条记录更新状态！');
      return;
    }
    const selected = this.selectRecords[0];
    this.util.submitConfirm('更新状态').then(() => {
      this.fetchService.put(REQUEST.USER_SETTING.NEW_TRANSFER + '/' + selected.transferOrderId, '', (res) => {
        if(!res.isError){
          this.message.success('更新状态成功！');
          this.search(null);
        }
      });
    });
  }

  closeTrans() {
    if (this.selectRecords.length !== 1) {
      this.message.warning('请选择一条记录关闭交易！');
      return;
    }
    const selected = this.selectRecords[0];
    this.util.submitConfirm('关闭交易').then(() => {
      this.fetchService.put(REQUEST.USER_SETTING.TRANS_CLOSE + selected.transferOrderId, '', (res) => {
        if(!res.isError){
          this.message.success('交易关闭成功！');
          this.search(null);
        }
      });
    });
  }

  linkClick(data) {
    const transorderid = data.rowData.transferOrderId;
    this.fetchService.get(REQUEST.USER_SETTING.NEW_TRANSFER+'/'+ transorderid, {}, (res: any) => {
      if (!res || !res.isError) {
        this.transOrderInfo = res.data;
        this.openTransOrderDetail();
      }else{
        this.message.create('error', '转账订单详情获取失败，请稍后重试');
      }
    });
  }

  openTransOrderDetail():void{
    this.baseInfoList = this.transferService.getBaseInfo;
    this.modalService.create({
      nzTitle: '转账订单详情',
      nzContent: this.showDetail,
      nzMaskClosable: true,
      nzClosable: true,
      nzWidth: 1320,
      nzFooter: null
    });
  }

  rendererInfo = (key: string): string => {
    if (key === 'bankCode') {
      let option = bankType.filter(item => item.value === this.transOrderInfo[key]);
      return option.length > 0 ? option[0].name : '';
    }
    if (key === 'state') {
      let option = transType.filter(item => item.value == this.transOrderInfo[key]);
      return option.length > 0 ? option[0].name : '';
    }
    if(key === 'payment'){
      return this.transOrderInfo[key] + '元';
    }
    if (key === 'succDate' || key === 'createTime' || key === 'updateTime') {
      return this.util.dateFormat(this.transOrderInfo[key]);
    }
    return this.transOrderInfo[key];
  }
}
