import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ViewerImgDirective} from './viewer-img/viewer-img.directive';
import {ViewerDirective} from './viewer/viewer.directive';
import {InputNoSpaceDirective} from './no-space/no-space.directive';
import {DebounceClickDirective} from './debounce-click/debounce-click.directive';

@NgModule({
  declarations: [
    ViewerDirective,
    ViewerImgDirective,
    InputNoSpaceDirective,
    DebounceClickDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ViewerDirective,
    ViewerImgDirective,
    InputNoSpaceDirective,
    DebounceClickDirective
  ]
})
export class DirectiveModule { }
