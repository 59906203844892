import { Component, OnInit } from '@angular/core';
import { CreditService } from './service/credit-service.service';

@Component({
  selector: 'app-credit-service',
  templateUrl: './credit-service.component.html',
  styleUrls: ['./credit-service.component.less']
})
export class CreditServiceComponent implements OnInit {

  errorMsg: string[];
  titleOne: string;
  titleTwo: string;
  titleThree: string;
  baseMainInfoList: Array<{ key: string, value: string }>;
  baseCenterInfoList: Array<{ key: string, value: string }>;
  baseBottomInfoList: Array<{ key: string, value: string }>;
  baseModel: any;

  constructor(
    private creditService: CreditService,
  ) {
  }

  ngOnInit() {
  }

  inquiryCompany = (data) => {
    this.baseMainInfoList = this.creditService.getCompanyMainInfo;
    this.baseCenterInfoList = this.creditService.getCompanyAllowInfo;
    this.baseModel = data.baseModel;
    this.titleOne = data.baseModel.enterpriseName;
    this.titleTwo = '道路运输经营许可证信息';
    this.titleThree = '';
  }

  inquiryCar = (data) => {
    this.baseMainInfoList = this.creditService.getCarMainInfo;
    this.baseCenterInfoList = this.creditService.getCarTransInfo;
    this.baseBottomInfoList = this.creditService.getCarPlyInfo;
    this.baseModel = data.baseModel;
    this.titleOne = data.baseModel.vehicleNumber;
    this.titleTwo = '车辆道路运输证信息';
    this.titleThree = '车辆行驶证信息';
  }

  inquiryDriver = (data) => {
    this.baseMainInfoList = this.creditService.getDriverMainInfo;
    this.baseCenterInfoList = this.creditService.getDriverAllowInfo;
    this.baseBottomInfoList = [];
    this.baseModel = data.baseModel;
    this.titleOne = data.baseModel.nameOfPerson;
    this.titleTwo = '从业资格证信息表';
    this.titleThree = '';
  }

  rendererInfo = (key: string): any => {
    if (key === 'netState') {
      return this.baseModel[key]=== '1' ? '是' : '否';
    }
    if (key === 'licensePlateTypeCode') {
      return this.baseModel[key]=== '1' ? '蓝色' : '黄色';
    }
    if (key === 'gender') {
      return this.baseModel[key]=== '1' ? '男' : '女';
    }
    return this.baseModel[key];
  }

}
