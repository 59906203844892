import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-explain',
  templateUrl: './explain.component.html',
  styleUrls: ['./explain.component.less']
})
export class ExplainComponent implements OnInit {
  
  @Input() text:{ title: any, content: any};

  constructor() {
  }

  ngOnInit() {
    // this.text.content.replace(/。/g,'abc<br>');
    // console.log(this.text.content);
    // this.text.content.split('。')
  }

}
