import { Component, OnInit } from '@angular/core';
import {CAR_INFO, HANGUP_CAR_COND} from '../../core/config/pageConfig';
import { FetchService } from '../../core/services/fetch.service';
import {convert, deepClone} from '../../shared/utils/adapter';
import { Condition } from '../../shared/model/carModels';
import { NzMessageService } from 'ng-zorro-antd';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {REQUEST} from '../../core/config/linkMap';
import {Validation} from '../../shared/utils/validation';
import {AuthenticationService} from '../../core/services/authentication.service';

@Component({
  selector: 'app-car-bind',
  templateUrl: './car-bind.component.html',
  styleUrls: ['./car-bind.component.less']
})
export class CarBindComponent implements OnInit {

  carId: String;
  plateNum: String;
  hangupCarId: String;
  errorMsg: String[];
  condition: Condition[];

  // 安联同步与否：0未绑， 1已绑
  // 对于0的时候除了需要调用更新接口，还需要调用暗恋关系绑定接口，1的时候则只需要调用更新接口
  anlianAsync: Number = 0;

  vehicleLicenseMainPC = {displayPath: '', path: ''};
  vehicleLicenseFrontPC = {displayPath: '', path: ''};
  vehicleLicenseBackendPC = {displayPath: '', path: ''};
  vehicleRegistration  = {displayPath: '', path: ''};
  carrierPC = {displayPath: '', path: ''};
  personAttachmentPC = {displayPath: '', path: ''};


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fetchService: FetchService,
    private authService: AuthenticationService,
    private message: NzMessageService) {
    this.condition = deepClone(HANGUP_CAR_COND);
    this.plateNum = this.route.snapshot.paramMap.get('id').split('_')[1];
  }

  ngOnInit() {
      const carBindSource = this.fetchService.carBindSource;
      if (carBindSource) {
        this.anlianAsync = carBindSource.anlianSync;
        const tempCarInfo = carBindSource.car;
        this.hangupCarId = tempCarInfo.carId;
        this.condition.forEach((field) => {
          const key = field.key;
          if (this.anlianAsync === 1 && key === 'plateNum') {
            field.disabled = true;
          }
          field.value = tempCarInfo[key] !== undefined ? tempCarInfo[key] + '' : null;
        });
        if (tempCarInfo.vehicleDriveLicenseHomepage) {
          this.vehicleLicenseMainPC.displayPath = REQUEST.DISPLAY_PIC_BASE + tempCarInfo.vehicleDriveLicenseHomepage;
          this.vehicleLicenseMainPC.path = tempCarInfo.vehicleDriveLicenseHomepage;
        }
        if (tempCarInfo.vehicleDriveLicenseSecondPositivePage) {
          this.vehicleLicenseFrontPC.displayPath = REQUEST.DISPLAY_PIC_BASE + tempCarInfo.vehicleDriveLicenseSecondPositivePage;
          this.vehicleLicenseFrontPC.path = tempCarInfo.vehicleDriveLicenseSecondPositivePage;
        }
        if (tempCarInfo.vehicleDriveLicenseBackPage) {
          this.vehicleLicenseBackendPC.displayPath = REQUEST.DISPLAY_PIC_BASE + tempCarInfo.vehicleDriveLicenseBackPage;
          this.vehicleLicenseBackendPC.path = tempCarInfo.vehicleDriveLicenseBackPage;
        }
        if (tempCarInfo.vehicleRegistration) {
          this.vehicleRegistration.displayPath = REQUEST.DISPLAY_PIC_BASE + tempCarInfo.vehicleRegistration;
          this.vehicleRegistration.path = tempCarInfo.vehicleRegistration;
        }
        if (tempCarInfo.cyczpfj) {
          this.carrierPC.displayPath = REQUEST.DISPLAY_PIC_BASE + tempCarInfo.cyczpfj;
          this.carrierPC.path = tempCarInfo.cyczpfj;
        }
        if (tempCarInfo.czsmgkfj) {
          this.personAttachmentPC.displayPath = REQUEST.DISPLAY_PIC_BASE + tempCarInfo.czsmgkfj;
          this.personAttachmentPC.path = tempCarInfo.czsmgkfj;
        }
      }
  }

  carBind() {
    this.errorMsg = Validation(this.condition);
    if (!this.errorMsg.length) {
      if (!this.vehicleLicenseMainPC.path) {
        this.message.create('warning', '请上传车辆行驶证主页!');
        return;
      }
      if (!this.vehicleLicenseFrontPC.path) {
        this.message.create('warning', '请上传车辆行驶证副页正面!');
        return;
      }
      if (!this.vehicleLicenseBackendPC.path) {
        this.message.create('warning', '请上传车辆行驶证副页反面!');
        return;
      }
      if (!this.vehicleRegistration.path) {
        this.message.create('warning', '请上传车辆机动车登记证书!');
        return;
      }
      if (!this.carrierPC.path) {
        this.message.create('warning', '请上传承运车辆照片!');
        return;
      }
      if (!this.personAttachmentPC.path) {
        this.message.create('warning', '请上传车辆所有人为个人附件照片!');
        return;
      }
      const tempParams = convert(this.condition);
      tempParams['vehicleDriveLicenseHomepage'] = this.vehicleLicenseMainPC.path;
      tempParams['vehicleDriveLicenseSecondPositivePage'] = this.vehicleLicenseFrontPC.path;
      tempParams['vehicleDriveLicenseBackPage'] = this.vehicleLicenseBackendPC.path;
      tempParams['vehicleRegistration'] = this.vehicleRegistration.path;
      tempParams['cyczpfj'] = this.carrierPC.path;
      tempParams['czsmgkfj'] = this.personAttachmentPC.path;
      this.fetchService.put(REQUEST.CAR.UPDATE + this.hangupCarId + '/' + this.authService.profile['companyId'], tempParams, (res: any) => {
        if (res && !res.isError) {
          // 0表示未绑定，需要调用安联同步接口来绑定
          if (this.anlianAsync === 0) {
            this.fetchService.post(REQUEST.CAR.CAR_REL_DELETE, {
              'header': this.plateNum,
              'body': res.plateNum
            }, (res1) => {
              if (res1 && !res1.isError) {
                this.message.create('success', '绑定成功!');
                this.router.navigate(['/carManagement/carList']);
              } else {
                this.message.create('error', '您选择的车辆暂未查询到挂车信息，请在App端新增车辆约束关系');
              }
            });
          } else {
            this.message.create('success', '绑定成功!');
            this.router.navigate(['/carManagement/carList']);
          }
        } else {
          // this.message.create('error', '绑定异常，请稍后重试!');
        }
      });
    }
  }
}
