import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { FetchService } from '../../core/services/fetch.service';
import { AuthenticationService } from '../../core/services/authentication.service';
import {NzMessageService, NzModalRef, NzModalService} from 'ng-zorro-antd';
import {Router, ActivatedRoute, ParamMap, NavigationEnd} from '@angular/router';
import { REQUEST } from '../../core/config/linkMap';
import { ENTERPRISE_FIELDS } from 'src/app/core/config/pageConfig';
import { Validation } from 'src/app/shared/utils/validation';
import { convert } from '../../shared/utils/adapter';
import {Condition, Message, MessageType} from '../../shared/model/carModels';
import {MessageService} from '../../core/services/message.service';
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {messageState, messageTypeValue} from '../../core/config/dropdown.conf';
import {SysSettingService} from './service/sys-setting.service';

@Component({
  selector: 'app-sys-setting',
  templateUrl: './sys-setting.component.html',
  styleUrls: ['./sys-setting.component.less']
})
export class SysSettingComponent implements OnInit, OnDestroy {

  companyPhpne = '89029901';
  errorMsg = [];
  enterprise: any;
  accountType = '1';
  corporateOutAcc = '6222621941599936';
  corporateOutName: string;
  money: string;
  transferDate: string;
  mark: string;
  // 收款账号
  corporateInAcc = '1231231203239';
  // 收款户名
  corporateInName = '武威腾宇物流中心有限公司孟津分公司';
  // 收款银行
  bank = '中国银行股份有限公司孟津支行';

  pageIndex = 1;
  pageSize = 5;
  total = 0;
  loading = false;
  dataSource: Message[] = [];

  tabIndex: number;
  messageTypeValue: object[];
  types: number[] = null;
  selectRecords: Message[];
  mapOfCheckedId: { [key: string]: boolean } = {};
  private destroy$ = new Subject();
  pwdForm: Condition[];
  @ViewChild('pwdEditor')
  private pwdEditor: TemplateRef<{}>;
  private tplModal: NzModalRef;
  private addModal: NzModalRef;
  msgForm: Condition[];
  @ViewChild('msgEditor')
  private msgEditor: TemplateRef<{}>;
  constructor(
    private fetchService: FetchService,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private message: NzMessageService,
    private msgTip: MessageService,
    private modalService: NzModalService,
    private sysSettingService: SysSettingService
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.tabIndex = +this.route.snapshot.paramMap.get('id');
      if (this.tabIndex === 3) {
        this.searchData();
      }
    });
    this.messageTypeValue = messageTypeValue;
    this.pwdForm = SysSettingService.getPwdForm();
  }

  ngOnInit() {
    this.getEnterpriseInfo();
  }

  // 获取企业资料
  getEnterpriseInfo() {
    this.fetchService.get(REQUEST.USER_SETTING.GET_ENTERPRICE_INFO + this.authService.profile['companyId'], {}, (res) => {
      ENTERPRISE_FIELDS.map((field) => {
        field.value = res[field.key];
      });
      this.enterprise = ENTERPRISE_FIELDS;
    });
  }

  // 企业资料完善
  submit() {
    this.errorMsg = [];
    setTimeout(() => {
      this.errorMsg = Validation(this.enterprise);
      if (!this.errorMsg.length) {
        this.fetchService.put(REQUEST.USER_SETTING.GET_ENTERPRICE_INFO + this.authService.profile['companyId'], convert(this.enterprise), (res) => {
          if (!res.isError) {
            this.message.create('success', '企业信息更新成功');
          } else {
            this.message.create('error', '企业信息更i性能失败');
          }
        });
      }
    }, 0);
  }

  // 立即转账
  transfer() {
    if (!this.corporateOutAcc || !this.corporateOutAcc.trim()) {
      return this.message.create('warning', '付款账号不能为空');
    }
    if (!this.corporateOutName || !this.corporateOutName.trim()) {
      return this.message.create('warning', '付款户名不能为空');
    }
    if (!this.money || !this.money.trim()) {
      return this.message.create('warning', '转账金额有误');
    }
    if (!this.transferDate) {
      return this.message.create('warning', '付转账时间不能为空');
    }
    const params = {
      accountType: this.accountType,
      corporateOutAcc: this.corporateOutAcc,
      corporateOutName: this.corporateOutName,
      money: this.money,
      transferDate: new Date(this.transferDate)['Format']('yyyy-MM-dd hh:mm:ss'),
      mark: this.mark,
      corporateInAcc: this.corporateInAcc,
      corporateInName: this.corporateInName,
      bank: this.bank,
      companyId: this.authService.profile['companyId'],
      companyName:  this.authService.profile['companyName'],
      companyPhpne: this.authService.profile['companyPhone'],
    };
    this.fetchService.post(REQUEST.USER_SETTING.NEW_TRANSFER, params, (res) => {
      if (!res.isError) {
        this.message.create('success', '转账成功');
      } else {
        this.message.create('error', '转账失败，请稍后重试');
      }
    });
  }

  /**
   * 标签页切换控制路由
   * @param index
   */
  tabChange(index: number) {
    this.router.navigate(['/userSetting', index], {relativeTo: this.route});
  }

  searchData(reset: boolean = false): void {
    if (reset) {
      this.pageIndex = 1;
    }
    this.searchNow();
  }

  searchNow(params = {}) {
    // params['company_id'] = this.authService.profile['companyId'];
    params['page'] = this.pageIndex;
    params['per_page'] = this.pageSize;
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.dataSource = [];
      this.total = 0;
      this.fetchService.get(REQUEST.MESSAGE.ALL, params, (res: any) => {
        this.loading = false;
        if (res._embedded) {
          this.dataSource = res._embedded.messageResourceList || [];
        }
        if (res.page) {
          this.total = res.page.totalElements;
        }
        resolve();
      });
    });
  }

  currentPageDataChange(currentData: Message[]): void {
    this.refreshStatus();
  }

  refreshStatus(): void {
    this.selectRecords = this.dataSource.filter(item => this.mapOfCheckedId[item.messageId]);
  }

  /**
   * 快速处理消息
   */
  resolve = (data: Message) => {
    this.fetchService.put(`${REQUEST.MESSAGE.RESOLVE}/${data.messageId}`, null, (res: any) => {
      if (!res.isError) {
        this.searchData(true);
        this.msgTip.getCount();
      }
    });
  }

  messageStateRender (value: number) {
    const state = messageState.filter(item => item.value === value);
    return state.length > 0 ? state[0].name : '';
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  pwdEditorForm(type: number, cb: (form: any) => void) {
    this.pwdForm = JSON.parse(JSON.stringify(SysSettingService.getPwdForm()));
    this.tplModal = this.modalService.create({
      nzTitle: type === 0 ? '修改密码' : '修改支付密码',
      nzContent: this.pwdEditor,
      nzMaskClosable: false,
      nzClosable: true,
      nzWidth: 450,
      nzFooter: [
        {
          label: '取消',
          onClick: () => this.tplModal.destroy()
        },
        {
          label: '确定',
          type: 'primary',
          onClick: com => {
            this.errorMsg = Validation(this.pwdForm);
            if (this.errorMsg.length > 0) {
              this.errorMsg.forEach(item => this.message.warning(item));
              return;
            }
            cb(convert(this.pwdForm));
          }
        }
      ]
    });
  }

  updatePwd(type: number) {
    const user_id = this.authService.profile['userId'];
    const ext = type === 0 ? 'password' : 'password_pay';
    this.pwdEditorForm(type, (form: any) => {
      this.fetchService.post(`${REQUEST.USER.ALL}/${user_id}/${ext}`, form, (res: any) => {
        if (!res.isError) {
          this.message.success('密码修改成功！');
          this.tplModal.close();
          if (type === 0) {
           this.logOut();
          }
        }
      });
    });
  }

  logOut() {
    this.msgTip.close();
    this.authService.logout(true);
  }

  createMsg() {
    const msg: Message = new Message();
    this.msgForm = this.sysSettingService.getMsgForm(msg);
    this.createMsgModal((form:any) => {
      this.fetchService.post(`${REQUEST.MESSAGE.ADD}`, form, (res: any) => {
        if (!res.isError) {
          this.message.success('消息新增成功！');
          this.addModal.close();
        }
      })
    })
  }

  createMsgModal(cb: (form: any) => void) {
    this.addModal = this.modalService.create({
      nzTitle: '新增消息',
      nzContent: this.msgEditor,
      nzMaskClosable: false,
      nzClosable: true,
      nzWidth: 450,
      nzFooter: [
        {
          label: '取消',
          onClick: () => this.addModal.destroy()
        },
        {
          label: '提交',
          type: 'primary',
          onClick: com => {
            this.errorMsg = Validation(this.msgForm);
            if (this.errorMsg.length > 0) {
              this.errorMsg.forEach(item => this.message.warning(item));
              return;
            }
            cb(convert(this.msgForm));
          }
        }
      ]
    });
  }
}
