import { TemplateRef } from '@angular/core';

export interface BaseTableData {
  data: BaseTableRow[];
  totalSize: number;
}

export interface QueryTab {
  title: string;
  field: string;
  showFilter?: boolean;
  searchValue?: any; // 搜索值
  defaultValue?: any; // 默认值
  displayValue?: any; // 展示值
  lexicon?: { value: any; label: string }[]; // 词典
}

// 列数据接口
export interface BaseTableColumn extends QueryTab {
  title: string;
  field: string;
  width?: string;
  left?: string;
  right?: string;
  type?: 'link' | 'thumbnail';
  customCell?: TemplateRef<any>; // 自定义单元格
  cellRenderer?: (e) => any; // 自定义renderer
  // 排序相关
  showSort?: boolean; // 是否显示排序
  sortValue?: 'descend' | 'ascend' | null; // 当前排序状态
  customSort?: boolean; // 是否自定义排序
  // 搜索相关
  showFilter?: boolean; // 是否显示搜索
  filterType?: string; // 搜索组件类型
  filterOptions?: { label: string; value: any }[]; // 下拉搜索组件选项
  filterWidth?: string; // 下拉搜索组件宽度
  filterMultiple?: boolean; // 是否多选
  customFilter?: TemplateRef<any>; // 自定义搜索组件
}

// 行数据接口
export class BaseTableRow {
  isChecked: boolean;
  [key: string]: any;
}

// 分页参数接口
export interface BaseTablePage {
  page: number;
  size: number;
}
