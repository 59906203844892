import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FetchService } from '../../../core/services/fetch.service';
import { REQUEST } from '../../../core/config/linkMap';
import { NzMessageService} from 'ng-zorro-antd';
import { Router } from '@angular/router';
import { OrderInfo } from '../../model/carModels';
import { freightType } from '../../../core/config/dropdown.conf';

@Component({
  selector: 'app-order-info',
  templateUrl: './order-info.component.html',
  styleUrls: ['./order-info.component.less']
})
export class OrderInfoComponent implements OnInit {

  @Input() order: OrderInfo;
  @Output() deleteOrder = new EventEmitter();
  title: string;
  isShowDelete = true;

  constructor(private router: Router,
    private fetchService: FetchService,
    private message: NzMessageService) { }

  ngOnInit() {
    if (this.order) {
      freightType.forEach((freight) => {
        if (freight.value + '' === this.order.goodsPropertyTypeCode) {
          this.title = freight.name;
        }
      });
    }
  }

  delete() {
    this.fetchService.delete(REQUEST.PRE_DIST.ALL + '/' + this.order['oId'], {}, (res) => {
      if (!res || !res.isError) {
        this.message.create('success', '预派车单删除成功！');
        this.deleteOrder.emit();
      }
    });
  }
}
