import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FetchService } from '../../core/services/fetch.service';
import { AuthenticationService } from '../../core/services/authentication.service';
import { NzModalService, NzMessageService, NzModalRef } from 'ng-zorro-antd';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { REQUEST } from '../../core/config/linkMap';
import { getCond, convert, deepClone } from '../../shared/utils/adapter';
import { Condition, OrderInfo, TrackInfo, ImportOption } from '../../shared/model/carModels';
import { DIST_COND } from '../../core/config/pageConfig';
import { DistManagementService } from './service/dist-management.service';
import { freightType, newinvoiceState, orderState, payState, orderType, sexType, ethnicType} from '../../core/config/dropdown.conf';
import { UtilService } from 'src/app/core/services/util.service';

@Component({
  selector: 'app-dist-management',
  templateUrl: './dist-management.component.html',
  styleUrls: ['./dist-management.component.less']
})
export class DistManagementComponent implements OnInit {

  pageIndex = 1;
  pageSize = 5;
  total = 1;
  loading = false;
  pageSizeOptions = [5,10,20];

  allChecked = false;
  indeterminate = true;

  isVisible = false;
  isTrackVisible = false;
  isWaybillVisible = false;

  did: string;
  orderId: string;
  carId: string;
  orderCode: string;
  orderCodeValue: string;
  selectOrder: any;

  className = 'super-search';
  modalType = 'list';
  order: any;
  dataSource = [];
  orderCodeOptions = [];
  condition: Condition[];
  trackList: TrackInfo[];
  BDSList: any[] = [];
  waybillClassName = 'modal-panel waybill-panel';
  orderTypeValue: string;

  @ViewChild('showDetail')
  private showDetail: TemplateRef<any>;
  orderInfo: any;
  baseInfoList: Array<{ key: string, value: string }>;
  licenseInfoList: Array<{ key: string, value: string }>;
  private superSearchParams: any; // 高级搜索查询条件

  private tplExportModal: NzModalRef;
  exportInfo: any;

  importList: ImportOption[];
  @ViewChild('importModal')
  private importModal: TemplateRef<{}>;
  private tplImportModal: NzModalRef;

  @ViewChild('evalDetail')
  private evalDetail: TemplateRef<any>;
  evalInfo: any;
  tyEvalInfoList: Array<{ key: string, value: string }>;
  drEvalInfoList: Array<{ key: string, value: string }>;
  cargoTypeOption=[];

  constructor(
    private router: Router,
    private message: NzMessageService,
    private modalService: NzModalService,
    private authService: AuthenticationService,
    private fetchService: FetchService,
    private util: UtilService,
    private distManagementService: DistManagementService
  ) {
    this.condition = JSON.parse(JSON.stringify(DIST_COND));
    this.baseInfoList = this.distManagementService.getBaseInfo;
    this.licenseInfoList = this.distManagementService.getLicenseInfo;
  }

  ngOnInit() {
    this.trackList = [];
    this.searchData();
  }

  searchData(reset: boolean = false): void {
    if (reset) {
      this.pageIndex = 1;
    }
    this.searchNow(this.superSearchParams);
  }

  // 用户输入完成
  onInput() {
    if (this.orderCodeValue) {
      const params = { dispatchCarCode: this.orderCodeValue };
      const reqUrl = REQUEST.ORDER.RETRIEVAL1;
      this.fetchService.get(reqUrl, params, (res: any) => {
        this.orderCodeOptions = res;
      });
    } else {
      this.searchData(true);
    }
  }

  // 下拉选择完成
  selectChange() {
    this.superSearchParams = { car_order_code: this.orderCodeValue };
    this.searchNow({ car_order_code: this.orderCodeValue });
  }

  showSuperSearch() {
    this.isVisible = true;
    this.getorderTypeSync();
  }

  //获取服务方地区
  getorderTypeSync() {
    const option = this.util.switchDic('0010');
    this.condition.map((item, index) => {
      if (item.key === 'sync') {
        this.condition[index].option = option;
        this.orderTypeValue = this.condition[index].value;
        item.change = (value) => {
          if (!!value) {
            this.orderTypeValue = value;
          }
          const url = this.util.getUrl(value);
          this.util.getArea(url, {}).then((area) => {
            this.condition.map((item1, index1) => {
              if (item1.key == 'cargo_address' || item1.key == 'delivery_address') {
                this.condition[index1].value = '';
                this.condition[index1].option = area;
              }
            });
          });
        }
      }
      if (item.key == 'cargo_address' || item.key == 'delivery_address') {
        item.focus = () => {
          if (this.orderTypeValue === '') {
            this.message.warning('请先选择订单类型，再选择线路');
          }
        }
      }
    });
  }

  superSearch(isExport?: boolean) {
    const carOrderCode = getCond(this.condition, 'car_order_code');
    const name = getCond(this.condition, 'name');
    const plateNum = getCond(this.condition, 'plate_num');
    const payState = getCond(this.condition, 'pay_state');
    const invoiceState = getCond(this.condition, 'invoice_state');
    const deliveryTimeActual = getCond(this.condition, 'dilivery_time_actual');
    const cargoAddress = getCond(this.condition, 'cargo_address');
    const deliveryAddress = getCond(this.condition, 'delivery_address');
    const sync = getCond(this.condition, 'sync');
    // const timeInteval = getCond(this.condition, 'time_inteval')
    const params = {};
    if (carOrderCode.value) {
      params['car_order_code'] = carOrderCode.value;
    }
    if (name.value) {
      params['name'] = name.value;
    }
    if (plateNum.value) {
      params['plate_num'] = plateNum.value;
    }
    if (payState.value) {
      params['pay_state'] = payState.value;
    }
    if (invoiceState.value) {
      params['invoice_state'] = invoiceState.value;
    }
    if (deliveryTimeActual.value) {
      params['dilivery_time_actual'] = new Date(deliveryTimeActual.value)['Format']('yyyy-MM-dd');
    }
    // if(timeInteval.value){
    //   params['time_inteval'] = new Date(timeInteval.value[0])['Format']('yyyy-MM-dd') + ',' +  new Date(timeInteval.value[1])['Format']('yyyy-MM-dd');
    // }
    if (cargoAddress.value) {
      const province = cargoAddress.value[0].split('+')[1];
      const rgion = cargoAddress.value[1].split('+')[1];
      const city = cargoAddress.value[2].split('+')[1];
      params['cargo_address'] = province + rgion + city;
    }
    if (deliveryAddress.value) {
      const province = deliveryAddress.value[0].split('+')[1];
      const rgion = deliveryAddress.value[1].split('+')[1];
      const city = deliveryAddress.value[2].split('+')[1];
      params['delivery_address'] = province + rgion + city;
    }
    if (sync.value) {
      params['sync'] = sync.value;
    }
    // console.log(params['time_inteval']);
    if (!isExport) {
      this.superSearchParams = params;
      this.pageIndex = 1; // 每次查询都要重置pageIndex
      this.searchNow(params).then(() => {
        this.isVisible = false;
      });
    }else{
      this.exportInfo = params;
    }
  }

  searchNow(params = {}, url = REQUEST.DIST.ALL) {
    params['company_id'] = this.authService.profile['companyId'];
    params['page'] = this.pageIndex;
    params['per_page'] = this.pageSize;
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.dataSource = [];
      this.fetchService.get(url, params, (res: any) => {
        this.loading = false;
        if (res._embedded) {
          this.dataSource = res._embedded.dispatchCarResourceList || [];
        } else {
          this.message.create('warning', '暂无相关数据');
        }
        if (res.page) {
          this.total = res.page.totalElements;
        }
        resolve();
      });
    });
  }

  clear() {
    this.condition = JSON.parse(JSON.stringify(DIST_COND));
    this.superSearchParams = {};
    this.orderCodeValue = null;
    this.pageSizeOptions = [5,10,20,50];
    this.pageSize = 5;
    this.searchData(true);
  }

  // 派车单编辑
  editOrder() {
    // 只有未发货的才可以编辑
    const selectAllData = this.dataSource.filter((order) => {
      return order.checked;
    });
    const selectAvailData = this.dataSource.filter((order) => {
      return order.checked && order.payState === 0;
    });
    if (selectAllData.length !== selectAvailData.length) {
      return this.message.create('warning', '您只能选择未支付的订单来编辑');
    }
    if (selectAvailData.length !== 1) {
      return this.message.create('warning', '您只能选择一条记录来编辑');
    }
    const selectOrder = selectAvailData[0];
    this.isWaybillVisible = true;
    this.did = selectOrder.did;
    this.orderId = selectOrder.oid;
    this.carId = selectOrder.carId;
    this.orderCode = selectOrder.orderCode;
    this.selectOrder = selectOrder;
  }

  // 查看轨迹
  checkTrack() {
    // 只要不是未发货都可以查看
    const selectAllData = this.dataSource.filter((order) => {
      return order.checked;
    });
    const selectAvailData = this.dataSource.filter((order) => {
      return order.checked && order.state > 0;
    });
    if (selectAllData.length !== selectAvailData.length) {
      return this.message.create('warning', '请您选择除未发货以外状态的订单来查看轨迹');
    }
    if (selectAvailData.length !== 1) {
      return this.message.create('warning', '您只能选择一条记录来查看轨迹');
    }
    const selectOrder = selectAvailData[0];
    this.isTrackVisible = true;
    this.modalType = 'list';
    this.carId = selectOrder.carId;
    this.orderId = selectOrder.orderId;
    this.orderCode = selectOrder.orderCode;
    this.did = selectOrder.did;
    this.selectOrder = selectOrder;
    this.searchTrack();
  }

  searchTrack() {
    this.trackList = [];
    this.BDSList = [];
    if (this.modalType === 'map') {
      this.fetchService.get(REQUEST.DIST.CAR_TRACK_MAP + this.selectOrder.did + '/' + this.selectOrder.plateNum, {}, (res) => {
        // this.fetchService.get(REQUEST.DIST.CAR_TRACK_MAP + 18 + '/' + 1111, {}, (res) => {
        if (!res.isError && res._embedded) {
          this.trackList = res._embedded.gpsResourceList || [];
        } else {
          this.trackList = [];
        }
      });
      const selectData: any[] = this.dataSource.filter(value => value.checked && value.state === 6);
      if (selectData.length === 1) {
        this.fetchService.get(`${REQUEST.DIST.CAR_BDS_MAP}${selectData[0].did}`, null, (res: any) => {
          if (!res.isError && res._embedded) {
            this.BDSList = res._embedded.beidouList || [];
          } else {
            this.BDSList = [];
          }
        });
      }
    } else {
      this.fetchService.get(REQUEST.DIST.CAR_TRACK_LIST + this.selectOrder.did, {}, (res) => {
        this.loading = false;
        const tempTrackList = [];
        if (res && !res.isError) {
          res.forEach((item) => {
            tempTrackList.push({
              distributeDatetime: new Date(item.distributeDatetime)['Format']('yyyy-MM-dd hh:mm:ss'),
              act: item.act,
              operators: item.operators
            });
          });
          this.trackList = tempTrackList;
        } else {
          this.trackList = [];
          this.message.create('error', '定位获取失败，请稍后重试');
        }
      });
    }
  }

  switch() {
    this.modalType = this.modalType === 'list' ? 'map' : 'list';
    this.trackList = [];
    this.searchTrack();
  }

  // 付款申请
  applyPay() {
    // 只有未付款的转台才能申请付款
    const selectAllData = this.dataSource.filter((order) => {
      return order.checked;
    });
    const selectAvailData = this.dataSource.filter((order) => {
      return order.checked && order.payState === 0 && order.state === 6;
    });
    if (selectAllData.length !== selectAvailData.length) {
      return this.message.create('warning', '您只能选择物流公司已签收的未支付订单来申请付款');
    }
    if (selectAvailData.length !== 1) {
      return this.message.create('warning', '您只能选择一条记录来申请付款');
    }

    const selectOrder = selectAvailData[0];
    const tempParams = {
      'orderCode': selectOrder.carOrderCode,
      'operationCompanyId': this.authService.profile['companyId'],
      'oppositeAccount': selectOrder.idNum,
      'oppositeAccountName': selectOrder.name,
      'offerType': '1',
      'proposer': selectOrder.name,
      'paymentAmount': selectOrder.orderMoney
    };
    this.fetchService.post(REQUEST.DIST.APPLY_PAY + '?user_id=' + this.authService.profile['userId'], tempParams, (res) => {
      if (res && res.isError) {
        this.message.create('error', '付款申请失败，请稍后重试');
      } else {
        this.message.create('success', '付款申请成功');
      }
      this.searchData();
    });
  }

  /**
   * 特殊信息渲染
   * @param key
   */
  rendererInfo = (key: string): any => {
    if (key === 'invoiceState') {
      const result = newinvoiceState.filter(item => item.value === this.orderInfo[key]);
      return result.length > 0 ? result[0].name : '';
    }
    if (key === 'payState') {
      const result = payState.filter((item) => item.value === this.orderInfo[key]);
      return result.length > 0 ? result[0].name : '';
    }
    if (key === 'state') {
      const result = orderState.filter((item) => item.value === this.orderInfo[key]);
      return result.length > 0 ? result[0].name : '';
    }
    if (key === 'goodsPropertyTypeCode') {
      const result = freightType.filter((item) => item.value.toString() === this.orderInfo[key]);
      return result.length > 0 ? result[0].name : '';
    }
    if (key === 'cargoType') {
      const result = this.cargoTypeOption.filter((item) => item.value === this.orderInfo[key]);
      return result.length > 0 ? result[0].name : '';
    }
    if (key === 'sync') {
      const result = orderType.filter((item) => item.value === this.orderInfo[key]);
      return result.length > 0 ? result[0].name : '';
    }
    if (key === 'cargo') {
      return this.orderInfo[key] + '吨';
    }
    if (key === 'dcollection') {
      return this.orderInfo[key] === 1 ? '是' : '否';
    }
    if (key === 'cash' || key === 'etcMoney' || key === 'orderMoney' || key === 'prepayment' || key === 'refuelCardMoney') {
      return this.orderInfo[key] + '元';
    }
    if (key === 'cargoAddress') {
      return this.orderInfo[key] + this.orderInfo['cargoAddrDetail'];
    }
    if (key === 'deliveryAddress') {
      return this.orderInfo[key] + this.orderInfo['deliveryAddrDetail'];
    }
    if (key === 'xbdm') {
      const result = sexType.filter((item) => item.value === this.orderInfo[key]);
      return result.length > 0 ? result[0].name : '';
    }
    if (key === 'mzdm') {
      const result = ethnicType.filter((item) => item.value === this.orderInfo[key]);
      return result.length > 0 ? result[0].name : '';
    }
    return this.orderInfo[key];
  }

  /**
   * 查看订单详情
   * @param order
   */
  orderDetail(order: any): void {
    this.orderInfo = order;
    // 获取货物类型字典
    this.cargoTypeOption = this.util.switchDic('0016');
    this.modalService.create({
      nzTitle: '订单详情',
      nzContent: this.showDetail,
      nzMaskClosable: true,
      nzClosable: true,
      nzWidth: 1500,
      nzFooter: null
    });
  }

  delete() {
    // 只有司机卸货之前才可删除
    const selectAllData = this.dataSource.filter((order) => {
      return order.checked;
    });
    const selectAvailData = this.dataSource.filter((order) => {
      return order.checked && order.state < 3;
    });
    if (selectAllData.length !== selectAvailData.length) {
      return this.message.create('warning', '只能选择司机卸货之前的派车单进行删除');
    }
    if (selectAvailData.length !== 1) {
      return this.message.create('warning', '请选择一条记录删除');
    }
    const orderId = selectAvailData[0].did;
    this.util.submitConfirm('删除', `派车单编号为${selectAvailData[0].carOrderCode}的派车单吗`).then(() => {
      this.fetchService.delete(REQUEST.DIST.ALL + '/' + orderId, '', (res) => {
        // if (!res.isError) {
        this.message.create('success', '派车单删除成功');
        this.searchData();
        // }
      });
    })
  }

  exportout() {
    this.superSearch(true);
    this.tplExportModal = this.modalService.confirm({
      nzTitle: `提示:默认导出当天派车单，也可在高级搜索中选择条件导出对应条件的派车单。`,
      nzOkText: '导出',
      nzOkType: 'primary',
      nzOnCancel: () => {
        this.message.info('导出操作取消');
        this.tplExportModal.close()
      },
      nzOnOk: () => {
        this.requestExport(this.exportInfo);
      },
    })
  }

  requestExport(params) {
    this.distManagementService.exportout(params).then((res: any) => {
      if (!res.error) {
        this.util.exportExcel(res.data.path);
        this.tplExportModal.close();
      }
    });
  }

  batchEdit(){
    this.importList = this.distManagementService.getImportOptionList;
    this.tplImportModal = this.modalService.create({
      nzTitle: '导入',
      nzContent: this.importModal,
      nzMaskClosable: false,
      nzClosable: true,
      nzWidth: 600,
      nzFooter: null
    });
  }

  propagateChangeData(data){
    this.tplImportModal.close();
    //批量修改
    if(data.response.code){
      this.searchData(true);
      this.message.create('success', '批量修改成功');
      return;
    }
     //批量搜索
    if(data.response._embedded){
      this.dataSource = data.response._embedded;
      this.total = this.dataSource.length;
      this.pageSize = 50;
      this.pageSizeOptions = [50];
      if(data.response.message.indexOf('成功') === -1 ){
        this.message.create('warning', data.response.message);
      }else{
        this.message.create('success', data.response.message);
      }
    }
  }

  checkEval(){
    // 只有签收后可查看评价
    const selectAllData = this.dataSource.filter((order) => {
      return order.checked;
    });
    const selectAvailData = this.dataSource.filter((order) => {
      return order.checked && order.state === 6;
    });
    if (selectAllData.length !== selectAvailData.length) {
      return this.message.create('warning', '只有物流公司签收后才可查看评价');
    }
    if (selectAllData.length !== 1) {
      return this.message.create('warning', '请选择一条记录查看评价');
    }
    const orderId = selectAllData[0];
    // this.fetchService.delete(REQUEST.DIST.EVAl + '/' + orderId, '', (res) => {
    //   if (!res.isError) {
    //     this.evalInfo = res.data;
    //   }
    // });
    this.evalInfo = {
      speed:1,
      accuracy:1,
      driverAttitude:1,
      package:1,
      service:1,
      workerAttitude:1,
      cargoTime:'12小时以内',
      deliveryTime:'12小时以内',
      violationFee:1
    };
    this.tyEvalInfoList = this.distManagementService.gettyEvalInfo;
    this.drEvalInfoList = this.distManagementService.getdrEvalInfo;
    this.modalService.create({
      nzTitle: '查看评价',
      nzContent: this.evalDetail,
      nzMaskClosable: true,
      nzClosable: true,
      nzWidth: 1000,
      nzFooter: null
    });
  }

  rendererEvalInfo =  (key: string): any => {
    if (key === 'violationFee') {
      return this.evalInfo[key] === 1 ? '不存在' : '存在';
    }
    if (key === 'speed' || key === 'accuracy' || key === 'driverAttitude' || key === 'package' || key === 'service' || key === 'workerAttitude') {
      return this.evalInfo[key] === 1 ? '满意' : '不满意';
    }
    return this.evalInfo[key];
  }
}
