import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// var wx = require('weixin-js-sdk');
import wx from 'weixin-js-sdk'
@Component({
  selector: 'app-mingsheng-sign-success',
  templateUrl: './mingsheng-sign-success.component.html',
  styleUrls: ['./mingsheng-sign-success.component.less']
})
export class MingshengSignSuccessComponent implements OnInit {
  type: any;
  page: any;
  constructor(
    private route: ActivatedRoute,
  ) { 
    this.route.queryParams.subscribe((params) => {
      this.type = params.type;
      this.page = params.page;
    });
  }

  ngOnInit() {
  }
  close() {
    
    if(this.type === 'app'){
      //h5跳转app
      let message = JSON.stringify({type:this.type, page:this.page})
      window.postMessage(message,'')
    }else if(this.type === 'applet'){
      console.log(wx)
      //h5跳转小程序
      if(this.page === 'createMember'){
        wx.miniProgram.navigateTo({
          url:'/pages/main/mine/approve/approve'
        })
      }else{
        wx.miniProgram.navigateTo({
          url:'/pages/main/mine/purse/cardsList/cardsList'
        })
      }
    }else if(this.type === 'appletManage'){
      console.log(wx)
      //h5跳转小程序
      if(this.page === 'createMember'){
        wx.miniProgram.navigateTo({
          url:'/pages/modules/userManage/userManage'
        })
      }
    }else{
      //web端
      let message = JSON.stringify({type:this.type, page:this.page})
      window.parent.postMessage(message, '*');
    }
  }
}
