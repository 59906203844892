import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../core/services/authentication.service';
import { FetchService } from '../../core/services/fetch.service';
import { REQUEST } from '../../core/config/linkMap';
import { LogManagementService } from './service/log-management.service';
import { BaseTableComponent } from 'src/app/shared/components/base-table/base-table.component';
import { TableColumnService } from 'src/app/core/services/table-column.service';
import { BaseTableColumn, BaseTableData, BaseTableRow } from 'src/app/shared/components/base-table/interface';
import { measureScrollX } from 'src/app/shared/utils/measure-scrollbar';
import { UtilService } from 'src/app/core/services/util.service';

@Component({
  selector: 'app-log-management',
  templateUrl: './log-management.component.html',
  styleUrls: ['./log-management.component.less']
})
export class LogManagementComponent implements OnInit {

   // 基础表格变量
   loading = false;
   tableData: BaseTableData;
   columns: BaseTableColumn[] = [];
   selectRecords: BaseTableRow[] = [];
   scrollX: string;
   queryParams: { [key: string]: any } = {};
   @ViewChild('baseTable')
   private baseTable: BaseTableComponent;
   searchParams: { [key: string]: any } = {};

  constructor(
    private router: Router,
    private modalService: NzModalService,
    private authService: AuthenticationService,
    private fetchService: FetchService,
    private message: NzMessageService,
    private LoginLogService: LogManagementService,
    private tableColumn: TableColumnService,
    private util: UtilService,
  ) { 
    this.columns = this.tableColumn.getLoginLogColumns();
    this.searchParams = {
      text:'',
      type:''
    }
  }

  ngOnInit() {
    this.scrollX = measureScrollX(this.columns);
  }

  search(value: any) {
    this.queryParams = {
      operate:value.text,
      log_type:value.type
    };
  }

  /* 请求调用 */
  load(params = {}) {
    /* 增加分页参数 */
    params['company_id'] = this.authService.profile['companyId'];
    params['per_page'] = params['size'];
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.tableData = { data: [], totalSize: 0 };
      this.fetchService.get(REQUEST.LOG.LOGALL, params, (res) => {
        this.loading = false;
        if (res._embedded) {
          this.tableData = {
            data: res._embedded.logResourceList || [],
            totalSize: res.page.totalElements
          };
        }
        resolve();
      });
    });
  }
}
