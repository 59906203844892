
import { Injectable } from '@angular/core';
import { BaseTableColumn } from '../../shared/components/base-table/interface';
import { RendererService } from './renderer.service';

@Injectable({
  providedIn: 'root'
})
export class TableColumnService {

  constructor(
    private renderer: RendererService
  ) { }

  getInsuranceColumns(): BaseTableColumn[] {
    return [
      { title: '编号', field: 'insuranceId', width: '50px' },
      { title: '派车单单号', field: 'carOrderCode', width: '320px' },
      { title: '是否投递成功', field: 'syncZj', width: '120px', cellRenderer: this.renderer.isBoolean },
      { title: '保额', field: 'totalInsuredAmount', width: '100px' },
      { title: '车牌号', field: 'vno', width: '100px' },
      { title: '车牌颜色', field: 'vc', width: '100px' },
      { title: '货物名称', field: 'cargoName', width: '150px' },
      { title: '货物类别', field: 'cargoType', width: '150px', cellRenderer: this.renderer.rendererCargoType },
      { title: '起运地', field: 'startAreaName', width: '200px' },
      { title: '停运地', field: 'finishAreaName', width: '200px' },
      // { title: '起运地', field: 'startAreaName', width: '200px', cellRenderer: this.renderer.rendererAddress('start') },
      // { title: '停运地', field: 'finishAreaName', width: '200px', cellRenderer: this.renderer.rendererAddress('end') },
      { title: '保障起期', field: 'riskStartDate', width: '150px' },
      { title: '保障止期', field: 'riskEndDate', width: '150px' },
    ];
  }

  getPartnerColumns(): BaseTableColumn[] {
    return [
      { title: '联系人', field: 'connector', width: '160px' },
      { title: '联系电话', field: 'mobile', width: '120px' },
      { title: '单位名称', field: 'name', width: '200px' },
      { title: '纳税人识别号', field: 'taxNum', width: '180px' },
      { title: '公司地址', field: 'gsdz', width: '200px' },
      { title: '详细地址', field: 'xxdz', width: '200px' },
      { title: '注册资本', field: 'zczb', width: '130px' },
      { title: '成立日期', field: 'slrq', width: '150px' },
      { title: '所属地区', field: 'ssdq', width: '200px', cellRenderer: this.renderer.rendererAddress('ssdq') },
      { title: '经营范围', field: 'jyfw', width: '220px' },
      { title: '平台注册时间', field: 'ptzcsj', width: '150px' },
      { title: '主管税务机关', field: 'zgswjgname', width: '260px' },
      { title: '企业类型', field: 'nsrlx', width: '100px' }
    ];
  }

  getIndustryColumns(): BaseTableColumn[] {
    return [
      { title: '行业编号', field: 'code', width: '80px' },
      { title: '行业名称', field: 'codename', width: '130px' }
    ]
  }

  getReviewDriverColumns(): BaseTableColumn[] {
    return [
      { title: '司机姓名', field: 'name', width: '80px', type: 'link' },
      { title: '注册手机', field: 'mobile', width: '120px' },
      { title: '身份证号码', field: 'idNum', width: '180px' },
      { title: '驾驶证类型', field: 'licenseType', width: '100px' },
      { title: '驾驶证号码', field: 'driverLicence', width: '160px' },
      { title: '车牌号码', field: 'plateNum', width: '100px' },
      { title: '车辆类型', field: 'carType', width: '120px' },
      { title: '纳税人姓名', field: 'taxpayerName', width: '100px' },
      { title: '纳税人身份证号码', field: 'taxpayerIdentity', width: '160px' },
      { title: '纳税人开户行', field: 'bankName', width: '100px' },
      { title: '纳税人银行卡号', field: 'bankAccout', width: '160px' }
    ];
  }

  getTaxOfficeColumns(): BaseTableColumn[] {
    return [
      { title: '税务机关代码', field: 'dm', width: '80px' },
      { title: '税务机关名称', field: 'mc', width: '130px' },
      { title: '税务机关简称', field: 'jc', width: '130px' }
    ]
  }

  getLoginLogColumns(): BaseTableColumn[] {
    return [
      { title: '日志类型', field: 'logType', width: '100px', cellRenderer: this.renderer.rendererLogType },
      { title: '操作信息', field: 'operate', width: '140px' },
      { title: '操作人姓名', field: 'createUser', width: '100px' },
      { title: '操作时间', field: 'createTime', width: '120px' },
      { title: '数据ID', field: 'dataId', width: '100px' },
      { title: '操作人公司IP', field: 'ip', width: '120px' },
      { title: '客户端', field: 'point', width: '100px' },
      { title: '功能方法', field: 'method', width: '100px' }
    ];
  }


  getTransColumns(): BaseTableColumn[] {
    return [
      { title: '转账订单号', field: 'transferOrderCode', width: '270px', type: 'link',},
      { title: '转账金额', field: 'payment', width: '100px' },
      { title: '转账银行', field: 'bankCode', width: '100px', cellRenderer: this.renderer.rendererBankType },
      { title: '转账公司名', field: 'companyName', width: '200px' },
      { title: '转账状态', field: 'state', width: '100px', cellRenderer: this.renderer.rendererTransType },
      { title: '到账时间', field: 'succDate', width: '130px', cellRenderer: this.renderer.dateFormat('YYYY-MM-DD HH:mm:ss') },
      { title: '银行支付地址', field: 'url', width: '150px' },
      { title: '创建人姓名', field: 'createUserName', width: '100px',},
      { title: '创建时间', field: 'createTime', width: '100px', cellRenderer: this.renderer.dateFormat('YYYY-MM-DD HH:mm:ss') },
      { title: '修改人姓名', field: 'updateUserName', width: '100px' },
      { title: '修改时间', field: 'updateTime', width: '100px',cellRenderer: this.renderer.dateFormat('YYYY-MM-DD HH:mm:ss') }
    ]
  }
  
  getBillingRecordColumns(): BaseTableColumn[] {
    return [
      { title: '发票代码', field: 'invoiceCode', width: '100px' },
      { title: '客户（受票方）', field: 'draweeName', width: '160px' },
      { title: '开票额', field: 'invoiceMoney', width: '100px' },
      { title: '开票状态', field: 'state', width: '100px', cellRenderer: this.renderer.rendererBillingType },
      { title: '申请时间', field: 'applyDateTime', width: '160px', cellRenderer: this.renderer.dateFormat('YYYY-MM-DD') },
      { title: '开票时间', field: 'performDatetime', width: '160px', cellRenderer: this.renderer.dateFormat('YYYY-MM-DD') },
      { title: '实付运费', field: 'driverMoney', width: '100px' },
      { title: '开票信息', field: 'operate', width: '120px' },
      { title: '备注', field: 'mark', width: '100px' }
    ];
  }

  getCarTransColumns(): BaseTableColumn[] {
    return [
      { title: '序号', field: 'orderId', width: '60px' },
      { title: '运单号', field: 'orderCode', width: '160px' },
      { title: '司机', field: 'driverName', width: '80px' },
      { title: '电话号码', field: 'driverPhone', width: '100px' },
      { title: '车牌号', field: 'plate_num', width: '100px' },
      { title: '起始地', field: 'deliveryAddrB', width: '100px' },
      { title: '目的地', field: 'deliveryAddrE', width: '100px' },
      { title: '货物名称', field: 'cargoName', width: '100px' },
      { title: '货物数量', field: 'cargoNum', width: '100px' },
      { title: '运输时间起', field: 'deliveryBtm', width: '120px', cellRenderer: this.renderer.dateFormat('YYYY-MM-DD')  },
      { title: '运输时间止', field: 'deliveryEtm', width: '120px', cellRenderer: this.renderer.dateFormat('YYYY-MM-DD')  },
      { title: '运输运费（元）', field: 'cash', width: '120px' },
      { title: '已支付运费（元）', field: 'orderMoney', width: '130px' },
      { title: '运输轨迹', field: 'infomap', width: '100px' },
      { title: '原始单据凭证', field: 'infoImg', width: '100px' }
    ];
  }
}
