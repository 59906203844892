import { Injectable } from '@angular/core';
import { BASE } from '../config/linkMap';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { OAuthService } from 'angular-oauth2-oidc';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../../core/services/authentication.service';

@Injectable({
  providedIn: 'root'
})

export class FetchService {

  public http;
  public carBindSource: any;
  constructor(
    http: HttpClient,
    private oAuthService: OAuthService,
    private modalService: NzModalService,
    private authService: AuthenticationService,
    private message: NzMessageService) {
    this.http = http;
  }
  public handleData(error:any) {
    if (error.status === 401 || error.status === 502) {
      this.modalService.confirm({
        nzTitle: '登陆提示',
        nzContent: '未登录或登录已过期,是否重新登录？',
        nzOkText: '去登录',
        nzOkType: 'danger',
        nzOnOk: () => {
          this.authService.logout(true);
          this.message.info(error.message);
          return;
        },
        nzOnCancel: () => {
          this.message.info('重新登录取消');
        }
      });
    } 
  }

  public get(url, params?: Object, cb?: Function) {
    let httpParams = new HttpParams();
    const self = this;
    if (params) {
      for (const key in params) {
        if (params[key] === false || params[key]) {
          httpParams = httpParams.set(key, params[key]);
        }
      }
    }
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.oAuthService.getAccessToken());
    self.http['get'](BASE + url, { params: httpParams, headers }).subscribe(data => {
      cb(data);
    }, error => {
      // this.handleData(error.error);
      if (error.error.status !== 404) {
        if (error.error.message) {
          this.message.create('error', `操作异常，异常原因：${error.error.message}`);
        }
      }
      cb({isError: true, code: error.error.status});
    });
  }

  public post(url, data?: Object, cb?: Function) {
    const self = this;
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.oAuthService.getAccessToken());
    self.http['post'](BASE + url,  data, { headers }).subscribe(res => {
      cb(res);
    }, error => {
      // this.handleData(error.error);
      if (error.error.message) {
        this.message.create('error', `操作异常，异常原因：${error.error.message}`);
      }
      cb({isError: true, code: error.error.status, errorMsg: error.error.message});
    });
  }

  //外部非登录系统请求
  public outpost(url, data?: Object, cb?: Function) {
    const self = this;
    // const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.oAuthService.getAccessToken());
    self.http['post'](BASE + url,  data, {}).subscribe(res => {
      cb(res);
    }, error => {
      // this.handleData(error.error);
      if (error.error.message) {
        this.message.create('error', `操作异常，异常原因：${error.error.message}`);
      }
      cb({isError: true, code: error.error.status, errorMsg: error.error.message});
    });
  }

  public put(url, data?: Object, cb?: Function) {
    const self = this;
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.oAuthService.getAccessToken());
    self.http['put'](BASE + url, data, { headers }).subscribe(res => {
      cb(res);
    }, error => {
      // this.handleData(error.error);
      if (error.error.message) {
        this.message.create('error', `操作异常，异常原因：${error.error.message}`);
      }
      cb({isError: true, code: error.error.status, message: error.error.message});
    });
  }

  public delete(url, params?: Object, cb?: Function) {
    let httpParams = new HttpParams();
    const self = this;
    if (params) {
      for (const key in params) {
        if (params[key]) {
          httpParams = httpParams.set(key, params[key]);
        }
      }
    }
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.oAuthService.getAccessToken());
    self.http['delete'](BASE + url, { headers, params: httpParams }).subscribe(data => {
      cb(data);
    }, error => {
      // this.handleData(error.error);
      if (error.error.message) {
        this.message.create('error', `操作异常，异常原因：${error.error.message}`);
      }
      cb({isError: true, code: error.error.status});
    });
  }

  /**
   * 部分修改
   * @param url
   * @param data
   * @param cb
   */
  public patch(url, data?: Object, cb?: Function) {
    const self = this;
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.oAuthService.getAccessToken());
    self.http['patch'](BASE + url, data, { headers }).subscribe(res => {
      cb(res);
    }, error => {
      // this.handleData(error.error);
      if (error.error.message) {
        this.message.create('error', `操作异常，异常原因：${error.error.message}`);
      }
      cb({isError: true, code: error.error.status, message: error.error.message});
    });
  }
}
