import { Component, OnInit } from '@angular/core';
import { FetchService } from '../../core/services/fetch.service';
import { AuthenticationService } from '../../core/services/authentication.service';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { REQUEST } from '../../core/config/linkMap';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Condition } from '../../shared/model/carModels';
import { DRIVER_INFO, ACCOUNT_INFO } from '../../core/config/pageConfig';
import { Validation } from '../../shared/utils/validation';
import { getCond, convert, dateFormat1, deepClone } from '../../shared/utils/adapter';
import { UtilService } from 'src/app/core/services/util.service';
import { ArrayService } from 'src/app/core/services/array.service';

@Component({
  selector: 'app-driver-info-edit',
  templateUrl: './driver-info-edit.component.html',
  styleUrls: ['./driver-info-edit.component.less']
})
export class DriverInfoEditComponent implements OnInit {

  // 下面两个字段需要零时保存并返回给服务端
  contractNo: string;
  taxpayerId: number;
  signcontractVerify: string;

  isShowCond = false;
  isShowUpload = false;
  driverInfo: any;
  driverId: string;
  userIdCopy: string;
  errorMsg: string[];
  condition: Condition[];
  driverFrontPC = {displayPath: '', path: ''};
  driverBackendPC = {displayPath: '', path: ''};
  lisencePC = {displayPath: '', path: ''};
  lisenceBackendPC = {displayPath: '', path: ''};
  taxpayerIdentityPic = {displayPath: '', path: ''};
  rtqcPic = {displayPath: '', path: ''};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fetchService: FetchService,
    private authService: AuthenticationService,
    private util: UtilService,
    private message: NzMessageService,
    private arrayService: ArrayService) {
    this.condition = JSON.parse(JSON.stringify(DRIVER_INFO));
    this.getAddress();
  }

  ngOnInit() {
    this.driverInfo = {};
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.driverId = params.get('id');
      const type = location.href.indexOf('unAuditList') > -1 ? 'ALL_ILLEGAL' : 'ALL';
      this.fetchService.get(REQUEST.DRIVER[type] + '/' + this.driverId, {}, (res: any) => {
        this.driverInfo = res;
        this.userIdCopy = res.userId;
        this.contractNo = res.contractNo;
        this.signcontractVerify = res.signcontractVerify;
        this.taxpayerId = res.taxpayerId;
        this.condition.forEach((field) => {
          if (field.dValue) {
            field.value = res[field.dValue];
          }
          const key = field.key;
          if (key === 'identityForever') {
            field.value = res[key] + '';
          } else if (field.type === 4 && res[key]) {
            field.value = new Date(res[key]);
          } else {
            field.value = res[key] ? res[key] : field.value ;
          }
        });

        // 设置上传图片默认展示url
        if (res.identityPicFront) {
          this.driverFrontPC.displayPath = REQUEST.DISPLAY_PIC_BASE + res.identityPicFront;
          this.driverFrontPC.path = res.identityPicFront;
        }
        if (res.identityPicBack) {
          this.driverBackendPC.displayPath = REQUEST.DISPLAY_PIC_BASE + res.identityPicBack;
          this.driverBackendPC.path = res.identityPicBack;
        }
        if (res.driverLicencePicMain) {
          this.lisencePC.displayPath = REQUEST.DISPLAY_PIC_BASE + res.driverLicencePicMain;
          this.lisencePC.path = res.driverLicencePicMain;
        }
        if (res.driverLicencePicSecond) {
          this.lisenceBackendPC.displayPath = REQUEST.DISPLAY_PIC_BASE + res.driverLicencePicSecond;
          this.lisenceBackendPC.path = res.driverLicencePicSecond;
        }
        if (res.taxpayerIdentityPic) {
          this.taxpayerIdentityPic.displayPath = REQUEST.DISPLAY_PIC_BASE + res.taxpayerIdentityPic;
          this.taxpayerIdentityPic.path = res.taxpayerIdentityPic;
        }
        if (res.rtqcPic) {
          this.rtqcPic.displayPath = REQUEST.DISPLAY_PIC_BASE + res.rtqcPic;
          this.rtqcPic.path = res.rtqcPic;
        }
        
        this.isShowCond = true;
        this.isShowUpload = true;
      });
    });
  }

  handleData(data: any) {
    const causeStr = data.replace(/\[|\]|【|】|\"|\'/g , '');
    return causeStr;
  }

  getAddress() {
    const option = this.util.switchDic('0021');
    this.condition.map((item,index) => {
      if(item.key == 'provinceCode'){
        this.condition[index].option = option;
      }
    });
  }

  submit() {
    this.errorMsg = [];
    /*对于身份证是否永久条件会关联截止日期的选择与否*/
    const isIdentityForever = getCond(this.condition, 'identityForever');
    const isIdentityEndDate = getCond(this.condition, 'identityEndDate');
    isIdentityEndDate.required = true;
    if (isIdentityForever.value === 'true') {
      isIdentityEndDate.required = false;
      this.message.create('warning', '由于您选择的身份证为永久，因此身份证截至日期自动失效');
      isIdentityEndDate.value = '';
    }
    setTimeout(() => {
      /** 正常其他数据类型校验*/
      this.errorMsg = Validation(this.condition);
      if (!this.errorMsg.length) {
        
        // 图片校验
        if (!this.driverFrontPC.path) {
          this.message.create('warning', '请上传身份证正面照!');
          return;
        }
        if (!this.driverBackendPC.path) {
          this.message.create('warning', '请上传身份证反面照!');
          return;
        }
        if (!this.lisencePC.path) {
          this.message.create('warning', '请上传驾驶证正面照!');
          return;
        }
        if (!this.lisenceBackendPC.path) {
          this.message.create('warning', '请上传驾驶证反面照!');
          return;
        }
        if (!this.taxpayerIdentityPic.path) {
          this.message.create('warning', '请上传纳税人身份证正面照片!');
          return;
        }
        if (!this.rtqcPic.path) {
          this.message.create('warning', '请上传道路从业资格证照片!');
          return;
        }
        /*提交*/
        const copyCondition = deepClone(this.condition);
        // dateFormat1(copyCondition);
        const tempParams = convert(copyCondition);
        /*对于身份证是否永久条件会关联截止日期的选择与否*/
        const identityForever = tempParams['identityForever'];
        const identityEndDate = tempParams['identityEndDate'];
        const identityStartDate = tempParams['identityStartDate'];
        // 首次签发日期
        const licenseFirstDetDate = tempParams['licenseFirstDetDate'];
        // 有效期开始日期
        const licenseStartDate = tempParams['licenseStartDate'];
        // 有效期结束日期
        const licenseEndDate = tempParams['licenseEndDate'];
        if (identityForever === 'true') {
          tempParams['identityEndDate'] = '';
        } else if (identityForever === 'false' && identityEndDate <= identityStartDate) {
          return this.message.create('warning', '身份证截至日期不能早于身份证开始日期');
        }
        if (licenseEndDate <= licenseStartDate) {
          return this.message.create('warning', '有效期结束日期不能早于有效期开始日期');
        }
        // 税登人和司机为同一人校验，姓名校验，身份证号校验
        if(tempParams['name'] !== tempParams['taxpayerName'] ){
          return this.message.create('warning', '司机姓名和纳税人姓名必须一致');
        }
        if(tempParams['idNum'] !== tempParams['taxpayerIdentity'] ){
          return this.message.create('warning', '身份证号码和纳税人身份证号码必须一致');
        }
        tempParams['identityPicFront'] = this.driverFrontPC.path;
        tempParams['identityPicBack'] = this.driverBackendPC.path;
        tempParams['driverLicencePicMain'] = this.lisencePC.path;
        tempParams['driverLicencePicSecond'] = this.lisenceBackendPC.path;
        tempParams['taxpayerIdentityPic'] = this.taxpayerIdentityPic.path;
        tempParams['rtqcPic'] = this.rtqcPic.path;
        tempParams['userId'] = this.userIdCopy;
        if (this.taxpayerId) {
          tempParams['taxpayerId'] = this.taxpayerId;
        }
        if (this.contractNo) {
          tempParams['contractNo'] = this.contractNo;
        }
        if (this.signcontractVerify) {
          tempParams['signcontractVerify'] = this.signcontractVerify;
        }
        tempParams['signcontractVerify'] = this.signcontractVerify;
        this.fetchService.put(REQUEST.DRIVER.UPDATE + this.driverId + '/anlian', tempParams, (res: any) => {
          if (!res.isError) {
            this.message.create('success', '操作成功！');
            const flag: boolean = this.router.url.indexOf('unAuditList') > -1;
            if (flag) {
              this.router.navigate(['/driverManagement/unAuditList']);
            } else {
              this.router.navigate(['/driverManagement/auditList']);
            }
          }
        });
      }
    }, 0);
  }


}
