import { Component, OnInit } from '@angular/core';
import { REQUEST } from '../../core/config/linkMap';
import { NzModalService } from 'ng-zorro-antd';
import { Router } from '@angular/router';
import { FetchService } from '../../core/services/fetch.service';
import { AuthenticationService } from '../../core/services/authentication.service';
import { getCond } from '../../shared/utils/adapter';
import { DRIVER_MANAGE_INFO } from '../../core/config/pageConfig';
import { Condition } from '../../shared/model/carModels';
import { DetailInfoComponent } from 'src/app/shared/components/detail-info/detail-info.component';
import { DriverManagementService } from '../driver-management/service/driver-management.service';

@Component({
  selector: 'app-unaudit-list',
  templateUrl: './unaudit-list.component.html',
  styleUrls: ['./unaudit-list.component.less']
})
export class UnauditListComponent implements OnInit {
  pageIndex = 1;
  pageSize = 5;
  total = 1;
  loading = false;

  allChecked = false;
  indeterminate = false;

  isVisible = false;
  isOkLoading = false;
  driverPhone = '';

  condition: Condition[];
  dataSource: any[];
  selectRecords = [];
  driverPhoneOptions = [];

  className = 'super-search';
  modalType: string;
  private superSearchParams: any;

  constructor(
    private router: Router,
    private modalService: NzModalService,
    private authService: AuthenticationService,
    private fetchService: FetchService,
    private driverManagementService: DriverManagementService) {
    this.condition = JSON.parse(JSON.stringify(DRIVER_MANAGE_INFO));
  }

  ngOnInit() {
    this.dataSource = [];
    this.selectRecords = [];
    this.searchData();
  }

  searchData(reset: boolean = false): void {
    if (reset) {
      this.pageIndex = 1;
    }
    this.searchNow(this.superSearchParams);
  }

  // 用户输入完成
  onInput() {
    if (this.driverPhone) {
      const params = {mobile: this.driverPhone};
      const reqUrl = REQUEST.DRIVER.RETRIEVAL_UNADUIT;
      this.fetchService.get(reqUrl, params, (res: any) => {
        this.driverPhoneOptions = res;
      });
    } else {
      this.searchData(true);
    }
  }

  // 下拉选择完成
  selectChange() {
    this.superSearchParams = {mobile: this.driverPhone};
    this.searchNow({mobile: this.driverPhone});
  }

  /* 高级搜索 */
  superSearch() {
    const name = getCond(this.condition, 'name');
    const mobile = getCond(this.condition, 'mobile');
    const driverLicence = getCond(this.condition, 'driverLicence');
    const licenseType = getCond(this.condition, 'licenseType');
    const params = {};
    if (name.value) {
      params['name'] = name.value;
    }
    if (mobile.value) {
      params['mobile'] = mobile.value;
    }
    if (driverLicence.value) {
      params['driver_licence'] = driverLicence.value;
    }
    if (licenseType.value) {
      params['license_type'] = licenseType.value;
    }
    this.superSearchParams = params;
    this.searchNow(params).then(() => {
      this.isOkLoading = false;
      this.isVisible = false;
    });
  }

  /* 请求调用 */
  searchNow(params = {}) {
    /* 增加分页参数 */
    params['company_id'] = this.authService.profile['companyId'];
    params['page'] = this.pageIndex;
    params['per_page'] = this.pageSize;
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.dataSource = [];
      this.fetchService.get(REQUEST.DRIVER.ALL_ILLEGAL, params, (res) => {
        this.loading = false;
        if (res._embedded) {
          this.dataSource = res._embedded.driverIllegalResourceList || [];
        }
        if (res.page) {
          this.total = res.page.totalElements;
        }
        resolve();
      });
    });
  }

  handleData(data: any) {
    const causeStr = data.replace(/\[|\]|【|】|\"|\'/g , '');
    return causeStr;
  }

  clear() {
    this.driverPhone = '';
    this.superSearchParams = {};
    this.condition = JSON.parse(JSON.stringify(DRIVER_MANAGE_INFO));
    this.searchData(true);
  }

  currentPageDataChange($event: Array<{ name: string; age: number; address: string; checked: boolean; disabled: boolean; }>): void {
    this.dataSource = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    const allChecked = this.dataSource.every(value => value.checked === true);
    const allUnChecked = this.dataSource.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
    /* 选择数据同步 */
    this.selectRecords = this.dataSource.filter(value => value.checked);
  }

  checkAll(value: boolean): void {
    this.dataSource.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  /* 司机编辑 */
  edit() {
    if (this.selectRecords.length === 1) {
      const selectAvilData = this.dataSource.filter(value => value.checked && value.plateNmum);
      if (selectAvilData.length === 0) {
        return this.modalService.warning({
          nzTitle: '警告信息：',
          nzContent: `<p>您选择的司机不能被编辑，请先绑定车辆！</p>`
        });
      }
      this.router.navigate(['/driverManagement/unAuditList/driverInfoEdit', this.selectRecords[0].driverId]);
    } else if (this.selectRecords.length === 0) {
      this.modalService.warning({
        nzTitle: '警告信息：',
        nzContent: `<p>请选择一个编辑项！</p>`
      });
    } else  {
      this.modalService.warning({
        nzTitle: '警告信息：',
        nzContent: `<p>只能选择一个编辑项！</p>`
      });
    }
  }

  // 司机绑定
  bind() {
    if (this.selectRecords.length === 1) {
      const selectAvilData = this.dataSource.filter(value => value.checked && (!value.plateNmum || value.plateNmum === '0' ));
      if (selectAvilData.length === 0) {
        return this.modalService.warning({
          nzTitle: '警告信息：',
          nzContent: `<p>您选择的司机已经绑定过车辆！</p>`
        });
      }
      this.router.navigate(['/driverManagement/unAuditList/driverInfoComplete', this.selectRecords[0].driverId]);
    } else if (this.selectRecords.length === 0) {
      this.modalService.warning({
        nzTitle: '警告信息：',
        nzContent: `<p>请选择一个绑定项！</p>`
      });
    } else  {
      this.modalService.warning({
        nzTitle: '警告信息：',
        nzContent: `<p>只能选择一个绑定项！</p>`
      });
    }
  }

  checkDriverDetail(data){
    this.driverManagementService.getUnreviewedDriverModel(data.driverId).then( (res:any) =>{
      if (!res.isError) {
        this.showDriverDetailModal(res);
      }
    });
   
  }

  showDriverDetailModal(data){
    const modal = this.modalService.create({
      nzTitle: '司机详情',
      nzContent: DetailInfoComponent,
      nzMaskClosable: false,
      nzClosable: true,
      nzWidth: 1350,
      nzComponentParams: {
        type: 'unreviewdDriver',
        infoModel: data
        
      },
      nzFooter:  null
    });
  }
}
