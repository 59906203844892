import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';
import { FetchService } from '../../core/services/fetch.service';
import { REQUEST } from '../../core/config/linkMap';
import { ETC_COND } from '../../core/config/pageConfig';
import { Condition, EtcTableRecord, PreCharge } from '../../shared/model/carModels';
import { getCond } from '../../shared/utils/adapter';
import { AuthenticationService } from '../../core/services/authentication.service';

@Component({
  selector: 'app-etc-record',
  templateUrl: './etc-record.component.html',
  styleUrls: ['./etc-record.component.less']
})
export class EtcRecordComponent implements OnInit {

  pageIndex = 1;
  pageSize = 5;
  total = 1;
  loading = false;

  searchMoreClassName = 'super-search';
  etcCardNumClassName = 'etc-card-modal';
  preChargeClassName = 'pre-charge-modal';
  revertClassName = 'revert-modal';
  isSearchMoreVisible = false;
  isOkLoading = false;
  isEtcCardTableVisible = false;
  isPreChargeVisible = false;
  isRevertVisible = false;

  etcCardModal: NzModalRef;
  preChargeModal: NzModalRef;
  payType = 'etc';
  curStep: number;
  cardNum: string;
  condition: Condition[];
  curRecord: EtcTableRecord;

  dataSource: any[];
  etcCodeValue:string;
  private superSearchParams:any;

  constructor(private fetchService: FetchService,private authService: AuthenticationService,) {
    this.dataSource = [];
    this.condition = JSON.parse(JSON.stringify(ETC_COND));

  }

  ngOnInit() {
    /* this.dataSource = ETC_RECORD; */
    this.searchData();
  }

  /*  高级搜索  */
  superSearch() {
    const etcNum = getCond(this.condition, 'etcNum');
    const driverName = getCond(this.condition, 'driverName');
    const mobile = getCond(this.condition, 'mobile');
    const plateNum = getCond(this.condition, 'plateNum');
    const params = {};
    if (etcNum.value) {
      params['etcNum'] = etcNum.value;
    }
    if (driverName.value) {
      params['driverName'] = driverName.value;
    }
    if (mobile.value) {
      params['mobile'] = mobile.value;
    }
    if (plateNum.value) {
      params['plateNum'] = plateNum.value;
    }
    this.superSearchParams = params;
    this.pageIndex = 1; // 每次查询都要重置pageIndex
    this.searchNow(params).then(() => {
      this.isOkLoading = false;
      this.isSearchMoreVisible = false;
    });
  }

  searchData(reset: boolean = false): void {
    if (reset) {
      this.pageIndex = 1;
    }
    this.searchNow(this.superSearchParams)
  }

  searchNow(params = {}, url = REQUEST.ETC.ALL) {
    params['company_id'] = this.authService.profile['companyId'];
    params['page'] = this.pageIndex;
    params['per_page'] = this.pageSize;
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.dataSource = [];
      this.fetchService.get(url, params, (res: any) => {
        this.loading = false;
        if (res._embedded) {
          this.dataSource = res._embedded.etcResourceList || [];
        }
        if (res.page) {
          this.total = res.page.totalElements;
        }
        resolve();
      });
    });
  }

  clear() {
    this.etcCodeValue = null;
    this.superSearchParams = {};
    this.condition = JSON.parse(JSON.stringify(ETC_COND));
    this.searchData(true);
  }
  /*  ETC卡号事件  */
  etcNumClick(row: EtcTableRecord) {
    this.curRecord = row;
    this.isEtcCardTableVisible = true;
  }

  /*  预充事件  */
  preChargeClick(row: EtcTableRecord) {
    this.curRecord = row;
    this.cardNum = this.curRecord.etcCardNum;
    this.isPreChargeVisible = true;
  }

  /*  回收事件 */
  revertClick(row: EtcTableRecord) {
    this.isRevertVisible = true;
    this.curRecord = row;
  }

  /*  确认回收 */
  revertNow() {
    this.isRevertVisible = false;
  }

  revertChargeSubmit() {
  }
}



