import { Component, OnInit } from '@angular/core';
import { REQUEST } from '../../core/config/linkMap';
import { NzModalService } from 'ng-zorro-antd';
import { Router } from '@angular/router';
import { FetchService } from '../../core/services/fetch.service';
import { AuthenticationService } from '../../core/services/authentication.service';
import { getCond } from '../../shared/utils/adapter';
import { DRIVER_MANAGE_INFO } from '../../core/config/pageConfig';
import { Condition } from '../../shared/model/carModels';
import { TableColumnService } from 'src/app/core/services/table-column.service';
import { BaseTableColumn, BaseTableRow, BaseTableData } from 'src/app/shared/components/base-table/interface';
import { measureScrollX } from 'src/app/shared/utils/measure-scrollbar';
import { DetailInfoComponent } from 'src/app/shared/components/detail-info/detail-info.component';
import { DriverManagementService } from './service/driver-management.service';

@Component({
  selector: 'app-driver-management',
  templateUrl: './driver-management.component.html',
  styleUrls: ['./driver-management.component.less']
})
export class DriverManagementComponent implements OnInit {
  
  pageIndex = 1;
  pageSize = 5;
  total = 1;
  loading = false;

  allChecked = false;
  indeterminate = false;

  isVisible = false;
  isOkLoading = false;
  driverPhone = '';

  condition: Condition[];
  dataSource: BaseTableData;
  selectRecords: BaseTableRow[] = [];
  driverPhoneOptions = [];

  className = 'super-search';
  modalType: string;
  private superSearchParams: any;
  columns: BaseTableColumn[] = [];
  scrollX: string;
  queryParams: { [key: string]: any } = {};
  
  constructor(
    private router: Router,
    private modalService: NzModalService,
    private authService: AuthenticationService,
    private fetchService: FetchService,
    private tableColumn: TableColumnService,
    private driverManagementService: DriverManagementService
    ) {
    this.condition = JSON.parse(JSON.stringify(DRIVER_MANAGE_INFO));
    this.columns = this.tableColumn.getReviewDriverColumns();
  }

  ngOnInit() {
    this.selectRecords = [];
    this.scrollX = measureScrollX(this.columns);
  }

  searchData(reset: boolean = false): void {
    if (reset) {
      this.pageIndex = 1;
    }
    this.searchNow(this.superSearchParams);
  }

  // 用户输入完成
  onInput() {
    if (this.driverPhone) {
      const params = {mobile: this.driverPhone};
      const reqUrl = REQUEST.DRIVER.RETRIEVAL;
      this.fetchService.get(reqUrl, params, (res: any) => {
        this.driverPhoneOptions = res;
      });
    } else {
      this.searchData(true);
    }
  }

  // 下拉选择完成
  selectChange() {
    this.superSearchParams = {mobile: this.driverPhone};
    this.searchNow({mobile: this.driverPhone});
  }

  /* 高级搜索 */
  superSearch() {
    const name = getCond(this.condition, 'name');
    const mobile = getCond(this.condition, 'mobile');
    const driverLicence = getCond(this.condition, 'driverLicence');
    const licenseType = getCond(this.condition, 'licenseType');
    const params = {};
    if (name.value) {
      params['name'] = name.value;
    }
    if (mobile.value) {
      params['mobile'] = mobile.value;
    }
    if (driverLicence.value) {
      params['driver_licence'] = driverLicence.value;
    }
    if (licenseType.value) {
      params['license_type'] = licenseType.value;
    }
    this.superSearchParams = params;
    this.searchNow(params).then(() => {
      this.isOkLoading = false;
      this.isVisible = false;
    });
  }

  /* 请求调用 */
  searchNow(params = {}) {
    /* 增加分页参数 */
    params['company_id'] = this.authService.profile['companyId'];
    params['page'] = this.pageIndex;
    params['per_page'] = this.pageSize;
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.dataSource = { data: [], totalSize: 0 };
      this.fetchService.get(REQUEST.DRIVER.ALL_DRIVERS, params, (res) => {
        this.loading = false;
        if (res._embedded) {
          this.dataSource = {
            data: res._embedded.driverResourceList || [],
            totalSize: res.page.totalElements
          };
        }
        if (res.page) {
          this.total = res.page.totalElements;
        }
        resolve();
      });
    });
  }

  clear() {
    this.driverPhone = '';
    this.superSearchParams = {};
    this.condition = JSON.parse(JSON.stringify(DRIVER_MANAGE_INFO));
    this.searchData(true);
  }

  /* 司机编辑 */
  edit() {
    if (this.selectRecords.length === 1) {
      this.router.navigate(['/driverManagement/auditList/driverInfoEdit', this.selectRecords[0].driverId]);
    } else if (this.selectRecords.length === 0) {
      this.modalService.warning({
        nzTitle: '警告信息：',
        nzContent: `<p>请选择一个编辑项！</p>`
      });
    } else  {
      this.modalService.warning({
        nzTitle: '警告信息：',
        nzContent: `<p>只能选择一个编辑项！</p>`
      });
    }
  }


  linkClick(data){
    this.driverManagementService.getReviewedDriverModel(data.rowData.driverId).then( (res:any) =>{
      if (!res.isError) {
        this.showDriverDetailModal(res);
      }
    });
   
  }

  showDriverDetailModal(data){
    const modal = this.modalService.create({
      nzTitle: '司机详情',
      nzContent: DetailInfoComponent,
      nzMaskClosable: false,
      nzClosable: true,
      nzWidth: 1350,
      nzComponentParams: {
        type: 'reviewedDriver',
        infoModel: data
        
      },
      nzFooter:  null
    });
  }
}
