import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FetchService } from '../../core/services/fetch.service';
import { AuthenticationService } from '../../core/services/authentication.service';
import { NzMessageService, NzModalRef, NzModalService } from 'ng-zorro-antd';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Validation } from '../../shared/utils/validation';
import { convert, dateFormat, getCond } from '../../shared/utils/adapter';
import { Condition, PreTableRecord, Base } from '../../shared/model/carModels';
import { PRE_DIST_COND_CREATE } from '../../core/config/pageConfig';
import { REQUEST } from '../../core/config/linkMap';
import { TableColumnService } from 'src/app/core/services/table-column.service';
import { UtilService } from 'src/app/core/services/util.service';
import { BaseTableData, BaseTableColumn, BaseTableRow } from 'src/app/shared/components/base-table/interface';
import { BaseTableComponent } from 'src/app/shared/components/base-table/base-table.component';
import { DistManagementService } from '../dist-management/service/dist-management.service';
import { ArrayService } from 'src/app/core/services/array.service';

@Component({
  selector: 'app-pre-dist-create',
  templateUrl: './pre-dist-create.component.html',
  styleUrls: ['./pre-dist-create.component.less']
})
export class PreDistCreateComponent implements OnInit {

  pageIndex = 1;
  pageSize = 5;
  total = 1;
  loading = false;

  allChecked = false;
  indeterminate = false;

  isVisible = false;
  isWaybillVisible = false;

  consignees = [];
  orderId = '';
  orderCode = '';
  carId = '';
  version: number;
  mode = 'create';
  dispatchMode = 'create';
  did = ''; // 拉运车辆派车单id: for edit
  carFiltration = [];
  curDispatchcar: any;
  className = 'car-choose-modal';
  waybillClassName = 'modal-panel waybill-panel';

  errorMsg: string[];
  condition: Condition[];
  dataSource = [];
  predispatchRes: any;
  orderType: string;
  tempCode: string;

  @ViewChild('industryModal')
  private industryModal: TemplateRef<{}>;
  private tplIndustryModal: NzModalRef;

  // 弹出框表格变量
  industryDataSource: BaseTableData;
  columns: BaseTableColumn[] = [];
  selectIndustrys: BaseTableRow[] = [];
  queryParams: { [key: string]: any } = {};
  @ViewChild('baseTable')
  private baseTable: BaseTableComponent;
  licenseInfoList: Array<{ key: string, value: string }>;
  haspublish = false;

  constructor(
    private fetchService: FetchService,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NzModalService,
    private util: UtilService,
    private tableColumn: TableColumnService,
    private message: NzMessageService,
    private arrayService: ArrayService,
    private distManagementService: DistManagementService) {
    this.columns = this.tableColumn.getIndustryColumns();
    this.licenseInfoList = this.distManagementService.getLicenseInfo;
  }

  ngOnInit() {
    this.condition = JSON.parse(JSON.stringify(PRE_DIST_COND_CREATE));
    this.getConsignees();
    this.getorderTypeSync();
    this.getCargoTypeDic();
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.tempCode = params.get('id');
      if (this.tempCode) {
        this.orderId = this.tempCode.split('_')[0];
        this.orderCode = this.tempCode.split('_')[1];
        // 编辑按钮进入--> 获取当前订单详情
        this.mode = 'edit';
        this.fetchService.get(REQUEST.PRE_DIST.ALL + '/' + this.orderId, {}, (res) => {
          this.predispatchRes = res;
          // 条件值预填充
          this.condition.forEach((field) => {
            const key = field.key;
            if (field.type === 1 && field.unit && key === 'cargoNum') {
              field.unit.value = res[field.unit.key];
              field.value = res[key];
            } else if (field.type === 4 && res[key]) {
              field.value = new Date(res[key]);
            } else if (field.type === 10 && res[key]) {
              const labelArr = [];
              const parts = res[key + '_'].split('_');
              parts.forEach((part: string) => {
                if (part && part.indexOf('+') > -1) {
                  labelArr.push(part.split('+')[1]);
                }
              });
              field.value = parts;
            } else if (key === 'codename') {
              field.value = res['codename'];
              field.click = this.showIndustryModal;
              // } else if (key === 'cargoType') {
              //   if(res[key] > 900){
              //     field.value = '' + res[key].toString();
              //   } else {
              //     field.value = '0' + res[key].toString();
              //   }
            } else {
              if (key === 'sync') {
                this.resetAddress(res['sync'], false);
              }
              field.value = res[key] !== undefined ? res[key] + '' : null;
            }
          });
          // 获取编辑转台下的当前订单所有的拉运车辆
          this.searchNow({ orderId: this.orderId }, REQUEST.PRE_DIST.PULL_VERHICAL);
        });
      } else {
        // 新建按钮进入--> 展示新建订单页面
        this.mode = 'create';
        this.condition.forEach((field) => {
          if (field.key === 'codename') {
            field.click = this.showIndustryModal;
          }
        });
        this.fetchService.post(REQUEST.PRE_DIST.ALL + '?companyId=' + this.authService.profile['companyId'], {}, (res) => {
          if (!res.isError) {
            this.orderId = res.oId;
            this.orderCode = res.orderCode;
            this.searchNow({ orderId: this.orderId }, REQUEST.PRE_DIST.PULL_VERHICAL);
          }
        });
      }
    });
  }

  // 获取货物类型字典
  getCargoTypeDic() {
    const option = this.util.switchDic('0016');
    this.condition.map((item, index) => {
      if (item.key === 'cargoType') {
        this.condition[index].option = option;
      }
    });
  }

  // 获取收发货人信息
  getConsignees(params = {}) {
    params['companyId'] = this.authService.profile['companyId'];
    const option = [];
    this.fetchService.get(REQUEST.PARTNER.ALLPARTNER, params, (res) => {
      if (res) {
        this.consignees = res || [];
        this.consignees.map(item => {
          const info = new Base();
          info.name = item.connector;
          info.value = item.id;
          option.push(info);
        });
      }
      this.condition.map((item, index) => {
        if (item.key === 'anlianConsigneeId' || item.key === 'anlianConsignerId') {
          this.condition[index].option = option;
        }
      });
    });
  }

  //获取订单类型
  getorderTypeSync() {
    const option = this.util.switchDic('0010');
    this.condition.map((item, index) => {
      if (item.key === 'sync') {
        item.option = option;
        item.change = (value) => {
          if (this.tempCode && this.predispatchRes.sync !== value) {
            let val = this.predispatchRes.sync;
            this.util.submitConfirm('切换订单类型', '吗？切换订单类型可能导致派车单无效', item, val).then(() => {
              this.message.success('已切换订单类型');
              this.resetAddress(value, true);
            });
          } else {
            this.resetAddress(value, true);
          }
        }
      }
    });
  }

  //重置地址
  resetAddress(val: string, bol: boolean) {
    const url = this.util.getUrl(val);
    this.util.getArea(url, {}).then((area) => {
      this.arrayService.visitTree(area, item => {
        if (!item.children) {
          item.isLeaf = true;
        }
      });
      this.condition.map((item1, index1) => {
        if (item1.key == 'cargoAddress' || item1.key == 'deliveryAddress') {
          if (bol) {
            this.condition[index1].value = '';
          }
          console.log(area)
          this.condition[index1].option = area;
        }
      });
    });
  }

  searchData(reset: boolean = false): void {
    if (reset) {
      this.pageIndex = 1;
    }
    this.searchNow({ orderId: this.orderId }, REQUEST.PRE_DIST.PULL_VERHICAL);
  }

  searchNow(params = {}, url: string) {
    params['page'] = this.pageIndex;
    params['per_page'] = this.pageSize;
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.dataSource = [];
      this.total = 0;
      this.fetchService.get(url, params, (res: any) => {
        this.loading = false;
        if (res._embedded) {
          this.dataSource = res._embedded.predispatchResourceList || [];
        }
        if (res.page) {
          this.total = res.page.totalElements;
        }
        if (this.dataSource && this.dataSource.length) {
          this.carFiltration = [];
          this.dataSource.map((source) => {
            this.carFiltration.push(source.carId);
          });
        }
        resolve();
      });
    });
  }

  /* 新建派车单 */
  createDispatchCar(data) {
    this.publishOrder(true);
    let timer = setTimeout(() => {
      if (this.haspublish) {
        this.did = '';
        this.dispatchMode = 'create';
        this.curDispatchcar = data;
        this.isWaybillVisible = true;
        this.carId = data.carId;
        this.version = data.version;
        this.haspublish = false;
      }
      clearTimeout(timer);
    }, 500)
  }

  /* 派车单完成之后需要刷新派车单列表 */
  dispatchFillDone() {
    this.isWaybillVisible = false;
    this.searchData();
  }

  /* 编辑派车单 */
  editDispatchCar(data) {
    this.publishOrder(true);
    let timer = setTimeout(() => {
      if (this.haspublish) {
        this.did = '';
        if (data.dispatchCarId) {
          this.dispatchMode = 'edit';
          this.curDispatchcar = data;
          this.isWaybillVisible = true;
          this.carId = data.carId;
          this.did = data.dispatchCarId;
          this.haspublish = false;
        }
      }
      clearTimeout(timer);
    }, 500)
  }

  showIndustryModal = () => {
    this.tplIndustryModal = this.modalService.create({
      nzTitle: '货物所属行业列表',
      nzContent: this.industryModal,
      nzMaskClosable: true,
      nzClosable: true,
      nzWidth: 1200,
      nzFooter: null
    })
  }

  selectIndustry() {
    if (this.selectIndustrys.length !== 1) {
      this.message.warning('请选择一条行业记录！');
      return;
    }
    const industry = this.selectIndustrys[0];
    this.condition.map((item: Condition) => {
      if (item.key === 'codename') {
        item.value = industry.codename;
      }
      if (item.key === 'hwsshy') {
        item.value = industry.code;
      }
    });
    this.tplIndustryModal.close();
  }


  /* 请求调用 */
  industrySearchNow(params: object) {
    /* 增加分页参数 */
    params['company_id'] = this.authService.profile['companyId'];
    params['per_page'] = params['size'];
    this.loading = true;
    this.industryDataSource = { data: [], totalSize: 0 };
    this.fetchService.get(REQUEST.PRE_DIST.INDUSTRY, params, (res) => {
      this.loading = false;
      if (res._embedded) {
        this.industryDataSource = {
          data: res._embedded.industryCodeList || [],
          totalSize: res.page.totalElements
        }
      }
    });
  }

  searchIndustry(value: any) {
    this.queryParams = { name: value };
  }

  /* 发布订单 */
  publishOrder(choseCar: boolean = false) {
    this.errorMsg = Validation(this.condition);
    if (this.errorMsg.length > 0 && choseCar && !this.haspublish) {
      return this.message.create('error', '请先完整填写订单信息！');
    }
    if (!this.errorMsg.length) {
      dateFormat(this.condition);
      const params = convert(this.condition);
      if (!params['unit']) {
        return this.message.create('error', '请选择单位!');
      }
      if (params['cargoAddress']) {
        const province = params['cargoAddress'][0].split('+')[1];
        const city = params['cargoAddress'][1].split('+')[1];
        if (!!params['cargoAddress'][2]) {
          const county = params['cargoAddress'][2].split('+')[1];
          params['pickupCountrySubdivisionCode'] = params['cargoAddress'][2].split('+')[0];
          params['cargoAddress'] = province + city + county;
        } else {
          params['pickupCountrySubdivisionCode'] = params['cargoAddress'][1].split('+')[0];
          params['cargoAddress'] = province + city;
        }
      }
      if (params['deliveryAddress']) {
        const province = params['deliveryAddress'][0].split('+')[1];
        const city = params['deliveryAddress'][1].split('+')[1];
        if (!!params['deliveryAddress'][2]) {
          const county = params['deliveryAddress'][2].split('+')[1];
          params['unloadCountrySubdivisionCode'] = params['deliveryAddress'][2].split('+')[0];
          params['deliveryAddress'] = province + city + county;
        } else {
          params['unloadCountrySubdivisionCode'] = params['deliveryAddress'][1].split('+')[0];
          params['deliveryAddress'] = province + city;
        }
      }
      // 预计卸货时间和提货最晚时间判断
      // 今天此时此刻 <= 提货最晚时间 <= 预计卸货时间
      const today = new Date()['Format']('yyyy-MM-dd hh:mm:ss');
      const pickupEndTime = params['pickupEndTime'];
      const unloadEndTime = params['unloadEndTime'];
      if (unloadEndTime < today) {
        return this.message.create('warning', '预计卸货时间已经过期，请重新选择');
      }
      if (pickupEndTime < today) {
        return this.message.create('warning', '提货最晚时间已经过期，请重新选择');
      }
      if (unloadEndTime < pickupEndTime) {
        return this.message.create('warning', '预计卸货时间不能比提货最晚时间早，请重新选择');
      }
      params['fdsj'] = today;
      params['companyId'] = this.authService.profile['companyId'];
      params['consignerId'] = params['anlianConsignerId'];
      // params['consignor'] = params['anlianConsignerId'];
      params['consignee'] = params['anlianConsigneeId'];
      params['orderCode'] = this.orderCode;
      params['oId'] = this.orderId;
      params['sync'] = '00554';
      // console.log(params);
      this.fetchService.put(REQUEST.ORDER.ALL, params, (res: any) => {
        if (!res.isError) {
          this.message.create('success', '预派车单发布成功！');
          this.haspublish = true;
          if (choseCar === false) {
            this.router.navigate(['/orderRecord/preDistManagement']);
          }
        }
      });
    }
  }

  /* 完成订单 */
  finishOrder() {
    if (this.mode === 'create') {
      this.message.create('warning', '请先发布订单！');
    } else {
      if (!this.dataSource || !this.dataSource.length) {
        return this.message.create('warning', '请先选择司机并填写相关运单信息！');
      }
      this.fetchService.put(REQUEST.PRE_DIST.ALL + '/' + this.orderId + '/state/2', {}, (res: any) => {
        if (!res || !res.isError) {
          this.message.create('success', '完成订单成功！');
          this.router.navigate(['/orderRecord/preDistManagement']);
        }
      });
    }
  }

  /* 删除派车单 */
  delDispatchCar(data) {
    this.fetchService.delete(REQUEST.PRE_DIST.GET_DIST_DETAIL + data.pid, {}, (res: any) => {
      const index = this.carFiltration.indexOf(data.carId);
      if (index > -1) {
        this.carFiltration.splice(index, 1);
      }
      if (!res || !res.isError) {
        this.message.create('success', '派车单删除成功！');
        this.searchData();
      }
    });
  }

  currentPageDataChange($event: Array<{ name: string; age: number; address: string; checked: boolean; disabled: boolean; }>): void {
    this.dataSource = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    const allChecked = this.dataSource.every(value => value.checked === true);
    const allUnChecked = this.dataSource.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  checkAll(value: boolean): void {
    this.dataSource.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }
}
