import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Condition, Role} from '../../shared/model/carModels';
import {NzMessageService, NzModalRef, NzModalService, NzTreeComponent, NzTreeNode} from 'ng-zorro-antd';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../core/services/authentication.service';
import {FetchService} from '../../core/services/fetch.service';
import {RoleManagementService} from './service/role-management.service';
import {REQUEST} from '../../core/config/linkMap';
import {convert} from '../../shared/utils/adapter';
import {Validation} from '../../shared/utils/validation';
import {ArrayService} from '../../core/services/array.service';


@Component({
  selector: 'app-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.less']
})
export class RoleManagementComponent implements OnInit {
  pageIndex = 1;
  pageSize = 5;
  total = 0;
  loading = false;
  allChecked = false;
  indeterminate = false;
  dataSource: Role[];
  selectRecords = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  roleForm: Condition[];
  errorMsg: string[];
  private tplModal: NzModalRef;
  @ViewChild('roleEditor')
  private roleEditor: TemplateRef<{}>;
  @ViewChild('authEditor')
  private authEditor: TemplateRef<{}>;
  @ViewChild('tree')
  private tree: NzTreeComponent;
  defaultCheckedKeys: string[];
  nodes: NzTreeNode[] = null;
  searchValue: string;
  roleName: string;
  searchParams: any;
  constructor(
    private router: Router,
    private modalService: NzModalService,
    private authService: AuthenticationService,
    private fetchService: FetchService,
    private message: NzMessageService,
    private arrayService: ArrayService,
    private roleManagementService: RoleManagementService
  ) {
    this.dataSource = [];
    this.selectRecords = [];
    this.getAllOperations();
  }

  ngOnInit() {
    this.searchData();
  }

  searchData(reset: boolean = false): void {
    if (reset) {
      this.pageIndex = 1;
    }
    this.searchNow();
  }

  /* 请求调用 */
  searchNow(params = {}) {
    /* 增加分页参数 */
    params['role_name'] = this.searchParams;
    params['company_id'] = this.authService.profile['companyId'];
    params['page'] = this.pageIndex;
    params['per_page'] = this.pageSize;
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.dataSource = [];
      this.fetchService.get(REQUEST.ROLE.ALL, params, (res) => {
        this.loading = false;
        if (res._embedded) {
          this.dataSource = res._embedded.roleResourceList || [];
        }
        if (res.page) {
          this.total = res.page.totalElements;
        }
        resolve();
      });
    });
  }

  currentPageDataChange(data: any[]): void {
    this.refreshStatus();
  }

  refreshStatus(): void {
    /* 选择数据同步 */
    this.selectRecords = this.dataSource.filter(item => this.mapOfCheckedId[item.roleId]);
    this.allChecked = this.dataSource.every(item => this.mapOfCheckedId[item.roleId]);
    this.indeterminate = this.dataSource.some(item => this.mapOfCheckedId[item.roleId]) && !this.allChecked;
  }

  checkAll(value: boolean): void {
    this.dataSource.forEach(item => this.mapOfCheckedId[item.roleId] = value);
    this.refreshStatus();
  }

  /**
   * 角色编辑modal
   * @param model
   * @param cb
   */
  roleEditorForm(model: Role, cb: (form: any) => void) {
    this.roleForm = JSON.parse(JSON.stringify(this.roleManagementService.getRoleForm(model)));
    this.tplModal = this.modalService.create({
      nzTitle: !!model.roleId ? '编辑角色' : '新增角色',
      nzContent: this.roleEditor,
      nzMaskClosable: false,
      nzClosable: true,
      nzFooter: [
        {
          label: '取消',
          onClick: () => this.tplModal.destroy()
        },
        {
          label: '确定',
          type: 'primary',
          onClick: com => {
            cb(convert(this.roleForm));
          }
        }
      ]
    });
  }

  submit(role: Role, form: any) {
    this.errorMsg = Validation(this.roleForm);
    if (this.errorMsg.length > 0) {
      this.errorMsg.forEach(item => this.message.warning(item));
      return;
    }
    const body = Object.assign(form, {
      companyId: this.authService.profile['companyId'],
      createUserId: this.authService.profile['userId'],
    });
    let message = '';
    if (role.roleId) {
      this.fetchService.patch(`${REQUEST.ROLE.ALL}/${role.roleId}`, body, (res: any) => {
        if (!res.isError) {
          message = '编辑角色成功';
          this.submitRefresh(message);
        }
      });
    } else {
      this.fetchService.post(`${REQUEST.ROLE.ALL}`, body, (res: any) => {
        if (!res.isError) {
          message = '新增角色成功';
          this.submitRefresh(message);
        }
      });
    }
  }

  submitRefresh(message: string) {
    this.message.success(message);
    this.tplModal.destroy();
    this.searchData();
  }

  create() {
    const role: Role = new Role();
    this.roleEditorForm(role, ((form: any) => this.submit(role, form)));
  }

  update() {
    if (this.selectRecords.length !== 1) {
      this.message.warning('请选择一条记录编辑！');
      return;
    }
    const role: Role = this.selectRecords[0];
    this.roleEditorForm(role, ((form: any) => this.submit(role, form)));
  }

  remove() {
    if (this.selectRecords.length <= 0) {
      this.message.warning('请选择要删除的记录！');
      return;
    }
    const ids = this.selectRecords.map(item => item.roleId);
    this.fetchService.delete(`${REQUEST.ROLE.ALL}/${ids}`, null, (res: any) => {
      if (!res || !res.isError) {
        this.message.success('删除角色成功！');
        this.searchData();
      }
    });
  }

  /**
   * 角色授权
   * @param role
   */
  auth(role: Role): void {
    this.roleName = role.roleName;
    this.fetchService.get(`${REQUEST.ROLE.ALL}/${role.roleId}/operations`, null, (data: any) => {
      if (!data.isError) {
        const array = !!data._embedded ? data._embedded.operationIdsResourceList : [];
        this.defaultCheckedKeys = array.length > 0 ? array[0].operIds : [];
        const modal: NzModalRef = this.modalService.create({
          nzTitle: '角色授权',
          nzContent: this.authEditor,
          nzMaskClosable: false,
          nzClosable: true,
          nzFooter: [
            {
              label: '取消',
              onClick: () => modal.destroy()
            },
            {
              label: '确定',
              type: 'primary',
              onClick: com => {
                const checkedKeys = this.arrayService.getKeysByTreeNode(this.tree.getTreeNodes(), {
                  includeHalfChecked: false
                }).map(item => {
                  return {
                    appId: 20,
                    operIds: [item]
                  };
                });
                if (checkedKeys.length <= 0) {
                  this.message.warning('请至少选择一个权限！');
                  return;
                }
                this.fetchService.put(`${REQUEST.ROLE.ALL}/${role.roleId}/operations`, checkedKeys, (res: any) => {
                  if (!res.isError) {
                    this.message.success('角色分配权限成功！');
                    modal.close();
                    this.searchData();
                  }
                });
              }
            }
          ]
        });
      }
    });
  }

  /**
   * 获取所有物流公司可使用的权限
   */
  getAllOperations(): void {
    this.fetchService.get(`${REQUEST.ROLE.OPERATIONS}`, null, (res: any) => {
      if (!res.isError) {
        const array = !!res._embedded ? res._embedded.operationResourceList : [];
        this.nodes = this.arrayService.arrToTreeNode(array.filter(item => item.appId === 20), {
          idMapName: 'operId',
          titleMapName: 'operName',
          parentIdMapName: 'parentOperId'
        });
      }
    });
  }

}
