import { Component, Input, Renderer2 } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
// import { format } from '@delon/util';
import { REQUEST } from '../../../core/config/linkMap';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'detail-list',
  templateUrl: './detail-list.component.html',
  styleUrls: [ './detail-list.component.less' ]
})
export class DetailListComponent {
  @Input() title: string;
  @Input() lists: Array<{key: string, value: string, span?: number}>;
  @Input() model: any;
  @Input() type: 'string' | 'image';
  @Input() rendererInfo: (e) => void = null; // 支持信息自定义渲染
  @Input() imageType: 'url' | 'base64' = 'url';
  baseUrl: string;
  previewVisible = false;
  previewImageUrl: string;
  previewImageTitle: string;
  tooltipTitle = '点击查看原图';

  constructor(
    private msg: NzMessageService,
    private renderer: Renderer2
  ) {
    this.baseUrl = REQUEST.DISPLAY_PIC_BASE;
  }

  /**
   * 根据key获取信息
   * @param key
   */
  getInfoByKey(key: string): string {
    if (key.indexOf('.') !== -1) {
      // return format('${' + key + '}', this.model, true);
    }
    if (String(this.model[key]).indexOf('\n') !== -1) {
      return this.model[key].replace(/\n/g, '<br />');
    }
    return !!this.rendererInfo ? this.rendererInfo(key) : this.model[key];
  }

  /**
   * 查看原图
   * @param uri
   * @param title
   * @param e
   */
  showPic(e, uri, title) {
    const src = e.target.src;
    if (src.indexOf('error.png') === -1) {
      this.previewImageUrl = this.imageType === 'url' ? this.baseUrl + uri : uri;
      this.previewImageTitle = title;
      this.previewVisible = true;
    } else {
      this.msg.error('图片未上传或已丢失！');
    }
  }

  showError(e) {
    this.renderer.setAttribute(e.target, 'src', 'assets/img/error.png');
  }

  showTitle(e) {
    const src = e.target.src;
    if (src.indexOf('error.png') === -1) {
      this.tooltipTitle = '点击查看原图';
    } else {
      this.tooltipTitle = '图片未上传或已丢失！';
    }
  }
}
