import {APP_INITIALIZER, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N, zh_CN } from 'ng-zorro-antd';
import { AppComponent } from './app.component';

/** 配置 angular i18n **/
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import {HttpClientModule} from '@angular/common/http';
import {StartupService, StartupServiceFactory} from './core/services/startup.service';
import {OAuthModule, OAuthStorage} from 'angular-oauth2-oidc';
import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';
import {LayoutModule} from './layout/layout.module';
import {PagesModule} from './pages/pages.module';
import {NgxPermissionsModule} from 'ngx-permissions';
import {AbmModule} from 'angular-baidu-maps';

registerLocaleData(zh);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    LayoutModule,
    PagesModule,
    NgxPermissionsModule.forRoot(),
    OAuthModule.forRoot(),
    AbmModule.forRoot({
      apiKey: 'E06eb9d756d0eafc722effb355657b4c'
    })
  ],
  providers: [
    StartupService,
    {
      provide: APP_INITIALIZER,
      useFactory: StartupServiceFactory,
      deps: [StartupService],
      multi: true
    },
    { provide: NZ_I18N, useValue: zh_CN },
    { provide: OAuthStorage, useValue: sessionStorage }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
