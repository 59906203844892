import { Injectable } from '@angular/core';
import { NzNotificationService, NzMessageService, NzModalService } from 'ng-zorro-antd';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { delay, retryWhen, takeUntil } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { FetchService } from './fetch.service';
import { REQUEST } from '../config/linkMap';
import { Message } from '../../shared/model/carModels';
import { NotifyPushService } from './push.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private destroy$ = new Subject();
  private ws: WebSocketSubject<any>;
  private count$ = new BehaviorSubject<number>(null);
  private open$ = new Subject();
  hassPushMsg = false;

  constructor(
    private notification: NzNotificationService,
    private oAuthService: OAuthService,
    private http: FetchService,
    private _nps: NotifyPushService,
    private authService: AuthenticationService,
    private message: NzMessageService,
    private modalService: NzModalService,
  ) {
  }

  receive(): void {
    this.ws = webSocket({
      url: `${REQUEST.WSS}/topic/Bearer ${this.oAuthService.getAccessToken()}`,
      openObserver: this.open$
    });
    this.ws.pipe(
      retryWhen(errors => errors.pipe(delay(1000))),
      takeUntil(this.destroy$)
    ).subscribe((data: Message) => {
      console.log(data);
      if (!!data) {
        if (data.messageType.code === '501' || data.messageType.code === '502') {
          if (!this.hassPushMsg) {
            this.notification.warning(data.messageType.title, data.messageType.desc);
            this.modalService.confirm({
              nzTitle: data.messageType.title,
              nzContent: data.messageType.desc,
              nzOkText: '去登录',
              nzOkType: 'danger',
              nzOnOk: () => {
                this.authService.logout(true);
                this.close();
                return;
              },
              nzOnCancel: () => {
                this.authService.logout(true);
                this.close();
                return;
              }
            });
            this.hassPushMsg = true;
          }
        } else {
          this.notification.warning(data.messageType.title, data.messageType.desc);
          this.getCount();
        }
        // 如果浏览器支持桌面通知并且用户允许通知
        if (this._nps.isSupported() && this._nps.permission === 'granted') {
          this._nps.create(data.messageType.title, {
            body: data.messageType.desc,
            icon: 'assets/img/logo.png',
            data: data
          }).subscribe(res => console.log(res));
        }
      }
    });
    this.open$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(data => {
      this.getCount();
    });
  }

  close(): void {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.ws) {
      this.ws.unsubscribe();
    }
  }

  get count(): Observable<number> {
    return this.count$.asObservable();
  }

  getCount(): void {
    this.http.get(`${REQUEST.MESSAGE.COUNT}`, {}, (res: any) => {
      if (!res.isError) {
        this.count$.next(res.data);
      }
    });
  }
}
