import { Injectable, TemplateRef } from '@angular/core';
import { FetchService } from '../../../core/services/fetch.service';
import { Condition, Insurance } from '../../../shared/model/carModels';
import { ZJCargoType } from '../../../core/config/dropdown.conf';
import { REQUEST } from '../../../core/config/linkMap';
import { RendererService } from '../../../core/services/renderer.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class ContractVertifyService {

  constructor(
    private fetchService: FetchService,
    private renderer: RendererService,
  ) { }
  
  getCodeForm(afterTem: TemplateRef<void>,mobile:any): Condition[] {
    return [
      {
        type: 1,
        label: '请输入手机号',
        value: mobile,
        key: 'mobile',
        required: true,
        rules: ['isPhone']
      },
      {
        type: 20,
        label: '验证码',
        value: null,
        key: 'code',
        after: afterTem ,
        required: true,
      }
    ]
  }
}
