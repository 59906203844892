/*  所有请求的URL全部在此处配置 */
import {environment} from '../../../environments/environment';

export const BASE = environment.baseUrl;
// export const BASE = 'http://192.168.0.111:18189';

export const REQUEST = {
  LOGOUT:'/uaa/logout',
  DISPLAY_PIC_BASE: environment.baseUrl + '/file?file=',
  FILE_UPLOAD_IMG:  environment.baseUrl + '/v1/files',
  EXPORT: environment.baseUrl + '/file?file=',
  WSS: environment.wss,
  DIC: '/v1/dict/type', // 获取下拉菜单列表
  CAR: {
    ALL: '/v1/car/manager/car',
    PLATE_NUM_SEARCH: '/v1/car/manager/car/plate_num/',
    SUPER_SEARCH: '/v1/car/manager/cars',
    UNBIND: '/v1/car/manager/company/unbind',
    DELETE_DRIVER: '/v1/car/manager/web/truckstodriver/',
    EDIT: '/v1/car/manager/car/id/',
    UPDATE: '/v1/car/manager/',
    ADD: '/v1/car/manager/car',
    CAR_QUERY: '/v1/car/manager/web/Gcar/',
    CAR_REL_DELETE: '/v1/car/manager/web/truckstotrucks',
    DIC: '/v1/dict/type'
  },
  CAR_HANGUP: {
    ALL: '/v1/car/manager/company/affli',
    OPERATE: '/v1/car/manager/company/',
    CHECK: '/v1/car/manager/company/affli/info',
  },
  DRIVER: {
    ALL_DRIVERS: '/v1/drivers',
    ALL: '/v1/drivers/',
    RETRIEVAL: '/v1/drivers/mobile',
    ADD: '/v1/driverillegals/anlian',
    ALL_ILLEGAL: '/v1/driverillegals',
    UPDATE: '/v1/driverillegals/',
    RETRIEVAL_UNADUIT: '/v1/driverillegals/mobile',
    GET_CAR: '/v1/car/manager/web/driver/',
    SAVE: '/v1/car/manager/web/truckstodriver',
    HNADDRESS:'/v1/area/hn'
  },
  ORDER: {
    ALL: '/v1/orders/',
    RETRIEVAL: '/v1/orders/ordercode',
    RETRIEVAL1: '/v1/dispatchcars/code',
    BATCHEDIT: BASE + '/v1/dispatchcars/import/update',
    BATCHSEARCH: BASE + '/v1/dispatchcars/import/search',
    DOWNLOAD: BASE + '/file?file=2020-08-25/'
  },
  ETC: {
    ALL: '/v1/etcs',
    REVERT: '/v1/etcs/{id}/state/4',
    BATCH: '/v1/etcs/file',
  },
  OIL: {
    ALL: '/v1/oilcards',
    OIL_CARD_PAY: '/v1/offer/order/pay/oil',
    SEARCH_PLATE: '/v1/car/manager/car/plate_num', // 模糊搜索车牌号
    CHARGE:'/v1/oilcards/applyCharge'
  },
  DIST: {
    ALL: '/v1/dispatchcars',
    RETRIEVAL: '/v1/dispatchcars/code',
    CAR_TRACK_MAP: '/v1/car/location/gpss/',
    CAR_BDS_MAP: '/v1/car/location/beidous/',
    CAR_TRACK_LIST: '/v1/dispatchcars/getlocus/',
    APPLY_PAY: '/v1/offer/order',
    APPLY_BILL: '/v1/invoice',
    EXPORT:'/v1/dispatchcars/export',
    EVAl:''
  },
  PRE_DIST: {
    ALL: '/v1/orders',
    EDIT_WAYBILL: '/v1/dispatchcars/',
    GET_CODE: '/v1/orders/getordercode/',
    GET_ORDER: '/v1/orders/ordercode/',
    GET_DIST_DETAIL: '/v1/predispatchs/',
    PULL_VERHICAL: '/v1/predispatchs/transport',
    SELECT_CAR: '/v1/car/manager/company',
    GET_DISPATCHCAR: '/v1/dispatchcars/getcodecar/',
    RECEIPT_PIC: '/v1/dispatchcars/carordercode/receiptpic/',
    UNLOADING_PIC: '/v1/dispatchcars/carordercode/unloadingpic/',
    PICKUP_PIC: '/v1/dispatchcars/carordercode/pickuppic/',
    CONTACT_DRIVER: '/v1/predispatchs/createlist',
    FINISH_ORDER: '/v1/dispatchcars/updatestatus/',
    BEIDOU: '/v1/car/location/beidous/anlian',
    INDUSTRY:'/v1/orders/industry',
    HNADDRESS:'/v1/area/hn',
    TJADDRESS:'/v1/area/tj',
    JCADDRESS:'/v1/area/jc',
    QRCODE: BASE + '/generateQRC'
  },
  USER_SETTING: {
    GET_ENTERPRICE_INFO: '/v1/companys/',
    GET_STAT: '/v1/dispatchcars/getcensus/company/',
    NEW_TRANSFER: '/v1/corporate/accounts',
    GET_AMOUNT: '/v1/offer/order/loans/',
    VIRTUAL_ACCOUNT: '/v1/virtualaccount/company/',
    TRANS_CLOSE:'/v1/corporate/accounts/close/'
  },
  MAKE_INVOICE: { // 开票管理
    ALL: '/v1/invoice/search', // 开票管理列表
    INVOICE_INFO: '/v1/invoice/detail', // 开票管理详情
    SUBMIT_MATERIAL: '/v1/invoice/supplementary', // 上传补充材料
    yunshuliebiao: '/v1/dispatchcars/getlocus', // 运输列表
    timegps: '/v1/car/location/gpss', // 通过时间获取运输的gps
    tijiaotupian: '/v1/invoice/supplementary', // 重新提交图片
    zhifu: '/v1/offer/order', // 支付
    ORDERLIST: '/v1/dispatchcars'
  },
  CLOSE_AN_ACCOUNT: { // 结算管理
    TRANSACTION_DETAIL: '/v1/offer/order/detials/search', // 交易明细列表
    PAYMENT_RECORD: '/v1/offer/order/search', // 付款记录列表
    TO_PAYMENT: '/v1/offer/order', // 付款记录支付
    TO_REPULSE: '/v1/offer/order/deny', // 付款记录拒绝
    CARCODE_INFO: '/v1/dispatchcars', // 根据carcode查询详情
    ACCOUNT:'/v1/offer/order/orders/suminfo',
    BATCHSEARCH:BASE + '/v1/offer/order/import/search',
    DOWNLOAD: BASE + '/file?file=2020-08-25/'
  },
  MESSAGE: {
    WSS: 'topic',
    ALL: '/v1/message/unhandle/detail',
    COUNT: '/v1/message/unhandle/count',
    RESOLVE: '/v1/message/handle/',
    ADD:'/v1/message'
  },
  USER: {
    ALL: '/v1/users',
    COMPANY: '/v1/companys',
    RECHARGE: '/v1/offer/order/pay/virtualaccount'
  },
  ROLE: {
    ALL: '/v1/roles',
    ZONES: '/v1/zones',
    OPERATIONS: '/v1/operations'
  },
  INSURANCE: {
    ALL: '/v1/car/insurance',
    SYNC: '/v1/car/insurance/sync/',
    AREA: '/v1/car/insurance/area/',
    POLICY: '/v1/car/insurance/policy/',
    ORDER: '/v1/dispatchcars/insurance',
    EXPORTLIST:'/v1/car/insurance/policy/export',
    OffLINEALL:'/v1/car/insurance/createUnderLine'
  },
  PARTNER: {
    ALL: '/v1/companypartners',
    ALLPARTNER: '/v1/companypartners/all',
    TAXOFFICE: '/v1/extern/taxOrgan'
  },
  LOG: {
    LOGALL: '/v1/logs'
  },
  CREDIT: {
    CARSEARCH:'/v1/logink?type=1',
    CARINTERNET:'/v1/logink?type=2',
    COMSEARCH:'/v1/logink?type=3',
    DRIVERSEAECH:'/v1/logink?type=4',
  },
  SIGN:{
    VERCODEE:'/v1/mobile/verify',
    VERCODE:'/v1/mobile/message',
    EACHCON:'/v1/signature/contract', //生成，撤销，作废，退回，获取单个合同
    CONDOWNLOAD:'/v1/signature/contract/download', //合同下载
    CONIMG:'/v1/signature/contract/img', //获取合同图片
    SIGNOFFLINE:'/v1/signature/contract/offline/sign', //线下签署合同
    SIGNED:'/v1/signature/contract/sign', //签署合同
    SIGNVERFY:'/v1/signature/contract/verify', //合同验真
    IMG:'/v1/signature/contract/signImg'//获取带签署地址的二维码的合同图片
  },
  ORDER_INSUR: {
    ALL: '/v1/car/insurance', // 查询保单
    ADD: '/v1/car/insurance', // 新建,编辑保单
    AREA: '/v1/car/insurance/area', //保险投保区域获取
    IMG:'/v1/signature/contract/signImg',//获取带签署地址的二维码的合同图片
    PLATE: '/v1/signature/contract/platform' //生成司机需要签署的平台合同
  }
};
