import { Component, OnInit, ViewChild } from '@angular/core';
import { FetchService } from '../../core/services/fetch.service';
import { BASE, REQUEST } from '../../core/config/linkMap';
import { SelectionModel } from '@angular/cdk/collections';
import { POSITION_COND } from '../../core/config/pageConfig';
import { PosTableRecord, Condition, TrackInfo } from '../../shared/model/carModels';

@Component({
  selector: 'app-position-record',
  templateUrl: './position-record.component.html',
  styleUrls: ['./position-record.component.less']
})
export class PositionRecordComponent implements OnInit {

  /*  分页参数 */
  pageIndex = 1;
  pageSize = 5;
  total = 1;
  loading = false;

  /*  表格选择参数 */
  allChecked = false;
  indeterminate = false;

  isVisible = false;
  className = 'position-check';

  modalType: string;
  condition: Condition[];
  trackList: TrackInfo[];
  dataSource: any[];

  constructor(private fetchService: FetchService) {
    this.dataSource = [];
  }

  ngOnInit() {
    this.condition = POSITION_COND;
  }

  switch() {
    this.modalType = this.modalType === 'list' ? 'map' : 'list';
    this.trackList = [];
  }

  checkTrack() {
    this.modalType = 'list';
    this.trackList = [];
    this.isVisible = true;
  }

  searchData(reset: boolean = false): void {
    if (reset) {
      this.pageIndex = 1;
    }
    /* this.loading = true;
    this.fetchService.get(REQUEST.CAR_HANGUP.ALL, {}, (res) => {
      this.dataSource = res._embedded.carResourceList || [];
      this.loading = false;
      this.total = res.page.totalPages;
    }); */
  }

  currentPageDataChange($event: Array<{ name: string; age: number; address: string; checked: boolean; disabled: boolean; }>): void {
    this.dataSource = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    const allChecked = this.dataSource.every(value => value.checked === true);
    const allUnChecked = this.dataSource.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  checkAll(value: boolean): void {
    this.dataSource.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }
}
