import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router
} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(
    private oAuthService: OAuthService,
    private router: Router
  ) {
  }

  private process(): boolean {
    const res = this.oAuthService.hasValidAccessToken();
    if (!res) {
      this.router.navigateByUrl('/login');
    }
    return res;
  }

  // lazy loading
  canLoad(route: Route): boolean {
    return this.process();
  }
  // all children route
  canActivateChild(_childRoute: ActivatedRouteSnapshot): boolean {
    return this.process();
  }
  // route
  canActivate(_route: ActivatedRouteSnapshot): boolean {
    return this.process();
  }
}
