import { Injectable, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Menu } from '../../shared/model/carModels';
import { Menus } from '../config/pageConfig';

@Injectable({
  providedIn: 'root'
})
export class BreadService implements OnInit {

  navList: Menu[];
  selectMenuPath = '';
  selectedMenuId: string;

  constructor() {
  }

  ngOnInit() {
  }

  getMenu() {
    return Menus;
  }

  // 选中菜单事件处理
  selectMenuChange(selectMenuPath: string) {
    let isSelectedFlag = false;
    Menus.map((menu) => {
      menu.selected = false;
      if (!isSelectedFlag && menu.path === selectMenuPath) {
        menu.selected = true;
        isSelectedFlag = true;
      }
    });
    Menus.map((menu) => {
      if (!isSelectedFlag && menu.subMenus) {
        // 如果有子菜单而且不是一级菜单
        menu.subMenus.map((sub) => {
          sub.selected = false;
          if (sub.path === selectMenuPath) {
            sub.selected = true;
            menu.expand = true;
          }
        });
      } else if (isSelectedFlag && menu.subMenus) {
        // 如果有子菜单而且是一级菜单，默认选中第一个
        menu.subMenus[0].selected = true;
        menu.subMenus[0].expand = true;
      }
    });
  }
}
