import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.less']
})
export class PaymentComponent implements OnInit {

  @Input() payType: string;
  @Input() cardNum: string;
  @Output() nzOnCancel = new EventEmitter();

  step: string;
  amount: string;
  password: string;
  amountTipClose: boolean;
  pwdTipClose: boolean;
  amountTipMsg: string;
  pwdTipMsg: string;
  restPwdNum: number;

  constructor() {
    this.amountTipClose = true;
    this.pwdTipClose = true;
  }

  ngOnInit() {
    this.step = 'amount';
  }

  close() {
    this.nzOnCancel.emit();
  }

  amountCheck() {
    const myNumber = /^(([1-9][0-9]*)|(([0]\.\d{1,4}|[1-9][0-9]*\.\d{1,4})))$/;
    this.amountTipMsg = '';
    this.amountTipClose = true;
    if (!this.amount) {
      this.amountTipMsg = '充值金额不能为空！';
      return this.amountTipClose = false;
    }
    if (!myNumber.test(this.amount)) {
      this.amountTipMsg = '您输入的充值金额格式有误！';
      return this.amountTipClose = false;
    }
    this.step = 'password';
  }

  passwordChange(password: string) {
    this.password = password;
    this.pwdTipClose = true;
    if (!password) {
      this.pwdTipClose = false;
      this.pwdTipMsg = '您输入的密码为空或者输入的密码格式有误！';
    }
  }

  preChargeSubmit() {
    this.passwordChange(this.password);
    if (this.password) {
      //this.step = 'success';
      this.step = 'success';
      //this.step = 'failure-not-enough';
    }
  }
}
