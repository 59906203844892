import { Injectable } from '@angular/core';
import {Condition, Role, User} from '../../../shared/model/carModels';
import {FetchService} from '../../../core/services/fetch.service';
import {REQUEST} from '../../../core/config/linkMap';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  constructor(
    private fetchService: FetchService
  ) { }

  getUserForm(model: User, oldRoles: number[],  roles: {[key: string]: string}[]): Condition[] {
    return [
      {type: 1, label: '手机号', value: model.userMobile, key: 'userMobile', required: true, rules: ['isPhone'], placeholder: '请输入手机号'},
      {type: 1, label: '姓名', value: model.userName, key: 'userName', required: true, placeholder: '请输入姓名'},
      {type: 1, label: '密码', value: '', key: 'password', required: !model.userId, isHide: !!model.userId, textType: 'password', placeholder: '请输入密码'},
      {type: 1, label: '确认密码', value: '', key: 'password1', required: !model.userId, isHide: !!model.userId, textType: 'password', placeholder: '请输入确认密码'},
      {type: 2, label: '角色名称', value: oldRoles, key: 'roles', required: true, mode: 'multiple', placeholder: '请选择角色名称', option: roles},
      {
        type: 11, label: '状态', value: model.userStatus ? model.userStatus.toString() : '1', key: 'userStatus', required: true, placeholder: '请选择状态',
        option: [
          {name: '锁定', value: 2},
          {name: '正常', value: 1},
        ]
      }
    ];
  }

  /**
   * 获取用户角色
   * @param userId
   * @returns {Promise}
   */
  getUserRoles(userId: number): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.fetchService.get(`${REQUEST.USER.ALL}/${userId}/roles`, {}, (res: any) => {
        if (res._embedded) {
          const ids = (res._embedded.roleResourceList as Role[]).map((item: Role) => item.roleId.toString());
          resolve(ids);
        }
        resolve(null);
      });
    });
  }

  /**
   * 获取所有角色
   * @returns {Promise<any[]>}
   * @param type
   */
  getAllRoles(): Promise<any[]> {
    const params = {
      role_type: 3,
      page: 1,
      per_page: 100
    };
    return new Promise((resolve, reject) => {
      this.fetchService.get(`${REQUEST.ROLE.ALL}`, params, (res: any) => {
        if (res._embedded) {
          const options: { value, name }[] = [];
          (res._embedded.roleResourceList as Role[]).map((item: Role) => {
            options.push({
              name: item.roleName,
              value: item.roleId
            });
          });
          resolve(options);
        }
        resolve([]);
      });
    });
  }
}
