import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { FetchService } from '../../core/services/fetch.service';
import { REQUEST } from '../../core/config/linkMap';
import { EtcCardRecord, EtcTableRecord } from '../../shared/model/carModels';

@Component({
  selector: 'app-etc-card-modal',
  templateUrl: './etc-card-modal.component.html',
  styleUrls: ['./etc-card-modal.component.less']
})
export class EtcCardModalComponent implements OnInit {

  pageIndex = 1;
  pageSize = 5;
  total = 1;
  loading = false;

  dataSource: any[];
  @Input() curRecord: EtcTableRecord;

  constructor(private fetchService: FetchService) {
    this.dataSource = [];
  }

  ngOnInit() {
  }

  searchData(reset: boolean = false): void {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loading = true;
    this.fetchService.get(REQUEST.ETC.ALL, {}, (res) => {
      this.dataSource = res._embedded.carResourceList || [];
      this.loading = false;
      this.total = res.page.totalPages;
    });
  }
}
